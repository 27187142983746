// @flow
/**
 * Created by FeNiX on 28-Apr-16.
 */
type Middleware = {
	onChange: () => string,
	value: () => string
}

export default function linkState(prop, middleware: Middleware = {}) {
	return {
		onChange: (e) => {
			if (middleware.onChange) {
				const value = middleware.onChange(e);
				this.setState({[prop]: value});
			} else {
				this.setState({[prop]: e.target.value});
			}
		},
		//If middleware for value is set use it, else
		value: middleware.value ? middleware.value(this.state[prop]) :
			(this.state[prop] !== null && this.state[prop] !== undefined ? this.state[prop] : "")
	}
}

export function connectState(prop) {
	return {
		onBlur: (e) => {
			this.setState({[prop]: e.target.value});
		}
	}
}
export function linkCheckState(prop, middleware: Middleware = {}) {
	return {
		onChange: (e) => {
            if (middleware.onChange) {
                const value = middleware.onChange(e);
                this.setState({[prop]: value});
            } else {
                this.setState({[prop]: e.target.checked});
            }
		},
		checked: this.state[prop],
		value: this.state[prop]
	}
}

export function connectCheckState(prop) {
	return {
		onChange: ({target}) => {
			this.setState({[prop]: target.checked});
		}
	}
}

export function linkRadioState(prop, value, onchange) {
	return {
		onChange: (e) => {
			this.setState({[prop]: value});
			onchange(e);
		},
		checked: this.state[prop] === value,
		value
	}
}
