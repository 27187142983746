import moment from "moment";

const isBoolean = (test) => typeof test === "boolean";
const isDate = (test) => /^\d{4}-\d{2}-\d{2}/.test(test);
const isNumeric = (test) => /^-?\d+(\.\d+)?/.test(test);
const isString = (test) => typeof test === "string";

export const sort = ({rows = [], column = '', direction = ''}) => {
    console.log(column);
    return [...rows].sort((a, b) => {
        const first = a[column];
        const second = b[column];
        switch (true) {
            case (first === null):
                return 1;
            case (second === null):
                return -1;
            case (isBoolean(first) && isBoolean(second)):
                switch (direction) {
                    case "ASC":
                        return +first > +second;
                    case "DESC":
                        return +first < +second;
                }
                break;
            case (isDate(first) && isDate(second)):
                switch (direction) {
                    case "ASC":
                        return (moment(first) > moment(second)) ? 1 : -1;
                    case "DESC":
                        return (moment(first) < moment(second)) ? 1 : -1;
                }
                break;
            case (isNumeric(first) && isNumeric(second)):
                switch (direction) {
                    case "ASC":
                        return (parseFloat(first) > parseFloat(second)) ? 1 : -1;
                    case "DESC":
                        return (parseFloat(first) < parseFloat(second)) ? 1 : -1;
                }
                break;
            case (isString(first) && isString(second)):
                switch (direction) {
                    case "ASC":
                        return (first.toLocaleLowerCase() > second.toLocaleLowerCase()) ? 1 : -1;
                    case "DESC":
                        return (first.toLocaleLowerCase() < second.toLocaleLowerCase()) ? 1 : -1;
                }
                break;
            default:
                switch (direction) {
                    case "ASC":
                        return (first > second) ? 1 : -1;
                    case "DESC":
                        return (first < second) ? 1 : -1;
                }
                break;
        }
    });
};
