import React, {
	Component
} from 'react';
import {api} from"helper/rest";
import {Group, Input, ReadOnly} from"components/inputs";
import linkState from"helper/state";
import ajax from"helper/ajax";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";

export class FormCreateModel extends Component {

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
			marca: "",
			submarca: "",
			tipo: ""
		};
	}

	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
	}

	fill(data) {
		this.filling = true;
		this.id = data.id;
		this.setState(data);
	}

	handleDelete(e, enable) {
		AlertStore.addToQueue({
			title: 'Desea eliminar este vehículo?',
			text: 'Esta acción solo la puede revertir un administrador',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}, {
			confirm: () => {
				let success = {
					title: "Vehículo eliminado",
					text: `#${this.id}`
				};
				let error = [];
				error[0] = {
					title: "El vehículo no fue eliminado",
					text: `#${this.id}`
				};
				ajax(api.delete(`/models/${this.id}`), {action: true, success, error})
					.promise
					.then(() => {
						ModalStore.closeModal();
					})
					.then(enable,enable)
			},
			dismiss: () => {
				enable();
			}
		})
	}

	handleForm(e) {
		e.preventDefault();
		if (this.filling) {
			let success = {
				text: `Modelo ${this.id} actualizado`
			};
			ajax(api.patch(`/models/${this.id}`, this.state), {action: true, success})
		} else {
			let success = {
				text: "Modelo guardado"
			};
			ajax(api.post("/models", this.state), {action: true, success})
				.promise
				.then(() => {
					this.setState(this.initialState);
				});
		}
	}

	render() {
		return (
			<Group title={`${this.filling ? "Editar" :"Agregar"} vehículo`} handleForm={this.handleForm.bind(this)}>
				{this.filling ? (
					<ReadOnly type="text"
					          label="ID"
					          value={this.state.id}
					          grid={2}
					/>
				) : null}
				<Input type="select"
				       extra={{autoFocus: true}}
				       label="Tipo"
				       state={this.linkState("tipo")}
				       required
				       options={[
							{value: "escolar",show: "Escolar"},
							{value: "turistico",show: "Turístico"}
						]}
				       grid={[12,3]}
				/>
				<Input type="text"
				       label="Marca"
				       state={this.linkState("marca")}
				       required
				/>
				<Input type="text"
				       label="Sub Marca"
				       state={this.linkState("submarca")}
				       required
				/>
				<div className="columns small-12">
					<div className="row">
						<Input type="submit"
						       text={"Guardar"}
						       grid={true}
						       label={false}
						       button={{color: "success"}}
						/>
						{this.filling ? (
							<Input type="button"
							       text={"Eliminar"}
							       grid={6}
							       button={{color: "alert"}}
							       label={false}
							       click={this.handleDelete.bind(this)}
							/>
						) : null}
					</div>
				</div>
			</Group>
		);
	}
}
