import React from "react";
import {Link} from "react-router-dom";

export class ErrorPage extends React.Component {
	render() {
		return (
			<div className="row text-center" style={{height: "100%"}}>
				<div className="columns small-12">
					<h3>Chispas! <br/> Parece que no hay nada por aquí...</h3>
					<Link to={"/app/home"}><em>Regresar al inicio.</em></Link>
				</div>
			</div>
		);
	}
}