import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Input,ReadOnly} from "../../../components/forms/input.jsx";
import {api} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import linkState from "../../../helper/state";
import ModalStore from "../../../stores/ModalStore";
import Modal from "../../../components/modal";
import {CreditoViviendaForm} from "./creditoViviendaForm";
import DataStore from "../../../stores/DataStore";
import {HomeCreditsTable} from "../../../components/tables/homeCreditsTable";
import { Menu } from "react-data-grid-addons";
let {ContextMenu, MenuItem} = Menu;


export class CreditoVivienda extends Component {
	static propTypes = {};
	static defaultProps = {};
	static resizable = true;

	monetize({value: number}) {
		return (
			<span style={{color: number === "" ? "red" : undefined}}>
				{number !== "" ? `$${parseFloat(number).toFixed(2)}` : "N/A"}
			</span>
		);
	}

	columns = [
		{key: "id", name: "#", resizable: this.resizable, width: 50},
		{key: "date", name: "Fecha", resizable: this.resizable},
		{key: "loan_type", name: "Tipo", resizable: this.resizable},
		{key: "import", name: "Importe", resizable: this.resizable, formatter: this.monetize},
	];

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.initialState = {
			loans: [],
			fetched: false,
			operator: "",
			operators: DataStore.getModel("operators"),
			raw: [],
			payment: "0",
			loan: "0",
			balance: "0"
		};
		this.state = {...this.initialState};
		this.getOperators = this.getOperators.bind(this);
		this.updateHomeCredits = this.updateHomeCredits.bind(this);
	}

	updateHomeCredits () {
		if (this.state.fetched) {
			this.getOperator();
		}
	}

	componentWillMount() {
		DataStore.on("OPERATORS_UPDATED",this.getOperators);
	}

	componentWillUnmount() {
		DataStore.removeListener("OPERATORS_UPDATED",this.getOperators);
		if (this.channel) {
			DataStore.closeChannel(this.channel);
		}
	}


	getOperators() {
		this.setState({
			operators: DataStore.getModel("operators")
		});
	}

	getOperator(initial) {
		if (initial) {
			this.channel = `App.Operator.${this.state.operator}`;
			DataStore.getChannel({
				priv: true,
				name: this.channel
			}).listen("OperatorHomeCreditChange",this.updateHomeCredits);
		}
		ajax(api.get(`operators/${this.state.operator}`), {action: true})
			.promise
			.then((response) => {
				this.setState({
					fetched: true,
					rawLoans: response.data.home_credits,
					...response.data.home_credits_balance
				});
			})
	}

	handleSubmit(e) {
		e.preventDefault();
		this.getOperator(true);
	}

	handleUpdate(e, enable) {
		this.getOperator();
		enable();
	}

	handleClose(e, enable) {
		DataStore.closeChannel(this.channel);
		this.channel = null;
		this.setState({
			...this.initialState,
			operators: this.state.operators,
			data: []
		}, enable);
	}

	handleOpenForm(e, enable) {
		ModalStore.openModal("vivienda-form");
		enable();
	}

	render() {
		const that = this;
		class Context extends Component {
			static propTypes = {
				rowIdx: PropTypes.number
			};
			handleEdit () {
				const {rowIdx: index} = this.props;
				ModalStore.openModal("vivienda-form",that.state.rawLoans[index]);
			}
			handleDelete () {
				// that.state.rawLoans[index].id
			}
			render() {
				const {id} = this.props
				return (
					<ContextMenu id={id}>
						<MenuItem onClick={this.handleEdit.bind(this)}>Editar</MenuItem>
					</ContextMenu>
				);
			}

		}
		return (
			<div>
				<Modal id="vivienda-form" grid={[12,7,5]}>
					<CreditoViviendaForm operator={this.state.operator} max={this.state.balance}/>
				</Modal>
				<Group title={"Crédito de vivienda"} style={{marginBottom: "1em"}} handleForm={this.handleSubmit.bind(this)}>
					<Input type="select"
					       label="Operador"
					       disabled={!this.state.operators.length || this.state.fetched}
					       options={this.state.operators.map(op => ({
								show: op.nombre,
								value: op.id
							}))}
					       state={this.linkState("operator")}
					/>
					<Input type="submit"
					       text="Abrir"
					       disabled={this.state.fetched}
					       button={{color: "success"}}
					/>
					<Input type="button"
					       text={this.state.fetched ? "Actualizar" : "..."}
					       button={{color: this.state.fetched ? "success" : ""}}
					       disabled={!this.state.fetched}
					       click={this.handleUpdate.bind(this)}
					/>
					<Input type="button"
					       text={this.state.fetched ? "Cerrar" : "..."}
					       button={{color: this.state.fetched ? "alert" : ""}}
					       disabled={!this.state.fetched}
					       click={this.handleClose.bind(this)}
					/>
					<Input type="button"
					       text={this.state.fetched ? "Agregar vale" : "..."}
					       button={{color: this.state.fetched ? "warning" : ""}}
					       disabled={!this.state.fetched}
					       click={this.handleOpenForm.bind(this)}
					/>
				</Group>
				<div className="group" style={{marginBottom: "1em"}}>
					<Group title={"Historial de pagos a crédito de vivienda"} group={false}/>
					<HomeCreditsTable data={this.state.rawLoans} contextMenu={<Context/>} contextMenuId={"credito-vivienda-row"}/>
				</div>
				<Group title={"Balance"}>
					<ReadOnly type="text"
					          label="Deducciones"
					          value={`$${parseFloat(this.state.loan).toFixed(2)}`}
					/>
					<ReadOnly type="text"
					          label="Pagos"
					          value={`$${parseFloat(this.state.payment).toFixed(2)}`}
					/>
					<ReadOnly type="text"
					          label="Saldo"
					          value={`$${parseFloat(this.state.balance).toFixed(2)}`}
					/>
				</Group>
			</div>
		);
	}
}
