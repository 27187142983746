// @flow

import React, {Component} from "react";
import PropTypes from 'prop-types';
import Grid from "helper/grid";
import AgAutocomplete from "components/react-algoliasearch";
import AuthStore from "stores/AuthStore";
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';

export class Input extends Component {
    static propTypes = {
        grid: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.array,
            PropTypes.number
        ]),
        type: PropTypes.string.isRequired,
        extra: PropTypes.object,
        events: PropTypes.objectOf(PropTypes.func),
        required: PropTypes.bool,
        state: PropTypes.shape({
            value: PropTypes.any,
            onChange: PropTypes.func
        }),
        disabled: PropTypes.bool,
        click: PropTypes.func,
        className: PropTypes.string,
        options: PropTypes.array,
        label: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        readOnly: PropTypes.bool,
        rows: PropTypes.number,
        text: PropTypes.string,
        hide: PropTypes.bool,
        button: PropTypes.shape({
            size: PropTypes.oneOf(["tiny", "small", "large", ""]),
            expanded: PropTypes.bool,
            color: PropTypes.oneOf(["secondary", "success", "alert", "warning", "disabled", ""])
        }),
        placeholder: PropTypes.string,
        getInput: PropTypes.func,
        value: PropTypes.any,
        datalist: PropTypes.array,
        datalistId: PropTypes.any,
        style: PropTypes.object,
        title: PropTypes.string,
        autocomplete: PropTypes.shape({
            index: PropTypes.string.isRequired,
            displayKey: PropTypes.string.isRequired,
            keyName: PropTypes.string.isRequired,
            onSelect: PropTypes.func.isRequired
        })
    };

    static defaultProps = {
        grid: true,
        type: "text",
        required: true,
        disabled: false,
        className: "",
        label: true,
        readOnly: false,
        button: {
            size: "",
            expanded: true,
            color: "",
            perfectCenter: false
        },
        placeholder: "",
        style: {}
    };

    constructor() {
        super();
        this.state = {
            disabled: false,
            datalistHelper: {
                isHidden() {
                }
            }
        };
        this.theLabel = {};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        this.setState({disabled: true});
        //enables button
        this.props.click(e, () => {
            this.setState({disabled: false});
        });
    }

    render() {
        const {size = "", color = "", expanded = true, perfectCenter = false, hollow = false} = this.props.button;
        const button = `button ${size} ${expanded ? "expanded" : ""} ${color} ${perfectCenter ? "center-button" : ""} ${hollow ? "hollow" : ""}`;
        const className = `${Grid(this.props.grid)} ${this.props.className} ${this.props.hide ? "hide" : ""} ${this.props.isInvalid ? "is-invalid-label" : ""}`
            .trim()
            .replace(/( +)/g, " ");
        //noinspection JSUnusedGlobalSymbols
        let props;
        props = {
            placeholder: this.props.placeholder,
            readOnly: this.props.readOnly,
            required: this.props.required,
            disabled: (this.props.disabled || this.state.disabled),
            type: this.props.type,
            autoFocus: this.props.autoFocus,
            ...this.props.events,
            ...this.props.extra,
        };
        if (this.props.isInvalid) {
            props = {
                ...props,
                className: "is-invalid-input"
            }
        }
        if (this.props.state) {
            props = {
                ...props,
                ...this.props.state,
            };
        } else {
            props = {
                ...props,
                value: this.props.value,
            };
        }
        if (this.props.datalist) {
            props = {
                ...props,
                list: this.props.datalistId || "datalist"
            };
        }
        if (this.props.getInput) {
            props.ref = this.props.getInput;
        }

        let requestedInput;
        let options;
        let datalist;
        if (this.props.datalist) {
            datalist = (
                <datalist id={this.props.datalistId || "datalist"}>
                    {this.props.datalist.map((opt, i) => (
                        <option key={i} value={opt}/>
                    ))}
                </datalist>
            )
        }
        switch (this.props.type) {
            case "select":
                if (this.props.options) {
                    options = this.props.options.map((opt, i) => (
                        <option key={i}
                                value={opt.value}
                                disabled={opt.disabled}>
                            {opt.show}
                        </option>
                    ));
                }
                requestedInput = (
                    <select value="" {...props}>
                        <option disabled={true} value=""> --- Selecciona ---</option>
                        {options}
                    </select>
                );
                break;
            case "select:multiple":
                requestedInput = (
                    <ReactSelect {...props} options={this.props.options} multi={true}/>
                );
                break;
            case "textarea":
                requestedInput = (
                    <textarea {...props} rows={this.props.rows}/>
                );
                break;
            case "autocomplete":
                requestedInput = (
                    <AgAutocomplete
                        apiKey={AuthStore.getUser().credentials.ALGOLIA_CLIENT_KEY}
                        appId={AuthStore.getUser().credentials.ALGOLIA_APP_ID}
                        indices={[{index: this.props.autocomplete.index}]}
                        displayKey={this.props.autocomplete.displayKey}
                        keyName={this.props.autocomplete.keyName}
                        placeholder=" "
                        inputId="input-search"
                        selected={this.props.autocomplete.onSelect}
                        otherProps={{
                            ...props,
                            type: "text",
                        }}
                    />
                );
                break;
            case "button":
            case "submit":
                if (this.props.click) {
                    requestedInput = (
                        <button onClick={this.handleClick} {...props} className={button}>
                            {this.props.value ? this.props.value : this.props.text}
                        </button>
                    );
                } else {
                    requestedInput = (
                        <button {...props} className={button}>
                            {this.props.value ? this.props.value : this.props.text}
                        </button>
                    );
                }
                break;
            default:
                requestedInput = (
                    <input  {...props}/>
                );
                break;
        }
        let label;
        switch (this.props.label) {
            case false:
                label = "";
                break;
            case true:
                label = " ";
                break;
            default:
                label = this.props.required ? `${this.props.label}*` : this.props.label;
                break;
        }
        return (
            <label className={className} style={this.props.style}>
                <b>{label}</b>
                {requestedInput}
                {this.props.errorMessage ? (
                    <span
                        className={`form-error ${this.props.isInvalid ? "is-visible" : ""}`}>{this.props.errorMessage}</span>
                ) : null}
                {datalist}
            </label>
        );
    }
}

export class ReadOnly extends Component {
    static propTypes = Input.propTypes;

    render() {
        const {extra = {}} = this.props;
        const props = {
            ...this.props,
            readOnly: true,
            extra: {
                ...extra,
                title: this.props.value ? this.props.value : (this.props.state ? this.props.state.value : "")
            }
        };
        return <Input {...props}/>
    }
}
