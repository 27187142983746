import React, {
	Component,
} from 'react';

import * as AuthActions from "actions/AuthActions";

export class Logout extends Component {
	static propTypes = {};
	static defaultProps = {};
	
	componentWillMount() {
		AuthActions.exit();
	}


	render() {
		return (
			<div className="row align-center align-middle">
				<div className="columns">
					<h2>Desconectando...</h2>
				</div>
			</div>
		)
	}
}
