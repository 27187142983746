import React, {Component} from "react";
import Modal from "components/modal";
import Group from "components/forms/group";
import {Input} from "components/forms/input";
import ModalStore from "stores/ModalStore";
import {generateUrl} from "helper/rest";
import linkState from "helper/state";
import LoadingStore from "stores/LoadingStore";
import 'scss/app/reportView.scss'

export class ReportView extends Component {
    constructor () {
        super();
        this.linkState = linkState.bind(this);
        this.state = {
            format: 'html',
        }
    }
    getUrl () {
        return generateUrl(ModalStore.getData(),this.state.format)
    }
    componentDidMount () {
        this.startLoading = () => {
            LoadingStore.startLoading();
        };
        this.stopLoading = () => {
            LoadingStore.stopLoading();
        };
        if (this._reportView) {
            this._reportView.addEventListener('loadstart', this.startLoading);
            this._reportView.addEventListener('loadend', this.stopLoading)
        }
    }
    componentWillUnmount () {
        if (this._reportView) {
            this._reportView.removeEventListener('loadstart', this.startLoading);
            this._reportView.removeEventListener('loadend', this.stopLoading)
        }
    }
    openWindow () {
        window.open(this.getUrl());
    }
    print () {
       //
        this._reportView.contentWindow.print()
    }
    download () {
        const url = this.getUrl() + '&download=1';
        window.location.assign(url);
    }
    render () {
        const options = [
            {show: 'HTML', value: 'html'},
            {show: 'PDF', value: 'pdf'}
        ];
        return (
            <Modal id={'report-view'} grid={[12,8, 6]}>
                <Group title={'Vista de reporte'} margin={true}>
                    <Input type={'select'} options={options} state={this.linkState('format')} label={'Formato'}/>
                    <Input type={'button'} text={'Abrir en ventana'} extra={{onClick: () => this.openWindow()}}/>
                    <Input type={'button'} text={'Descargar'} disabled={this.state.format === 'html'} extra={{onClick: () => this.download()}}/>
                    <Input type={'button'} text={'Imprimir'} disabled={this.state.format === 'image'} extra={{onClick: () => this.print()}}/>
                    <Input type={'button'} text={'Guardar reporte'} disabled={true}/>
                </Group>
                <iframe src={this.getUrl()} frameBorder="0" className={'report-view'} ref={(ref) => this._reportView = ref} title="reportReview"/>
            </Modal>
        )
    }
}
