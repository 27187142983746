import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import DataGrid from "react-data-grid";
import date from "helper/date";

export class HomeCreditsTable extends Component {
	static propTypes = {
		data: PropTypes.array.isRequired,
		contextMenu: PropTypes.element,
		contextMenuId: PropTypes.string
	};

	static defaultProps = {
		data: []
	};

	static resizable = true;

	monetizeWithColor({value: {number, payment}}) {
		return (
			<span style={{color: payment ? "green" : "red"}}>
				{`$${parseFloat(number).toFixed(2)}`}
			</span>
		);
	}

	monetize({value: number}) {
		return (
			<span>
				{`$${parseFloat(number).toFixed(2)}`}
			</span>
		);
	}

	columns = [
		{key: "id", name: "#", resizable: this.resizable, width: 50},
		{key: "date", name: "Fecha", resizable: this.resizable},
		{key: "type", name: "Tipo", resizable: this.resizable},
		{key: "import", name: "Importe", resizable: this.resizable, formatter: this.monetizeWithColor},
		{key: "balance", name: "Saldo", resizable: this.resizable, formatter: this.monetize},
	];

	render() {
		let data = [];
		this.props.data.reduce((carrie,loan) => {
			const balance = parseFloat(loan.is_payment ? -loan.import : loan.import) + parseFloat(carrie);
			data.push({
				id: loan.id,
				date: date(loan.date),
				type: loan.is_payment ? "Pago" : "Deducción",
				import: {
					number: loan.import,
					payment: loan.is_payment
				},
				balance
			});
			return balance;
		},0);
		return (
			<DataGrid columns={this.columns}
			          rowGetter={(i)=>data[i]}
			          rowsCount={data.length}
			          minHeight={500 - 115 - 80}
			          rowKey="id"
			          contextMenu={this.props.contextMenu}
			          contextMenuId={this.props.contextMenuId}
			/>
		);
	}
}
