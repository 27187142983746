import React, {Component} from 'react';
import {Catalog} from "components/catalog/catalog";
import {FormClient} from "./formClient";

export class Clientes extends Component {
	render() {
		return (
			<Catalog model={"clients"}
			         name={"Clientes"}
			         singular={"Cliente"}
			         keys={["id","nombre"]}
			         columns={[
		                {key: "id", name: "Numero cliente"},
		                {key: "nombre", name: "Nombre"},
		                {key: "telefono", name: "Teléfono"},
		                {key: "rfc", name: "RFC"},
		                {key: "calle", name: "Calle"},
		                {key: "colonia", name: "Colonia"},
		                {key: "delegacion", name: "Delegación"},
		                {key: "codigo_postal", name: "Código postal"},
		                {key: "email", name: "Email"},
	                 ]}
			>
				<FormClient/>
			</Catalog>
		);
	}
}
