import React, {Component} from "react";
import PropTypes from "prop-types"
import ArrowRight from "components/svg/arrowRight";
import ArrowDown from "components/svg/arrowDown";
import {NavLink} from 'react-router-dom';

export class Sub extends Component {
	static contextTypes = {
		switchNav: PropTypes.func.isRequired
	};

	static propTypes = {
		to: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		clickable: PropTypes.bool.isRequired
	};

	static defaultProps = {
		clickable: true
	};

	constructor() {
		super();
		this.state = {
			open: false
		};
		this.toggleRender = this.toggleRender.bind(this);
	}

	toggleRender() {
		this.setState({
			open: !this.state.open
		});
	}


	render() {
		const {to, name, clickable} = this.props;
		const link = to && clickable === true ? (
			<NavLink to={to}
			      activeClassName="current-menu"
			>
				{name}
			</NavLink>
		) : (
			<span>
				{name}
			</span>
		);
		const childrens = (
			<ul className="nav-item-children">
				{this.props.children}
			</ul>
		);
		const svg = (
			<div className="nav-item-icon"
			     onClick={this.toggleRender}
			>
				{this.state.open ? <ArrowDown/> : <ArrowRight/>}
			</div>
		);
		return (
			<li className="nav-container">
				<div className={"nav-item " + (to && clickable ? "" : "no-click")}>
					<div className="nav-item-text">
						<span className="link">{link}</span>
					</div>
					{this.props.children ? svg : ""}
				</div>
				{this.state.open ? childrens : null}
			</li>
		);
	}
}
