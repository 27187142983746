import React, {
	Component
} from 'react';

import {Catalog} from"components/catalog/catalog";
import {CatalogForm} from"components/catalog/catalogForm";
import date from"helper/date";

export class TiposGasto extends Component {
	static propTypes = {};
	static defaultProps = {};

	render() {
		return (
			<Catalog columns={[
				{key: "type",name: "Tipo"},
				{key: "created_at", name: "Creado", formatter: ({value}) => <span>{date(value)}</span>},
				{key: "updated_at", name: "Actualizado", formatter: ({value}) => <span>{date(value)}</span>}
				]}
			         keys={["type"]}
			         singular={"Tipo de gasto"}
			         model={"expense_types"}
			         name={"Tipos de gasto"}>
				<CatalogForm inputs={[
					{type: "text", state: "type", label: "Tipo", grid: [12]}
				]}/>
			</Catalog>
		);
	}
}
