// fetch the token
import {api, pdfUrl} from "./rest";
import ajax from "./ajax";
import AlertStore from "stores/AlertStore";
import ModalStore from "stores/ModalStore";
// for now ony handles V2
export const generate = (data, enable) => {
    ajax(api.post("pdf/generate/v2", data), {action: true})
        .promise
        .then(response => ModalStore.openModal('report-view', response.data.token))
        .catch(() => {
            AlertStore.addToQueue({
                title: "Ha ocurrido un error",
                text: "La petición para el documento no ha procedido correctamente",
                type: "error"
            });
        })
        .then(enable, enable);
};

// show a pdf in new window
// 1 - On the same handler open a window
// 2 - Once the data is ready, attach the data to the window created before
// -- Alternative
// 1 - Fetch the token for the pdf
// 2 - Set the location of the new window with the provided token

// show an html in app window
// 1 - Get the data
// 2 - Create the in app window and load the data into iframe
// -- Optional
// 1 - Load the url in the iframe
// 2 - Wait for the onready event and show the page
// 2.1 - In the meantime show the loading component

// To showcase a report
// Loading starts
// Fetch the token
// Token is ready
// Loading stops
// Ask the user what they want
// Inline (Button for open full page) | Inline + Print | Full Page | Download pdf | View PDF (if supported, or fallback) | Image | Save for later

// Inline
// Create url
// Set url in iframe on floating page

// Inline + Print
// Inline but run window.print() on page

// Full page
// Open window with created url

// Download pdf
// Create url with pdf requested and set the link as download

// View PDF
// Create url with pdf requested and open new window with that url

// Image
// Create url with img requested and open new window with that url

// Save for later
// Make request to save the content of the report in the storage and show it on the user page with name and the same options, Allow delete

//Report endpoints
// Recaudacion
// Contrato
// Ordenes
// Roll
// Cuentas por cobrar
// Cuentas por pagar
// Pagos de servicios
// Nomina
// Ruta
// Ventas
