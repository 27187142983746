import React, {
	Component
} from 'react';

import {api} from"helper/rest";
import {Group, Input, ReadOnly} from"components/inputs";
import linkState from"helper/state";
import ajax from"helper/ajax";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";

export class FormSocios extends Component {
	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
			name: "",
		};
	}

	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
	}

	fill(data) {
		this.initialState = data;
		this.filling = true;
		this.id = data.id;
		this.setState(data);
	}

	handleDelete(e, enable) {
		AlertStore.addToQueue({
			title: this.state.deleted_at ? 'Desea eliminar <b>permanentemente</b> este socio?' : 'Desea eliminar este socio?',
			text: this.state.deleted_at ? "Esta acción <b>NO</b> puede ser revertida." : 'Esta acción solo la puede revertir un administrador.',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}, {
			confirm: () => {
				let error = [];
				error[0] = {
					title: `El socio <b>#${this.id}</b> no pudo ser eliminado`,
					text: "Ha ocurrido un error, favor de reportarlo"
				};
				let url = this.state.deleted_at ? `/partners/fulminate/${this.id}` : `/partners/${this.id}`;
				ajax(api.delete(url), {action: true, error})
					.promise
					.then((response) => {
						if (response.data.deleted) {
							AlertStore.addToQueue({
								text: `El socio <b>#${this.id}</b> ha sido eliminado`,
								type: "success"
							});
							ModalStore.closeModal();
						} else {
							AlertStore.addToQueue({
								text: `El socio <b>#${this.id}</b> no ha sido eliminado`,
								type: "info"
							});
						}
					}, () => {
						return true;
					})
					.then(() => {
						enable();
					})
			},
			dismiss: () => {
				enable();
			}
		})
	}

	handleRestore(e, enable) {
		let error = [];
		error[0] = {
			text: `El socio <b>#${this.id}</b> <em>no</em> se ha restaurado`
		};
		let success = {
			text: `El socio <b>#${this.id}</b> se ha restaurado`
		};
		ajax(api.patch(`/partners/restore/${this.id}`, this.state), {action: true, success, error})
			.promise
			.then(() => {
				ajax(api.get(`/partners/${this.id}`), {action: true})
					.promise
					.then((response) => {
						this.initialState = response.data;
					    this.setState(response.data);
					});
			})
			.then(enable, enable)
	}

	handleForm(e) {
		e.preventDefault();
		let error = [];
		error[422] = {
			text: `El nombre <b>${this.state.name}</b> ya está ocupado por otro socio`
		};
		if (this.filling) {
			let success = {
				text: `Socio <b>#${this.id}</b> actualizado`
			};
			ajax(api.patch(`/partners/${this.id}`, this.state), {action: true, success, error})
				.promise
				.then((response) => {
					if (!response.data.updated) {
						AlertStore.addToQueue({
							text: "No se realizo ningún cambio",
							type: "info"
						});
					}
					ModalStore.hasChanged(false);
					this.initialState = this.state;
				});
		} else {
			let success = {
				text: "Socio guardado"
			};
			ajax(api.post("/partners", this.state), {action: true, success, error})
				.promise
				.then(() => {
					this.setState(this.initialState, () => {
						ModalStore.hasChanged(false);
					});
				})
		}
	}

	render() {
		return (
			<Group title={`${this.filling ? "Editar" :"Agregar"} socio`} handleForm={this.handleForm.bind(this)}>
				{this.filling ? (
					<ReadOnly type="text"
					          label="ID"
					          value={this.state.id}
					          grid={[3,2]}
					/>
				) : null}
				<Input type="text"
				       label="Nombre"
				       state={this.linkState("name")}
				       extra={{autoFocus: true}}
				       required
				       disabled={this.filling && this.state.deleted_at}
				/>
				<div className="columns small-12">
					<div className="row">
						<Input type="submit"
						       text={"Guardar"}
						       grid={true}
						       button={{color: "success"}}
						       label={false}
						       disabled={this.filling && this.state.deleted_at}
						/>
						{this.filling ? (
							<Input type="button"
							       text={`Eliminar${this.state.deleted_at ? " (Permanente)" : ""}`}
							       grid={true}
							       button={{color: "alert"}}
							       label={false}
							       click={this.handleDelete.bind(this)}
							/>
						) : null}
						{this.filling && this.state.deleted_at ? (
							<Input type="button"
							       text="Restaurar"
							       grid={true}
							       button={{color: "warning"}}
							       label={false}
							       click={this.handleRestore.bind(this)}
							/>
						) : null}
					</div>
				</div>
			</Group>
		);
	}
}
