export const exit = () => {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.msExitFullscreen) {
		document.msExitFullscreen();
	}
};

export const open = (element) => {
	if (element.requestFullscreen) {
		element.requestFullscreen();
	} else if (element.mozRequestFullScreen) {
		element.mozRequestFullScreen();
	} else if (element.webkitRequestFullScreen) {
		element.webkitRequestFullScreen();
	} else if (element.msRequestFullscreen) {
		element.msRequestFullscreen();
	}
};

export const check = () => {
	const variables = [
		"webkitIsFullScreen",
		"mozFullScreen",
		"msFullscreenElement"
	];
	return variables.find(v => document[v]);
};

export const listenChange = (cb) => {
	[
		"webkitfullscreenchange",
		"mozfullscreenchange",
		"fullscreenchange",
		"MSFullscreenChange"
	].forEach((event) => {
		document.addEventListener(event, cb);
	});
};

export const removeListener = (cb) => {
	[
		"webkitfullscreenchange",
		"mozfullscreenchange",
		"fullscreenchange",
		"MSFullscreenChange"
	].forEach((event) => {
		document.removeEventListener(event, cb);
	});
};