import React, {
	Component
} from 'react';

import Group from"components/forms/group";
import {Input, ReadOnly} from"components/forms/input.jsx";
import {api} from"helper/rest";
import ajax from"helper/ajax";
import linkState from"helper/state";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";

export class UsuariosForm extends Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
			name: "",
			email: "",
			password: "",
			passwordRepeat: "",
			birthday: "",
		};
		function debounce(func, wait, immediate) {
			var timeout;
			return function () {
				var context = this, args = arguments;
				var later = function () {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		}
		this.requests = [];
		this.emailInUse = debounce(this.emailInUse.bind(this), 500);
	}

	addRequest(request) {
		let index = this.requests.push(request) - 1;
		return this.requests[index];
	}

	componentWillMount() {
		if (ModalStore.getData()) {
			let data = ModalStore.getData();
			this.filling = true;
			this.id = data.id;
			this.setState({
				name: data.name,
				email: data.email
			});
		}
	}


	componentWillUpdate(nextProps, nextState) {
		//email is valid
		if (this.email.checkValidity()) {
			//email has changed
			if (this.state.email !== nextState.email && nextState.email !== "") {
				this.email.setCustomValidity("");
				this.emailInUse(nextState.email);
			}
		} else {
			this.email.setCustomValidity("");
			if (nextState.email !== "") {
				this.emailInUse(nextState.email);
			}
		}
		if (nextState.password !== nextState.passwordRepeat) {
			this.password.setCustomValidity("La contraseña no coincide")
		} else {
			this.password.setCustomValidity("")
		}
	}


	componentWillUnmount() {
		this.requests.forEach(cancelable => cancelable.cancel());
	}

	emailInUse(email) {
		// this.addRequest(ajax(api.get("users", {
		// 	params: {
		// 		search: "email",
		// 		email
		// 	}
		// })))
		// 	.promise
		// 	.then(({data}) => {
		// 		if (data) {
		// 			this.email.setCustomValidity("El correo ya esta en uso por otro usuario");
		// 			this.setState({});
		// 		} else {
		// 			this.email.setCustomValidity("");
		// 		}
		// 	})
		// 	.catch(() => {
		// 		this.email.setCustomValidity("El correo es invalido");
		// 		this.setState({});
		// 	})
	}

	handleDelete(e,enable) {
		AlertStore.addToQueue({
			title: 'Desea eliminar este usuario?',
			text: 'Esta acción es definitiva',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}, {
			confirm: () => {
				ajax(api.delete(`users/${this.id}`), {action: true, success: {
					title: "Usuario eliminado",
					text: `#${this.id}`
				}, error: {
					[403]: {
						title: "El usuario no puede ser eliminado",
						text: `#${this.id}`
					}
				}})
					.promise
					.then(() => {
						ModalStore.closeModal();
					})
					.catch(enable);
			}
		});
	}

	handleSubmit(e) {
		e.preventDefault();
		const {email, password, name} = this.state;
		let data = {
			email,
			name,
			password
		};
		if (this.filling) {
			if (password === "") {
				delete data.password
			}
			ajax(api.patch(`users/${this.id}`, data), {
				action: true, success: {
					text: "Usuario actualizado"
				}
			})
		} else {
			ajax(api.post("users", data), {
				action: true, success: {
					text: "Usuario guardado"
				}
			})
				.promise
				.then(() => {
					this.setState({...this.initialState});
				})
		}
	}


	render() {
		const passwordDontMatch = (this.state.password && this.state.passwordRepeat) && (this.state.password !== this.state.passwordRepeat);
		//email exists ?
		//is not valid?
		//otherwise false
		const emailError = this.email ? this.email.validity.customError : false;
		return (
			<Group title={`${this.filling ? "Editar" : "Agregar"} usuario`} handleForm={this.handleSubmit.bind(this)}  className={"limit-height"}>
				{this.filling ? (
					<ReadOnly type="text"
					          grid={[12,2]}
					          value={this.id}
					          label="ID"
					/>
				) : null}
				<Input type="text"
				       label="Nombre"
				       state={this.linkState("name")}
				       grid={[12,this.filling ? 10 : 12]}
				/>
				<Input type="email"
				       label="Email"
				       state={this.linkState("email")}
				       inUse={true}
				       grid={[12,12]}
				       isInvalid={emailError}
				       getInput={(ref) => this.email = ref}
				/>
				<Input type="password"
				       label="Contraseña"
				       state={this.linkState("password")}
				       required={!this.filling}
				       extra={{min: 6}}
				       grid={[12,12]}
				/>
				<Input type="password"
				       label="Repetir contraseña"
				       isInvalid={passwordDontMatch}
				       state={this.linkState("passwordRepeat")}
				       getInput={(ref) => this.password = ref}
				       required={!this.filling}
				       extra={{min: 6}}
				       grid={[12,12]}
				/>
				<Input type="submit"
				       grid={[12,true]}
				       button={{color: "success"}}
				       text="Guardar"
				/>
				{this.filling ? (
					<Input type="button"
					       grid={[12,true]}
					       button={{color: "alert"}}
					       text="Eliminar"
					       click={this.handleDelete.bind(this)}
					/>
				) : null}
			</Group>
		);
	}
}
