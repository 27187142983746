import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from"components/forms/group";
import {Input} from"components/forms/input.jsx";
import {api} from"helper/rest";
import ajax from"helper/ajax";
import linkState from"helper/state";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";
import Modal from"components/modal";
import DataGrid from "react-data-grid";
import Fuse from "fuse.js";
import {UsuariosForm} from "./usuariosForm";
import {InvitacionForm} from "./invitacionForm";
import DataStore from"stores/DataStore";
import date from"helper/date";
import { Menu } from "react-data-grid-addons";
let {ContextMenu, MenuItem} = Menu;

export class Usuarios extends Component {
	static propTypes = {};
	static defaultProps = {};
	resizable = true;
	columns = [
		{key: "id", name: "ID", resizable: this.resizable, width: 50},
		{key: "name", name: "Nombre", resizable: this.resizable},
		{key: "email", name: "Email", resizable: this.resizable}
	];

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
			search: "",
			users: DataStore.getModel("users"),
		};
		this.update = this.update.bind(this);
	}

	update () {
		this.setState({
			users: DataStore.getModel("users")
		});
	}

	componentWillMount() {
		DataStore.on("USERS_UPDATED", this.update);
	}

	componentWillUnmount() {
		DataStore.removeListener("USERS_UPDATED", this.update);
	}

	render() {
		let users = this.state.users.map(user => ({
			id: user.id,
			name: user.name,
			email: user.email,
			created: date(user.created_at),
			updated: date(user.updated_at),
		}));

		if (this.state.search) {
			let fuse = new Fuse(users,{
				keys: ['name', 'email']
			});
			users = fuse.search(this.state.search)
		}



		const parent = this;

		class Context extends Component {
			static propTypes = {
				rowIdx: PropTypes.number
			};

			handleEdit() {
				const {rowIdx: index} = this.props;
				ModalStore.openModal("user-form", parent.state.users.find(user => user.id === parseInt(users[index].id)));
			}

			handleDelete() {
				AlertStore.addToQueue({
					title: 'Desea eliminar este usuario?',
					text: 'Esta acción es definitiva',
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: "#da3116",
					confirmButtonText: 'Eliminar',
					cancelButtonText: 'Cancelar',
				}, {
					confirm: () => {
						const {rowIdx: index} = this.props;
						ajax(api.delete(`users/${users[index].id}`), {action: true, success: {
							title: "Usuario eliminado",
								text: `#${users[index].id}`
						}, error: {
							[403]: {
								title: "El usuario no puede ser eliminado",
								text: `#${users[index].id}`
							}
						}})
					}
				});
			}

			handleReactivate () {

			}

			render() {
				const {rowIdx: index, id} = this.props;
				let find = false;
				if (index !== -1 && users[index]) {
					find = parent.state.users.find(user => user.id == users[index].id);
				}
				if (find && find.deleted_at) {
					return (
						<ContextMenu id={id}>
							<MenuItem onClick={this.handleEdit.bind(this)}>Re-activar</MenuItem>
						</ContextMenu>
					);
				} else {
					return (
						<ContextMenu id={id}>
							<MenuItem onClick={this.handleEdit.bind(this)}>Editar</MenuItem>
							<MenuItem onClick={this.handleDelete.bind(this)}>Eliminar</MenuItem>
						</ContextMenu>
					);
				}
			}

		}
		return (
			<div>
				<Group title={"Catalogo de usuarios"} margin>
					<Input type="text"
					       state={this.linkState("search")}
					       label={"Buscar"}
						   autoFocus={true}
					/>
					<Input type="button"
					       button={{color: "success"}}
					       text={"Invitar usuario"}
					       events={{onClick: () => ModalStore.openModal("invitacion-form")}}
					/>
					<Input type="button"
					       button={{color: "warning"}}
					       text={"Agregar usuario"}
					       events={{onClick: () => ModalStore.openModal("user-form")}}
					/>
				</Group>

				<div className="group with-margin">
					<Group title={"Usuarios"} group={false}/>
					<DataGrid columns={this.columns}
					          rowGetter={(i)=>users[i]}
					          rowsCount={users.length}
					          minHeight={500}
					          rowKey="id"
					          contextMenu={<Context/>}
										contextMenuId="usuarios-row"
					/>
				</div>

				<Modal id={"user-form"} grid={[12,6,4]}>
					<UsuariosForm/>
				</Modal>

				<Modal id={"invitacion-form"} grid={[12,6,6]}>
					<InvitacionForm/>
				</Modal>
			</div>
		);
	}
}
