import React, {
  Component
} from 'react'

import Group from 'components/forms/group'
import { Input, ReadOnly } from 'components/forms/input.jsx'
import { api } from 'helper/rest'
import ajax from 'helper/ajax'
import linkState from 'helper/state'
import DataStore from 'stores/DataStore'
import { Table } from 'components/table/table'
import { date, money, getMoney, boolean, checkbox } from 'helper/formatters'
import AlertStore from 'stores/AlertStore'
import { sort } from 'helper/sort'
import { TODAY } from 'helper/const'
import { watch } from 'helper/watch'
import { generate } from 'helper/print'

export class Pagar extends Component {
  static propTypes = {}
  static defaultProps = {}

  constructor (props, context) {
    super(props, context)
    this.linkState = linkState.bind(this)
    this.state = this.initialState = {
      partner: '',
      bus: '',
      from: '',
      to: '',
      filter: '0',
      client: '',
      client_search: '',
      factura: '-1',
      numero_factura: '',
      pago: '-1',
      liquidated: '1',
      clients: DataStore.getModel('clients'),
      partners: DataStore.getModel('partners'),
      buses: DataStore.getModel('buses'),
      sort: null,
      data: null,
      selected: []
    }
  }

  handleClean (e, enable) {
    enable()
    const {partners} = this.state
    this.setState({
      ...this.initialState,
      partners
    })
  }

  updatePartners () {
    this.setState({
      partners: DataStore.getModel('partners')
    })
  }

  updateBuses () {
    this.setState({
      buses: DataStore.getModel('buses')
    })
  }

  componentWillMount () {
    DataStore.on('ORDERS_UPDATED', this.handleSearch.bind(this))
    DataStore.on('PARTNERS_UPDATED', this.updatePartners.bind(this))
    DataStore.on('BUSES_UPDATED', this.updateBuses.bind(this))
    DataStore.getChannel({
      priv: true,
      name: 'App.Order.0'
    })
      .listen('OrderOrderPaymentChange', () => {
        this.handleSearch()
      })
  }

  componentWillUnmount () {
    DataStore.closeChannel('App.Order.0')
    DataStore.removeListener('PARTNERS_UPDATED', this.updatePartners.bind(this))
    DataStore.removeListener('ORDERS_UPDATED', this.handleSearch.bind(this))
    DataStore.removeListener('BUSES_UPDATED', this.updateBuses.bind(this))
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.data !== null) {
      watch({
        previous: prevState,
        current: this.state,
        callback: () => {
          this.handleSearch()
          this.setState({
            selected: []
          })
        },
        watchList: [
          'pago',
          'factura',
          'partner',
          'liquidated',
          'bus',
          'client'
        ]
      })
    }
  }

  handleGridSort (column, direction) {
    if (direction !== 'NONE') {
      this.setState({
        ...this.state,
        sort: {
          column,
          direction
        }
      })
    } else {
      this.setState({
        sort: null
      })
    }
  }

  getReport (e, enable) {
    const ids = this.rows.map(({service}) => service)
    const {from, to} = this
    const data = {
      ids,
      report: 'cuentas_pagar',
      factura: this.state.factura !== '-1' ? this.state.factura : null,
      pago: this.state.pago !== '-1' ? this.state.pago : null,
      partner: this.state.filter === '0' ? null : this.state.partner,
      from,
      to,
      numero_factura: this.state.numero_factura,
    }
    generate(data, enable)
  }

  getSelectionReport (e, enable) {
    const ids = this.state.selected;
    const {from, to} = this;
    const data = {
      ids,
      report: 'cuentas_pagar',
      factura: this.state.factura !== '-1' ? this.state.factura : null,
      pago: this.state.pago !== '-1' ? this.state.pago : null,
      partner: this.state.filter === '0' ? null : this.state.partner,
      from,
      to,
      numero_factura: this.state.numero_factura,
    }
    generate(data, enable)
  }

  onRowsSelected (rows) {
    if (this.state.liquidated !== '1') {
      AlertStore.addToQueue({
        type: 'info',
        text: 'No es posible seleccionar folios si el filtro <b>Liquidado a empresa</b> no esta fijado en <b>Liquidados</b>'
      })
      return
    }
    const ids = rows.map(({row: {service}}) => service)
    this.setState({
      selected: this.state.selected.concat(ids)
    })
  }

  onRowsDeselected (rows) {
    const ids = rows.map(({row: {service}}) => service)
    this.setState({
      selected: this.state.selected.filter(index => ids.indexOf(index) === -1)
    })
  }

  markAs (status, e, enable) {
    let ids = this.state.selected
    AlertStore.addToQueue({
      title: 'Seleccionar fecha de confirmación de pago(s)',
      text: `<input type="date" required="required" value="${TODAY}" id="paymentDate" style="width: 50%; margin: 0 auto;">`,
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#3adb76',
      preConfirm: function () {
        if (document.querySelector('#paymentDate').reportValidity()) {
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      }
    }, {
      confirm: () => {
        ajax(api.post('/service/paid_status', {
          ids,
          status,
          date: document.querySelector('#paymentDate').value
        }), {action: true}, true)
          .then(() => {
            this.handleSearch()
            this.setState({
              selected: []
            })
          })
          .then(enable, enable)
      }
    })
  }

  handleRowUpdate (e) {
    const {rowIdx, updated: {paid_value}, key} = e
    const {service} = this.rows[rowIdx]
    if (key !== 'Enter') {
      return
    }
    console.log({paid_value})
    switch (true) {
      case paid_value === '':
      case paid_value === '0':
        ajax(api.patch(`service/${service}/paid_value`, {
          import: null
        }), {action: true}, true)
          .then(() => {
            this.handleSearch()
          })
        break
      case /^\d+(\.\d+)?$/.test(paid_value):
        ajax(api.patch(`service/${service}/paid_value`, {
          import: paid_value
        }), {action: true}, true)
          .then(() => {
            this.handleSearch()
          })
        break
      default:
        //hack to prevent dismiss with enter event
        setTimeout(() => {
          AlertStore.addToQueue({
            text: 'El valor introducido debe ser un <em>numero positivo</em>, opcionalmente con decimales o estar vacío',
            type: 'info'
          })
        }, 0)
        break
    }
  }

  handleSearch (e) {
    if (e) {
      e.preventDefault()
    }

    const {from, to, pago, factura, partner, filter, liquidated, bus, client, numero_factura} = this.state

    let params = {
      from,
      to,
      client
    }

    if (filter === '1' || filter === '2') {
      params = {
        ...params,
        partner
      }
    }

    if (filter === '3') {
      params = {
        ...params,
        filter: 'autobus',
        bus_id: bus
      }
    }

    if (filter === '4') {
      params = {
        ...params,
        filter: 'numero_factura',
        numero_factura: numero_factura
      }

      if (partner) {
        params = {
          ...params,
          partner
        }
      }
    }

    if (pago !== '-1') {
      params = {
        ...params,
        pago
      }
    }

    if (factura !== '-1') {
      params = {
        ...params,
        factura
      }
    }

    if (liquidated !== '-1') {
      params = {
        ...params,
        liquidated
      }
    }

    ajax(api.get(`service`, {
      params
    }), {action: true}, true)
      .then(({data}) => {
        console.log(data);
        this.from = from
        this.to = to
        this.setState({
          data
        })
      })
  }

  render () {
    let columns = [
      {key: 'salida', name: 'Fecha', formatter: date, width: 120},
      {key: 'cliente', name: 'Cliente', resizable: true, width: 250},
      {key: 'destino', name: 'Destino', resizable: true, width: 250},
      {key: 'bus', name: 'Autobús', resizable: true, width: 120},
      {key: 'operator', name: 'Operador', resizable: true, width: 120},
      {key: 'id', name: 'Folio', width: 80},
      {key: 'service', name: 'No. viaje', width: 100},
      {key: 'con_factura', name: 'Factura', width: 90, formatter: boolean},
      {key: 'costo_unitario', name: 'Valor', formatter: money, width: 100},
      {key: 'costo_unitario_impuesto', name: 'Impuesto', formatter: money, width: 100},
      {key: 'costo_unitario_total', name: 'Total', formatter: money, width: 100},
      {key: 'balance', name: 'Balance de orden', formatter: money, width: 170},
      {
        key: 'paid_value',
        name: 'Pago a proveedor',
        formatter: ({value}) => value ? getMoney(value) : <span>N/A</span>,
        width: 170,
        editable: true
      },
      {key: 'paid_at', name: 'Pagado en', formatter: date, width: 120},
      {key: 'paid', name: 'Pagado', formatter: checkbox, width: 120}
    ]
    let rows = this.state.data ? this.state.data
      .map(({
              //service
              id: service,
              paid,
              paid_at,
              paid_value,
              bus,
              operator,
              order: {
                client: {
                  nombre
                },
                salida,
                destino,
                id,
                con_factura,
                costo_unitario,
                tax_rate,
                balance: {
                  balance
                }
              }
            }) => ({
        bus: bus ? bus.clave : 'N/A',
        operator: operator ? operator.nombre : 'N/A',
        service,
        salida,
        destino,
        cliente: nombre,
        id,
        con_factura,
        costo_unitario,
        costo_unitario_impuesto: con_factura ? (costo_unitario * tax_rate) / 100 : 0,
        costo_unitario_total: con_factura ? (costo_unitario * (100 + Number(tax_rate))) / 100 : costo_unitario,
        paid,
        paid_at,
        paid_value: paid_value || false,
        balance
      })) : []

    if (this.state.sort) {
      const {column, direction} = this.state.sort
      rows = sort({rows, column, direction})
    }

    let sale = rows.reduce((carry, {costo_unitario, paid_value}) => {
      return carry + parseFloat(paid_value || costo_unitario)
    }, 0)

    let payment = rows.filter(({paid}) => paid).reduce((carry, {costo_unitario, paid_value}) => {
      return carry + parseFloat(paid_value || costo_unitario)
    }, 0)

    let balance = sale - payment

    this.rows = rows

    let partners

    const GENERAL = '0'
    const SOCIO = '1'
    const PROVEEDOR = '2'
    const AUTOBUS = '3'
    const NUMERO_FACTURA = '4'

    switch (this.state.filter) {
      case SOCIO:
        partners = this.state.partners.filter(({external}) => !external)
        break
      case PROVEEDOR:
        partners = this.state.partners.filter(({external}) => external)
        break
      case NUMERO_FACTURA:
        partners = [
          {
            id: "",
            name: "Todos"
          }
        ].concat(this.state.partners);
        break;
      case GENERAL:
        partners = []
        break
    }

    return (
      <div>
        <Group group={true} title={'Cuentas por pagar'} handleForm={this.handleSearch.bind(this)} margin>
          <Input type="select"
                 label="Filtro"
                 options={[
                   {show: 'Autobús', value: AUTOBUS},
                   {show: 'General', value: GENERAL},
                   {show: 'Socio', value: SOCIO},
                   {show: 'Proveedor', value: PROVEEDOR},
                   {show: 'Numero de factura', value: NUMERO_FACTURA}
                 ]}
                 state={this.linkState('filter')}
                 grid={[12, 3]}
          />
          {this.state.filter === NUMERO_FACTURA ? (
              <Input type="text"
                     grid={[12, 3]}
                     label={'Numero de factura'}
                     state={this.linkState('numero_factura')}
              />
          ) : null}
          {
            this.state.filter !== AUTOBUS ? (
                <Input type="select"
                       grid={[12, 3]}
                       label={'Socio/Proveedor'}
                       state={this.linkState('partner', {value: (value) => this.state.filter === '0' ? '' : value})}
                       disabled={this.state.filter === '0'}
                       options={partners.map(({id: value, name: show}) => ({show, value}))}
                       required={this.state.filter !== NUMERO_FACTURA}
                />
            ) : (
                <Input type="select"
                       grid={[12, 3]}
                       label={'Autobús'}
                       state={this.linkState('bus')}
                       options={this.state.buses.map(({id: value, clave: show}) => ({show, value}))}
                />
            )
          }
          {
              this.state.filter !== NUMERO_FACTURA ? (
                      <Input type="date"
                             label={'Inicio'}
                             state={this.linkState('from')}
                             grid={[12, 3]}
                      />
              ) : null
          }
          {
            this.state.filter !== NUMERO_FACTURA ? (
                <Input type="date"
                       label={'Fin'}
                       state={this.linkState('to')}
                       extra={{min: this.state.from}}
                       grid={[12, 3]}
                />
            ) : null
          }



          <Input type="select"
                 grid={[12, 4, 3]}
                 label="Liquidado a empresa"
                 options={[
                   {show: 'Todos', value: '-1'},
                   {show: 'Liquidados', value: '1'},
                   {show: 'Sin liquidar', value: '0'}
                 ]}
                 state={this.linkState('liquidated')}
          />
          <Input type="select"
                 grid={[12, 4, 3]}
                 label="Pago a socio/proveedor"
                 options={[
                   {show: 'Todos', value: '-1'},
                   {show: 'Pagados', value: '1'},
                   {show: 'Sin pagar', value: '0'}
                 ]}
                 state={this.linkState('pago')}
          />
          <Input type="select"
                 grid={[12, 4, 3]}
                 label="Factura"
                 options={[
                   {show: 'Todos', value: '-1'},
                   {show: 'Con factura', value: '1'},
                   {show: 'Sin factura', value: '0'}
                 ]}
                 state={this.linkState('factura')}
          />
          <Input type="autocomplete"
                 grid={[12, 4, 3]}
                 label={"Cliente"}
                 required={false}
                 state={this.linkState('client_search')}
                 autocomplete={{
                   index: "clients",
                   displayKey: "nombre",
                   keyName: "nombre",
                   onSelect: (e, {id: client, nombre: client_search}) => this.setState({client, client_search})
                 }}
          />
          <Input type="submit"
                 button={{color: 'success'}}
                 text={this.state.data ? 'Actualizar' : 'Abrir'}
                 grid={[6, 4, true]}
          />
          <Input type="button"
                 button={{color: 'alert'}}
                 text={'Cerrar'}
                 disabled={this.state.data === null}
                 click={this.handleClean.bind(this)}
                 grid={[6, 4, true]}
          />
        </Group>
        <Group group={true} title={'Acciones'} margin={true}>
          <Input type="button"
                 text="Marcar pagado"
                 button={{color: 'success'}}
                 grid={[12, 4, 3]}
                 click={this.markAs.bind(this, true)}
                 disabled={!this.state.data}
          />
          <Input type="button"
                 text="Marcar no pagado"
                 button={{color: 'alert'}}
                 grid={[12, 4, 3]}
                 click={this.markAs.bind(this, false)}
                 disabled={!this.state.data}
          />
          <Input type="button"
                 text="Generar reporte"
                 button={{color: 'warning'}}
                 grid={[12, 4, 3]}
                 click={this.getReport.bind(this)}
                 disabled={!this.state.data}
          />
          <Input type="button"
                 text="Generar reporte de seleccion"
                 button={{color: 'warning'}}
                 grid={[12, 4, 3]}
                 click={this.getSelectionReport.bind(this)}
                 disabled={!this.state.selected.length}
          />
          <ReadOnly type="text"
                    label="A pagar"
                    value={getMoney(sale)}
                    grid={[12, 4, 4]}
          />
          <ReadOnly type="text"
                    label="Pagado"
                    value={getMoney(payment)}
                    grid={[12, 4, 4]}
          />
          <ReadOnly type="text"
                    label="Saldo"
                    value={getMoney(balance)}
                    grid={[12, 4, 4]}
          />
        </Group>
        <Table title={'Servicios'} columns={columns} data={rows} rowKey={'service'}
               sortable={true}
               extra={{
                 enableCellSelect: true,
                 onRowUpdated: this.handleRowUpdate.bind(this),
                 onGridSort: this.handleGridSort.bind(this),
                 rowSelection: {
                   onRowsSelected: this.onRowsSelected.bind(this),
                   onRowsDeselected: this.onRowsDeselected.bind(this),
                   showCheckbox: true,
                   enableShiftSelect: true,
                   selectBy: {
                     keys: {
                       rowKey: 'service',
                       values: this.state.selected
                     }
                   }
                 }
               }}/>
      </div>
    )
  }
}
