import React, {
	Component
} from 'react';

import Group from"components/forms/group";
import {Input} from"components/forms/input.jsx";
import {api} from"helper/rest";
import ajax from"helper/ajax";
import linkState from"helper/state";


export class InvitacionForm extends Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
		    email: ""
		};
	}

	handleInvite (e) {
		e.preventDefault();
		ajax(api.post("users/invite",this.state),{success: {
			title: "Enviando invitación...",
			text: "Solicitud procesada"
		}})
			.promise
			.then(() => {
			    this.setState({...this.initialState});
			})
	}

	render() {
		return (
			<Group title={"Invitar usuario"} handleForm={this.handleInvite.bind(this)}>
				<Input type="email"
					   label="Dirección de correo"
					   state={this.linkState("email")}
				       grid={[12,8,9]}
				/>
				<Input type="submit"
				       button={{color: "success"}}
				       text="Enviar"
				       grid={[12,4,3]}
				/>
			</Group>
		);
	}
}
