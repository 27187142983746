import React from "react";
import {AccessCard} from "./accessCard";
import Group from "components/forms/group";
import Plus from "components/svg/plus";
import Search from "components/svg/search";
import Credit from "components/svg/credit";
import Pencil from "components/svg/pencil";
import Link from "components/svg/link";
import PermissionStore from "stores/PermissionStore";

export class Home extends React.Component {
	render() {
		return (
			<div className="row">
				<Group rowClassName={"align-justify"} group={false}>
					{PermissionStore.roles(["secretary","seller","administrator"]) ? <AccessCard title="Crear nueva orden" icon={Plus} link={"/app/ordenes/nueva"}/> : null}
					{PermissionStore.roles(["secretary","seller","administrator"]) ? <AccessCard title="Consultar ordenes" icon={Search} link={"/app/ordenes/consulta"}/> : null}
					{PermissionStore.roles(["secretary","administrator"]) ? <AccessCard title="Asignar orden" icon={Pencil} link={"/app/ordenes/asigna"}/> : null}
					{PermissionStore.roles(["treasury","administrator"]) ? <AccessCard title="Pago de orden" icon={Credit} link={"/app/ordenes/paga"}/> : null}
					{PermissionStore.roles(["treasury","administrator"]) ? <AccessCard title="Recaudaciones" icon={Link} link={"/app/reportes/recaudacion"}/> : null}
				</Group>
			</div>
		);
	}
}
