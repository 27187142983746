import React, {Component} from "react";
import PropTypes from "prop-types"
import Group from "components/forms/group";
import {Input, ReadOnly} from "components/forms/input";
import linkState from "helper/state";
import ajax from "helper/ajax";
import {api} from "helper/rest";
import "./agregar.scss";
import {mapStateToData} from "helper/transformer";
import AlertStore from "stores/AlertStore";
import ModalStore from "stores/ModalStore";
import DataStore from "stores/DataStore";

export class FormGastosComprobados extends Component {
	static propTypes = {
		service: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
			"data:expense_type": "",
			"data:reference_type": "",
			"data:external_business": "",
			"data:date": "",
			"data:reference": "",
			"data:import": "",
			"data:comment": "",
			ext_business: DataStore.getModel("external_businesses"),
			expense: DataStore.getModel("expense_types")
		};
		this.expense_types = [
			"EXPENSE_TYPES_UPDATED",
			this.updateExpenseTypes.bind(this)
		];
		this.external_businesses = [
			"EXTERNAL_BUSINESSES_UPDATED",
			this.updateExternalBusinesses.bind(this)
		]
	}

	updateExpenseTypes () {
		this.setState({
			expense: DataStore.getModel("expense_types")
		});
	}

	updateExternalBusinesses () {
		this.setState({
			ext_business: DataStore.getModel("external_businesses")
		});
	}

	fill(data) {
		console.log(data);
		this.filling = true;
		this.id = data.id;
		this.setState({
			"data:expense_type": data.expense_type.type,
			"data:reference_type": data.reference_type,
			"data:external_business": data.external_business.name,
			"data:date": data.date,
			"data:reference": data.reference,
			"data:import": data.import,
			"data:comment": data.comment || "",
		});
	}

	componentWillUnmount() {
		DataStore.removeListener(...this.external_businesses);
		DataStore.removeListener(...this.expense_types);
	}


	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
		DataStore.on(...this.external_businesses);
		DataStore.on(...this.expense_types);
	}


	handleForm(e) {
		e.preventDefault();
		let data = mapStateToData(this.state);
		if (data.comment === "" || data.comment === null) {
			delete data.comment
		}
		if (this.filling) {
			ajax(api.patch(`service/${this.props.service.id}/expense/${this.id}`,data),{action: true, success: {
				title: "Comprobación de gasto actualizado",
				text: `ID: ${this.id}`,
			}})
		} else {
			ajax(api.post(`service/${this.props.service.id}/expense`, data), {action: true})
				.promise
				.then((response)=> {
					AlertStore.addToQueue({
						title: "Comprobación de gasto guardado",
						text: `ID: ${response.data.id}`,
						type: "success"
					});
					this.setState(this.initialState);
				})
		}
	}

	handleDelete (e,enable) {
		AlertStore.addToQueue({
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			title: "Eliminar vale de comprobación de gastos",
			text: "¿Desea eliminar este vale? <br><em>Esta acción solo la puede revertir un administrador</em>",
			type: "warning"
		},{confirm: () => {
			ajax(api.delete(`service/${this.props.service.id}/expense/${this.id}`),{action : true, success: {
				text: "Vale de comprobación de gastos eliminado",
			}})
				.promise
				.then(() => {
					enable();
					ModalStore.closeModal();
				})
		}, dismiss: () => {
			enable();
		}});
	}

	render() {
		return (
			<Group grid={12} title="Gasto de viaje comprobado" handleForm={this.handleForm.bind(this)}>
				<ReadOnly grid={2} type="text" label="Servicio" state={{value: this.props.service.id}}/>
				<ReadOnly grid={5} label="Salida" type="date"
				          value={this.props.service.order.salida.split(" ")[0]}
				/>
				<ReadOnly grid={5} label="Regreso" type="date"
				          value={this.props.service.order.regreso.split(" ")[0]}
				/>
				<Input type="text"
				       label="Tipo de gasto"
				       datalist={this.state.expense.map(e => e.type)}
				       datalistId={"expense"}
				       grid={[6]}
				       state={this.linkState("data:expense_type")}
				       required
				/>
				<Input type="text"
				       label="Empresa externa"
				       datalist={this.state.ext_business.map(e => e.name)}
				       datalistId={"ext_business"}
				       state={this.linkState("data:external_business")}
				       grid={[6]}
				       required
				/>
				<Input grid={[3]}
				       type="date"
				       label="Fecha del gasto"
				       state={this.linkState("data:date")}
				       extra={{min: this.props.service.order.salida.split(" ")[0]}}
				/>
				<Input grid={[3]}
				       type="select"
				       label="Tipo de referencia"
				       options={[{show: "Nota"}, {show: "Factura"}]}
				       state={this.linkState("data:reference_type")}
				/>
				<Input grid={[3]}
				       type="text"
				       label="Referencia"
				       state={this.linkState("data:reference")}
				/>
				<Input grid={[3]}
				       type="number"
				       label="Importe"
				       state={this.linkState("data:import")}
				/>
				<Input type="textarea" grid={12} label="Comentario" state={this.linkState("data:comment")}
				       required={false} rows={1}/>
				<Input grid={true}
				       type="submit"
				       text="Guardar"
				       button={{color: "success"}}
				/>
				{this.filling ? (
					<Input type={"button"}
					       grid={3}
					       text="Eliminar"
					       click={this.handleDelete.bind(this)}
					       button={{color: "alert"}}
					/>
				) : null}
			</Group>
		);
	}
}
