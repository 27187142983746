import React, {
	Component
} from 'react';

import Group from "../../../components/forms/group";
import {ReadOnly} from "../../../components/forms/input.jsx";
import {Order} from "../../../misc/propTypes";

export class ConsultItemAsignations extends Component {
	static propTypes = {
		order: Order,
	};
	static defaultProps = {};

	render() {
		const asignaciones = this.props.order.buses.map((service) => (
			<Group group={false} grid={12} key={service.id}>
				<ReadOnly label="Viaje"
				          value={service.id}
				          grid={[2]}
				          type="text"
				/>
				<ReadOnly label="Autobus"
				          value={service.bus ? service.bus.clave || service.bus.id : "N/A" }
				          grid={[5]}
				          type="text"
				/>
				<ReadOnly label="Operador"
				          value={service.operator ? service.operator.nombre : "N/A"}
				          grid={[5]}
				          type="text"
				/>
			</Group>
		));
		return (
			<Group grid={[12]} group={false} title={"Unidades"}>
				{asignaciones}
			</Group>
		);
	}
}
