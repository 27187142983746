import React from "react";
import {Input} from "../../../components/forms/input";
import linkState from "../../../helper/state";
import ajax from "../../../helper/ajax";
import Group from "../../../components/forms/group";
import {api} from "../../../helper/rest";
import {SearchInputs} from "./searchInputs";
import {AssignInputs} from "./assignInputs";
import "./asigna.scss";
import AlertStore from "../../../stores/AlertStore";
import DataStore from "../../../stores/DataStore";
import {RegisterMatchParam} from "../../../helper/registerMatchParam";

const FOLIO = "0";
const RANGO_FECHAS = "1";
const FECHA = "2";
const options = [
	{show: "Folio", value: FOLIO},
	{show: "Rango de fechas", value: RANGO_FECHAS},
	{show: "Fecha", value: FECHA},
];

export class Asigna extends React.Component {
	constructor(props) {
		super(props);
		this.linkState = linkState.bind(this);
		this.initialState = {
			data: null,
			buses: DataStore.getModel("buses"),
			operators: this.getActiveOperators(),
			//filter
			filter: FOLIO,
			//search
			folio: "",
			from: "",
			to: "",
			date: "",
			assign: []
		};
		this.state = {...this.initialState};
		this.updateBuses = this.updateBuses.bind(this);
		this.updateOperators = this.updateOperators.bind(this);
		this.matchParam = new RegisterMatchParam(this, "id");
		this.matchParam.on("RECEIVED", (parameter) => {
			this.setState({folio: parameter}, () => {
				this.getOrder()
			});
		});
		this.matchParam.on("CLEARED", () => {
			this.setState({folio: ""}, () => {
				// this.getOrder();
			});
		});
	}

	getActiveOperators () {
		return DataStore.getModel("operators").filter(({activo}) => activo);
	}

	updateBuses () {
		this.setState({
			buses: DataStore.getModel("buses")
		});
	}

	updateOperators () {
		this.setState({
			operators: this.getActiveOperators()
		});
	}

	componentWillMount() {
		DataStore.on("BUSES_UPDATED", this.updateBuses);
		DataStore.on("OPERATORS_UPDATED", this.updateOperators);
	}

	componentWillUnmount() {
		DataStore.removeListener("BUSES_UPDATED", this.updateBuses);
		DataStore.removeListener("OPERATORS_UPDATED", this.updateOperators);
	}


	getOrder(e) {
		if (e) {
			e.preventDefault();
		}
		let request = undefined;
		const {
			folio,
			from,
			to,
			date
		} = this.state;
		switch (this.state.filter) {
			case FOLIO :
				request = api.get(`order/${folio}`, {
					params: {
						trashed: 2
					}
				});
				break;
			case RANGO_FECHAS:
				request = api.get(`order`, {
					params: {
						filter: "date:range",
						from,
						to,
						trashed: 2
					}
				});
				break;
			case FECHA:
				request = api.get(`order`, {
					params: {
						filter: "date",
						date,
						trashed: 2
					}
				});
				//
				break;
			default:
				throw new Error("UNKNOWN FILTER STATE");
		}
		ajax(request, {
			action: true,
			error: this.state.filter === FOLIO ? {
				"404": {text: `El folio "${this.state.folio}" no existe.`}
			} : {}
		})
			.promise
			.then(({data}) => {
				this.setState({
					data
				});
			})
	}

	handleClose(e, enable) {
		this.refs.assign.assign = [];
		this.setState({
			data: null,
			assign: []
		}, enable);
		this.matchParam.clear();
	}

	handleAssign(e) {
		e.preventDefault();
		ajax(api.post("/service/assign", {
			assign: this.refs.assign.assign
		}), {
			action: true,
			success: {
				text: "Asignaciones guardadas"
			}
		})
			.promise
			.catch((error) => {
				if (error.response && error.response.status === 403 && error.response.data.error === "LIQUIDATION_DEFINED") {
					const {service_id: id} = error.response.data;
					const text = `No es posible cambiar cambiar la asignación para el no. de viaje <em>ID: ${id}</em>`;
					AlertStore.addToQueue({
						text,
						type: "info"
					})
				}
			});
		console.log(this.refs.assign.assign)
	}

	render() {
		return (
			<div>
				<Group form={true} handleForm={this.getOrder.bind(this)} title={"Asignar folio"} className={"asignar"}>
					<Input type="select"
					       label="Filtro"
					       state={this.linkState("filter")}
					       required
					       options={options}
					       grid={[12,12,6]}
					       disabled={this.state.data}
						   autoFocus={true}
					/>
					<SearchInputs parent={this} grid={[12,12,6]}/>
					<Input type="submit"
					       text="Abrir"
					       button={{color: "success"}}
					       disabled={this.state.data}
					       grid={[12,6]}
					/>
					<Input type="button"
					       text="Cerrar"
					       button={{color: "warning"}}
					       click={this.handleClose.bind(this)}
					       disabled={!this.state.data}
					       grid={[12,6]}
					/>
				</Group>
				{this.state.data ? (
					<Group handleForm={this.handleAssign.bind(this)}
					       className={"asignar-form"}
						   group={false}
					       rowClassName={"align-center"}
					>
						<AssignInputs parent={this} ref="assign"/>
						<Input grid={[12,8,6]}
						       className={"large-offset-6"}
						       type={"submit"}
						       text={"Guardar"}
						       button={{color: "success"}}
						/>
					</Group>
				) : null }
			</div>
		);
	}
}