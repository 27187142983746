import React, {
	Component
} from 'react';
import {Catalog} from"components/catalog/catalog";
import {CatalogForm} from"components/catalog/catalogForm";

export class Vendedores extends Component {
	static propTypes = {};
	static defaultProps = {};

	render() {
		return (
			<Catalog keys={["name"]}
					 singular={"Vendedor"}
					 model={"sellers"}
					 name={"Vendedores"}
					 columns={[
						 {key: "name",name: "Nombre"},
					 ]}
			>
				<CatalogForm identifier="name" inputs={[
					{type: "text", state: "name", label: "Nombre", grid: [12]}
				]}/>
			</Catalog>
		);
	}
}
