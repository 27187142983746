import React from "react";
import {Input} from "../../components/forms/input";
import linkState from "../../helper/state";
import Group from "../../components/forms/group";
import ajax from "../../helper/ajax";
import {ConsultList} from "./consulta/consultList";
import {api} from "../../helper/rest";

export class Cancela extends React.Component {
	constructor(props) {
		super(props);
		this.linkState = linkState.bind(this);
		this.initialState = this.state = {
			folio: "",
			fetching: false,
			fetched: false,
			data: null
		};
		this.getOrder = this.getOrder.bind(this);
		this.handleSwitch = this.handleSwitch.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	getOrder(e, enable: Function = () => null, action: Boolean = true,force = false) {
		if (e) {
			e.preventDefault();
		}
		if (!force && this.state.data && (this.state.data.id == this.state.folio)) {
			enable();
			return;
		}
		ajax(api.get(`order/${this.state.folio}`,{
			params: {
				trashed: 1
			}
		}), {
			action, error: {
				"404": {
					text: `El folio "${this.state.folio}" no existe.`,
					type: "info",
				}
			}
		})
			.promise
			.then((response) => {
				this.setState({fetched: true, data: response.data})
			})
			.then(enable, enable)
	}

	handleSwitch(e, enable) {
		if (this.state.data.deleted_at) {
			ajax(api.patch(`/order/${this.state.data.id}/recover`), {
				action: true,
				success: {text: `Orden ${this.state.data.id} recuperada`},
			})
				.promise
				.then(() => {
					this.getOrder(undefined,undefined,false,true);
				})
				.then(enable,enable);
		} else {
			ajax(api.delete(`/order/${this.state.data.id}/cancel`),{
				action: true,
				success: {text: `Orden ${this.state.data.id} cancelada`},
			})
				.promise
				.then(() => {
					this.getOrder(undefined,undefined,false,true);
				})
				.then(enable,enable);
		}
	}

	handleClose (e,enable) {
		this.setState({...this.initialState},enable);
	}

	render() {
		return (
			<div>
				<Group form={true} handleForm={this.getOrder} title={"Cancelar folio"} style={{marginBottom: "1em"}}>
					<Input type="number" label="Folio" state={this.linkState("folio")} extra={{step: 1, min: 1}} autoFocus={true}/>
					<Input type="submit" label=" " text="Abrir" button={{color: "success"}} click={this.getOrder}/>
					{this.state.data ? (
						<Input type="button"
						       text={this.state.data.deleted_at ? "Recuperar" : "Cancelar"}
						       click={this.handleSwitch}
						       disabled={!this.state.data}
						       button={{color: this.state.data.deleted_at ? "warning" : "alert"}}
						/>
					) : (
						<Input type="button"
						       text={"..."}
						       disabled={true}
						/>
					)}
					{this.state.data ? (
						<Input type="button"
						       text={"Cerrar"}
						       click={this.handleClose}
						/>
					) : (
						<Input type="button"
						       text={"..."}
						       disabled={true}
						/>
					)}
				</Group>
				<ConsultList orders={this.state.data || null} precios={true} asignaciones={true} pagos={true}/>
			</div>
		);
	}
}