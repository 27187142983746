import React, {
    Component,
} from 'react';

import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import {date} from "helper/formatters";
import DataStore from "stores/DataStore";
import {Table} from "components/table/table";
import {sort} from "helper/sort";
import {generate} from "helper/print";

export class ReporteNomina extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.initialState = {
            filter: "autobus",
            from: "",
            to: "",
            bus_id: '',
            operator_id: '',
            bus_selection: [],
            operator_selection: [],
            data: null,
            buses: DataStore.getModel("buses"),
            operators: DataStore.getModel("operators"),
            sort: null
        };
        this.state = {...this.initialState};
        this.buses = DataStore.listenChanges("buses", () => {
            this.setState({
                buses: DataStore.getModel("buses")
            });
        });
        this.operators = DataStore.listenChanges("operators", () => {
            this.setState({
                operators: DataStore.getModel("operators")
            });
        });
    }

    componentWillMount() {
        this.buses.listen();
        this.operators.listen();
    }

    componentWillUnmount() {
        this.buses.stop();
        this.operators.stop();
    }

    handleOpen(e) {
        if (e) {
            e.preventDefault();
        }
        const {from, to, filter, bus_id, operator_id} = this.state;
        let request = {
            from,
            to,
        };
        switch (this.state.filter) {
            case "autobus":
                if (this.state.bus_id !== "0") {
                    request = {
                        ...request,
                        filter,
                        bus_id
                    };
                }
                break;
            case "operador":
                if (this.state.operator_id !== "0") {
                    request = {
                        ...request,
                        filter,
                        operator_id
                    };
                }
                break;
            case "autobus:multiple":
                request = {
                    ...request,
                    filter,
                    bus_selection: this.state.bus_selection.map(bus => bus.value)
                };
                break;
            case "operador:multiple":
                request = {
                    ...request,
                    filter,
                    operator_selection: this.state.operator_selection.map(operator => operator.value)
                };
                break;
            default:
                //
        }
        ajax(api.get("service", {
            params: {
                ...request
            }
        }), {action: true}, true)
            .then(({data}) => {
                switch (this.state.filter) {
                    case "autobus":
                        if (this.state.bus_id === "0") {
                            this.setState({
                                data: data.filter(({bus}) => bus)
                            });
                        } else {
                            this.setState({
                                data
                            });
                        }
                        break;
                    case "operador":
                        if (this.state.operator_id === "0") {
                            this.setState({
                                data: data.filter(({operator}) => operator)
                            });
                        } else {
                            this.setState({
                                data
                            });
                        }
                        break;
                    default:
                        this.setState({
                            data
                        });
                        break;
                }
            })
    }

    handleGenerate(e, enable) {
        let {
            filter,
            from,
            to,
            operator_id,
            bus_id
        } = this.state;
        generate({
            report: "nomina",
            filter,
            from,
            to,
            operator_id,
            bus_id,
            ids: this.state.data.map(({id}) => id),
        },enable)
    }

    handleGridSort(column, direction) {
        if (direction !== "NONE") {
            this.setState({
                sort: {
                    column,
                    direction
                }
            });
        } else {
            this.setState({
                sort: null
            });
        }
    }

    render() {
        let columns = [
            {name: "Fecha", key: "date", formatter: date},
            {name: "Folio", key: "order_id"},
            {name: "Cliente", key: "client"},
            {name: "Destino", key: "destino"},
            {name: "Autobús", key: "bus"},
            {name: "Operador", key: "operator"},
            {name: "No. Viaje", key: "id"},
        ];

        columns = columns.map(c => ({...c, sortable: true}));
        //services
        let rows = this.state.data ? this.state.data
            .map(({
                      id,
                      order: {
                          client: {
                              nombre: client
                          },
                          salida: date,
                          destino,
                          id: order_id
                      },
                      bus,
                      operator
                  }) => ({
                date,
                order_id,
                client,
                destino,
                bus: bus ? bus.clave : "N/A",
                operator: operator ? operator.nombre : "N/A",
                id
            })) : [];

        if (this.state.sort) {
            const {column, direction} = this.state.sort;
            rows = sort({
                rows,
                column,
                direction
            });
        }

        return (
            <div>
                <Group title={"Reporte de nomina"} margin handleForm={this.handleOpen.bind(this)}>
                    <Input type="select"
                           label={"Tipo"}
                           state={this.linkState("filter")}
                           options={[
                               {show: "Autobús", value: "autobus"},
                               {show: "Autobuses", value: "autobus:multiple"},
                               {show: "Operador", value: "operador"},
                               {show: "Operadores", value: "operador:multiple"},
                           ]}
                    />
                    {this.state.filter === "autobus" ? (
                        <Input type={"select"}
                               label={"Autobús"}
                               state={this.linkState("bus_id")}
                               options={[{show: "Todos", value: "0"}].concat(this.state.buses
                                   .map(({
                                             clave: show,
                                             id: value,
                                         }) => ({
                                       show,
                                       value
                                   })))}
                        />
                    ) : (this.state.filter === "operador" ? (
                        <Input type={"select"}
                               label={"Operador"}
                               state={this.linkState("operator_id")}
                               options={[{show: "Todos", value: "0"}].concat(this.state.operators
                                   .map(({
                                             nombre: show,
                                             id: value
                                         }) => ({
                                       show,
                                       value
                                   })))}
                        />
                    ) : (this.state.filter === "autobus:multiple" ? (
                        <Input type="select:multiple" label={"Autobuses"}
                               state={this.linkState('bus_selection', {onChange: a => a})}
                               options={
                                   this.state.buses
                                       .map(({
                                                 clave: label,
                                                 id: value,
                                             }) => ({
                                           label,
                                           value
                                       }))
                               }/>
                    ) : (this.state.filter === "operador:multiple" ? (
                        <Input type="select:multiple" label={"Operadores"}
                               state={this.linkState('operator_selection', {onChange: a => a})}
                               options={
                                   this.state.operators
                                       .map(({
                                                 nombre: label,
                                                 id: value,
                                             }) => ({
                                           label,
                                           value
                                       }))
                               }/>
                    ) : null)))}
                    <Input type="date"
                           label={"Inicio"}
                           state={this.linkState("from")}
                    />
                    <Input type="date"
                           label={"Fin"}
                           extra={{min: this.state.from}}
                           state={this.linkState("to")}
                    />
                    <Input type="button"
                           text={"Generar reporte"}
                           click={this.handleGenerate.bind(this)}
                           disabled={!this.state.data || (this.state.data && !this.state.data.length)}
                           button={{
                               color: "warning"
                           }}
                    />
                    <Input type="submit"
                           text={"Abrir"}
                           button={{
                               color: "success"
                           }}
                    />
                </Group>
                <Table title={"Viajes"} columns={columns} data={rows} rowKey={"id"}
                       extra={{
                           onGridSort: this.handleGridSort.bind(this)
                       }}/>
            </div>
        );
    }
}
