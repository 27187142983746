import React from "react";

import {Catalog} from"components/catalog/catalog";
import {FormCreateBus} from "./formCreateBus";
import {checkbox} from"helper/formatters";


export class Autobuses extends React.Component {
	render() {
		return (
			<Catalog model={"buses"}
			         name={"Autobuses"}
			         singular={"Autobús"}
			         keys={["clave","model.submarca","partner.name"]}
			         dependencies={["models","operators","partners"]}
			         columns={[
		                	{key: "clave", name: "Numero económico"},
		                	{key: "model.submarca", name: "Vehículo"},
							{key: "operator.nombre", name: "Operador", nullValue: "- N/A -"},
							{key: "partner.name", name: "Socio/Proveedor"},
							{key: "sits", name: "Asientos"},
							{key: "year", name: "Año"},
							{key: "license_plate_number", name: "Placas"},
							{key: "symbolic", name: "Simbólico", formatter: checkbox},
		                ]}
			>
				<FormCreateBus/>
			</Catalog>
		);
	}
}
