import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AuthStore from 'stores/AuthStore'
import Loading from 'components/loading/loading'
import { Layout } from 'components/layout'
import { Redirect } from 'react-router-dom'

export class Authenticator extends Component {
  static propTypes = {
    component: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      authenticated: false
    }
    this.goToLogin = this.goToLogin.bind(this)

  }

  goToLogin () {
    this.setState({
      authenticated: false
    })
  }

  componentDidMount () {
    console.info('Authenticator will mount')
    AuthStore.on('LOGIN_SCREEN', this.goToLogin)
  }

  componentWillUnmount () {
    AuthStore.removeListener('LOGIN_SCREEN', this.goToLogin)
  }

  render () {
    let authorized = AuthStore.connected
    let authenticate = AuthStore.hasToken()
    if (!authorized) {
      console.info('Authenticator is not authorized')
      if (authenticate) {
        console.info('Authenticator will authenticate')
        AuthStore.auth()
          .then(() => {
            this.setState({authenticated: true})
            console.info('Authenticator authenticated')
          })
      } else {
        console.info('Authenticator can\'t authenticate')
      }
    } else {
      console.info('Authenticator is authorized')
    }
    //authorized = I am connected
    //authenticate = I have a token and I want to try it out
    //authenticated = My token was valid and I'm free to continue
    switch (true) {
      case (authorized === true):
      case (authenticate === true && this.state.authenticated === true):
        return (
          <Layout location={this.props.location}>
            {this.props.children}
          </Layout>
        )
      case (authenticate === true && this.state.authenticated === false):
        return (
          <Loading loading={true}/>
        )
      case (authenticate === false):
        if (this.props.location.pathname === '/app/logout') {
          return (
            <Redirect to={`/login`}/>
          )
        } else {
          return (
            <Redirect to={`/login?next=${this.props.location.pathname}`}/>
          )
        }
    }
  }
}
