import React from "react";
import {ConsultList}from "./consultList";
import Group from "../../../components/forms/group";
import {Input} from "../../../components/forms/input.jsx";
import linkState, {linkCheckState} from "../../../helper/state";
import AlertStore from "../../../stores/AlertStore";
import PermissionStore from "../../../stores/PermissionStore";
import {api, pdfUrl} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import DataStore from "../../../stores/DataStore";
import {ReadOnly} from "../../../components/forms/input";

export class Consulta extends React.Component {
	constructor() {
		super();
		this.linkState = linkState.bind(this);
		this.linkCheckState = linkCheckState.bind(this);
		this.state = {
			search: "Ultimo",
			Desde: "",
			Hasta: "",
			Fecha: "",
			Folio: "",
			Ultimo: 5,
			Cliente: "",
			Servicio: "",
			Vendedor: "",
			sellers: DataStore.getModel("sellers"),
			ordenes: [],
			withPrices: "0",
			withTrashed: "2",
			con_asignaciones: false,
			con_precios: PermissionStore.roles("accountant"),
			con_pagos: PermissionStore.roles("accountant"),
		};
		this.renderForm = this.renderForm.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.updateSellers = this.updateSellers.bind(this);
		this.search = this.search.bind(this);
	}

	updateSellers() {
		this.setState({
			sellers: DataStore.getModel("sellers")
		});
	}

	componentWillMount() {
		this.initialize();
		DataStore.on("ORDERS_UPDATED", this.search);
		DataStore.on("SERVICES_UPDATED", this.search);
		DataStore.on("SELLERS_UPDATED", this.updateSellers);
	}

	componentWillUnmount() {

		DataStore.removeListener("SERVICES_UPDATED", this.search);
		DataStore.removeListener("ORDERS_UPDATED", this.search);
		DataStore.removeListener("SELLERS_UPDATED", this.updateSellers);
	}


	initialize() {
		this.search();
	}

	handleResponse(request) {
		ajax(request, {
			error: {
				"404": {
					text: "No hay resultados para esta búsqueda",
					type: "info"
				}
			},
			action: true
		})
			.promise
			.then(({data}) => {
				console.log(data);
				if (Array.isArray(data)) {
					this.setState({ordenes: data}, () => console.log(this.state));
				} else {
					this.setState({ordenes: [data]});
				}
			})
			.catch((error) => {
				console.error(error);
				this.setState({ordenes: []});
			});
	}

	getIds() {
		return this.state.ordenes.map(orden => orden.id);
	}

	roll(e, enableButton) {
		let append = {};
		const withPrices = this.state.withPrices;
		if (this.state.search === "Fecha") {
			append.date = this.state.Fecha
		}
		let tempWindow = window.open();
		ajax(api.post("pdf/generate", {
			craft: "roll",
			ids: this.getIds(),
			withPrices,
			...append
		}))
			.promise
			.then((response) => {
				tempWindow.location.href = pdfUrl(response.data.token);
			})
			.then(enableButton, enableButton);
	}

	order(e, enableButton) {
		let tempWindow = window.open();
		const withPrices = this.state.withPrices;
		ajax(api.post("pdf/generate", {
			craft: "order",
			ids: this.getIds(),
			withPrices
		}))
			.promise
			.then((response) => {
				if (tempWindow) {
					tempWindow.location.href = pdfUrl(response.data.token);
				}
			})
			.then(enableButton, enableButton);
	}

	search(e) {
		if (e) {
			e.preventDefault();
		}
		const { factura, withTrashed: trashed } = this.state;
		switch (this.state.search) {
			case "Folio":
				switch (true) {
					case /^\d+$/.test(this.state.Folio):
						this.handleResponse(api.get(`order/${this.state.Folio}`,{
							params: {
								trashed,
								factura
							}
						}));
						break;
					case /^\d+(,\d+)+,?$/.test(this.state.Folio):
						const folios = this.state.Folio.split(",");
						this.handleResponse(api.get("order", {
							params: {
								filter: "multiple",
								id: folios,
								trashed,
								factura
							}
						}));
						break;
					case /^(\d+)-(\d+)$/.test(this.state.Folio):
						const from = this.state.Folio.match(/^(\d+)-(\d+)$/)[1];
						const to = this.state.Folio.match(/^(\d+)-(\d+)$/)[2];
						this.handleResponse(api.get("order", {
							params: {
								filter: "range",
								from,
								to,
								trashed,
								factura
							}
						}));
						break;
					default:
						AlertStore.addToQueue({
							text: "Sintaxis invalido para la búsqueda",
							type: "warning"
						});
						break;
				}
				break;
			case "Fecha":
				this.handleResponse(api.get("order", {
					params: {
						filter: "date",
						date: this.state.Fecha,
						factura,
						trashed
					}
				}));
				break;
			case "Fecha de captura":
				this.handleResponse(api.get("order", {
					params: {
						filter: "date:created",
						date: this.state.Fecha,
						factura,
						trashed
					}
				}));
				break;
			case "Cliente":
				this.handleResponse(api.get("order", {
					params: {
						filter: "client",
						name: this.state.Cliente,
						from: this.state.Desde,
						to: this.state.Hasta,
						factura,
						trashed
					}
				}));
				break;
			case "Ultimo":
				this.handleResponse(api.get("order", {
					params: {
						filter: "latest",
						amount: this.state.Ultimo,
						factura,
						trashed
					}
				}));
				break;
			case "Vendedor":
				this.handleResponse(api.get("order", {
					params: {
						filter: "seller",
						id: this.state.Vendedor,
						from: this.state.Desde,
						to: this.state.Hasta,
						factura,
						trashed
					}
				}));
				break;
			case "Rango de Fecha":
				this.handleResponse(api.get("order", {
					params: {
						filter: "date:range",
						from: this.state.Desde,
						to: this.state.Hasta,
						factura,
						trashed
					}
				}));
				break;
			case "No. Viaje":
				this.handleResponse(api.get("order", {
					params: {
						filter: "service",
						service: this.state.Servicio,
						trashed,
						factura
					}
				}));
		}
	}

	handleSearchChange(e) {
		this.setState({search: e.target.value});
	}

	renderForm(props) {
		let {grid, search} = props;
		switch (search) {
			case "Rango de Fecha":
				return (
					<Group grid={grid} group={false}>
						<Input label="Desde"
						       type="date"
						       grid={6}
						       state={this.linkState("Desde")}
						/>
						<Input label="Hasta"
						       type="date"
						       grid={6}
						       state={this.linkState("Hasta")}
						       extra={{min: this.state.Desde}}
						/>
					</Group>
				);
			case "Fecha":
			case "Fecha de captura":
				return (
					<Input label="Fecha"
					       type="date"
					       grid={grid}
					       state={this.linkState("Fecha")}
					/>
				);
			case "Folio":
				return (
					<Input label="Folio"
					       type="text"
					       grid={grid}
					       state={this.linkState("Folio")}
					/>
				);
			case "No. Viaje":
				return (
					<Input label="No. Viaje"
					       type="number"
					       extra={{step: 1, min: 1}}
					       grid={grid}
					       state={this.linkState("Servicio")}
					/>
				);
			case "Ultimo":
				return (
					<Input label="Cantidad"
					       type="number"
					       grid={grid}
					       state={this.linkState("Ultimo")}
					/>
				);
			case "Destino":
				return (
					<Input label="Destino"
					       type="text"
					       grid={grid}
					       state={this.linkState("Destino")}
					/>
				);
			case "Vendedor":
				return (
					<Group grid={grid} group={false}>
						<Input label="Vendedor"
						       type="select"
						       options={this.state.sellers.map(s => ({show: s.name,value:s.id}))}
						       state={this.linkState("Vendedor")}
						/>
						<Input label="Desde"
						       type="date"
						       state={this.linkState("Desde")}
						/>
						<Input label="Hasta"
						       type="date"
						       state={this.linkState("Hasta")}
						       extra={{min: this.state.Desde}}
						/>
					</Group>
				);
			case "Cliente":
				return (
					<Group grid={grid} group={false}>
						<Input type="autocomplete"
						       grid={4}
						       label={"Cliente"}
						       state={this.linkState("Cliente")}
						       disabled={this.state.filter === "0"}
						       autocomplete={{
					            index: "clients",
					            displayKey: "nombre",
					            keyName: "nombre",
					            onSelect: (e,{nombre: Cliente}) => this.setState({Cliente},this.search)
					       }}
						/>
						<Input label="Desde"
						       type="date"
							   grid={4}
						       state={this.linkState("Desde")}
						/>
						<Input label="Hasta"
						       type="date"
							   grid={4}
						       state={this.linkState("Hasta")}
						       extra={{min: this.state.Desde}}
						/>
					</Group>
				);
			default:
				return (
					<Input type="text" value=""/>
				)
		}
	}

	render() {
		const SearchForm = this.renderForm;
		return (
			<div>
				<Group handleForm={this.search.bind(this)} title={"Consultar ordenes"} className={"consulta-form"} margin minimize={true}>
					<Input label="Tipo de búsqueda"
					       type="select"
					       grid={[12,6,3]}
					       options={["Ultimo","Fecha","Rango de Fecha","Folio", "Cliente","No. Viaje","Vendedor","Fecha de captura"].sort().map((s)=> ({show: s, value: s}))}
					       state={{value: this.state.search, onChange: this.handleSearchChange.bind(this)}}
						   autoFocus={true}
					/>
					<SearchForm search={this.state.search} grid={[12,6,9]}/>
					<Input type="select"
						   grid={[12,6,4]}
						   label="Precios en roll"
						   options={[
							   {show: "Predeterminado", value: "0"},
							   {show: "Con precios", value: "1"},
							   {show: "Sin precios", value: "2"},
						   ]}
						   state={this.linkState("withPrices")}
					/>
					<Input type="select"
					       grid={[12,6,4]}
					       label="Factura"
					       disabled={this.state.search === "Folio" || this.state.search === "No. Viaje"}
					       options={[
					            {show: "Todos", value: "0"},
					            {show: "Con factura", value: "1"},
					            {show: "Sin factura", value: "2"},
					       ]}
					       state={this.linkState("factura",{value: (value) => this.state.search === "Folio" || this.state.search === "No. Viaje" ? "0" : value})}
					/>
					<Input type="select"
					       grid={[12,6,4]}
					       label="Estado"
					       options={[
					            {show: "Todos", value: "0"},
					            {show: "Cancelados", value: "1"},
					            {show: "No cancelados", value: "2"},
					       ]}
					       state={this.linkState("withTrashed")}
					/>
					<Input text="Buscar"
					       type="submit"
					       grid={[12,6,4]}
					       button={{color: "success"}}
					/>
					<Input text="Generar ordenes"
					       type="button"
					       grid={[6,6,4]}
					       button={{color: "warning"}}
					       disabled={!this.state.ordenes.length}
					       click={this.order.bind(this)}
					/>
					<Input text="Generar roll"
					       type="button"
					       grid={[6,6,4]}
					       button={{color: "warning"}}
					       disabled={!this.state.ordenes.length}
					       click={this.roll.bind(this)}
					/>
					<Input type={"checkbox"}
					       grid={[4,4,4]}
					       label="Asignaciones"
					       state={this.linkCheckState("con_asignaciones")}
					       required={false}
					       className={"text-center"}
					/>
					<Input type={"checkbox"}
					       grid={[4,4,4]}
					       label="Precios"
					       state={this.linkCheckState("con_precios")}
					       required={false}
					       className={"text-center"}
					/>
					<Input type={"checkbox"}
					       grid={[4,4,4]}
					       label="Pagos"
					       state={this.linkCheckState("con_pagos")}
					       required={false}
					       className={"text-center" }
					/>
				</Group>
				<Group title="Resultado(s)" minimize={true} margin center={true}>
					<ReadOnly grid={3}
							  type={"number"}
							  label="Ordenes"
							  value={this.state.ordenes.length}
					/>
					<ReadOnly grid={3}
							  type={"number"}
							  label="Unidades"
							  value={this.state.ordenes.reduce((carry,order) => {
								  return carry + order.buses.length;
							  },0)}
					/>
					<ReadOnly grid={3}
							  type={"number"}
							  label="Cancelados"
							  value={this.state.ordenes.reduce((carry,order) => {
							  	if (order.deleted_at) {
									return ++carry;
								} else {
									return carry;
								}
							  },0)}
					/>
				</Group>
				<ConsultList orders={this.state.ordenes}
				             asignaciones={this.state.con_asignaciones}
				             precios={this.state.con_precios}
				             pagos={this.state.con_pagos}
				/>
			</div>
		);
	}
}