import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Input} from "../../../components/forms/input.jsx";

const FOLIO = "0";
const RANGO_FECHAS = "1";
const FECHA = "2";

export class SearchInputs extends Component {
	static propTypes = {
		parent: PropTypes.any.isRequired
	};
	static defaultProps = {};

	render() {
		const {parent, grid, parent: {
			state: {
				filter
			}
		}} = this.props;
		switch (filter) {
			case FOLIO:
				return (
					<Input type="number"
					       label="Folio"
					       state={parent.linkState("folio")}
					       required
					       disabled={parent.state.data}
					       extra={{min: 1, step: 1}}
					       grid={grid}
					/>
				);
			case RANGO_FECHAS:
				return (
					<Group group={false} grid={grid}>
						<Input type="date"
						       grid={[12,6]}
						       label="Inicio"
						       state={parent.linkState("from")}
						       required
						       disabled={parent.state.data}
						/>
						<Input type="date"
						       grid={[12,6]}
						       label="Fin"
						       state={parent.linkState("to")}
						       required
						       disabled={parent.state.data}
						/>
					</Group>
				);
			case FECHA:
				return (
					<Input type="date"
					       label="Fecha"
					       state={parent.linkState("date")}
					       required
					       disabled={parent.state.data}
					       grid={grid}
					/>
				);
			default:
				console.error(`Invalid option for RenderSearchInputs component, received: ${filter}`);
				return null;
		}
	}
}
