import React, {Component} from "react";
import PropTypes from "prop-types"

import Grid from "helper/grid";
import {objectToClass} from "helper/objectToClass";
import {Collapse} from 'react-collapse';
import ReactTooltip from 'react-tooltip'
import {open,exit,listenChange, removeListener, check} from "helper/fullscreen"

export default class Group extends Component {
	static propTypes = {
		className: PropTypes.string,
		title: PropTypes.string,
		grid: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.bool,
			PropTypes.arrayOf(PropTypes.number)
		]),
		children: PropTypes.node,
		form: PropTypes.bool,
		handleForm: PropTypes.func,
		hidden: PropTypes.bool,
		group: PropTypes.bool,
		style: PropTypes.object,
		collapse: PropTypes.bool,
		id: PropTypes.string,
		rowClassName: PropTypes.string,
		margin: PropTypes.bool,
		center: PropTypes.bool,
		minimize: PropTypes.bool,
		minimizeState: PropTypes.bool,
		minimizeEvent: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.bool
		]),
		fullscreen: PropTypes.bool
	};
	static defaultProps = {
		rowClassName: "",
		className: "",
		grid: true,
		hidden: false,
		group: true,
		form: false,
		collapse: false,
		style: {},
		margin: false,
		center: true,
		minimize: false,
		minimizeState: false,
		minimizeEvent: false,
		fullscreen: false
	};

	constructor (props) {
		super(props);
		this.state = {
			minimized: props.minimizeState,
			fullscreen: document.fullscreen,
			me: this.id()
		};
		this.switchFullscreenState = this.switchFullscreenState.bind(this);
	}


	id () {
		// Math.random should be unique because of its seeding algorithm.
		// Convert it to base 36 (numbers + letters), and grab the first 9 characters
		// after the decimal.
		return '_' + Math.random().toString(36).substr(2, 9);
	}

	switchFullscreenState (e) {
		this.setState({
			fullscreen: check()
		});
	}
	componentDidMount () {
		listenChange(this.switchFullscreenState);
	}

	componentWillUnmount () {
		removeListener(this.switchFullscreenState);
	}
	render () {
		const {collapse, center, rowClassName, minimize, fullscreen} = this.props;
		let title = null;
		let content = null;
		let fullscreenId = `fullscreen${this.state.me}`;
		let minimizeId = `minimize${this.state.me}`;
		if (this.props.title) {
			title = (
				<span className="columns small-12 group-title" style={{"position": "relative"}}>
                    <b>{this.props.title}</b>
					{minimize ? (
						<div data-for={minimizeId} className="switch tiny" style={{
							position: "absolute",
							right: "0.3rem",
							top: "0.3rem",
							marginBottom: "0"
						}}>
							<input className="switch-input" id={minimizeId} type="checkbox" checked={!this.state.minimized} onChange={({target}) => {
								this.setState({minimized: !target.checked})
							}} style={{
								height: "24px"
							}}/>
							<label className="switch-paddle" htmlFor={minimizeId}>
								<span className="show-for-sr">{this.state.minimized ? "Abrir" : "Cerrar"} {this.props.title} </span>
							</label>
							<ReactTooltip id={minimizeId} effect="solid" getContent={() => `${this.state.minimized ? "Maximiza" : "Minimiza"} el contenido de este elemento`}/>
						</div>
					) : null}
					{fullscreen ? (
						<div data-for={fullscreenId} className="switch tiny" style={{
							position: "absolute",
							left: "0.3rem",
							top: "0.3rem",
							marginBottom: "0"
						}}>
							<input className="switch-input" id={fullscreenId} type="checkbox" checked={this.state.fullscreen} onClick={() => {
								if (!check()) {
									open(this._container);
								} else {
									exit();
								}
							}} style={{
								height: "24px"
							}}/>
							<label className="switch-paddle" htmlFor={fullscreenId}>
								<span className="show-for-sr">{this.state.fullscreen ? "Minimizar" : "Maximizar"} {this.props.title}</span>
							</label>
							<ReactTooltip id={fullscreenId} effect="solid" getContent={() => `Ver pantalla completa de este elemento`}/>
						</div>
					) : null}
                </span>
			);
		}
		const rowClasses = ['row', ...objectToClass({
			collapse,
			"align-spaced": center
		}), rowClassName].join(' ').trim();

		const theme = {
			collapse: 'columns small-12',
			content: rowClasses
		};

		if (this.props.handleForm || this.props.form) {
			content = (
				<form className={rowClasses} onSubmit={this.props.handleForm} ref={form => this.form = form}
					  id={this.props.id}>
					{title}
					{minimize ? (
						<Collapse isOpened={!this.state.minimized} theme={theme}>
							{this.props.children}
						</Collapse>
					) : this.props.children}
				</form>
			);
		} else {
			content = (
				<div className={rowClasses}>
					{title}
					{minimize ? (
						<Collapse isOpened={!this.state.minimized} theme={theme}>
							{this.props.children}
						</Collapse>
					) : this.props.children}
				</div>
			);
		}
		const className = `${this.props.group ? "group" : ""} ${Grid(this.props.grid)} ${this.props.className} ${this.props.hidden ? "hide" : ""} ${this.props.margin ? "with-margin" : ""}`.trim();
		return (
			<div className={className} style={this.props.style} ref={(c) => this._container = c}>
				{content}
			</div>
		)
	}
}
