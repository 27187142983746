import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Payment} from "../paga/payment";

export class ConsultItemPagos extends Component {
	static propTypes = {
		payments: PropTypes.array.isRequired
	};
	static defaultProps = {
		payments: []
	};

	render() {
		return (
			<Group grid={12} group={false} title="Pagos">
				<Payment payments={this.props.payments} deletes={false}/>
			</Group>
		);
	}
}
