import React, {Component} from "react";
import PropTypes from "prop-types"
import {url} from "helper/rest";
import PermissionStore from "../stores/PermissionStore";
import "./nav.scss";
import {Sub} from "./sub";

const logo = `${url}/static/logo`;

export class Nav extends Component {
	static propTypes = {
		links: PropTypes.array.isRequired,
		root: PropTypes.string
	};

	render() {
		const makelinks = (parentLink) => {
			return ({name, to, links = false, component, role, permission,hide}, i) => {
				if (hide) {
					return null;
				}
				/*
				 Friendly reminder to never reassign parameters it fucks the little heart of js and mine
				 */
				if (parentLink === "") return null;
				const newLink = (parentLink && to) ? `${parentLink}/${to}` : false;
				if (PermissionStore.can(role, permission)) {
					if (Array.isArray(links) && links.length > 0) {
						return (
							<Sub key={i} to={newLink || to} name={name} clickable={Boolean(component)}>
								{links.map(makelinks(newLink || to))}
							</Sub>
						);
					} else {
						if (name) {
							return (
								<Sub key={i} to={newLink || to} name={name} clickable={Boolean(component)}/>
							);
						} else {
							return null
						}
					}
				} else {
					return null
				}
			};
		};
		const menus = this.props.links.map(makelinks(this.props.root));
		return (
			<nav className={["no-select",this.props.open ? "open" : ""].join(" ")} id="sidebar">
				<ul>
					{menus}
					<div className="nav-logo">
						<img src={logo} className="logo" alt="logo"/>
					</div>
				</ul>
			</nav>
		);
	}
}
