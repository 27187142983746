// @flow

import React, {
	Component
} from 'react';

import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import {api} from "../../../../helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import DataGrid from "react-data-grid";
import AlertStore from "stores/AlertStore";
import DataStore from "stores/DataStore";
import date from "helper/date";
import {debounce} from "helper/debounce";
import {money} from "helper/formatters";

export class ValeGastos extends Component {
	static propTypes = {};
	static defaultProps = {};
	columns = [
		{key: "service", name: "No. viaje"},
		{key: "date", name: "Fecha", formatter: ({value}) => <span>{date(value)}</span>},
		{key: "expense_type", name: "Tipo"},
		{key: "external_business", name: "Empresa externa"},
		{key: "reference_type", name: "Tipo de referencia"},
		{key: "reference", name: "Referencia"},
		{key: "import", name: "Importe", formatter: money},
		{key: "comment", name: "Comentario"},
	];

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = this.initialState = {
			service_data: null,
			service: "",
			date: "",
			expense_type: "",
			external_business: "",
			reference: "",
			reference_type: "",
			import: "",
			comment: "",
			data: [],
			external_businesses: DataStore.getModel("external_businesses"),
			expense_types: DataStore.getModel("expense_types"),
			editing: false,
			selected: null,
			grid: null
		};
		this.requests = [];
		this.serviceExists = debounce(this.serviceExists.bind(this), 150);
		this.updateExpenseTypes = this.updateExpenseTypes.bind(this);
		this.updateExternalBusinesses = this.updateExternalBusinesses.bind(this);
	}

	serviceExists(service: string) {
		this.addRequest(ajax(api.get(`/service/${service}`)))
			.promise
			.then(({data: service_data}) => {
				if (service_data.has_liquidation) {
					this.service.setCustomValidity("El no. de viaje se encuentra en modo lectura por que pertenece a una liquidación");
					this.setState({
						service_data: null
					});
					return;
				} else {
					this.setState({
						service_data
					});
					this.service.setCustomValidity("");
				}
			})
			.catch(() => {
				this.setState({
					service_data: null
				});
				this.service.setCustomValidity("El no. de viaje proporcionado no existe");
			})
	}

	addRequest(request) {
		this.requests.push(request);
		return request;
	}


	componentWillUpdate(nextProps, nextState) {
		//is valid
		if (this.state.service !== nextState.service && nextState.service !== "") {
			this.service.setCustomValidity("Validando...");
			this.serviceExists(nextState.service);
		} else {
			if (nextState.service === "") {
				this.service.setCustomValidity("");
			}
		}
	}
	componentWillMount() {
		this.grid();
		DataStore.on("EXTERNAL_BUSINESSES_UPDATED", this.updateExternalBusinesses);
		DataStore.on("EXPENSE_TYPES_UPDATED", this.updateExpenseTypes);
	}

	componentWillUnmount() {
		DataStore.removeListener("EXTERNAL_BUSINESSES_UPDATED", this.updateExternalBusinesses);
		DataStore.removeListener("EXPENSE_TYPES_UPDATED", this.updateExpenseTypes);
	}

	updateExternalBusinesses() {
		this.initialState.external_businesses = DataStore.getModel("external_businesses");
		this.setState({
			external_businesses: DataStore.getModel("external_businesses")
		});
	}

	updateExpenseTypes() {
		this.initialState.expense_types = DataStore.getModel("expense_types");
		this.setState({
			expense_types: DataStore.getModel("expense_types")
		});
	}

	handleDelete(e, enable) {
		this.setState({
			...this.initialState,
			data: this.state.data.filter((e, i) => i !== this.state.selected),
		}, enable);
	}

	handleSubmit(e, enable) {
		if (this.state.data.length === 0) {
			AlertStore.addToQueue({
				text: "Registre al menos un vale",
				type: "warning"
			});
			enable();
		} else {
			ajax(api.post("mass/service/expense", {
				data: this.state.data
			}), {
				action: true, success: {
					text: "Servicios guardados"
				}
			})
				.promise
				.then(() => {
					this.setState({
						data: []
					});
				})
				.then(enable, enable);
		}
	}

	handleForm(e) {
		e.preventDefault();
		let {expense_types, external_businesses} = this.state;
		let data = {...this.state};
		delete data.expense_types;
		delete data.external_businesses;
		delete data.data;
		delete data.editing;
		delete data.selected;
		delete data.service_data;
		delete data.grid;
		let newData = [];
		if (this.state.editing) {
			let currentData = [...this.state.data];
			currentData[this.state.selected] = data;
			newData = currentData;
		} else {
			newData = [
				...this.state.data,
				data
			];
		}
		this.setState({
			...this.initialState,
			data: newData,
			expense_types,
			external_businesses,
			editing: false
		},this.grid.bind(this));
		this.service.focus();
	}

	onRowClick(row) {
		this.setState({
			selected: row,
			...this.state.data[row],
			editing: true
		});
	}

	grid () {
		this.setState({
			grid: <DataGrid columns={this.columns}
			                rowGetter={(i)=>this.state.data[i]}
			                rowsCount={this.state.data.length}
			                minHeight={400}
			                emptyRowsView={() => (
					          	  <em style={{display: "block", margin: "1em 0",textAlign: "center"}}>
					          	      Registre sus vales
					          	  </em>
					          )}
			                onRowClick={this.onRowClick.bind(this)}
			                rowSelection={{
					              showCheckbox: false,
					              selectBy: {
					                  indexes: [this.state.selected]
					              }
					        }}
			/>
		});
	}


	render() {
		return (
			<div>
				<Group title={"Datos"} handleForm={this.handleForm.bind(this)} margin>
					<Input type="number"
					       label={"No. viaje"}
					       state={this.linkState("service")}
					       grid={[12,3]}
					       getInput={(ref) => this.service = ref}
					/>
					<Input type="date"
					       label={"Fecha"}
					       state={this.linkState("date")}
					       extra={{min: this.state.service_data ? this.state.service_data.order.salida.split(" ")[0] : ""}}
					       grid={[12,3]}
					/>
					<Input type="text"
					       datalist={this.state.expense_types.map(t => t.type)}
					       datalistId={"type"}
					       label={"Tipo de gasto"}
					       state={this.linkState("expense_type")}
					       grid={[12,3]}
					/>
					<Input type="text"
					       datalist={this.state.external_businesses.map(x => x.name)}
					       datalistId={"ext_business"}
					       label={"Empresa externa"}
					       state={this.linkState("external_business")}
					       grid={[12,3]}
					/>
					<Input type="select"
					       options={[{show: "Nota", value: "Nota"},{show: "Factura", value: "Factura"}]}
					       label={"Tipo de referencia"}
					       state={this.linkState("reference_type")}
					       grid={[12,4]}
					/>
					<Input type="text"
					       label={"Referencia"}
					       state={this.linkState("reference")}
					       grid={[12,4]}
					/>
					<Input type="number"
					       label={"Importe"}
					       state={this.linkState("import")}
					       grid={[12,4]}
					       extra={{min: 0,step: "any"}}
					/>
					<Input type="textarea"
					       label={"Comentario"}
					       state={this.linkState("comment")}
					       grid={[12,true]}
					       required={false}
					       extra={{max: "255"}}
					/>
					<Input type="submit"
					       button={{color: "success"}}
					       text={this.state.editing ? "Actualizar" : "Agregar"}
					       grid={[12,true]}
					/>
					{this.state.editing ? (
						<Input type="button"
						       button={{color: "alert"}}
						       text="Quitar"
						       click={this.handleDelete.bind(this)}
						       grid={[12,true]}
						/>
					) : null}
				</Group>
				<div className="group with-margin">
					<Group title={"Vales de gastos comprobados"}/>
					{this.state.grid}
				</div>
				<Group>
					<Input type="button"
					       text={"Guardar"}
					       button={{color: "success"}}
					       click={this.handleSubmit.bind(this)}
					/>
				</Group>
			</div>
		);
	}
}