import React, {
	Component,
} from 'react';

import {Catalog} from"components/catalog/catalog";
import {FormSocios} from "./formSocios";

export class Socios extends Component {
	render() {
		return (
			<Catalog columns={[
					{key: "name",name: "Nombre"},
				]}
			         keys={["name"]}
			         filter={(data: Array) => data.filter(({external}) => !external)}
			         singular={"Socio"}
			         model={"partners"}
			         name={"Socios"}>
				<FormSocios/>
			</Catalog>
		);
	}
}
