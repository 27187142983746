export const idParse = (search, filter = 'auto') => {
    switch (filter) {
        case 'auto':
            let s = new Searchable(search);
            switch (true) {
                case s.isValidList():
                    return s.getList();
                case s.isValidSingle():
                    return [s.getSingle()];
                default:
                    throw new Error('invalid_query')
            }
    }
};

class Searchable {
    listRegex = /^\d+(,\d+)+,?$/;
    rangeRegex = /^(\d+)-(\d+)$/;
    singleRegex = /^\d+$/;

    constructor(search) {
        this.search = search;
    }

    isValidList() {
        return this.listRegex.test(this.search);
    }

    getList() {
        // get an array of numbers and filter those empty entries
        return this.search.split(',').filter(x => x);
    }

    isValidRange() {
        return this.rangeRegex.test(this.search);
    }

    isValidSingle() {
        return this.singleRegex.test(this.search);
    }

    getSingle () {
        return this.search;
    }
}
