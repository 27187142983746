import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Input, ReadOnly} from "../../../components/forms/input.jsx";
import {api} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import DataStore from "../../../stores/DataStore";
import AlertStore from "../../../stores/AlertStore";
import ModalStore from "../../../stores/ModalStore";
import linkState, {linkCheckState} from "../../../helper/state";
import {GoogleMap} from "../../../components/maps/googleMap";
import Modal from "../../../components/modal";
import {GoogleMapAutocomplete} from "../../../components/maps/googleMapAutocomplete";
import {GmapSearch} from "../../../components/maps/gmapSearch";
import moment from "moment";
import { money, getMoney } from 'helper/formatters'

export class Ruta extends Component {
	static propTypes = {
		data: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			client_id: PropTypes.number,
			responsible_name: PropTypes.string,
			responsible_phone: PropTypes.string,
			destination: PropTypes.string,
			destination_location: PropTypes.string,
			pickup_name: PropTypes.string,
			pickup_calle: PropTypes.string,
			pickup_colonia: PropTypes.string,
			pickup_delegacion: PropTypes.string,
			pickup_location: PropTypes.string,
			observations: PropTypes.string,
			departs_at: PropTypes.string,
			arrives_at: PropTypes.string,
			arrives_after: PropTypes.number,
			monday: PropTypes.bool,
			tuesday: PropTypes.bool,
			wednesday: PropTypes.bool,
			thursday: PropTypes.bool,
			friday: PropTypes.bool,
			saturday: PropTypes.bool,
			sunday: PropTypes.bool,
			units: PropTypes.array,
			sits: PropTypes.number,
			bus_model_id: PropTypes.number,
			price_per_unit: PropTypes.string,
			with_map: PropTypes.bool,
			with_bill: PropTypes.bool,
			with_price: PropTypes.bool,
		})
	};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.linkCheckState = linkCheckState.bind(this);
		this.initialState = {
			name: '',
			client: '',
			client_id: '',
			responsible_name: '',
			responsible_phone: '',
			destination: '',
			destination_location: null,
			pickup_name: '',
			pickup_calle: '',
			pickup_colonia: '',
			pickup_delegacion: '',
			pickup_location: null,
			observations: '',
			departs_at: '',
			arrives_at: '',
			arrives_after: '0',
			units: '',
			sits: '',
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false,
			units_defaults: [],
			bus_model_id: '',
			price_per_unit: '',
			with_map: false,
			with_price: false,
			with_bill: false,
            update_tax_rate: false,
			autocompleted: false,
			buses: DataStore.getModel("buses"),
			operators: DataStore.getModel("operators"),
			models: DataStore.getModel("models"),
			config_tax_rate: DataStore.getConfig('tax_rate')
		};
		this.state = {...this.initialState};

		this.stores = [
			["MODELS_UPDATED", this.listener("models")],
			["BUSES_UPDATED", this.listener("buses")],
			["OPERATORS_UPDATED", this.listener("operators")],
			["CONFIGS_UPDATED", () => {
				this.setState({
					config_tax_rate: DataStore.getConfig('tax_rate')
				})
			}],
		];
	}

	listener(model) {
		return () => {
			this.setState({
				[model]: DataStore.getModel(model)
			});
		}
	}

	cancel () {
		let {buses,operators,models} = this.state;
		this.setState({
			...this.initialState,
			buses,
			operators,
			models
		});
	}

	fill (data) {
		this.id = data.id;
		this.filling = true;
		let copy = {...data};
		delete copy.id;
		delete copy.client;
		delete copy.vehicle;
		this.setState({
			...copy,
			autocompleted: true,
			arrives_at: moment(copy.arrives_at,"HH:mm:ss").format("HH:mm"),
			departs_at: moment(copy.departs_at,"HH:mm:ss").format("HH:mm"),
			client: data.client.nombre,
			units_defaults: data.units_defaults.map(({operator_id,bus_id}) => ({
				bus_id: bus_id,
				operator_id: operator_id
			}))
		});
	}

	componentWillUnmount() {
		this.stores.forEach(store => {
			DataStore.removeListener(...store);
		});
	}

	componentWillMount() {
		if (this.props.data) {
			this.fill(this.props.data);
		}
		this.stores.forEach(store => {
			DataStore.on(...store);
		});
	}

	assignBus (i) {
		return {
			value: this.state.units_defaults[i].bus_id,
			onChange: ({target: {value}}) => {
				let copy = [...this.state.units_defaults];
				copy[i] = {
					...copy[i],
					bus_id: value
				};
				let bus = this.state.buses.find(bus => bus.id == value);
				if (bus && bus.symbolic) {
					copy[i] = {
						...copy[i],
						operator_id: ""
					};
				}
				if (bus && bus.operator) {
					copy[i] = {
						...copy[i],
						operator_id: bus.operator.id
					};
				}
				this.setState({
					units_defaults: copy
				});
			}
		}
	}

	assignOp (i) {
		return {
			value: this.state.units_defaults[i].operator_id,
			onChange: ({target: {value}}) => {
				let copy = [...this.state.units_defaults];
				copy[i] = {
					...copy[i],
					operator_id: value
				};
				this.setState({
					units_defaults: copy
				});
			}
		}
	}

	handleFreeBuses() {
		const {buses} = this.state;
		const usedBuses = this.state.units_defaults.map(s => s.bus_id);
		return [{show: "--- Sin Asignar ---", value: ""}].concat(buses.map((bus) => {
			let b = {
				value: bus.id,
				show: bus.full_name,
				disabled: false
			};
			if (usedBuses.find(ubus => ubus == bus.id) && !bus.symbolic) {
				b.disabled = true;
			}
			return b;
		}));
	}

	handleFreeOperators() {
		const {operators} = this.state;
		const usedOps = this.state.units_defaults.map(s => s.operator_id);
		return [{show: "--- Sin Asignar ---", value: ""}].concat(operators.map((op) => {
			let o = {
				value: op.id,
				show: op.nombre,
				disabled: false
			};
			if (usedOps.find(uop => uop == op.id)) {
				o.disabled = true;
			}
			return o;
		}));
	}

	handleSubmit (e) {
		if (e) {
			e.preventDefault();
		}
		function deleteData(data, properties: Array) {
			let copy = {...data};
			properties.forEach(prop => {
				delete copy[prop];
			});
			return copy
		}

		let data = deleteData(this.state, [
			"autocomplete",
			"client",
			"buses",
			"operators",
			"models"
		]);
		data.units_defaults = data.units_defaults.map(({
			operator_id,
			bus_id
		}) => ({
			operator_id: operator_id || null,
			bus_id: bus_id || null,
		}));
		if (this.filling) {
			ajax(api.patch(`routes/${this.id}`, data), {action: true,success: {
				text: `Ruta actualizada`
			}},true)
				.then(() => {
					this.props.close();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						if (error.response.status === 422) {
							let {name} = error.response.data;
							if (name && name[0]) {
								AlertStore.addToQueue({
									text: "El nombre debe ser unico",
									type: "error"
								});
							}
						}
					}
				});
		} else {
			ajax(api.post("routes", data), {action: true,success: {
				text: `Ruta creada`
			}},true)
				.catch((error) => {
					if (error.response && error.response.data) {
						if (error.response.status === 422) {
							let {name} = error.response.data;
							if (name && name[0]) {
								AlertStore.addToQueue({
									text: "El nombre debe ser unico",
									type: "error"
								});
							}
						}
					}
				})
				.then(() => {
					this.cancel();
					this._continue.focus()
				})
		}
	}


	autoCompleteDestination (place) {
		this.setState({
			destination: place.formatted_address,
			destination_location: JSON.stringify(place)
		});
	}

	autoCompletePickup(place) {


		if (!place.geometry) {
			AlertStore.addToQueue({
				text: "No se pudo procesar la dirección correctamente",
				type: "error",
				allowEscapeKey: false,
				allowOutsideClick: false
			});
			return;
		}

		let findType = (type): string | null => {
			let result = place.address_components.find(comp => comp.types.find(types => types === type));
			if (result) {
				return result.long_name
			} else {
				return null;
			}
		};

		let typeFound = null;
		let found = place.types.find(types => {
			if (
				//direcciones
			types === "street_address" ||
			//calles
			types === "route" ||
			//metros
			types === "subway_station" ||
			types === "premise"
			) {
				typeFound = types;
				return true;
			}
		});
		if (found) {
			let calle = "";
			let colonia = "";
			let delegacion = "";
			if (typeFound === "subway_station" || typeFound === "premise") {
				if (findType("route")) {
					calle = findType("route");
				} else {
					calle = place.formatted_address.split(",")[0]
				}
				if (findType("sublocality_level_1")) {
					colonia = findType("sublocality_level_1");
				}
				if (findType("administrative_area_level_3")) {
					delegacion = findType("administrative_area_level_3");
				}
			}
			if (typeFound === "street_address") {
				calle = findType("route") + " #" + findType("street_number");
				colonia = findType("sublocality_level_1");
				delegacion = findType("administrative_area_level_3");
			}
			if (typeFound === "route") {
				if (findType("route")) {
					calle = findType("route");
				} else {
					calle = place.formatted_address.split(",")[0]
				}
				if (findType("sublocality_level_1")) {
					colonia = findType("sublocality_level_1");
				}
				if (findType("administrative_area_level_3")) {
					delegacion = findType("administrative_area_level_3");
				}
			}
			this.setState({
				pickup_calle: calle,
				pickup_colonia: colonia,
				pickup_delegacion: delegacion,
				pickup_location: JSON.stringify(place)
			},() => {
				this._next2.focus();
			});
		} else {
			AlertStore.addToQueue({
				text: "La búsqueda necesita ser mas especifica",
				type: "info",
				allowEscapeKey: false,
				allowOutsideClick: false
			});
		}
	}

	componentWillUpdate(nextProps, nextState) {
		const nextUnits = parseInt(nextState.units) || 0;
		const prevUnits = parseInt(this.state.units) || 0;
		if (nextUnits !== prevUnits) {
			let units_defaults;
			if (nextUnits > prevUnits) {
				units_defaults = this.state.units_defaults.concat(
					new Array((nextUnits - prevUnits)).fill({
						bus_id: "",
						operator_id: ""
					})
				)
			} else {
				units_defaults = this.state.units_defaults.splice(0, nextUnits);
			}
			this.setState({
				units_defaults
			});
		}
	}

	handlePickupSearch() {
		ModalStore.openModal("pickup-search", {
			calle: this.state.pickup_calle,
			colonia: this.state.pickup_colonia,
			delegacion: this.state.pickup_delegacion
		});
	}

    renderNumber (flo) {
        return getMoney(flo);
    }


    getTaxRate() {
		if (this.props.data) {
            console.log(this.props.data.tax_rate);
			if (this.props.data.tax_rate) {
                return parseFloat(this.props.data.tax_rate) / 100;
			} else {
                return 0;
			}
		} else {
			if (this.state.with_bill && this.state.config_tax_rate && this.state.config_tax_rate.rate) {
				return parseFloat(this.state.config_tax_rate.rate) / 100
			} else {
				return 0;
			}
		}
    }

    getTax() {
        return this.getSale() * this.getTaxRate();
    }

    getSale() {
        const costoUnitario = this.state.price_per_unit ? this.state.price_per_unit : 0;
        return parseFloat(costoUnitario);
    }

    getTotal() {
        return this.getSale() + this.getTax();
    }


	render() {
		if (this._clientInput) {
			if (!this.state.autocompleted) {
				if (this.state.client === "") {
					this._clientInput.setCustomValidity("");
				} else {
					this._clientInput.setCustomValidity("Nombre de cliente incompleto");
				}
			} else {
				this._clientInput.setCustomValidity("");
			}
		}
		return (
			<div>
				<Modal id={"pickup-search"} grid={[12,8,6]}>
					<GmapSearch autocomplete={this.autoCompletePickup.bind(this)}/>
				</Modal>
				<Modal id="pickup-map" grid={[12,8,6]}>
					<Group title="Dirección para presentarse">
						<GoogleMap id="pickup"
						           bound
						           listener={{"place_changed": this.autoCompletePickup.bind(this)}}
						/>
					</Group>
				</Modal>
				<Group handleForm={this.handleSubmit.bind(this)}>
					<Group group={false} title={"Ruta"}>
						<Input type="text"
						       label={"Nombre de la ruta"}
						       state={this.linkState("name")}
						       getInput={(input) => this._continue = input}
							   autoFocus={true}
						/>
					</Group>
					<Group group={false} title={"Datos del cliente"} grid={12}>
						<Input type="autocomplete"
						       label={"Cliente"}
						       grid={[12,4]}
						       state={this.linkState("client",{onChange: (e) => {
						            if (this.client_temp !== null && e.target.value !== this.client_temp) {
						                this.setState({autocompleted: false, client_id: null});
						                this.client_temp = null;
						            }
						            return e.target.value;
						       }})}
						       required
						       getInput={(ref) => this._clientInput = ref}
						       autocomplete={{
						            index: "clients",
						            displayKey: "nombre",
						            keyName: "nombre",
						            onSelect: (e,{nombre: client,id: client_id}) => {
						                this.client_temp = client;
						                this.setState({client,client_id, autocompleted: true});
						            }
						       }}
						/>
						<Input type="text"
						       label={"Nombre del responsable"}
						       state={this.linkState("responsible_name")}
						       grid={[12,4]}
						/>
						<Input type="text"
						       label={"Telefono del responsable"}
						       state={this.linkState("responsible_phone")}
						       required={false}
						       grid={[12,4]}
						/>
					</Group>
					<Group group={false} title={"Datos de la ruta"} grid={12}>
						<GoogleMapAutocomplete listener={{"place_changed": this.autoCompleteDestination.bind(this)}}
						                       types={null}>
							<Input state={this.linkState("destination")}
							       label="Destino"
							       type="text"
							       grid={[12,12,this.state["destination_location"] ? 9 : 12]}
							       extra={{id: "destino"}}
							/>
						</GoogleMapAutocomplete>
						{this.state["destination_location"] ? (
							<Input type="button"
							       text={"Eliminar autocompletado"}
							       click={()=>{
							            this.setState({
							                "destination_location": null,
							                "destination": null
							            });
							       }}
							       button={{color: "alert"}}
							       grid={[12,12,3]}
							/>
						) : null}
						<Input type="text"
						       label={"Presentarse en"}
						       state={this.linkState("pickup_name")}
						       grid={[12]}
						/>
						<Input type="text"
						       label={"Calle"}
						       state={this.linkState("pickup_calle")}
						       grid={[12,4]}
						/>
						<Input type="text"
						       label={"Colonia"}
						       state={this.linkState("pickup_colonia")}
						       grid={[12,4]}
						/>
						<Input type="text"
						       label={"Delegación"}
						       state={this.linkState("pickup_delegacion")}
						       grid={[12,4]}
						/>
						<Input type="button"
						       text="Buscar en mapa"
						       button={{color: "success"}}
						       grid={[true]}
						       events={{onClick: () => this.handlePickupSearch()}}
						       disabled={this.state.pickup_calle === "" || this.state.pickup_location}
						/>
						<Input type="button"
						       text="Abrir mapa"
						       grid={[true]}
						       disabled={this.state.pickup_location}
						       events={{onClick: () => ModalStore.openModal("pickup-map")}}
						/>
						{this.state.pickup_location ? (
							<Input type="button"
							       text={"Eliminar autocompletado"}
							       click={(e,enable)=>{
							            this.setState({
							                pickup_location: null,
							                pickup_calle: "",
							                pickup_colonia: "",
							                pickup_delegacion: "",
							            },enable);
						            }}
							       button={{color: "alert"}}
							       grid={[true]}
							/>
						) : null}
						<Input type="textarea"
						       label={"Observaciones"}
						       state={this.linkState("observations")}
						       grid={[12]}
						       getInput={(ref) => this._next2 = ref}
						       required={false}
						/>
					</Group>
					<Group group={false} title={"Horarios"} grid={12}>
						<Input type="time"
						       label={"Salida"}
						       state={this.linkState("departs_at")}
						       grid={[12,4]}
						/>
						<Input type="time"
						       label={"Regreso"}
						       state={this.linkState("arrives_at")}
						       extra={{min: this.state.arrives_after > 0 ? "" : this.state.departs_at}}
						       grid={[12,4]}
						/>
						<Input type="select"
						       label={"Cuando"}
						       state={this.linkState("arrives_after")}
						       options={[
						       	{show: "El mismo dia", value: "0"},
						       	{show: "El dia siguiente", value: "1"},
						       ]}
						       grid={[12,4]}
						/>
					</Group>
					<Group group={false} title={"Dias de la semana"} grid={12}>
						{
							[
								["Lunes","monday"],
								["Martes","tuesday"],
								["Miércoles","wednesday"],
								["Jueves","thursday"],
								["Viernes","friday"],
								["Sábado","saturday"],
								["Domingo","sunday"]
							].map(([day,name]) => (
								<Input type="checkbox"
								       label={day}
								       key={day}
								       state={this.linkCheckState(name)}
								       grid={[12,true]}
								       className={"text-center"}
								       required={false}
								/>
							))
						}
					</Group>
					<Group group={false} title={"Unidades y costos"} grid={12}>
						<Input type="select"
						       label={"Vehículo"}
						       state={this.linkState("bus_model_id")}
						       options={this.state.models.map(({full_name: show,id: value}) => ({show,value}))}
						       grid={true}
						/>
						<Input type="number"
						       label={"Unidade(s)"}
						       state={this.linkState("units")}
						       extra={{min: 1, step: 1}}
						       grid={true}
						/>
						<Input type="number"
						       label={"Asientos"}
						       state={this.linkState("sits")}
						       extra={{min: 1, step: 1}}
						       grid={true}
						/>
						<Input type="number"
						       label={"Costo / Unidad"}
						       state={this.linkState("price_per_unit")}
						       extra={{min: 1, step: "any"}}
						       grid={true}
						/>
						{this.state.with_bill ? (
							<Input type="text"
								   label={"Impuestos / Unidad"}
								   value={this.renderNumber(this.getTax())}
								   readOnly={true}
								   grid={true}
							/>
						) : null}
                        {this.state.with_bill ? (
							<Input type="text"
								   label={"Total / Unidad"}
								   value={this.renderNumber(this.getTotal())}
								   readOnly={true}
								   grid={true}
							/>
                        ) : null}
					</Group>
					{parseInt(this.state.units) > 0 ? (
						<Group group={false} title={"Asignación predeterminada"} grid={12}>
							{this.state.units_defaults.map((x,i) => (
								<Group group={false} key={i} grid={12}>
									<ReadOnly type={"text"}
									       grid={2}
									       value={i + 1}
									/>
									<Input type={"select"}
									       grid={5}
									       label={"Bus"}
									       options={this.handleFreeBuses()}
									       state={this.assignBus(i)}
									       required={false}
									/>
									<Input type={"select"}
									       grid={5}
									       label={"Operador"}
									       options={this.handleFreeOperators()}
									       state={this.assignOp(i)}
									       required={false}
									/>
								</Group>
							))}
						</Group>
					) : null}
					<Group group={false} title={"Opciones"} grid={12}>
						<Input type="checkbox"
						       label={"Mostrar mapa"}
						       state={this.linkCheckState("with_map")}
						       grid={[12,true]}
						       className={"text-center"}
						       required={false}
						/>
						<Input type="checkbox"
						       label={"Mostrar precios"}
						       state={this.linkCheckState("with_price")}
						       grid={[12,true]}
						       className={"text-center"}
						       required={false}
						/>
						<Input type="checkbox"
						       label={"Factura"}
						       grid={[12,true]}
						       className={"text-center"}
						       required={false}
							   state={this.linkCheckState("with_bill", {
                                   onChange: (e) => {
                                       if (!e.target.checked) {
                                           this.setState({
                                               'update_tax_rate': false
                                           })
                                       }
                                       return e.target.checked
                                   }
                               })}
						/>
                        {this.props.data ? (
							<Input label="Usar nueva tasa de impuestos"
								   type="checkbox"
								   grid={true}
								   required={false}
								   disabled={!this.state.with_bill}
								   className={"text-center"}
								   state={this.linkCheckState('update_tax_rate')}
							/>
                        ) : null}
					</Group>
					<Group group={false} title={"Acciones"} grid={12}>
						<Input type="submit"
						       grid={[true]}
						       button={{color: "success"}}
						       text={"Guardar"}
						/>
						{!this.filling ? (
							<Input type="button"
							       grid={[true]}
							       button={{color: "alert"}}
							       events={{onClick: () => this.cancel()}}
							       text={"Cancelar"}
							/>
						) : null}
					</Group>
				</Group>
			</div>
		);
	}
}
