import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import DataGrid from "react-data-grid";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import DataStore from "stores/DataStore";
import AlertStore from "stores/AlertStore";
import { Menu } from "react-data-grid-addons";
let {ContextMenu, MenuItem} = Menu;

export class ExcepcionesRutas extends Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.initialState = {
			data: DataStore.getModel("route_exceptions"),
			routes: DataStore.getModel("routes"),
			route_id: '',
			date: '',
		};
		this.state = {...this.initialState};
	}

	updateRouteExceptions () {
		this.setState({
			data: DataStore.getModel("route_exceptions")
		});
	}

	updateRoutes () {
		this.setState({
			routes: DataStore.getModel("routes")
		});
	}

	componentWillMount() {
		DataStore.on("ROUTE_EXCEPTIONS_UPDATED", this.updateRouteExceptions.bind(this));
		DataStore.on("ROUTES_UPDATED", this.updateRoutes.bind(this));
	}

	componentWillUnmount() {
		DataStore.removeListener("ROUTE_EXCEPTIONS_UPDATED", this.updateRouteExceptions.bind(this));
		DataStore.removeListener("ROUTES_UPDATED", this.updateRoutes.bind(this));
	}


	handleSubmit (e) {
		if (e) {
			e.preventDefault();
		}
		let {route_id, date} = this.state;
		ajax(api.post("/route_exceptions",{
			route_id,
			date
		}),{action: true},true)
			.then(() => {
			    this.setState({
				    ...this.initialState,
				    data: this.state.data
			    });
			})
	}


	render() {
		let columns = [
			{key: "route", name: "Ruta"},
			{key: "date", name: "Fecha"},
		];
		let rows = this.state.data.map(({
			id,
			date,
			route: {
				name: route
			}
		}) => ({
			id,
			date,
			route
		}));
		class Menu extends Component {
			static propTypes = {
				rowIdx: PropTypes.number
			};

			handleDelete () {
				let {id} = rows[this.props.rowIdx];
				AlertStore.addToQueue({
					text: `Desea eliminar la excepción de ruta?`,
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: "#da3116",
					confirmButtonText: 'Eliminar',
					cancelButtonText: 'Cancelar',
				},{confirm: () => {
					ajax(api.delete(`route_exceptions/${id}`),{action: true, success: {
						text: `Excepción de ruta eliminada`
					}})
				}})
			}

			render() {
				const {id} = this.props
				return (
					<ContextMenu id={id}>
						<MenuItem onClick={this.handleDelete.bind(this)}>Eliminar</MenuItem>
					</ContextMenu>
				);
			}
		}
		return (
			<div>
				<Group title={"Excepciones"} handleForm={this.handleSubmit.bind(this)} margin>
					<Input type="select"
					       label={"Ruta"}
					       options={this.state.routes.map(({name: show, id: value}) => ({show,value}))}
					       state={this.linkState("route_id")}
						   autoFocus={true}
					/>
					<Input type="date"
					       label={"Fecha"}
					       state={this.linkState("date")}
					/>
					<Input type="submit"
					       text={"Agregar"}
					       button={{color: "success"}}
					/>
				</Group>
				<div className="group with-margin">
					<DataGrid columns={columns}
					          rowGetter={(i)=>rows[i]}
					          rowsCount={rows.length}
					          minHeight={500}
					          contextMenu={<Menu/>}
										contextMenuId={"excepciones-row"}
					          rowKey="id"
					/>
				</div>
			</div>
		);
	}
}
