import "./loading.scss";

import React from "react";
import PropTypes from "prop-types";

export default class Loading extends React.Component {
	static propTypes = {
		loading: PropTypes.bool
	};

	render() {
		return this.props.loading ? (
			<div className="upper-container">
				<div className="loaders-container">
					<div className="loader loader-1">Loading...</div>
					<div className="loader loader-2">Loading...</div>
					<div className="loader loader-3">Loading...</div>
				</div>
			</div>
		) : null
	}
}
