// @flow

import React, {Component} from 'react';
import {Table} from "components/table/table";
import Group from "components/forms/group";
import {Input} from "components/forms/input";
import linkState from "helper/state";
import {asynx} from "helper/ajax";
import {api} from "helper/rest";
import AlertStore from "stores/AlertStore";
import {Menu} from "react-data-grid-addons";
import {date} from "helper/formatters";

let {ContextMenu, MenuItem} = Menu;

export class HistorialFacturas extends Component {
    constructor(props) {
        super(props);
        this.linkState = linkState.bind(this);
        this.state = {
            data: [],
            page: 1,
            pages: 1,
            resultsPerPage: 25,
        }
    }

    componentWillMount() {
        this.getData();
    }

    getPages() {
        const {page, pages} = this.state;
        return `${page} / ${pages}`;
    }

    setPage(move, enable) {
        this.setState({
            page: move === 'increase' ? this.state.page + 1 : this.state.page - 1
        }, () => this.getData(enable));
    }

    getData(enable) {
        const {page, resultsPerPage} = this.state;
        return asynx(api.get('payments/mass', {
            params: {
                page,
                per_page: resultsPerPage
            }
        }), {
            enable,
            action: true,
            error: {
                default: {
                    text: 'Error inesperado, reporte generado'
                }
            }
        }).then(({data: {data}}) => {
            this.setState({
                data
            })
        })
    }

    handleRevert({id}) {
        console.log(id);
        AlertStore.addToQueue({
            title: `Desea revertir el pago de la factura <b>#${id}</b>?`,
            text: 'Esta accion es irreversible',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#da3116",
            confirmButtonText: 'Revertir',
            cancelButtonText: 'Cancelar',
        }, {
            confirm: async () => {
                await asynx(api.delete(`/payments/mass/${id}`), {
                    action: true, success: {
                        text: `Pago de la factura <b>#${id}</b> revertida`
                    }
                });
                this.getData()
            }
        })
    }

    render() {
        const columns = [
            {key: 'id', name: '#'},
            {key: 'created_at', name: 'Fecha de creación', formatter: date},
            {key: 'ids', name: 'Pagos'},
            {key: 'invoice_number', name: 'Numero de factura'},
            {key: 'rolledback', name: 'Estado'},
            {key: 'rolledback_at', name: 'Fecha de reversion', formatter: date},
            {key: 'rolledback_by', name: 'Revertido por'},
        ];

        const options = [
            {show: '25', value: 25},
            {show: '50', value: 50},
            {show: '100', value: 100}
        ];

        const rows = this.state.data
            .map(({
                      id,
                      ids,
                      invoice_number,
                      rolledback,
                      rolledback_at,
                      rolledback_by,
                      created_at
                  }) => ({
                id,
                ids: ids.length,
                invoice_number,
                rolledback: !rolledback ? 'Activo' : `Revertido`,
                rolledback_at,
                rolledback_by: rolledback_by || 'N/A',
                created_at
            }));

        const context = this;

        class Menu extends Component {
            render() {
                let {rowIdx, id} = this.props;
                let row = rows[rowIdx];
                return (
                    <ContextMenu id={id}>
                        <MenuItem onClick={context.handleRevert.bind(context, row)}>Revertir pago</MenuItem>
                    </ContextMenu>
                )
            }
        }

        return (
            <div>
                <Group margin title="Historial de pago de facturas">
                    <Input type="button" text="Anterior" button={{color: 'success'}}
                           click={this.setPage.bind(this, 'decrease')} disabled={this.state.page === 1}/>
                    <Input type="text" label="Pagina" value={this.getPages()} readOnly/>
                    <Input type="select" label="Resultados por pagina" options={options}
                           state={this.linkState('resultsPerPage')}/>
                    <Input type="button" text="Siguiente" button={{color: 'success'}}
                           click={this.setPage.bind(this, 'increase')} disabled={this.state.page === this.state.pages}/>
                </Group>
                <Table title="Lista" columns={columns} data={rows} extra={{
                    contextMenu: <Menu/>,
                    contextMenuId: "historial-row"
                }}/>
            </div>
        )
    }
}
