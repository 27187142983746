import axios from "axios";
import AuthStore from "stores/AuthStore";
import * as AuthActions from "actions/AuthActions";
import AlertStore from "stores/AlertStore";
import {offline} from "helper/offline";
import Nanobar from "nanobar";

let {origin, hostname} = window.location;

let baseURL = hostname === 'localhost' ? 'http://localhost:8080/api' : `${origin}/api`;

const timeout = 15000;

class EndPoint {
	constructor() {
		let nanobarDownload = new Nanobar();
		let nanobarUpload = new Nanobar();
		let API = axios.create({
			baseURL,
			timeout,
			onDownloadProgress (ev) {
				let percentComplete = ev.loaded / ev.total * 100;
				nanobarDownload.go(percentComplete);
			},
			onUploadProgress (ev) {
				let percentComplete = ev.loaded / ev.total * 100;
				nanobarUpload.go(percentComplete);
			}
		});
		API.interceptors.response.use(response => response, (error) => {
			if (offline.state === "down") {
				console.log("adding new promise");
				return new Promise((resolve, reject) => {
					offline.on("up", () => {
						console.log("now online");
						console.log("redoing request");
						API.request(error.config).then(resolve, reject);
					});
				});
			} else {
				if (error.response && error.response.status === 400) {
					AlertStore.addToQueue({
						text: "Las credenciales de acceso ya no son validas",
						type: "info"
					});
					AuthActions.logout(true);
				}
				return Promise.reject(error);
			}
		});
		API.interceptors.request.use((config) => {
			config.headers["Authorization"] = AuthStore.getToken();
			return config;
		});
		this.API = API;
	}
}
const endpoint = new EndPoint();

export default endpoint;

export const api = endpoint.API;

export const url = baseURL;

export const pdfUrl = (token) => `${baseURL}/document?token=${token}`;

export const generateUrl = (token,format) => `${baseURL}/document?token=${token}&format=${format}`;
