//style
import "./registro.scss";
//libs
import React, {Component} from "react";
import PropTypes from "prop-types";
//components
import Group from "components/forms/group";
import {Input} from "components/forms/input";
import Modal from "components/modal";
import {GoogleMap} from "components/maps/googleMap";
import {GoogleMapAutocomplete} from "components/maps/googleMapAutocomplete";
import {GmapSearch} from "components/maps/gmapSearch";
//helpers
import linkState, {linkCheckState} from "helper/state";
import ajax from "helper/ajax";
import {mapDataToState, mapStateToData} from "helper/transformer";
//stores
import ModalStore from "stores/ModalStore";
import AlertStore from "stores/AlertStore";
//api
import {api} from "helper/rest";
//proptypes
import {Order} from "misc/propTypes";
import DataStore from "stores/DataStore";
import { money, getMoney } from 'helper/formatters'


export default class Registro extends Component {
    static propTypes = {
        reload: PropTypes.func,
        fill: Order,
    };

    stores = [
        "models",
        "payment_types",
        "sellers",
        "ads",
        "routes"
    ];

    constructor() {
        super();
        this.linkState = linkState.bind(this);
        this.linkCheckState = linkCheckState.bind(this);
        this.state = {
            "data:con_factura": false,
            "data:con_precio": false,
            "data:credit": false,
            "data:con_mapa": true,
            "data:update_tax_rate": false,
            autocompletedClient: false,
            clientEditable: false,
            autocompletedClientLocationFromMap: false,
            ...this.stores.reduce((carry, store) => {
                return {
                    ...carry,
                    [store]: DataStore.getModel(store)
                };
            }, {}),
            config_tax_rate: DataStore.getConfig('tax_rate')
        };

        this._stores = this.stores.map((store) => {
            return [DataStore.getModelEventName(store), this.listener(store)];
        });

        this._stores.push([
            DataStore.getModelEventName('configs'), () => {
                this.setState({
                    config_tax_rate: DataStore.getConfig('tax_rate')
                })
            }
        ]);

        this.handleDates = this.handleDates.bind(this);
    }

    listener(model) {
        return () => {
            this.setState({
                [model]: DataStore.getModel(model)
            });
        }
    }

    componentWillUnmount() {
        this._stores.forEach(store => {
            DataStore.removeListener(...store);
        });
    }

    componentDidMount() {
        this._main = document.querySelector("main");
        this._start.focus();
    }

    componentWillMount() {
        // fetch tax rate
        // asynx(api.get());
        this._stores.forEach(store => {
            DataStore.on(...store);
        });
        this.clearForm();
        if (this.props.fill) {
            this.filling(this.props.fill);
        }
    }

    filling(fill) {
        let [salidaFecha, salidaHora] = fill.salida.split(" ");
        let [regresoFecha, regresoHora] = fill.regreso.split(" ");
        //split unneeded data
        let data = {...fill};
        delete data.buses;
        delete data.balance;
        delete data.editor;
        delete data.model;
        delete data.register;
        delete data.payments;
        delete data.deposit;
        delete data.seller;
        delete data.route;
        this.setState({
            ...mapDataToState(data),
            salidaFecha,
            salidaHora,
            regresoFecha,
            regresoHora,
            "data:bus_model_id": fill.model.id,
            "data:seller_id": fill.seller ? fill.seller.id : "",
            "data:ad_id": fill.ad ? fill.ad.id : "",
            "data:route_id": fill.route ? fill.route.id : "",
            "load_order_id": "",
            autocompletedClient: true
        });
    }

    autocompleteClientLocationFromMap(place) {
        if (!place.geometry) {
            return;
        }

        let findType = (type) => {
            let result = place.address_components.find(comp => comp.types.find(types => types === type));
            if (result) {
                return result
            } else {
                AlertStore.addToQueue({
                    text: "No se pudo procesar la dirección correctamente",
                    type: "error",
                    allowEscapeKey: false,
                    allowOutsideClick: false
                });
                throw new Error("address type couldn't be found");
            }
        };
        if (this.state.autocompletedClient && !this.state.clientEditable) {
            AlertStore.addToQueue({
                text: "No puede cambiar la dirección mientras no habilite la edición",
                type: "info",
                allowEscapeKey: false,
                allowOutsideClick: false
            });
            return
        }
        //set data returned from get place
        if (place.types.find(types => types === "street_address")) {
            let calle = findType("route").long_name;
            let numero = findType("street_number").long_name;
            let codigo_postal = findType("postal_code").long_name;
            let colonia = findType("sublocality_level_1").long_name;
            let delegacion = findType("administrative_area_level_3").long_name;
            this.setState({
                ...mapDataToState({
                    client: {
                        calle: `${calle} #${numero}`,
                        colonia,
                        delegacion,
                        codigo_postal,
                        location: JSON.stringify(place)
                    }
                }),
                autocompletedClientLocationFromMap: true
            }, () => {
                this._next1.focus();
            });
        } else {
            AlertStore.addToQueue({
                text: "La búsqueda necesita ser mas especifica",
                type: "info",
                allowEscapeKey: false,
                allowOutsideClick: false
            });
        }
    }

    autocompleteDestino(place) {
        this.setState(mapDataToState({
            destino: place.formatted_address,
            location_destino: JSON.stringify(place)
        }));
    }

    autocompletePickup(place) {
        function datafy(data) {
            let temp = {};
            for (let key in data) {
                temp["data:" + key] = data[key];
            }
            return temp;
        }

        if (!place.geometry) {
            AlertStore.addToQueue({
                text: "No se pudo procesar la direccion correctamente",
                type: "error",
                allowEscapeKey: false,
                allowOutsideClick: false
            });
            return;
        }

        let findType = (type): string | null => {
            let result = place.address_components.find(comp => comp.types.find(types => types === type));
            if (result) {
                return result.long_name
            } else {
                return null;
            }
        };

        let typeFound = null;
        let found = place.types.find(types => {
            if (
                //direcciones
            types === "street_address" ||
            //calles
            types === "route" ||
            //metros
            types === "subway_station" ||
            types === "premise"
            ) {
                typeFound = types;
                return true;
            }
        });
        if (found) {
            let calle = "";
            let colonia = "";
            let delegacion = "";
            if (typeFound === "subway_station" || typeFound === "premise") {
                if (findType("route")) {
                    calle = findType("route");
                } else {
                    calle = place.formatted_address.split(",")[0]
                }
                if (findType("sublocality_level_1")) {
                    colonia = findType("sublocality_level_1");
                }
                if (findType("administrative_area_level_3")) {
                    delegacion = findType("administrative_area_level_3");
                }
            }
            if (typeFound === "street_address") {
                calle = findType("route") + " #" + findType("street_number");
                colonia = findType("sublocality_level_1");
                delegacion = findType("administrative_area_level_3");
            }
            if (typeFound === "route") {
                if (findType("route")) {
                    calle = findType("route");
                } else {
                    calle = place.formatted_address.split(",")[0]
                }
                if (findType("sublocality_level_1")) {
                    colonia = findType("sublocality_level_1");
                }
                if (findType("administrative_area_level_3")) {
                    delegacion = findType("administrative_area_level_3");
                }
            }
            this.setState(datafy({
                calle,
                colonia,
                delegacion,
                location_pickup: JSON.stringify(place)
            }), () => {
                this._next2.focus();
            });
        } else {
            AlertStore.addToQueue({
                text: "La búsqueda necesita ser mas especifica",
                type: "info",
                allowEscapeKey: false,
                allowOutsideClick: false
            });
        }
    }

    autocompleteClient(e, data) {
        let mutableData = {...data};
        delete mutableData._highlightResult;
        delete mutableData.objectID;
        delete mutableData.last_editor_id;
        this.setState({
            ...mapDataToState(mutableData, ["client"]),
            autocompletedClient: true
        }, () => this.FocusElement());
    }

    loadOrderById (e, enable) {
        ajax(api.get(`order/${this.state.load_order_id}`), {
            action: true, error: {
                [404]: {
                    text: "El id de orden no existe",
                    type: "info"
                }
            }
        })
          .promise
          .then((response) => {
              this.filling(response.data)
              ModalStore.closeModal();
          })
          .catch(() => {
              this.setState({
                  "load_order_id": ""
              });
          })
          .then(enable, enable)
    }

    getClientById(e, enable) {
        ajax(api.get(`clients/${this.state["data:client.id"]}`), {
            action: true, error: {
                [404]: {
                    text: "El id de cliente no existe",
                    type: "info"
                }
            }
        })
            .promise
            .then((response) => {
                let client = mapDataToState({
                    client: response.data
                });
                this.setState({
                    ...client,
                    autocompletedClient: true
                }, this.FocusElement.bind(this));
                ModalStore.closeModal();
            })
            .catch(() => {
                this.setState({
                    "data:client.id": ""
                });
            })
            .then(enable, enable)
    }

    clearForm() {
        this.setState({
            ...mapDataToState({
                client: {
                    id: "",
                    clave: "",
                    nombre: "",
                    telefono: "",
                    rfc: "",
                    calle: "",
                    colonia: "",
                    delegacion: "",
                    firma: "",
                    email: "",
                    codigo_postal: "",
                    location: null
                },
                destino: "",
                presentarse: "",
                calle: "",
                colonia: "",
                delegacion: "",
                observaciones: "",
                regreso: "",
                salida: "",
                unidades: "",
                sits: "",
                costo_unitario: "",
                bus_model_id: "",
                seller_id: "",
                ad_id: "",
                cobrar: "",
                route_id: "",
                con_factura: false,
                con_precio: false,
                con_mapa: true,
                credit: false,
                location_pickup: null,
                location_destino: null,
                responsible_name: "",
                responsible_phone: "",
            }),
            ...mapDataToState(this.getAdvances().map((advance) => {
                return {
                    ...advance,
                    deleted: true
                }
            })),
            regresoFecha: "",
            regresoHora: "",
            salidaFecha: "",
            salidaHora: "",
            autocompletedClient: false,
            clientEditable: false,
            autocompletedClientLocationFromMap: false,
            load_order_id: ""
        }, () => {
            if (this._main) {
                this._main.scrollTop = 0;
            }
        });
    }

    clearClient(e, enable) {
        enable();

        e.stopPropagation();

        const clearClient = mapDataToState({
            client: {
                id: "",
                clave: "",
                nombre: "",
                telefono: "",
                rfc: "",
                calle: "",
                colonia: "",
                delegacion: "",
                firma: "",
                email: "",
                codigo_postal: "",
                location: null
            }
        });

        this.setState({
            ...clearClient,
            autocompletedClient: false,
            autocompletedClientLocationFromMap: false,
            clientEditable: true,
        });
    }

    FocusElement() {
        this.autocomplete_continue.focus();
    }

    triggerErrorAlert({errors}) {
        let errorBag = [];
        if (errors.codigo_postal && errors.codigo_postal[0] === "The codigo postal must be a number.") {
            errorBag.push("El código postal debe ser un numero.")
        }
        if (errors.email && errors.email[0] === "The email must be a valid email address.") {
            errorBag.push("El correo electrónico debe ser valido.")
        }
        if (errors.email && errors.email[0] === "UNIQUE") {
            errorBag.push("El correo electrónico ya esta en uso por otro cliente.")
        }
        if (errors.rfc && errors.rfc[0] === "UNIQUE") {
            errorBag.push("El RFC ya esta en uso por otro cliente.")
        }
        if (errorBag.length > 0) {
            AlertStore.addToQueue({
                text: `<ul>${errorBag.map(error => `<li>${error}</li>`).join()}</ul>`,
                type: "info"
            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.getTotal() - this.getAnticipo() < 0) {
            AlertStore.addToQueue({
                text: "La suma total de anticipos no puede superar la venta",
                type: "info"
            });
            return;
        }

        let nullifyEmptyStrings = (data, properties) => {
            let data1 = {...data};
            properties.forEach((prop) => {
                data1[prop] = data1[prop] === "" ? null : data1[prop];
            });
            return data1;
        };
        let data = mapStateToData({...this.state});
        data.cobrar = data.cobrar === "" || parseFloat(data.cobrar) === 0 || data.credit ? null : data.cobrar;

        let clientPurge = [
            "email",
            "rfc",
            "codigo_postal"
        ];
        let dataPurge = [
            "responsible_name",
            "responsible_phone",
            "presentarse",
            "seller_id",
            "ad_id",
            "route_id",
            "observaciones"
        ];
        data.advances = this.getAdvances();
        data.client = nullifyEmptyStrings(data.client, clientPurge);
        data = nullifyEmptyStrings(data, dataPurge);
        //check if data exist because when fill deposit object might not be defined
        if (data.credit && !Object.keys(data.advances).length) {
            delete data.advances;
        }
        if (this.props.fill) {
            ajax(api.patch(`order/${this.state["data:id"]}`, data), {action: true})
                .promise
                .then((response) => {
                    AlertStore.addToQueue({
                        title: "Orden actualizada",
                        text: `Folio <a href="/ordenes/consulta?search=folio&id=${this.state["data:id"]}">No. ${this.state["data:id"]}</a>`,
                        type: "success"
                    });
                    this.props.reload();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.triggerErrorAlert(error.response.data);
                    }
                });
        } else {
            ajax(api.post("order", data), {action: true})
                .promise
                .then((response) => {
                    const text = `Folio <a href="/app/ordenes/consulta?id=${response.data.order}">No. ${response.data.order}</a><br>
<a href="/app/ordenes/asigna?id=${response.data.order}">Asignar orden</a><br>
Cliente <a href="/app/catalogos/clientes?id=${response.data.client}">No. ${response.data.client}</a>`;

                    AlertStore.addToQueue({
                        title: "Orden guardada",
                        text,
                        type: "success"
                    });
                    this.clearForm();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.triggerErrorAlert(error.response.data);
                    }
                });
        }
    }

    setAsClient(e) {
        if (e.target.value === "=") {
            return this.state["data:client.nombre"];
        } else {
            return e.target.value;
        }
    }

    renderNumber(flo) {
        return getMoney(flo);
    }


    getTaxRate() {
        if (this.props.fill) {
            if (this.props.fill.tax_rate) {
                return parseFloat(this.props.fill.tax_rate) / 100
            } else {
                return 0
            }
        } else {
            if (this.state['data:con_factura'] && this.state.config_tax_rate) {
                return parseFloat(this.state.config_tax_rate.rate) / 100
            } else {
                return 0
            }
        }
    }

    getTax() {
        return this.getSale() * this.getTaxRate();
    }

    getSale() {
        const unidades = this.state["data:unidades"] ? this.state["data:unidades"] : 0;
        const costoUnitario = this.state["data:costo_unitario"] ? this.state["data:costo_unitario"] : 0;
        return parseInt(unidades) * parseFloat(costoUnitario);
    }

    getTotal() {
        return this.getSale() + this.getTax();
    }

    getAnticipo() {
        return this.getAdvances().reduce((carry, adv) => {
            if (adv.payment_type_id === "0") {
                return carry;
            } else {
                const imp = adv.import ? adv.import : 0;
                return parseFloat(imp) + carry;
            }
        }, 0);
    }

    getSaldo() {
        return this.getTotal() - this.getAnticipo()
    }

    handleDates(name, fecha = null, hora = null) {
        const xfecha = fecha == null ? this.state[`${name}Fecha`] : fecha;
        const xhora = hora == null ? this.state[`${name}Hora`] : hora;
        this.setState({
            [`data:${name}`]: `${xfecha} ${xhora}`
        });
        if (fecha) {
            return fecha;
        }
        if (hora) {
            return hora
        }
    }

    handleCancel(e, enable) {
        AlertStore.addToQueue({
            text: "Se perderán los cambios si continua con esta acción",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Cancelar',
            cancelButtonText: "No, Regresar"
        }, {
            confirm: () => {
                this.clearForm();
                enable();
            },
            dismiss: () => {
                enable();
            }
        })
    }

    handleMapSearch() {
        ModalStore.openModal("search-map", {
            calle: this.state["data:client.calle"],
            colonia: this.state["data:client.colonia"],
            delegacion: this.state["data.client.delegacion"]
        });
    }

    handlePickupSearch() {
        ModalStore.openModal("pickup-search", {
            calle: this.state["data:calle"],
            colonia: this.state["data:colonia"],
            delegacion: this.state["data:delegacion"]
        });
    }

    addPayment(e, enable) {
        let payments = this.getAdvances().length;
        this.setState({
            ...mapDataToState({
                advances: {
                    [payments]: {
                        import: "",
                        payment_type_id: "",
                        reference: "",
                        deleted: false
                    }
                }
            })
        }, enable);
    }

    removePayment(index, enable) {
        this.setState({
            ...mapDataToState({
                advances: {
                    [index]: {
                        deleted: true
                    }
                }
            })
        }, enable);
    }

    getAdvances() {
        let state = mapStateToData(this.state);
        let advances = state.advances ? state.advances : [];
        return Object.keys(advances).reduce((carry, key) => {
            if (!advances[key].deleted) {
                let advance = {...advances[key]};
                delete advance.deleted;
                carry[key] = advance;
            }
            return carry;
        }, []);
    }

    render() {
        const contentEditable = !this.state.autocompletedClient ||
            this.state.clientEditable;
        let pickup = {};
        let client = {};
        if (this.state["data:client.location"]) {
            let a = JSON.parse(this.state["data:client.location"]);
            client.center = a.geometry.location;
            client.marker = a;
            client.zoom = 17;
        }
        if (this.state["data:location_pickup"]) {
            let b = JSON.parse(this.state["data:location_pickup"]);
            pickup.center = b.geometry.location;
            client.marker = b;
            pickup.zoom = 17;
        }
        let advances = this.getAdvances();
        return (
            <div>
                <Modal id={"search-map"} grid={[12, 8, 6]}>
                    <GmapSearch autocomplete={this.autocompleteClientLocationFromMap.bind(this)}/>
                </Modal>
                <Modal id={"pickup-search"} grid={[12, 8, 6]}>
                    <GmapSearch autocomplete={this.autocompletePickup.bind(this)}/>
                </Modal>
                <Modal id="autocompletar-id" grid={[12, 5, 3]}>
                    <Group title="Abrir cliente" handleForm={(e) => {
                        e.preventDefault();
                        this.getClientById();
                    }}>
                        <Input type="number"
                               label="Numero"
                               state={this.linkState("data:client.id")}
                               readOnly={this.state.autocompletedClient}
                               required={true}
                               extra={{step: 1, min: 1}}
                        />
                        <Input type="submit"
                               text="Abrir"
                               button={{color: "success"}}
                        />
                    </Group>
                </Modal>

                <Modal id="autocompletar-orden" grid={[12, 5, 3]}>
                    <Group title="Usar orden" handleForm={(e) => {
                        e.preventDefault();
                        this.loadOrderById();
                    }}>
                        <Input type="number"
                               label="Numero"
                               state={this.linkState("load_order_id")}
                               required={true}
                               extra={{step: 1, min: 1}}
                        />
                        <Input type="submit"
                               text="Abrir"
                               button={{color: "success"}}
                        />
                    </Group>
                </Modal>

                <Modal id="client-map" grid={[12, 8, 6]}>
                    <Group title="Dirección del client">
                        <GoogleMap listener={{"place_changed": this.autocompleteClientLocationFromMap.bind(this)}}
                                   bound
                                   id="client"
                                   {...client}
                        />
                    </Group>
                </Modal>
                <Modal id="pickup-map" grid={[12, 8, 6]}>
                    <Group title="Dirección para presentarse">
                        <GoogleMap id="pickup"
                                   bound
                                   listener={{"place_changed": this.autocompletePickup.bind(this)}}
                                   {...pickup}
                        />
                    </Group>
                </Modal>
                <Group form={true} handleForm={this.handleSubmit.bind(this)} group={true}>
                    <Group title="Datos del cliente" grid={[12]} group={false}>
                        <Group group={false} grid={12}>
                            <Input label="Editar"
                                   type="checkbox"
                                   state={this.linkCheckState("clientEditable", {
                                       onChange: (e) => {
                                           return e.target.value;
                                       }
                                   })}
                                   required={false}
                                   className={"text-center"}
                                   grid={[3, 2]}
                                   disabled={!this.state.autocompletedClient}
                            />

                            <Input type={"button"}
                                   button={{color: "alert", perfectCenter: true}}
                                   text={"Borrar"}
                                   grid={[9, true]}
                                   disabled={!this.state.autocompletedClient}
                                   click={this.clearClient.bind(this)}
                            />

                            <Input type="button"
                                   text="Abrir cliente"
                                   events={{onClick: () => ModalStore.openModal("autocompletar-id")}}
                                   disabled={this.state.autocompletedClient}
                                   grid={[6, true]}
                            />

                            <Input type="button"
                                   text="Usar orden"
                                   events={{onClick: () => ModalStore.openModal("autocompletar-orden")}}
                                   grid={[6, true]}
                            />

                        </Group>
                        <Group grid={12} group={false}>
                            <Input type="number"
                                   label="Numero de cliente"
                                   state={this.linkState("data:client.id")}
                                   readOnly={true}
                                   required={false}
                                   grid={[3, 2]}
                            />
                            <Input type="autocomplete"
                                   grid={[9, true]}
                                   label={"Nombre Completo"}
                                   state={this.linkState("data:client.nombre")}
                                   readOnly={!contentEditable}
                                   getInput={(ref) => this._start = ref}
                                   disabled={this.state.filter === "0"}
                                   autocomplete={{
                                       index: "clients",
                                       displayKey: "nombre",
                                       keyName: "nombre",
                                       onSelect: this.autocompleteClient.bind(this),
                                   }}
                            />
                            <Input state={this.linkState("data:client.telefono")}
                                   label="Telefono"
                                   type="tel"
                                   grid={[12, true]}
                                   readOnly={!contentEditable}
                            />
                            <Input state={this.linkState("data:client.rfc")}
                                   label="RFC"
                                   type="text"
                                   grid={[12, true]}
                                   required={false}
                                   readOnly={!contentEditable}
                            />
                        </Group>
                        <Group grid={12} group={false}>
                            <Input state={this.linkState("data:client.calle")}
                                   label="Calle(s)"
                                   type="text"
                                   grid={[12, true]}
                                   readOnly={this.state.autocompletedClientLocationFromMap || !contentEditable}
                            />
                            <Input state={this.linkState("data:client.colonia")}
                                   label="Colonia"
                                   type="text"
                                   grid={[12, true]}
                                   readOnly={this.state.autocompletedClientLocationFromMap || !contentEditable}
                            />
                            <Input state={this.linkState("data:client.delegacion")}
                                   label="Delegación"
                                   type="text"
                                   grid={[6, true]}
                                   readOnly={this.state.autocompletedClientLocationFromMap || !contentEditable}
                            />
                            <Input state={this.linkState("data:client.codigo_postal")}
                                   label="Código Postal"
                                   type="number"
                                   required={false}
                                   readOnly={this.state.autocompletedClientLocationFromMap || !contentEditable}
                                   grid={[6, true]}
                                   extra={{min: 0}}
                            />
                        </Group>
                        <Group grid={12} group={false}>
                            <Input type="button" text={"Copiar cliente"} grid={[12, 2]} button={{color: "primary"}}
                                   click={(e, enable) => {
                                       this.setState({
                                           "data:client.firma": this.state["data:client.nombre"]
                                       }, enable)

                                   }}/>
                            <Input state={this.linkState("data:client.firma", {onChange: this.setAsClient.bind(this)})}
                                   type="text"
                                   label="Nombre del contratante" grid={[12, 5]}
                                   getInput={(ref) => this._next1 = ref}
                                   readOnly={!contentEditable}
                            />
                            <Input state={this.linkState("data:client.email")}
                                   type="email"
                                   label="E-mail" grid={[12, 5]}
                                   required={false}
                                   readOnly={!contentEditable}
                            />
                        </Group>
                    </Group>
                    <Group title={"Datos del responsable"} grid={[12]} group={false}>
                        <Input type="button" text={"Copiar cliente"} grid={[12, 2]} button={{color: "primary"}}
                               click={(e, enable) => {
                                   this.setState({
                                       "data:responsible_name": this.state["data:client.nombre"]
                                   }, enable)

                               }}/>
                        <Input state={this.linkState("data:responsible_name", {onChange: this.setAsClient.bind(this)})}
                               type="text"
                               label="Nombre del responsable"
                               grid={[12, 5]}
                               getInput={(ref) => this.autocomplete_continue = ref}
                               required={false}
                        />
                        <Input state={this.linkState("data:responsible_phone")}
                               type="tel"
                               label="Teléfono"
                               grid={[12, 5]}
                               required={false}
                        />
                    </Group>
                    <Group title="Datos de viaje" grid={[12]} group={false}>
                        <Input state={this.linkState("data:destino")}
                               label="Destino"
                               type="text"
                               grid={[12, 12, this.state["data:location_destino"] ? 9 : 12]}
                               extra={{id: "destino"}}
                        />
                        {this.state["data:location_destino"] ? (
                            <Input type="button"
                                   text={"Eliminar autocompletado"}
                                   click={(e, enable) => {
                                       this.setState({
                                           "data:location_destino": null,
                                           "data:destino": null
                                       }, enable);
                                   }}
                                   button={{color: "alert"}}
                                   grid={[12, 12, 3]}
                            />
                        ) : null}
                        <Input type="text"
                               label="Presentarse en"
                               grid={[12]}
                               state={this.linkState("data:presentarse")}
                               required={false}
                        />
                        <Input state={this.linkState("data:calle")}
                               label="Calle(s)"
                               type="text"
                               grid={[12, 4]}
                        />
                        <Input state={this.linkState("data:colonia")}
                               label="Colonia"
                               type="text"
                               grid={[12, 4]}
                        />
                        <Input state={this.linkState("data:delegacion")}
                               label="Delegación"
                               type="text"
                               grid={[12, 4]}
                        />
                        <Input state={this.linkState("data:observaciones")}
                               type="textarea"
                               getInput={(ref) => this._next2 = ref}
                               label="Observaciones"
                               rows={3} required={false}
                               grid={12}
                        />
                    </Group>
                    <Group grid={[12]} group={false}>
                        <Group grid={[12, 6]} group={false}>
                            <Input
                                state={this.linkState("salidaFecha", {onChange: ({target}) => this.handleDates("salida", target.value)})}
                                label="Salida"
                                type="date"
                                grid={[6]}
                            />
                            <Input
                                state={this.linkState("salidaHora", {onChange: ({target}) => this.handleDates("salida", null, target.value)})}
                                label="Hora"
                                type="time"
                                grid={[6]}
                            />
                        </Group>
                        <Group grid={[12, 6]} group={false}>
                            <Input
                                state={this.linkState("regresoFecha", {onChange: ({target}) => this.handleDates("regreso", target.value)})}
                                label="Regreso"
                                type="date"
                                grid={[6]}
                                extra={{min: this.state.salidaFecha}}
                            />
                            <Input
                                state={this.linkState("regresoHora", {onChange: ({target}) => this.handleDates("regreso", null, target.value)})}
                                label="Hora "
                                type="time"
                                extra={{min: this.state.salidaFecha === this.state.regresoFecha ? this.state.salidaHora : ""}}
                                grid={[6]}/>
                        </Group>
                    </Group>
                    <Group grid={[12]} group={false} title={"Venta y anticipo(s)"}>
                        <Input state={this.linkState("data:bus_model_id")}
                               label="Vehículo"
                               type="select"
                               grid={[6, true]}
                               options={this.state.models.map((bus) => {
                                   return {value: bus.id, show: bus.submarca}
                               })}
                        />
                        <Input state={this.linkState("data:unidades")}
                               label="Unidades"
                               type="number"
                               grid={[6, true]}
                               extra={{min: 1, step: 1}}/>
                        <Input state={this.linkState("data:sits")}
                               label="Asientos"
                               type="number"
                               grid={[6, true]}
                               extra={{min: 1, step: 1}}/>
                        <Input state={this.linkState("data:costo_unitario")}
                               label="Costo"
                               type="number"
                               extra={{step: "any"}}
                               grid={[6, true]}/>
                        {this.state['data:con_factura'] ? (
                            <Input value={this.renderNumber(this.getSale())}
                                   label="Venta"
                                   type="text"
                                   grid={[12, true]}
                                   readOnly={true}
                            />
                        ) : null}
                        {this.state['data:con_factura'] ? (
                            <Input value={this.renderNumber(this.getTax())}
                                   label="Impuestos"
                                   type="text"
                                   grid={[12, true]}
                                   readOnly={true}
                            />
                        ) : null}
                        <Input value={this.renderNumber(this.getTotal())}
                               label="Total"
                               type="text"
                               grid={[12, true]}
                               readOnly={true}
                        />
                    </Group>
                    {this.state["data:credit"] ? null : <Group title="Anticipos" group={false}></Group>}
                    {this.state["data:credit"] ? null : advances.map((advance, index) => {
                        return (
                            <Group grid={[12]} group={false}>
                                <Input type="select"
                                       grid={[6, 3]}
                                       label="Tipo de anticipo"
                                       options={this.state.payment_types.map((opt) => ({
                                           show: opt.type,
                                           value: opt.id
                                       }))}
                                       state={this.linkState(`data:advances.${index}.payment_type_id`, {value: (value) => this.state["data:credit"] ? "" : value})}
                                       required={true}
                                       disabled={this.state["data:credit"]}
                                />
                                <Input
                                    disabled={this.state[`data:advances.${index}.payment_type_id`] === "0" || this.state["data:credit"]}
                                    grid={[6, 3]}
                                    state={this.linkState(`data:advances.${index}.reference`, {value: (value) => this.state["data:credit"] || this.state[`data:advances.${index}.payment_type_id`] === "0" ? "" : value})}
                                    type="text"
                                    required={this.state[`data:advances.${index}.payment_type_id`] === "1"}
                                    label="Referencia"
                                />
                                <Input
                                    disabled={this.state[`data:advances.${index}.payment_type_id`] === "0" || this.state["data:credit"]}
                                    state={this.linkState(`data:advances.${index}.import`, {
                                        value: (value) => this.state["data:credit"] || this.state[`data:advances.${index}.payment_type_id`] === "0" ? "" : value
                                    })}
                                    extra={{max: this.getTotal(), step: "any", min: "0.01"}}
                                    label="Importe"
                                    type="number"
                                    grid={[6, 3]}
                                />
                                <Input type="button" text={"Quitar"} grid={[6, 3]} button={{color: "alert"}}
                                       click={(e, enable) => {
                                           this.removePayment(index, enable)
                                       }}/>
                            </Group>
                        )
                    })}
                    <Group grid={[12]} group={false}>
                        <Input grid={[12, 4]} type="button" text={"Agregar anticipo"} button={{color: "success"}}
                               click={this.addPayment.bind(this)} disabled={this.state["data:credit"]}/>
                        <Input label="Saldo"
                               type="text"
                               grid={[12, 4]}
                               readOnly={true}
                               value={!this.state['data:credit'] ? this.renderNumber(this.getSaldo()) : ''}
                               extra={{min: 0}}
                        />
                        <Input
                            state={this.linkState("data:cobrar", {value: (value) => this.state["data:credit"] ? "" : value})}
                            extra={{step: "any", min: 0}}
                            label="Cobrar al abordar"
                            type="number"
                            grid={[12, 4]}
                            required={false}
                            disabled={this.state["data:credit"]}
                        />
                    </Group>
                    <Group grid={[12, 12, 6]} group={false} title={"Opciones"}>
                        <Input label="Mostrar mapa"
                               grid={true}
                               className="text-center"
                               type="checkbox"
                               required={false}
                               state={this.linkCheckState("data:con_mapa")}
                        />
                        <Input label="Mostrar precios"
                               grid={true}
                               className="text-center"
                               type="checkbox"
                               required={false}
                               state={this.linkCheckState("data:con_precio")}
                        />
                        <Input label="Factura"
                               type="checkbox"
                               grid={true}
                               required={false}
                               className={"text-center"}
                               state={this.linkCheckState("data:con_factura", {
                                   onChange: (e) => {
                                       if (!e.target.checked) {
                                           this.setState({
                                               'data:update_tax_rate': false
                                           })
                                       }
                                       return e.target.checked
                                   }
                               })}
                        />
                        <Input label="Crédito"
                               type="checkbox"
                               grid={true}
                               required={false}
                               className={"text-center"}
                               state={this.linkCheckState("data:credit")}
                        />
                        {this.props.fill ? (
                            <Input label="Usar nueva tasa de impuestos"
                                   type="checkbox"
                                   grid={true}
                                   required={false}
                                   disabled={!this.state['data:con_factura']}
                                   className={"text-center"}
                                   state={this.linkCheckState("data:update_tax_rate")}
                            />
                        ) : null}
                    </Group>
                    <Group group={false} title={"Agente de ventas y publicidad"} grid={[12, 12, 6]}>
                        <Input label="Vendedor"
                               type="select"
                               grid={[6]}
                               options={[{
                                   show: "Sin vendedor",
                                   value: ""
                               }].concat(this.state.sellers.map(s => ({show: s.name, value: s.id})))}
                               state={this.linkState("data:seller_id")}
                               required={false}
                        />
                        <Input label="Publicidad"
                               type="select"
                               grid={[6]}
                               options={[{
                                   show: "Sin anuncio",
                                   value: ""
                               }].concat(this.state.ads.map(s => ({show: s.name, value: s.id})))}
                               state={this.linkState("data:ad_id")}
                               required={false}
                        />
                    </Group>
                    <Group group={false} title={"Rutas"} grid={12} center={true} minimize={true} minimizeState={true}>
                        <Input grid={[6]}
                               type="select"
                               label={'Asignar ruta'}
                               options={[{
                                   show: "Sin ruta",
                                   value: ""
                               }].concat(this.state.routes.map(({name: show, id: value}) => ({show, value})))}
                               state={this.linkState("data:route_id")}
                               required={false}
                        />
                    </Group>
                    <Group group={false} title={"Acciones"} grid={[12]}>
                        <Input grid={true}
                               type="submit"
                               button={{color: "success"}}
                               text="Guardar"
                        />
                        {!this.props.fill ? (
                            <Input grid={true}
                                   type="submit"
                                   button={{color: "alert"}}
                                   text="Cancelar"
                                   click={this.handleCancel.bind(this)}
                            />
                        ) : null}
                    </Group>
                </Group>
            </div>
        );
    }
}
