import Pusher from "pusher-js";
import React from "react";
import Alert from 'react-s-alert';

let pusher = new Pusher('8b3430cd6c02685f3b02', {
	cluster: 'mt1'
});

let channel = pusher.subscribe('Updates');

channel.bind('WebAppUpdate', function () {
	Alert.info((
		<div className="row column">
			<p>Hay una nueva version disponible</p>
			<br/>
			<button onClick={() => {
				window.location.reload(true);
			}} className="button success">
				Actualizar ahora!
			</button>
		</div>
	), {
		timeout: "none"
	});
});