/* global google */

import "./gmap.scss";
import React, {Component} from 'react';
import PropTypes from "prop-types";

type Props = {
    listener: { [id: string]: (place: {}) => void } | null,
    bound: boolean,
    input: boolean,
    center: {
        lat: number,
        lng: number
    },
    zoom: number,
    id: string,
    marker: boolean
};

export class GoogleMap extends Component {

    props: Props;

    static propTypes = {
        listener: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool
        ]).isRequired,
        bound: PropTypes.bool.isRequired,
        input: PropTypes.bool.isRequired,
        center: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired
        }).isRequired,
        id: PropTypes.string.isRequired,
        marker: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool
        ]).isRequired,
    };

    static defaultProps = {
        listener: false,
        bound: true,
        input: true,
        center: {
            "lat": 19.4326077,
            "lng": -99.133208
        },
        zoom: 13,
        marker: false
    };

    constructor(props: Props, context) {
        super(props, context);
        this.mapId = "KLDKD-" + props.id + "-map";
        this.inputId = "KLDKD-" + props.id + "-input";
    }


    componentDidMount() {
        if (window.google) {
            const mapElement = document.getElementById(this.mapId);
            const map = this.setupMap(mapElement);
            this.map = map;
            if (this.props.input) {
                const inputElement = document.getElementById(this.inputId);
                const autocomplete = this.setupAutocomplete(inputElement);

                inputElement.addEventListener("keydown", (event) => {
                    if (event.keyCode == 13) {
                        event.preventDefault();
                    }
                });
                this.bindAutocompleteToMap(map, autocomplete, inputElement);

                this.enableMarkers(map, autocomplete);

                if (this.props.listener) {
                    Object.keys(this.props.listener).map((listener) => {
                        const callback = this.props.listener[listener];
                        autocomplete.addListener(listener, () => {
                            callback(autocomplete.getPlace());
                        })
                    });
                }

                if (this.props.bound) {
                    this.setBoundsToAutocomplete(autocomplete);
                }
            }

            if (this.props.marker) {
                this.renderMarker(map, this.props.marker)
            }
        }
    }

    setupMap(element) {
        return new google.maps.Map(element, {
            center: this.props.center,
            zoom: this.props.zoom
        });
    }

    setupAutocomplete(element) {
        return new google.maps.places.Autocomplete(
            element,
            {
                types: ['geocode'],
                componentRestrictions: {country: "mx"}
            }
        );
    }

    bindAutocompleteToMap(map, input, inputElement) {
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputElement);
        input.bindTo('bounds', map);
    }

    setBoundsToAutocomplete(input) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.geolocationCircle = new google.maps.Circle({
                    center: this.geolocation,
                    radius: 50000
                });
                input.setBounds(this.geolocationCircle.getBounds());
            });
        }
    }

    enableMarkers(map, input) {
        input.addListener('place_changed', () => {
            var place = input.getPlace();
            this.renderMarker(map, place);
        });
    }

    renderMarker(map, place) {
        var infowindow = new google.maps.InfoWindow();
        var marker = new google.maps.Marker({
            map: map,
            anchorPoint: new google.maps.Point(0, -29)
        });
        infowindow.close();
        marker.setVisible(false);
        if (!place.geometry) {
            return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }
        marker.setIcon(/** @type {google.maps.Icon} */({
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(35, 35)
        }));
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        var address = '';
        if (place.address_components) {
            address = [
                (place.address_components[0] && place.address_components[0].short_name || ''),
                (place.address_components[1] && place.address_components[1].short_name || ''),
                (place.address_components[2] && place.address_components[2].short_name || '')
            ].join(' ');
        }

        infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
        infowindow.open(map, marker);
    }

    render() {
        return (
            <div className="map-container">
                <div id={this.mapId} className="map"></div>
                {this.props.input ? (
                    <input id={this.inputId}
                           className={"controls"}
                           type="text"
                           placeholder="Introducir dirección"
                    />
                ) : null}
            </div>
        );
    }
}
