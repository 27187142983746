import PropTypes from "prop-types";

//TODO: create date type, decimal type, email type

export const Client = PropTypes.shape({
    "saved": PropTypes.bool,
    "clave": PropTypes.string,
    "nombre": PropTypes.string,
    "telefono": PropTypes.string,
    "calle": PropTypes.string,
    "colonia": PropTypes.string,
    "delegacion": PropTypes.string,
    "rfc": PropTypes.string,
    "email": PropTypes.string,
    "responsable": PropTypes.string,
    "firma": PropTypes.string
});

export const Balance = PropTypes.shape({
    "sale": PropTypes.number,
    "deposit": PropTypes.number,
    "payment": PropTypes.number,
    "balance": PropTypes.number
});

export const Register = PropTypes.shape({
    "name": PropTypes.string,
    "email": PropTypes.string
});

export const Model = PropTypes.shape({
    "marca": PropTypes.string,
    "submarca": PropTypes.string,
    "sits": PropTypes.number,
    "price_per_day": PropTypes.string,
    "price_per_km": PropTypes.string
});

export const Bus = PropTypes.shape({
    "clave": PropTypes.string,
    "license_plate_number": PropTypes.string,
    "engine_number": PropTypes.string,
    "year": PropTypes.number,
    "retired": PropTypes.bool,
    "model": Model
})

export const Operator = PropTypes.shape({
    "clave": PropTypes.string,
    "nombre": PropTypes.string,
    "telefono": PropTypes.string,
    "direccion": PropTypes.string,
    "nacimiento": PropTypes.string,
    "ingreso": PropTypes.string,
    "egreso": PropTypes.string,
    "assigned_bus": Bus
});

export const Buses = PropTypes.arrayOf(
    PropTypes.shape({
        "id": PropTypes.number,
        "index": PropTypes.number,
        "order_id": PropTypes.number,
        "cobrar": PropTypes.string,
        "cobrar_return": PropTypes.string,
        "expenses": PropTypes.string,
        "expenses_return": PropTypes.string,
        "comision": PropTypes.string,
        "accounted": PropTypes.bool,
        "operator": Operator,
        "bus": Bus
    })
);

export const Payments = PropTypes.arrayOf(
    PropTypes.shape({
        "import": PropTypes.string,
        "created_at": PropTypes.string
    })
)

export const Order = PropTypes.shape({
    "id": PropTypes.number,
    "calle": PropTypes.string,
    "colonia": PropTypes.string,
    "delegacion": PropTypes.string,
    "cita": PropTypes.string,
    "destino": PropTypes.string,
    "observaciones": PropTypes.string,
    "salida": PropTypes.string,
    "regreso": PropTypes.string,
    "tipo_pago": PropTypes.string,
    "unidades": PropTypes.number,
    "costo_unitario": PropTypes.string,
    "saldo_positivo": PropTypes.string,
    "cobrar": PropTypes.string,
    "cancelado": PropTypes.bool,
    "con_precio": PropTypes.bool,
    "factura": PropTypes.bool,
    "numero_factura": PropTypes.number,
    "created_at": PropTypes.string,
    "updated_at": PropTypes.string,
    "balance": Balance,
    "client": Client,
    "register": Register,
    "model": Model,
    "buses": Buses,
    "payments": Payments
});

export const ServiceBalance = PropTypes.shape({
    expenses: PropTypes.string,
    expenses_return: PropTypes.string,
    expenses_declared: PropTypes.string,
    balance: PropTypes.string
});

export const ServiceExpense = PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    reference: PropTypes.string,
    description: PropTypes.string,
    import: PropTypes.string,
    external_business: PropTypes.shape({
        name: PropTypes.string
    }),
    expense_type: PropTypes.shape({
        type: PropTypes.string
    })
});

export const Service = PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    order_id: PropTypes.number,
    cobrar: PropTypes.string,
    cobrar_return: PropTypes.string,
    expenses: PropTypes.string,
    expenses_return: PropTypes.string,
    comision: PropTypes.string,
    accounted: PropTypes.bool,
    balance: ServiceBalance,
    expenses_declared: PropTypes.arrayOf(ServiceExpense),
    bus: Bus,
    operator: Operator
});
