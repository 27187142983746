import Breakpoints from "breakpoints-js/dist/breakpoints.js";

Breakpoints({
	small: {
		min: 0,
		max: 639
	},
	medium: {
		min: 640,
		max: 1023
	},
	xlarge: {
		min: 1024,
		max: 1439
	},
	xxlarge: {
		min: 1440,
		max: Infinity
	}
});

export const breakpoints = Breakpoints;
