import React from "react";
import formatDate from "./date";
import formatDateTime from "./dateTime";

export const getMoney = function getMoney (value) {
	const [number, decimal] = parseFloat(value).toFixed(2).split(".")
	const digits = number.split('')
	let counter = 0
	const separated = []
	digits.reverse().forEach((digit) => {
		if (counter % 3 === 0 && counter !== 0) {
			separated.push(",")
		}
		separated.push(digit)
		counter++;
	})
	return "$" + [separated.reverse().join(''), decimal].join(".")
}

export const money = ({value}) => <span>{getMoney(value)}</span>;

export const date = ({value}) => <span>{value ? formatDate(value) : "N/A"}</span>;

export const dateTime = ({value}) => <span>{value ? formatDateTime(value) : "N/A"}</span>;

export const boolean = ({value}) => <span>{value ? "Si" : "No"}</span>;

export const checkbox = ({value}) => (
	<div className="checkbox-rdg-container">
		<input type="checkbox" checked={value} value={value} readOnly className="checkbox-rdg-input"/>
	</div>
);