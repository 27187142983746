// @flow

import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "components/forms/group";
import {Input, ReadOnly} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import ModalStore from "stores/ModalStore";
import Modal from "components/modal";
import {PrestamosForm} from "./prestamosForm";
import DataStore from "stores/DataStore";
import {LoansTable} from "components/tables/loansTable";
import { Menu } from "react-data-grid-addons";
import { money, getMoney } from 'helper/formatters'
let {ContextMenu, MenuItem} = Menu;

export class Prestamos extends Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.initialState = {
			loans: [],
			fetched: false,
			operator: "",
			operators: DataStore.getModel("operators"),
			raw: [],
			payment: "0",
			loan: "0",
			balance: "0"
		};
		this.channel = null;
		this.state = {...this.initialState};
		this.updateOperators = this.updateOperators.bind(this);
		this.updateLoans = this.updateLoans.bind(this);
	}

	updateOperators() {
		this.setState({
			operators: DataStore.getModel("operators")
		});
	}

	updateLoans () {
		if (this.state.fetched) {
			this.getOperator();
		}
	}

	componentWillMount() {
		DataStore.on("OPERATORS_UPDATED", this.updateOperators);
	}

	componentWillUnmount() {
		DataStore.removeListener("OPERATORS_UPDATED", this.updateOperators);
		if (this.channel) {
			DataStore.closeChannel(this.channel);
		}
	}


	getOperator(initial: boolean) {
		if (initial) {
			this.channel = `App.Operator.${this.state.operator}`;
			DataStore.getChannel({
				priv: true,
				name: this.channel
			}).listen("OperatorLoanChange",this.updateLoans);
		}
		ajax(api.get(`operators/${this.state.operator}`), {action: true})
			.promise
			.then((response) => {
				this.setState({
					fetched: true,
					rawLoans: response.data.loans,
					...response.data.loans_balance
				});
			})
	}

	handleSubmit(e) {
		e.preventDefault();
		this.getOperator(true);
	}

	handleUpdate(e, enable) {
		this.getOperator();
		enable();
	}

	handleClose(e, enable) {
		DataStore.closeChannel(this.channel);
		this.channel = null;
		this.setState({
			...this.initialState,
			operators: this.state.operators,
			data: []
		}, enable);
	}

	handleOpenForm(e, enable) {
		ModalStore.openModal("prestamos-form");
		enable();
	}

	render() {
		const that = this;
		class Context extends Component {
			static propTypes = {
				rowIdx: PropTypes.number
			};

			handleEdit() {
				const {rowIdx: index} = this.props;
				ModalStore.openModal("prestamos-form", that.state.rawLoans[index]);
			}

			render() {
				const {id} = this.props;
				return (
					<ContextMenu id={id}>
						<MenuItem onClick={this.handleEdit.bind(this)}>Editar</MenuItem>
					</ContextMenu>
				);
			}

		}
		return (
			<div>
				<Modal id="prestamos-form" grid={[12,7,5]}>
					<PrestamosForm operator={this.state.operator}
					               max={this.state.balance}/>
				</Modal>
				<Group title={"Prestamos"} style={{marginBottom: "1em"}} handleForm={this.handleSubmit.bind(this)}>
					<Input type="select"
					       label="Operador"
					       disabled={!this.state.operators.length || this.state.fetched}
					       options={this.state.operators.map(op => ({show: op.nombre,value: op.id}))}
					       state={this.linkState("operator")}
					/>
					<Input type="submit"
					       text="Abrir"
					       disabled={this.state.fetched}
					       button={{color: "success"}}
					/>
					<Input type="button"
					       text={this.state.fetched ? "Actualizar" : "..."}
					       button={{color: this.state.fetched ? "success" : ""}}
					       disabled={!this.state.fetched}
					       click={this.handleUpdate.bind(this)}
					/>
					<Input type="button"
					       text={this.state.fetched ? "Cerrar" : "..."}
					       button={{color: this.state.fetched ? "alert" : ""}}
					       disabled={!this.state.fetched}
					       click={this.handleClose.bind(this)}
					/>
					<Input type="button"
					       text={this.state.fetched ? "Agregar vale" : "..."}
					       button={{color: this.state.fetched ? "warning" : ""}}
					       disabled={!this.state.fetched}
					       click={this.handleOpenForm.bind(this)}
					/>
				</Group>
				<div className="group" style={{marginBottom: "1em"}}>
					<Group title={"Historial de prestamos"} group={false}/>
					<LoansTable loans={this.state.rawLoans} contextMenu={<Context/>} contextMenuId={"prestamos-row"}/>
				</div>
				<Group title={"Balance"}>
					<ReadOnly type="text"
					          label="Prestamos"
					          value={getMoney(parseFloat(this.state.loan))}
					/>
					<ReadOnly type="text"
					          label="Pagos"
					          value={getMoney(parseFloat(this.state.payment))}
					/>
					<ReadOnly type="text"
					          label="Saldo"
					          value={getMoney(parseFloat(this.state.balance))}
					/>
				</Group>
			</div>
		);
	}
}
