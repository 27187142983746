// @flow

import dispatcher from "misc/dispatcher";

export const logout = () => {
	dispatcher.dispatch({
		type: "LOG_OUT"
	});
};

export const exit = () => {
	dispatcher.dispatch({
		type: "LOG_OUT_EXIT"
	});
};