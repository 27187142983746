import React, {
	Component,
} from 'react';

import {Catalog} from"components/catalog/catalog";
import {CatalogForm} from"components/catalog/catalogForm";
import date from"helper/date";

export class EmpresasExternas extends Component {
	render() {
		return (
			<Catalog model={"external_businesses"}
			         name={"Empresas externas"}
			         singular={"Empresa externa"}
			         keys={["name"]}
			         columns={[
		                	{key: "name", name: "Nombre"},
		                	{key: "created_at", name: "Creado", formatter: ({value}) => <span>{date(value)}</span>},
							{key: "updated_at", name: "Actualizado", formatter: ({value}) => <span>{date(value)}</span>}
		                ]}
			>
				<CatalogForm inputs={[
							 	{type: "text", state: "name", label: "Nombre", grid: [12],}
							 ]}
				/>
			</Catalog>
		)
	}
}
