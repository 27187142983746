// @flow

import React from "react";
import EventEmitter2 from "eventemitter2";
import dispatcher from "misc/dispatcher";
import Echo from "laravel-echo";
import AuthStore from "./AuthStore";
import Alert from 'react-s-alert';
import {api, url} from "helper/rest";
import * as AuthActions from "../actions/AuthActions";
import AlertStore from "./AlertStore";
import ajax from "helper/ajax";
import {debounce} from "helper/debounce";

window.Pusher = require('pusher-js');

type Channel = {
	name: string,
	connection: Object,
};

class DataStore extends EventEmitter2 {
	channels: Array<Channel>;

	constructor () {
		console.warn("INSTANTIATING DATA STORE");
		super();

		this.onAny((event, value) => {
			console.info({
				event, value
			})
		});

		this.models = {};

		this.channels = [];

		AuthStore.on("CONNECTED", () => {
			console.warn("Auth CONNECTED");
			this.getEcho();
		});

		this.on("CONNECTED", () => {
			console.warn("instantiating channels");
			let userChannel = `App.User.${AuthStore.getUser().id}`;
			let userConnection = this.echo.private(userChannel);
			this.channels.push({
				name: userChannel,
				connection: userConnection
			});
			userConnection
				.notification((notification) => {
					console.log("notification received", notification);
					console.log("type", notification.type);
					if (notification.type === "App\\Notifications\\LoggedOut") {
						console.log("logging out by server request");
						// I DIDNT CALLED LOGOUT
						console.info("DID I CALLED LOGOUT?", AuthStore.I_CALLED_LOGOUT);
						if (!AuthStore.I_CALLED_LOGOUT) {
							AlertStore.addToQueue({
								text: "Se ha cerrado session remotamente",
								type: "info"
							});
							AuthActions.logout();
						}
						AuthStore.I_CALLED_LOGOUT = false;
					}
				});
			let roomChannel = "Online.0";
			let roomConnection = this.echo.join(roomChannel);
			this.channels.push({
				name: roomChannel,
				connection: roomConnection
			});
			roomConnection
				.listen("WebAppUpdate", () => {
					Alert.info((
						<div className="row column">
							<p>Hay una nueva version disponible</p>
							<br/>
							<button onClick={() => {
								window.location.reload(true);
							}} className="button success">
								Actualizar ahora
							</button>
						</div>
					), {
						timeout: "none"
					});
				})
				.here((users) => {
					const usersWithoutMe = users.filter(u => u.id !== AuthStore.getUser().id);
					if (usersWithoutMe.length > 0) {
						Alert.info((
							<div>
								Usuarios en linea
								<br/>
								<ul>
									{usersWithoutMe.map(user => <li>{user.name}</li>)}
								</ul>
							</div>
						), {
							position: 'bottom-right',
							effect: 'slide'
						});
					}
					if (AuthStore.connectedBy !== "storage") {
						Alert.success(`Bienvenid@ ${AuthStore.getUser().name}`, {
							position: 'bottom-right',
							effect: 'slide',
							html: true
						});
					}
				})
				.joining((user) => {
					Alert.info(`${user.name} esta en linea`, {
						position: 'bottom-right',
						effect: 'slide',
						html: true
					});
				})
				.leaving((user) => {
					Alert.info(`${user.name} se ha desconectado`, {
						position: 'bottom-right',
						effect: 'slide',
						html: true
					});
				});
			this.listen({
				priv: true,
				name: "ModelChange.0",
				events: [
					{
						event: "ModelChange",
						callback: ({model = "", data = {}, type = ""}) => {
							console.info({model, data, type});
							Alert.info(`${model} ${type.toLowerCase()} ID: ${data.id}`, {
								position: 'bottom-right',
								effect: 'slide'
							});
							this.emit("SOMETHING_CHANGED");
							switch (model) {
								case "User":
									this.fetchModel("users");
									break;
								case "Service":
									this.servicesUpdated();
									break;
								case "Order":
									this.ordersUpdated();
									break;
								case "Client":
									this.fetchModel("clients");
									break;
								case "Operator":
									this.fetchModel("operators");
									break;
								case "BusModel":
									this.fetchModel("models");
									break;
								case "Bus":
									this.fetchModel("buses");
									break;
								case "Partner":
									this.fetchModel("partners");
									break;
								case "Seller":
									this.fetchModel("sellers");
									break;
								case "ExternalBusiness":
									this.fetchModel("external_businesses");
									break;
								case "ExpenseType":
									this.fetchModel("expense_types");
									break;
								case "DepositType":
									this.fetchModel("deposit_types");
									break;
								case "PaymentType":
									this.fetchModel("payment_types");
									break;
								case "RouteException":
									this.fetchModel("route_exceptions");
									break;
								case "Ad":
									this.fetchModel("ads");
									break;
								case "Route":
									this.fetchModel("routes");
									break;
                                case "AppConfig":
                                    this.fetchModel("configs");
                                    break;
							}
						}
					}
				]
			});
		});

		this.ordersUpdated = debounce(this.ordersUpdated, 500);
		this.servicesUpdated= debounce(this.servicesUpdated, 500);
	}

	ordersUpdated () {
		this.emit("ORDERS_UPDATED");
	}

	servicesUpdated () {
		this.emit("SERVICES_UPDATED");
	}

	getChannel ({priv = false, name}: { priv: boolean, name: string }) {
		let channel = this.channels.find(({name: connectionName}) => connectionName === name);
		if (channel) {
			return channel.connection;
		} else {
			return this.listen({priv, name}).connection;
		}
	}

	closeChannel (name) {
		this.echo.leave(name);
	}

	listen ({priv = false, name, events = []}) {
		let connection = this.echo[priv ? "private" : "channel"](name);
		events.forEach(({event, callback}) => {
			connection.listen(event, callback)
		});
		let data = {
			name,
			connection
		};
		this.channels.push(data);
		return data;
	}

	fetchArray (models) {
		let requests = models.map(name => ajax(api.get(name)).promise);
		Promise.all(requests).then((results) => {
			results.map((response, i) => {
				const name = models[i];
				this[name] = response.data;
				this.emit(`${name.toUpperCase()}_UPDATED`)
			})
		})
	}

	listenChanges (model: string, callback) {
		return {
			listen: () => {
				this.on(`${model.toUpperCase()}_UPDATED`, callback)
			},
			stop: () => {
				this.removeListener(`${model.toUpperCase()}_UPDATED`, callback)
			}
		}
	}

	disconnect () {
		this.channels.forEach(({name}) => {
			console.log("leaving channel", name);
			this.echo.leave(name);
		});
		this.channels = [];
		this.echo = null;
	}

	getEcho () {
		this.echo = new Echo({
			auth: {
				headers: {
					"Authorization": AuthStore.getToken()
				}
			},
			authEndpoint: `${url}/broadcasting/auth`,
			broadcaster: 'pusher',
			key: AuthStore.getUser().credentials.PUSHER_KEY,
			cluster: AuthStore.getUser().credentials.PUSHER_CLUSTER,
			encrypted: false
		});
		this.emit("CONNECTED");
	}

	getModelAutoUpdate ([name, ...rest], component: Object) {
		const modelUpdateEvent = this.getModelEventName(name);
		let updater = () => {
			component.setState({
				[name]: this.getModel(name, ...rest)
			});
		};
		this.on(modelUpdateEvent, updater);

		let cwum = component.componentWillUnmount.bind(component);

		component.componentWillUnmount = () => {
			cwum();
			this.removeListener(modelUpdateEvent, updater);
		};

		return this.getModel(name);
	}

	getModel (name, filter?: (data: Array) => Array, force?: boolean = false, extraParams = {}): Array {
		if (!this.models[name]) {
			this.fetchModel(name,extraParams);
		}
		if (force) {
			this.fetchModel(name,extraParams);
			return this.models[name] || [];
		}
		if (filter) {
			return filter(this.models[name] || []);
		} else {
			return this.models[name] || [];
		}
	}

	fetchModel (name: string, extraParams = {}) : Array {
		return ajax(api.get(name, {
			params: {
				trashed: 0,
				...extraParams
			}
		}))
			.promise
			.then((response) => {
				this.models[name] = response.data;
				this.emit(`${name.toUpperCase()}_UPDATED`)
			})
	}

	static getModelEventName (name:string) : string {
		return `${name.toUpperCase()}_UPDATED`
	}

	getConfig (name) {
        let config = this.getModel('configs').find(e => e.name === name);
        if (!config) {
            return null
        } else {
            return config.value
        }
	}

	handleActions (action) {
		switch (action.type) {
			case "LOG_OUT":
			case "LOG_OUT_EXIT":
				this.disconnect();
				break;
		}
	}

	getModelEventName (name) {
		return `${name.toUpperCase()}_UPDATED`;
	}
}

const dataStore = new DataStore();

dispatcher.register(dataStore.handleActions.bind(dataStore));

window.data = dataStore;

export default dataStore;
