import React, {Component} from "react";
import PropTypes from "prop-types"
import {Header} from "components/header";
import LoadingStore from "stores/LoadingStore";
import ModalStore from "stores/ModalStore";
import Loading from "components/loading/loading";
import {Nav} from "components/nav";
import {AppRoutes, AppRoot} from "../menus/menu";
import Alert from 'react-s-alert';
import {breakpoints} from "helper/breakpoints";
import {objectToClass} from "helper/objectToClass";
import {ReportView} from "components/reportView";

export class Layout extends Component {
	static propTypes = {
		children: PropTypes.element,
		location: PropTypes.shape({
			pathname: PropTypes.string
		}).isRequired
	};

	static childContextTypes = {
		switchNav: PropTypes.func,
	};

	constructor() {
		super();
		this.state = {
			loading: false,
			openNav: false,
			sticky: false,
			wide: false,
		};
		this.loadingChange = this.loadingChange.bind(this);
		this.manageMouseInteractions = this.manageMouseInteractions.bind(this);
		this.manageMouseLeaving = this.manageMouseLeaving.bind(this);
	}

	getChildContext() {
		return {
			switchNav: this.switchNav.bind(this)
		};
	}

	loadingChange() {
		this.setState({
			loading: LoadingStore.isLoading()
		})
	}

	switchNav(sticky: boolean = false) {
		this.setState({
			openNav: !this.state.openNav,
			sticky: sticky
		});
	}

	manageMouseInteractions({pageX, pageY}) {
		if (pageY > 75 && !ModalStore.getOpenId()) {
			if (pageX < 50 && !this.state.openNav) {
				this.switchNav();
			}
			if (pageX > 200 && this.state.openNav) {
				this.switchNav();
			}
		}
	}

	manageMouseLeaving(e) {
		if (e.toElement == null && e.relatedTarget == null) {
			if (this.state.openNav) {
				this.setState({
					openNav: false
				});
			}
		}
	}

	componentDidMount() {
		this.main = window.document.querySelector("main");
		if (!breakpoints.is("small")) {
			window.document.body.addEventListener("mousemove", this.manageMouseInteractions);
		}
		window.addEventListener("mouseout", this.manageMouseLeaving);
	}

	componentWillMount() {
		LoadingStore.on("change", this.loadingChange);
		breakpoints.on('change', () => {
			if (breakpoints.is("small")) {
				window.document.body.removeEventListener("mousemove", this.manageMouseInteractions);
			} else {
				window.document.body.addEventListener("mousemove", this.manageMouseInteractions);
			}
		});
	}

	componentWillUnmount() {
		LoadingStore.removeListener("change", this.loadingChange);
		window.document.body.removeEventListener("mousemove", this.manageMouseInteractions);
		window.removeEventListener("mouseout", this.manageMouseLeaving);
		breakpoints.off("small");
	}

	flipWide () {
		this.setState({
			wide: !this.state.wide
		},() => {
			//Hack for resizing the tables
			window.dispatchEvent(new Event('resize'));
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.openNav === true && this.props.location.pathname !== nextProps.location.pathname) {
			this.switchNav();
			if (this.main) {
				this.main.scrollTop  = 0;
			}
		}
	}

	render() {
		return (
			<div className="with-bg">
				<Header switchNav={() => {
					this.switchNav()
				}} wide={this.state.wide} flipWide={this.flipWide.bind(this)}/>
				<div className="row small-12" style={{position: "relative"}}>
					<div id="menu">
						<Nav links={AppRoutes} key={"nav"} root={AppRoot} open={this.state.openNav}/>
					</div>
					<main className={[
						...objectToClass({
							wide: this.state.wide
						}),
						"columns"
					].join(" ")} onClick={()=>this.state.openNav === true && this.setState({openNav: false})}>
						{this.props.children}
						<ReportView/>
						<Loading loading={this.state.loading}/>
					</main>
				</div>
				<Alert stack={{limit: 6}} effect='slide' position='bottom-right'/>
			</div>
		);
	}
}
