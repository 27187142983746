import React, {
	Component
} from 'react';
import Group from "../../../components/forms/group";
import {Input} from "../../../components/forms/input.jsx";
import {api} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import linkState from "../../../helper/state";
import DataStore from "../../../stores/DataStore";
import {Ruta} from "./ruta";

export class EditaRuta extends Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.initialState = {
			routes: DataStore.getModel("routes"),
			data: null,
			id: ""
		};
		this.state = {...this.initialState};
	}

	updateRoutes () {
		this.setState({
			routes: DataStore.getModel("routes")
		});
	}

	componentWillMount() {
		DataStore.on("ROUTES_UPDATED",this.updateRoutes.bind(this));
		let { params } = this.props;
		console.log(this.props.params);
		if (params && params.id && params.id !== "undefined") {
			this.setState({
				id : params.id
			},() => {
				this.handleOpen();
			});
		}
	}

	componentWillUnmount() {
		DataStore.removeListener("ROUTES_UPDATED",this.updateRoutes.bind(this));
	}


	handleOpen (e) {
		if (e) {
			e.preventDefault()
		}
		ajax(api.get(`routes/${this.state.id}`),{action: true},true)
			.then(({data}) => {
			    this.setState({
			    	data
			    });
			})
	}

	handleClose (e,enable) {
		this.setState({
			...this.initialState,
			routes: this.state.routes
		},enable);
	}


	render() {
		return (
			<div>
				<Group margin title={"Editar ruta"} handleForm={this.handleOpen.bind(this)}>
					<Input type="select"
					       state={this.linkState("id")}
					       options={this.state.routes.map(({name: show, id: value}) => ({show,value}))}
					       label={"Ruta"}
					       disabled={this.state.data}
						   autoFocus={true}
					/>
					<Input type="submit"
					       text={"Abrir"}
					       button={{color: "success"}}
					       disabled={this.state.data}
					/>
					<Input type="button"
					       text={"Cerrar"}
					       button={{color: "alert"}}
					       disabled={!this.state.data}
					       click={this.handleClose.bind(this)}
					/>
				</Group>
				{this.state.data ? <Ruta data={this.state.data} update={this.handleOpen.bind(this)} close={this.handleClose.bind(this)}/> : null}
			</div>
		);
	}
}
