import EventEmitter2 from "eventemitter2";

class ModalStore extends EventEmitter2 {
	constructor() {
		super();
		this.openId = undefined;
		this.data = undefined;
		this.changed = false;
	}
	isChanged () {
		return this.changed;
	}
	getOpenId () {
		return this.openId;
	}
	closeModal() {
		this.openId = undefined;
		this.data = undefined;
		this.changed = false;
		this.emit("change");
	}
	hasChanged (changed) {
		this.changed = changed;
	}
	getData () {
		return this.data ? this.data : false;
	}
	openModal(id,data) {
		this.openId = id;
		this.data = data;
		this.emit("change");
	}
}

const modalStore = new ModalStore();

export default modalStore;