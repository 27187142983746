import React, {
	Component,
} from 'react';

import {Catalog} from"components/catalog/catalog";
import {FormCreateModel} from "./formCreateModel";

export class Modelos extends Component {
	static propTypes = {};
	static defaultProps = {};

	render() {
		return (
			<Catalog columns={[
					{key: "tipo",name: "Tipo", formatter: ({value}) => <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>},
					{key: "marca", name: "Marca"},
					{key: "submarca", name: "Submarca"}
				]}
			         keys={["tipo", "marca", "submarca"]}
			         singular={"Vehículo"}
			         model={"models"}
			         name={"Vehículos"}>
				<FormCreateModel/>
			</Catalog>
		);
	}
}
