import "./modal.scss";
import React, {Component} from 'react';
import PropTypes from "prop-types"
import Grid, {propTypes as gridPropType} from "helper/grid";
import ReactCSSTransitionGroup  from "react-addons-css-transition-group";
import ModalStore from "stores/ModalStore";
import Draggable from 'react-draggable';
import AlertStore from "stores/AlertStore";
import Mousetrap from "mousetrap";
import Cross from "components/svg/cross"
import Eye from "components/svg/eye"

export default class Modal extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		grid: gridPropType,
		children: PropTypes.any
	};

	constructor() {
		super();
		this.state = {
			openId: ModalStore.getOpenId()
		};
		this.updateOpenId = this.updateOpenId.bind(this)
	}

	componentWillMount() {
		ModalStore.on("change", this.updateOpenId);
		Mousetrap.bind("esc",this.close.bind(this))
	}

	componentWillUnmount() {
		ModalStore.removeListener("change", this.updateOpenId);
		Mousetrap.unbind("esc");
	}

	updateOpenId() {
		this.setState({
			openId: ModalStore.getOpenId()
		})
	}

	close() {
		if (ModalStore.isChanged()) {
			const confirm = () => {
				ModalStore.closeModal();
			};
			const dismiss = () => {
				//
			};
			AlertStore.addToQueue({
				title: "Desea cerrar esta ventana?",
				text: "<strong>Los cambios no serán guardados</strong>",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: "#da3116",
				confirmButtonText: 'Cerrar',
				cancelButtonText: 'Cancelar',
				allowEscapeKey: false,
				allowOutsideClick: false
			}, {confirm, dismiss})
		} else {
			ModalStore.closeModal();
		}
	}

	handleCloseByClick() {
		this.close();
	}


	render() {
		let modalContent = this.props.id === this.state.openId ? (
			<div className="modal-bg columns" onClick={this.handleCloseByClick.bind(this)}>
				<div className="row align-center align-middle" style={{height: "100%"}}>
					<Draggable handle=".modal-drag" bounds="parent">
						<div className={Grid(this.props.grid)} onClick={e => e.stopPropagation()}>
							<div className="row modal-center">
								<div className="modal-drag">
									<Eye/>
								</div>
								<div className="modal-close"
								     onClick={this.close.bind(this)}
								>
									<Cross/>
								</div>
								{this.props.children}
							</div>
						</div>
					</Draggable>
				</div>
			</div>
		) : null;
		return (
			<ReactCSSTransitionGroup transitionName="modal"
			                         transitionEnterTimeout={350}
			                         transitionLeaveTimeout={350}>
				{modalContent}
			</ReactCSSTransitionGroup>
		);
	}
}
