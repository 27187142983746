import React, {Component} from "react";
import PropTypes from "prop-types"
import ConsultItem from "./consultItem";

export class ConsultList extends Component {
	static propTypes = {
		orders: PropTypes.any,
		asignaciones: PropTypes.bool.isRequired,
		precios: PropTypes.bool.isRequired,
		pagos: PropTypes.bool.isRequired
	};
	static defaultProps = {
		orders: null,
		asignaciones: false,
		precios: false,
		pagos: false
	};

	render() {
		let empty = (
			<div>
				<h3 className="text-center">No existen ordenes para esta busqueda</h3>
			</div>
		);
		if (this.props.orders === false) {
			return (
				<div>
					<h3 className="text-center">Realize una búsqueda</h3>
				</div>
			);
		}
		if (this.props.orders === null) {
			return null;
		}
		if (Array.isArray(this.props.orders)) {
			if (this.props.orders.length > 0) {
				return (
					<div className="consult-form-container row align-center columns">
						{this.props.orders.map(
							(order) => <ConsultItem key={order.id}
							                        order={order}
							                        precios={this.props.precios}
							                        asignaciones={!order.deleted_at && this.props.asignaciones}
							                        pagos={!order.deleted_at && this.props.pagos}
							/>
						)}
					</div>
				);
			} else {
				return empty;
			}
		}
		if (this.props.orders === Object(this.props.orders)) {
			return (
				<div className="consult-form-container row align-center columns">
					<ConsultItem key={this.props.orders.id}
					             order={this.props.orders}
					             precios={this.props.precios}
					             asignaciones={!this.props.orders.deleted_at && this.props.asignaciones}
					             pagos={!this.props.orders.deleted_at && this.props.pagos}
					/>
				</div>
			);
		}
	}
}
