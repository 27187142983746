import React from "react";
import {ReadOnly} from "../../../components/forms/input";
import Group from "../../../components/forms/group";
import {Service} from "../../../misc/propTypes";
import { money, getMoney } from 'helper/formatters'

export default class GastosInfo extends React.Component {
	static propTypes = {
		info: Service
	};
	//when order is undefined
	static defaultProps = {
		info: {
			operator: {
				nombre: "N/A",
			},
			bus: {
				full_name: "N/A"
			},
			order: {
				id: "N/A",
				destino: "N/A"
			},
			balance: {
				cobrar_balance: 0,
				deposits: 0,
				expenses_declared: 0,
				expenses_return: 0,
				balance: 0
			}
		}
	};
	render() {
		let {info} = this.props;
		if (!info.operator) {
			info.operator = {
				nombre: "N/A"
			};
		}
		if (!info.bus) {
			info.bus = {
				full_name: "N/A"
			};
		}
		return (
			<Group grid={this.props.grid} title={"Datos del viaje"} style={{marginBottom: "1em"}}>
				<ReadOnly type="text"
				          label="Folio"
				          grid={[6,3]}
				          state={{value: info.order.id}}
				/>
				<ReadOnly type="text"
				          label="Destino"
				          grid={[6,3]}
				          state={{value: info.order.destino}}
				/>
				<ReadOnly type="text"
				          label="Operador"
				          grid={[6,3]}
				          state={{value: info.operator.nombre}}
				/>
				<ReadOnly type="text"
				          label="Autobús"
				          grid={[6,3]}
				          state={{value: info.bus.full_name}}
				/>
				<ReadOnly type="text"
				          grid={[6,true]}
				          label="Vale pendiente de cobro"
				          state={{value: getMoney(parseFloat(info.balance.cobrar_balance))}}
				/>
				<ReadOnly grid={[6,true]}
				          type="text"
				          label="Vale para gastos de viaje"
				          state={{value: getMoney(parseFloat(info.balance.deposits))}}
				/>
				<ReadOnly type="text"
				          grid={[6,true]}
				          label="Gastos comprobados"
				          state={{value: getMoney(parseFloat(info.balance.expenses_declared))}}
				/>
				<ReadOnly type="text"
				          grid={[6,true]}
				          label="Gastos devueltos"
				          state={{value: getMoney(parseFloat(info.balance.expenses_return))}}
				/>
				<ReadOnly grid={true}
				          type="text"
				          label="Saldo"
				          state={{value: getMoney(parseFloat(info.balance.balance))}}
				          extra={{style: {color: parseFloat(info.balance.balance) === 0 ? undefined : Number.parseFloat(info.balance.balance) < 0 ? "green" : "red"}}}
				/>
			</Group>
		);
	}
}