/**
 * Created by FeNiX on 11-Apr-16.
 */
import EventEmitter2 from "eventemitter2";

class LoadingStore extends EventEmitter2 {
	constructor() {
		super();
		this.loading = 0;
	}
	startLoading () {
		this.loading++;
		this.emit("change");
	}
	stopLoading () {
		this.loading--;
		this.emit("change");
	}
	isLoading () {
		return this.loading > 0;
	}
}

const loadingStore = new LoadingStore();

export default loadingStore;
