import React, { Component } from 'react'
import { api } from 'helper/rest'
import { Input, ReadOnly } from 'components/forms/input'
import Group from 'components/forms/group'
import { ConsultList } from '../consulta/consultList'
import { Payment } from './payment'
import linkState, { linkCheckState } from 'helper/state'
import ajax from 'helper/ajax'
import DataStore from 'stores/DataStore'

export class Paga extends Component {
  constructor (props) {
    super(props)
    this.linkState = linkState.bind(this)
    this.linkCheckState = linkCheckState.bind(this)
    this.initialState = {
      folio: '',
      payment_type: '',
      payment_types: DataStore.getModel('payment_types'),
      reference: '',
      confirmed: true,
      data: null,
      fetched: false
    }
    this.state = {...this.initialState}
    this.getOrder = this.getOrder.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
    this.updatePaymentTypes = this.updatePaymentTypes.bind(this)
    this.cancelar = this.cancelar.bind(this)
  }

  updatePaymentTypes () {
    this.setState({
      payment_types: DataStore.getModel('payment_types')
    })
  }

  componentWillMount () {
    DataStore.on('PAYMENT_TYPES_UPDATED', this.updatePaymentTypes)
  }

  componentWillUnmount () {
    DataStore.removeListener('PAYMENT_TYPES_UPDATED', this.updatePaymentTypes)
  }

  fetchOrder () {
    ajax(api.get(`order/${this.state.folio}`), {
      action: true, error: {
        404: {
          text: `El folio "${this.state.folio}" no existe.`
        }
      }
    })
      .promise
      .then((response) => {
        this.setState({
          fetched: true,
          data: response.data
        })
      })
  }

  handleUpdate () {
    this.fetchOrder()
  }

  getOrder (e) {
    if (e) {
      e.preventDefault()
    }
    this.fetchOrder()
  }

  handlePayment (e) {
    e.preventDefault()
    ajax(api.post(`order/${this.state.folio}/payments`, {
      payment_type_id: this.state.payment_type,
      reference: this.state.reference || null,
      import: this.state.amount,
      confirmed: this.state.confirmed
    }), {
      action: true,
      success: {
        text: 'Pago realizado'
      }
    })
      .promise
      .then(() => {
        this.setState({
          reference: '',
          payment_type: '',
          amount: '',
          confirmed: true
        })
        this._next.focus()
        this.handleUpdate()
      })
  }

  cancelar (e, enable) {
    this.setState({
      ...this.initialState,
      payment_types: this.state.payment_types
    }, enable)
  }

  render () {
    return (
      <div>
        <Group form={true} handleForm={this.getOrder} title={'Pagar folio'} style={{marginBottom: '1em'}}>
          <Input type="text"
                 label="Folio"
                 state={this.linkState('folio')}
                 grid={[12, 4]}
                 disabled={this.state.fetched}
                 autoFocus={true}
          />
          <Input type="submit"
                 text="Abrir"
                 button={{color: 'success'}}
                 disabled={this.state.fetched}
                 grid={[6, 4]}
          />
          <Input type="button"
                 text="Cerrar"
                 button={{color: 'warning'}}
                 click={this.cancelar}
                 disabled={!this.state.fetched}
                 grid={[6, 4]}
          />
        </Group>
        {this.state.data ? (
          <Group title={'Pagos'} grid={[12]} group={true} style={{marginBottom: '1em'}}>
            <Payment payments={this.state.data.payments}
                     deletes={true}
                     update={this.getOrder}
            />
          </Group>
        ) : null}
        {this.state.data && !(this.state.data.balance.balance === '0.00') ? (
          <Group form={true} handleForm={this.handlePayment} title={'Datos de pago'}
                 style={{marginBottom: '1em'}}>
            <ReadOnly type="text"
                      label="Saldo"
                      grid={[12, 6, true]}
                      state={{value: '$' + this.state.data.balance.balance}}/>
            <Input type="checkbox"
                   label="Confirmado"
                   state={this.linkCheckState('confirmed')}
                   grid={[12, 3, 1]}
                   required={false}
            />
            <Input type="select"
                   getInput={(ref) => this._next = ref}
                   label="Tipo de pago"
                   options={this.state.payment_types.map((opt) => ({show: opt.type, value: opt.id}))}
                   state={this.linkState('payment_type')}
                   grid={[6, 6, true]}
                   required
            />
            <Input state={this.linkState('reference')}
                   type="text"
                   label="Referencia"
                   grid={[6, 4, true]}
                   required={false}
            />
            <Input state={this.linkState('amount')}
                   extra={{max: this.state.data.balance.balance, step: 'any'}}
                   label="Importe"
                   type="number"
                   grid={[6, 4, true]}
                   required
            />
            <Input type="submit"
                   button={{color: 'success'}}
                   text="Hacer pago"
                   disabled={this.state.data.balance.balance === '0.00'}
                   grid={[6, 4, true]}
            />
          </Group>
        ) : null}
        <ConsultList orders={this.state.data} precios={true} asignaciones={true}/>
      </div>
    )
  }
}
