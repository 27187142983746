import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import DataGrid from "react-data-grid";
import date from "helper/date";
import { money, getMoney } from 'helper/formatters'

export class LoansTable extends Component {
	static propTypes = {
		loans: PropTypes.array.isRequired,
		contextMenu: PropTypes.element,
		contextMenuId: PropTypes.string
	};

	static defaultProps = {
		loans: []
	};

	static resizable = true;

	monetizeWithColor({value: {number, payment}}) {
		return (
			<span style={{color: payment ? "green" : "red"}}>
				{getMoney(parseFloat(number))}
			</span>
		);
	}

	monetize({value: number}) {
		return (
			<span>
				{getMoney(parseFloat(number))}
			</span>
		);
	}

	columns = [
		{key: "id", name: "#", resizable: this.resizable, width: 50},
		{key: "date", name: "Fecha", resizable: this.resizable},
		{key: "loan_type", name: "Tipo", resizable: this.resizable},
		{key: "comment", name: "Descripción", resizable: this.resizable},
		{key: "import", name: "Importe", resizable: this.resizable, formatter: this.monetizeWithColor},
		{key: "balance", name: "Saldo", resizable: this.resizable, formatter: this.monetize},
	];


	render() {
		let loans = [];
		this.props.loans.reduce((carrie,loan) => {
			const balance = parseFloat(loan.is_payment ? -loan.import : loan.import) + parseFloat(carrie);
			loans.push({
				id: loan.id,
				date: date(loan.date),
				loan_type: loan.is_payment ? "Pago" : "Préstamo",
				import: {
					number: loan.import,
					payment: loan.is_payment
				},
				comment: loan.comment,
				balance
			});
			return balance;
		},0);
		return (
			<DataGrid columns={this.columns}
			          rowGetter={(i)=>loans[i]}
			          rowsCount={loans.length}
			          minHeight={500 - 115 - 80}
			          rowKey="id"
			          contextMenu={this.props.contextMenu}
			          contextMenuId={this.props.contextMenuId}
			/>
		);
	}
}
