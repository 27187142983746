import {asyncComponent} from "../async/asyncComponent";
import {DEBUG} from "misc/debug";
import {Home} from "pages/home/home";
import {Login} from "pages/login/login";
import {Logout} from "pages/logout/logout";
import {Nueva} from "pages/ordenes/nueva";
import {Consulta} from "pages/ordenes/consulta/consulta";
import {Modifica} from "pages/ordenes/modifica";
import {Paga} from "pages/ordenes/paga/paga";
import {Cancela} from "pages/ordenes/cancela";
import {Asigna} from "pages/ordenes/asigna/asigna";
import {Contrato} from "pages/ordenes/contrato";
import {Modelos} from "pages/catalogo/modelos/modelos";
import {Clientes} from "pages/catalogo/clientes/clientes";
import {Socios} from "pages/catalogo/socios/socios";
import {Operadores} from "pages/catalogo/operadores/operadores";
import {Usuarios} from "pages/catalogo/usuarios/usuarios";
import {Autobuses} from "pages/catalogo/autobuses/autobuses";
import {Vendedores} from "pages/catalogo/vendedores/vendedores";
import {EmpresasExternas} from "pages/catalogo/empresasExternas/empresasExternas";
import {Gastos} from "pages/operadores/gastos/gastos";
import {Prestamos} from "pages/operadores/prestamos/prestamos";
import {CreditoVivienda} from "pages/operadores/creditoVivienda/creditoVivienda";
import {ValeGastos} from "pages/operadores/vales/gastos/valeGastos";
import {Recaudacion} from "pages/ordenes/recaudacion/recaudacion";
import {TiposGasto} from "pages/catalogo/tiposGasto/tiposGasto";
import {TiposDeposito} from "pages/catalogo/tiposDeposito/tiposDeposito";
import {TiposPago} from "pages/catalogo/tiposPago/tiposPago";
import {Publicidad} from "pages/catalogo/publicidad/publicidad";
import {Ruta} from "pages/ordenes/rutas/ruta";
import {ListaRutas} from "pages/ordenes/rutas/listaRutas";
import {Facturas} from "pages/ordenes/facturas/facturas";
import {HistorialFacturas} from "pages/ordenes/facturas/historial/historial";
import {Proveedores} from "pages/catalogo/proveedores/proveedores";
import {Cobrar} from "pages/reportes/cobrar/cobrar";
import {Pagar} from "pages/reportes/pagar/pagar";
import {Pagos} from "pages/reportes/pagos/pagos";
import {EditaRuta} from "pages/ordenes/rutas/editaRuta";
import {GeneracionRutas} from "pages/ordenes/rutas/generacionRutas";
import {ExcepcionesRutas} from "pages/ordenes/rutas/excepcionesRutas";
import {ReporteNomina} from "pages/reportes/nomina/reporteNomina";
import {ReporteRuta} from "pages/reportes/ruta/reporteRuta";
import {ReporteVentas} from "pages/reportes/venta/reporteVentas";
import {Perfil} from "pages/perfil/perfil";

function sortByName (arr) {
	return arr.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
}

export const ActionRoutes = [
	{
		to: "/login",
		component: Login
	},
	{
		to: "/register",
		component: asyncComponent(() => import("pages/register/register").then(module => module.Register))
	},
	{
		to: "/app(/)?",
		redirects: "/app/home"
	},
	{
		to: "/",
		redirects: "/app/home"
	},
];

export const AppRoot = "/app";

export const AppRoutes = [
	{
		name: "Inicio",
		to: "home",
		component: Home
	},
	{
		name: "Perfil",
		to: "perfil",
		component: Perfil,
		hide: !DEBUG
	},
    {
        name: "Calendario",
        to: "calendar",
        component: asyncComponent(() => import("pages/graficas/calendario/calendario").then(module => module.default))
    },
	{
		name: "Catálogos",
		to: "catalogos",
		role: ["administrator", "secretary"],
		links: sortByName([
			{name: "Socios", to: "socios", component: Socios},
			{name: "Autobuses", to: "autobuses", component: Autobuses},
			{name: "Operadores", to: "operadores", component: Operadores},
			{name: "Vehículos", to: "modelos", component: Modelos},
			{name: "Clientes", to: "clientes", component: Clientes},
			{
				name: "Usuarios",
				to: "usuarios",
				component: Usuarios,
				role: "administrator"
			},
			{name: "Vendedores", to: "vendedores", component: Vendedores},
			{
				name: "Cuentas", to: "cuentas", links: [
				{name: "Empresas externas", to: "empresas_externas", component: EmpresasExternas},
				{name: "Tipos de gasto", to: "tipos_gasto", component: TiposGasto},
				{name: "Tipos de deposito", to: "tipos_deposito", component: TiposDeposito},
			]
			},
			{name: "Tipos de pago", to: "tipos_pago", component: TiposPago},
			{name: "Publicidad", to: "publicidad", component: Publicidad},
			{name: "Proveedores", to: "proveedores", component: Proveedores},
		])
	},
	{
		name: "Ordenes",
		to: "ordenes",
		role: ["secretary", "administrator", "treasury", "seller"],
		links: sortByName([
			{name: "Registrar", to: "nueva", component: Nueva, role: ["administrator", "secretary"]},
			{
				name: "Consultar",
				to: "consulta",
				component: Consulta,
				role: ["administrator", "secretary", "seller", "treasury"]
			},
			{
				name: "Pagar",
				to: "paga",
				component: Paga,
				role: ["administrator", "treasury"],
				links: [
					// {name: "En masa", to: 'masa',component: }
				]
			},
			{
				name: "Modificar",
				to: "modifica",
				component: Modifica,
				role: ["administrator", "secretary", "seller", "treasury"]
			},
			{name: "Cancelar", to: "cancela", component: Cancela, role: ["administrator", "secretary", "seller"]},
			{
				name: "Contrato",
				to: "contrato",
				component: Contrato,
				role: ["administrator", "secretary", "seller"]
			},
			{name: "Asignar", to: "asigna", component: Asigna, role: ["administrator", "secretary"]},
			{
				name: "Facturas",
				to: "factura",
				component: Facturas,
				role: ["administrator", "treasury", "secretary"],
				links: [
					{
						name: 'Historial',
						to: 'historial',
						component: HistorialFacturas
					}
				]
			},
			{
				name: "Rutas",
				to: "rutas",
				role: ["administrator", "secretary"],
				links: [
					{name: "Crear", to: "crear", component: Ruta},
					{name: "Catalogo", to: "catalogo", component: ListaRutas},
					{name: "Generar", to: "generar", component: GeneracionRutas},
					{name: "Editar", url: "editar/:id?", to: "editar", component: EditaRuta},
					{name: "Excepciones", to: "excepciones", component: ExcepcionesRutas},
				]
			},
			{name: "Recaudación", to: "recaudacion", component: Recaudacion, role: ["administrator", "treasury"],},
		])
	},
	{
		name: "Reportes",
		to: "reportes",
		role: [
			"administrator",
			"treasury",
			"secretary",
			"accountant"
		],
		links: [
			{name: "Cuentas por cobrar", to: "cuentas_cobrar", component: Cobrar, role: ["administrator", "treasury"],},
			{name: "Cuentas por pagar", to: "cuentas_pagar", component: Pagar, role: ["administrator", "treasury"],},
			{name: "Pagos de servicios", to: "pagos", component: Pagos, role: ["administrator", "treasury"],},
			{
				name: "Nomina",
				to: "nomina",
				component: ReporteNomina,
				role: ["administrator", "secretary", "accountant", "treasury"],
			},
			{name: "Ruta", to: "ruta", component: ReporteRuta, role: ["administrator", "secretary"],},
			{
				name: "Ventas",
				to: "ventas",
				component: ReporteVentas,
				role: ["administrator", "secretary", "accountant", "treasury"],
			},
		]
	},
	{
		name: "Administración",
		to: "admin",
		role: "administrator",
		links: [
			{
				name: "Permisos",
				to: "permisos",
				component: asyncComponent(() => import("pages/administracion/permisos/permisos").then(module => module.Permisos))
			},
			{
				name: "Historial",
				to: "historial",
				component: asyncComponent(() => import("pages/administracion/registro/registro").then(module => module.Registro))
			},
			{
				name: "Configuracion",
				to: "configuracion",
                component: asyncComponent(() => import("pages/administracion/configuracion/configuracion").then(module => module.Configuracion))
			}
		]
	},
	{
		name: "Salir",
		to: "logout",
		component: Logout
	}
];
