import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Input} from "../../../components/forms/input.jsx";
import ModalStore from "../../../stores/ModalStore";
import {api} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import linkState from "../../../helper/state";
import AlertStore from "../../../stores/AlertStore";

export class PrestamosForm extends Component {
	static propTypes = {
		operator: PropTypes.string.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.initialState = {
			date: "",
			type: "",
			import: "",
			comment: ""
		};
		this.state = {...this.initialState};
	}


	componentWillMount() {
		if (ModalStore.data) {
			const data = ModalStore.data;
			this.filling = true;
			this.id = data.id;
			this.setState({
				date: data.date,
				import: data.import,
				type: data.is_payment ? "1" : "2",
				comment: data.comment
			});
		}
	}


	handleSubmit(e) {
		e.preventDefault();
		const data = {
			date: this.state.date,
			is_payment: this.state.type === "1",
			import: this.state.import,
			comment: this.state.comment || null
		};
		if (this.filling) {
			ajax(api.patch(`operators/${this.props.operator}/loans/${this.id}`, data), {action: true,success: {
				text: "Vale actualizado"
			}})
				.promise
				.catch((error) => {
					if (error.response && error.response.status === 403 && error.response.data.error === "LIQUIDATION_DEFINED") {
						AlertStore.addToQueue({
							text: "No es posible editar esta entrada ya que fue definida por una liquidación",
							type: "info"
						})
					}
				})
		} else {
			ajax(api.post(`operators/${this.props.operator}/loans`, data), {action: true,success: {
				text: "Vale guardado"
			}})
				.promise
				.then(() => {
					this.setState({...this.initialState});
				})
		}
	}

	handleDelete(e, enable) {
		AlertStore.addToQueue({
			title: 'Desea eliminar este vale?',
			text: 'Esta acción solo la puede revertir un administrador',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}, {
			confirm: () => {
				ajax(api.delete(`operators/${this.props.operator}/loans/${this.id}`), {action: true,success: {
					title: "Vale eliminado",
					text: `#${this.id}`
				}, error: {
					0: {
						title: "El vale no fue eliminado",
						text: `#${this.id}`
					}
				}})
					.promise
					.then(() => {
						ModalStore.closeModal();
					})
					.then(enable,enable);
			},
			dismiss: () => {
				enable();
			}
		});
	}

	render() {
		return (
			<Group handleForm={this.handleSubmit.bind(this)} title={`${this.filling ? "Editar" : "Agregar"} vale ${this.filling ? `#${this.id}` : ""}`.trim()}>
				<Input type={"date"}
				       label={"Fecha"}
				       state={this.linkState("date")}
				       grid={[12,4]}
				/>
				<Input type={"select"}
				       label={"Tipo"}
				       options={[{show: "Pago",value: "1"},{show: "Préstamo",value: "2"}]}
				       state={this.linkState("type")}
				       grid={[12,4]}
				/>
				<Input type={"number"}
				       label={"Importe"}
				       state={this.linkState("import")}
				       grid={[12,4]}
				       extra={{min: 1,step: "any", max: this.state.type === "1" && !this.filling ? this.props.max : ""}}
				/>
				<Input type="textarea"
				       label="Descripción"
				       state={this.linkState("comment")}
				       grid={[12]}
				       required={false}
				/>

				<Input grid={true}
				       type="submit"
				       text="Guardar"
				       button={{color: "success"}}
				       label={false}
				/>
				{this.filling ? (
					<Input type="button"
					       text="Eliminar"
					       button={{color: "alert"}}
					       label={false}
					       click={this.handleDelete.bind(this)}
					/>
				) : null}
			</Group>
		);
	}
}
