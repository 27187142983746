/* global google */

import React, {Component} from 'react';
import PropTypes from "prop-types";
import Group from "../forms/group";
import {GoogleMap} from "./googleMap";
import ModalStore from "stores/ModalStore";
import AlertStore from "stores/AlertStore";
import {Input} from "../forms/input.jsx";

export class GmapSearch extends Component {
    static propTypes = {
        autocomplete: PropTypes.func.isRequired
    };
    static defaultProps = {};

    componentDidMount() {
        if (window.google) {
            let map = this.refs.searchMap.map;
            let geocoder = new google.maps.Geocoder();
            let {calle = "", colonia = "", delegacion = ""} = ModalStore.getData();
            let search = `${calle} ${colonia} ${delegacion}`.replace(/\s+/g, " ").trim();
            geocoder.geocode({'address': search}, (results, status) => {
                console.log("search done");
                console.log("search:", search);
                console.log("result:", results);
                if (status === google.maps.GeocoderStatus.OK) {
                    map.setCenter(results[0].geometry.location);
                    map.setZoom(18);
                    new google.maps.Marker({
                        map: map,
                        position: results[0].geometry.location
                    });
                    this.result = results[0];
                } else {
                    console.error("Map search result not ok");
                    AlertStore.addToQueue({
                        text: "No se pudo localizar la dirección",
                        type: "info",
                        onClose: () => {
                            ModalStore.closeModal();
                        }
                    })
                }
            });
        }
    }

    autocomplete() {
        this.props.autocomplete(this.result);
        ModalStore.closeModal();
    }


    render() {
        return (
            <Group title={"Resultado"}>
                <GoogleMap id={"search"} ref="searchMap" input={false}/>
                <Group group={false}>
                    <Input type={"button"}
                           button={{color: "success"}}
                           text={"Continuar"}
                           grid={[12, 6]}
                           extra={{autoFocus: true}}
                           events={{onClick: () => this.autocomplete()}}
                    />
                    <Input type={"button"}
                           button={{color: "alert"}}
                           text={"Cerrar"}
                           grid={[12, 6]}
                           events={{onClick: () => ModalStore.closeModal()}}
                    />
                </Group>
            </Group>
        );
    }
}
