import React from "react";
import PropTypes from "prop-types";

export const propTypes = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.number
]);

export default function grid(grid: number | Array | boolean = true): string {
    if (grid === true) {
        return "columns";
    }
    if (typeof grid === "number") {
        if (grid > 12 || grid < 0) {
            throw new Error(`Grid accepts integers in the range 1-12, integer: "${grid}" was received`);
        }
        return `columns small-${grid}`;
    }
    if (Array.isArray(grid)) {
        if (grid.length > 3) {
            throw new Error(`Grid accepts arrays with 3 elements, ${grid.length} were received`);
        }
        let small = grid[0] === true ? "small-expand" : ( grid[0] ? `small-${grid[0]}` : "" );
        let medium = grid[1] === true ? "medium-expand" : ( grid[1] ? `medium-${grid[1]}` : "" );
        let large = grid[2] === true ? "large-expand" : ( grid[2] ? `large-${grid[2]}` : "" );
        return `columns ${small} ${medium} ${large}`.trim().replace(/( +)/g, " ");
    } else {
        return "";
    }
}
