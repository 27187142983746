export function mapDataToState (object,prefix = []) {
    function createPrefixes (prefixes) {
        return prefixes.reduce(function (previousValue, currentValue) {
            if (previousValue === "") {
                return currentValue;
            }
            return `${previousValue}.${currentValue}`;
        }, "");
    }
    let data = {};
    for (let prop in object) {
        let prefixes = [...prefix,prop];
        if ( object[prop] === Object(object[prop])) {
            data = {
                ...data,
                ...mapDataToState(object[prop],prefixes)
            };
        } else {
            data[`data:${createPrefixes(prefixes)}`] = object[prop];
        }
    }
    return data;
}
export function mapStateToData (state) {
    function goLevelsDeep (levels,data,value) {
        let levl = [...levels];
        //no need to go levels deep, write to the currenct object
        if (levels.length === 1) {
            if (data[levels[0]] !== undefined) {
                throw new Error("Trying to overwrite parameter in object");
            } else {
                return {
                    ...data,
                    [levels[0]]: value
                }
            }
        }
        //we will likely need to work levels deeper
        levl.shift();
        if (data[levels[0]] === Object(data[levels[0]])) {
            return {
                ...data,
                [levels[0]]: goLevelsDeep(levl,data[levels[0]],value)
            };
        } else {
            if (data[levels[0]] !== undefined) {
                throw new Error("Trying to overwrite parameter in object");
            } else {
                return {
                    ...data,
                    [levels[0]]: goLevelsDeep(levl,{},value)
                }
            }
        }
    }
    let data = {};
    for (let prop in state) {
        let it = /^data:(.+)$/g.exec(prop);
        if (it) {
            if (it[1].split(".").length === 1) {
                data[it[1]] = state[prop];
            } else {
                let levels = it[1].split(".");
                data = goLevelsDeep(levels,data,state[prop]);
            }
        }
    }
    return data;
}

window.mapDataToState = mapDataToState;
window.mapStateToData = mapStateToData;