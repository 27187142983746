import React, {
    Component
} from 'react';

import Group from "../../../components/forms/group";
import {Input} from "../../../components/forms/input.jsx";
import {api} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import linkState from "../../../helper/state";
import DataGrid from "react-data-grid";
import AlertStore from "../../../stores/AlertStore";
import date from "../../../helper/date";
import {idParse} from "helper/idParse";
import {asynx} from "helper/ajax";
import { sort } from 'helper/sort'

export class Facturas extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.initialState = {
            filter: "date",
            id: "",
            date: "",
            from: "",
            to: "",
            client: "",
            numero_factura: "",
            data: [],
            editable: [],
            paid: false,
            sort: null
        };
        this.state = {...this.initialState};
    }

    handleRowUpdate({rowIdx: index, updated: {numero_factura: factura}}) {
        let numero_factura;
        if (factura === "") {
            numero_factura = null;
        } else {
            numero_factura = parseInt(factura);
            if (isNaN(numero_factura)) {
                AlertStore.addToQueue({
                    text: "El numero de factura debe ser un numero entero positivo",
                    type: "info"
                });
                return;
            }
            if (numero_factura < 1) {
                AlertStore.addToQueue({
                    text: "El numero de factura no puede ser menor a 1",
                    type: "info"
                });
                return;
            }
        }
        let update = [...this.state.editable];
        update[index] = {
            ...update[index],
            numero_factura
        };
        this.setState({
            editable: update
        });
    }

    handleSubmit(e, enable) {
        ajax(api.post("order/factura", {
            facturas: this.state.editable
        }), {
            action: true, success: {
                text: "Números de factura guardados"
            }
        })
            .promise
            .then(enable, enable)
    }

    handleGridSort (column, direction) {
        if (direction !== 'NONE') {
            this.setState({
                ...this.state,
                sort: {
                    column,
                    direction
                }
            })
        } else {
            this.setState({
                sort: null
            })
        }
    }

    handleClose(e, enable) {
        this.setState({
            ...this.initialState
        }, enable);
    }

    handleCellDrag({fromRow, toRow, cellKey, value}) {
        let editable = [...this.state.editable];
        for (let i = fromRow; i <= toRow; i++) {
            editable[i][cellKey] = value;
        }
        this.setState({editable});
    }

    async handleOpen(e) {
        e.preventDefault();
        let searchParams;
        switch (this.state.filter) {
            case "date":
                searchParams = {
                    filter: "date",
                    date: this.state.date
                };
                break;
            case "numero_factura":
                searchParams = {
                    filter: "numero_factura",
                    numero_factura: this.state.numero_factura
                };
                break;
            case "id":
                try {
                    let result = idParse(this.state.id);
                    console.log(result);
                    searchParams = {
                        filter: "multiple",
                        id: result
                    };
                } catch (e) {
                    if (e.message === 'invalid_query') {
                        AlertStore.addToQueue({
                            type: 'info',
                            text: 'Sintaxis incorrecta'
                        });
                        return;
                    } else {
                        throw e;
                    }
                }
                break;
            case "date:range":
                searchParams = {
                    filter: "date:range",
                    from: this.state.from,
                    to: this.state.to
                };
                break;
            case "client":
                searchParams = {
                    filter: "client",
                    name: this.state.client,
                    from: this.state.from,
                    to: this.state.to
                };
                break;
        }

        let {data} = await asynx(api.get("order", {
            params: {
                ...searchParams,
                factura: "1",
                trashed: "2"
            }
        }), {action: true});

        if (searchParams.filter === "numero_factura") {
            let {data: {paid}} = await asynx(api.get("payments/mass/check", {
                params: {
                    invoice_number: this.state.numero_factura
                }
            }), {action: true});
            this.setState({
                paid
            });
        }

        this.setState({
            editable: data.map(({id, numero_factura}) => ({id, numero_factura})),
            data: data.map(({id, salida, destino}) => ({id, salida, destino}))
        });
    }

    handleMassPay(enable) {
        asynx(api.post('payments/mass', {
            range: 'invoice_number',
            invoice_number: this.state.numero_factura
        }), {enable, action: true, success: "Numero de factura pagado"})
    }


    render() {
        const columns = [
            {key: "id", name: "Folio", sortable: true},
            {key: "salida", name: "Salida", formatter: ({value}) => <span>{date(value)}</span>, sortable: true},
            {key: "destino", name: "Destino", sortable: true},
            {key: "numero_factura", name: "Numero de factura", editable: true, sortable: true},
        ];
        const options = [
            {show: "Folio", value: "id"},
            {show: "Rango de fecha", value: "date:range"},
            {show: "Fecha", value: "date"},
            {show: "Cliente", value: "client"},
            {show: "Numero de factura", value: "numero_factura"},
        ].sort(o => o.show);

        let rows = this.state.data.map((data) => {
            const {numero_factura} = this.state.editable.find(({id}) => id == data.id);
            return {
                ...data,
                numero_factura: numero_factura === null ? "" : numero_factura
            }
        });

        if (this.state.sort) {
            const {column, direction} = this.state.sort
            rows = sort({rows, column, direction})
        }

        const grid_search = [12, 12, 8];
        let search;
        switch (this.state.filter) {
            case "id":
                search = (
                    <Input type="text"
                           label={"Folio"}
                           state={this.linkState("id")}
                           disabled={rows.length > 0}
                           grid={grid_search}
                    />
                );
                break;
            case "numero_factura":
                search = (
                    <Input type="number"
                           label={"Numero de factura"}
                           state={this.linkState("numero_factura")}
                           extra={{min: 1, step: 1}}
                           disabled={rows.length > 0}
                           grid={grid_search}
                    />
                );
                break;
            case "date":
                search = (
                    <Input type="date"
                           label={"Fecha"}
                           state={this.linkState("date")}
                           grid={grid_search}
                           disabled={rows.length > 0}
                    />
                );
                break;
            case "date:range":
                search = (
                    <Group group={false} grid={grid_search}>
                        <Input type="date"
                               label={"Inicio"}
                               state={this.linkState("from")}
                               grid={[6]}
                               disabled={rows.length > 0}
                        />
                        <Input type="date"
                               label={"Fin"}
                               state={this.linkState("to")}
                               grid={[6]}
                               disabled={rows.length > 0}
                        />
                    </Group>
                );
                break;
            case "client":
                search = (
                    <Group group={false} grid={grid_search}>
                        <Input type="autocomplete"
                               label={"Cliente"}
                               state={this.linkState("client")}
                               disabled={rows.length > 0}
                               grid={[12, 4]}
                               autocomplete={{
                                   index: "clients",
                                   displayKey: "nombre",
                                   keyName: "nombre",
                                   onSelect: (e, {nombre: client}) => this.setState({client})
                               }}
                        />
                        <Input type="date"
                               label={"Inicio"}
                               state={this.linkState("from")}
                               disabled={rows.length > 0}
                               grid={[6, 4]}
                        />
                        <Input type="date"
                               label={"Fin"}
                               state={this.linkState("to")}
                               disabled={rows.length > 0}
                               grid={[6, 4]}
                        />
                    </Group>
                );
                break;
            default:
                search = null;
                break;
        }
        return (
            <div>
                <Group title={"Asignación de numero de factura"} handleForm={this.handleOpen.bind(this)} margin>
                    <Input type="select"
                           label="Filtro"
                           state={this.linkState("filter")}
                           options={options}
                           extra={{autoFocus: true}}
                           grid={[12, 12, 4]}
                           disabled={rows.length > 0}
                           autoFocus={true}
                    />
                    {search}
                    <Input type="submit"
                           text={"Abrir"}
                           button={{color: "success"}}
                           grid={[12, 6]}
                           disabled={rows.length > 0}
                    />
                    <Input type="button"
                           text={"Cerrar"}
                           button={{color: "alert"}}
                           grid={[12, 6]}
                           disabled={rows.length === 0}
                           click={this.handleClose.bind(this)}
                    />
                </Group>
                <div className="group with-margin">
                    <Group title={"Ordenes"} group={false}/>
                    <DataGrid columns={columns}
                              enableCellSelect={true}
                              rowGetter={(i) => rows[i]}
                              rowsCount={rows.length}
                              minHeight={300}
                              onCellsDragged={this.handleCellDrag.bind(this)}
                              onRowUpdated={this.handleRowUpdate.bind(this)}
                              onGridSort={this.handleGridSort.bind(this)}
                    />
                </div>
                <Group>
                    {this.state.filter === 'numero_factura' ? (
                        <Input type="button"
                               button={{color: "warning"}}
                               text={this.state.paid ? "Factura pagada" : "Liquidar factura"}
                               grid={[12, 6, 4]}
                               disabled={rows.length === 0 || this.state.paid}
                               click={this.handleMassPay.bind(this)}
                        />
                    ) : null}
                    <Input type="button"
                           button={{color: "success"}}
                           text={"Guardar"}
                           grid={[12, 6, 4]}
                           className={this.state.filter !== 'numero_factura' ? "medium-offset-6 large-offset-8" : "medium-offset-2 large-offset-4"}
                           disabled={rows.length === 0}
                           click={this.handleSubmit.bind(this)}
                    />
                </Group>
            </div>
        );
    }
}
