// @flow

import React, {
    Component
} from 'react';

import PropTypes from "prop-types";

import {api} from "helper/rest";
import {Group, Input} from "../inputs";
import linkState, {linkCheckState} from "helper/state";
import ajax from "helper/ajax";
import ModalStore from "stores/ModalStore";
import AlertStore from "stores/AlertStore";

export class CatalogForm extends Component {
    static propTypes = {
        model: PropTypes.string,
        singular: PropTypes.string,
        inputs: PropTypes.array.isRequired,
        identifier: PropTypes.string
    };

    static defaultProps = {
        identifier: "id"
    };

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.linkCheckState = linkCheckState.bind(this);
        this.initialState = {};
        props.inputs.forEach(({state}) => {
            this.initialState[state] = "";
            this.initialState[`error-${state}`] = "";
        });
        this.state = {...this.initialState};
    }

    componentWillMount() {
        if (ModalStore.data) {
            this.fill(ModalStore.data);
        }
    }

    fill(data) {
        this.filling = true;
        this.id = data.id;
        this.identifier = this.props.identifier === "id" ? data.id : data[this.props.identifier];
        let filteredData = {};
        this.props.inputs.forEach(({state}) => {
            filteredData[state] = data[state];
        });
        this.setState(filteredData);
    }

    handleDelete(e, enable) {
        AlertStore.addToQueue({
            title: `Desea eliminar ${this.props.singular[this.props.singular.length - 1] === "a" ? "esta" : "este"} ${this.props.singular.toLowerCase()}?`,
            text: 'Esta acción solo la puede revertir un administrador',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#da3116",
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
        }, {
            confirm: () => {
                ajax(api.delete(`${this.props.model}/${this.id}`), {
                    success: {
                        title: `${this.props.singular} <strong>${this.identifier}</strong> ${this.props.singular[this.props.singular.length - 1] === "a" ? "eliminada" : "eliminado"}`
                    }
                })
                    .promise
                    .then(() => {
                        enable();
                        ModalStore.closeModal();
                    })
            }, dismiss: enable
        })
    }

    alertFor422(error) {
        if (error.response && error.response.status === 422) {
            //data is wrong
            let errors = {};
            for (let errorItem in error.response.data) {
                // let {label} = this.props.inputs.find(input => input.state == errorItem);
                switch (errorItem) {
                    case "UNIQUE":
                        errors[`error-${errorItem}`] = `Este campo debe ser único`;
                        break;
                    default:
                        //
                }
            }
            this.setState({
                ...errors
            });
            AlertStore.addToQueue({
                text: "Uno o mas campos contienen errores",
                type: "error"
            })
        }
    }

    clearErrors() {
        let errors = {};
        for (let item: string in this.initialState) {
            if (/^error/.test(item)) {
                errors[item] = "";
            }
        }
        this.setState({
            ...errors
        });
    }

    handleForm(e) {
        e.preventDefault();
        let data = {...this.state};
        delete data.egreso;
        if (this.filling) {
            ajax(api.patch(`/${this.props.model}/${this.id}`, data), {
                action: true, success: {
                    text: `${this.props.singular} <strong>${this.identifier}</strong> ${this.props.singular[this.props.singular.length - 1] === "a" ? "actualizada" : "actualizado"}`
                }
            })
                .promise
                .then(this.clearErrors.bind(this))
                .catch(this.alertFor422.bind(this))
        } else {
            ajax(api.post(`${this.props.model}`, data), {
                action: true, success: {
                    text: `${this.props.singular} ${this.props.singular[this.props.singular.length - 1] === "a" ? "guardada" : "guardado"}`
                }
            })
                .promise
                .then(() => {
                    this.setState(this.initialState);
                })
                .catch(this.alertFor422.bind(this))
        }
    }

    render() {
        return (
            <Group handleForm={this.handleForm.bind(this)}
                   title={`${this.filling ? "Editar" : "Agregar"} ${this.props.singular.toLowerCase()}`}>
                {this.props.inputs.map(({type, state, label, grid, required = true}, i) => (
                    <Input type={type}
                           state={this.linkState(state)}
                           label={label}
                           grid={grid}
                           extra={{autoFocus: i === 0}}
                           required={required}
                           errorMessage={this.state[`error-${state}`]}
                           isInvalid={this.state[`error-${state}`] !== ""}
                    />
                ))}
                <Input type="submit"
                       text={"Guardar"}
                       grid={true}
                       button={{color: "success"}}
                       label={true}
                />
                {this.filling ? (
                    <Input type="button"
                           text={"Eliminar"}
                           grid={[6]}
                           button={{color: "alert"}}
                           label={true}
                           click={this.handleDelete.bind(this)}
                    />
                ) : null}
            </Group>
        );
    }
}
