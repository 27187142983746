// @flow

import React, {
	Component,
} from 'react';

import {api} from"helper/rest";
import {Group, Input} from"components/inputs";
import linkState, {linkCheckState} from"helper/state";
import ajax from"helper/ajax";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";
import DataStore from"stores/DataStore";


export class FormCreateBus extends Component {

	constructor() {
		super();
		this.linkState = linkState.bind(this);
		this.linkCheckState = linkCheckState.bind(this);
		this.initialState = {
			"bus_model_id": "",
			"partner_id": "",
			"operator_id": "",
			"license_plate_number": "",
			"clave": "",
			"year": "",
			"sits": "",
			"symbolic": false
		};
		this.state = {
			...this.initialState,
			models: DataStore.getModel("models"),
			partners: DataStore.getModel("partners"),
			operators: [],
		};
		this.requests = [
			["MODELS_UPDATED", this.listener("models")],
			["PARTNERS_UPDATED", this.listener("partners")],
			["OPERATORS_UPDATED", this.fetchUnassignedOperators.bind(this)],
		]
	}

	fetchUnassignedOperators() {
		this.cancelable = ajax(api.get("operators", {
			params: {
				filter: "unassigned"
			}
		}));
		this.cancelable
			.promise
			.then(({data: operators}) => {
				this.setState({
					operators
				});
			})
	}

	listener(model) {
		return () => {
			this.setState({
				[model]: DataStore.getModel(model)
			});
		}
	}

	fill(data) {
		this.filling = true;
		this.id = data.id;
		let {
			model,
			partner,
			operator,
			clave,
			year,
			license_plate_number,
			sits,
		} = data;
		this.setState({
			"bus_model_id": model.id,
			"partner_id": partner.id,
			"operator_id": operator ? operator.id : "",
			clave,
			year,
			sits,
			license_plate_number,
		});
	}

	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
		this.requests.forEach(r => {
			DataStore.on(...r)
		});
		this.fetchUnassignedOperators();
	}

	componentWillUnmount() {
		this.requests.forEach(r => {
			DataStore.removeListener(...r)
		});
		if (this.cancelable) {
			this.cancelable.cancel();
		}
	}

	handleDelete(e, enable) {
		AlertStore.addToQueue({
			title: 'Desea eliminar este autobús?',
			text: 'Esta acción solo la puede revertir un administrador',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}, {
			confirm: () => {
				let success = {
					title: "Autobús eliminado",
					text: `#${this.id}`
				};
				let error = [];
				error[0] = {
					title: "El autobús no fue eliminado",
					text: `#${this.id}`
				};
				ajax(api.delete(`/buses/${this.id}`), {action: true, success, error})
					.promise
					.then(() => {
						ModalStore.closeModal();
					})
					.then(() => {
						enable();
					})
			},
			dismiss: () => {
				enable();
			}
		})
	}

	handleError(errorList: Array) {
		let errorBag = [];

		if (errorList.clave && errorList.clave[0] === "UNIQUE") {
			errorBag.push("El numero económico debe ser único.")
		}
		if (errorBag.length > 0) {
			AlertStore.addToQueue({
				text: `${errorBag.map(error => `<p>${error}</p>`).join()}`,
				type: "info"
			})
		}
	}

	handleForm(e) {
		e.preventDefault();
		let data = {...this.state};
		delete data.models;
		delete data.partners;
		delete data.operators;
		if (data.operator_id === "") {
			data.operator_id = null;
		}
		if (data.symbolic) {
			data.operator_id = null;
		}
		if (this.filling) {
			let success = {
				text: `Autobús ${this.state.clave ||  ""}  actualizado`
			};
			ajax(api.patch(`/buses/${this.id}`, data), {action: true, success})
				.promise
				.then(() => {
					this._first.focus();
				})
				.catch((error) => {
					if (error.response && error.response.status === 422) {
						this.handleError(error.response.data);
					}
				})
		} else {
			let success = {
				text: `Autobús ${this.state.clave ||  ""} guardado`
			};
			ajax(api.post("/buses", data), {action: true, success})
				.promise
				.then(() => {
					this.setState(this.initialState);
					this._first.focus();
				})
				.catch((error) => {
					if (error.response && error.response.status === 422) {
						this.handleError(error.response.data);
					}
				})
		}
	}

	render() {
		let models = this.state.models.map(m => ({show: `${m.marca} ${m.submarca}`, value: m.id}));
		let partners = this.state.partners.map(p => ({show: p.name, value: p.id}));
		let operators = this.state.operators.filter(({activo}) => activo).map(p => ({show: p.nombre, value: p.id}));
		if (this.filling && ModalStore.data && ModalStore.data.operator) {
			operators = operators.concat([{
				show: ModalStore.data.operator.nombre,
				value: ModalStore.data.operator.id
			}]).sort(op => op.nombre)
		}
		operators = [{
			show: "Ninguno",
			value: ""
		}].concat(operators);
		return (
			<Group handleForm={this.handleForm.bind(this)} title={`${this.filling ? "Editar" : "Agregar"} autobús`}  className={"limit-height"}>
				<Input type={"checkbox"}
				       label="Simbólico"
				       state={this.linkCheckState("symbolic")}
				       required={false}
				       grid={[12,3]}
				       className={'text-center'}
				       getInput={(ref) => this._first = ref}
				/>
				<Input grid={[12,3]}
				       type="select"
				       label="Modelo"
				       state={this.linkState("bus_model_id")}
				       required
				       options={models}
				/>
				<Input grid={[12,3]}
				       type="select"
				       label="Socio o proveedor"
				       state={this.linkState("partner_id")}
				       options={partners}
				       required
				/>
				<Input grid={[12,3]}
				       type="select"
				       label="Conductor"
				       state={this.linkState("operator_id",{value: (value) => this.state.symbolic ? "" : value})}
				       options={operators}
				       required={false}
				       disabled={this.state.symbolic}
				/>
				<Input grid={[6,3]}
				       type="text"
				       label="Numero económico"
				       state={this.linkState("clave")}
				       required={false}
				/>
				<Input grid={[6,3]}
				       type="text"
				       label="Numero de placas"
				       state={this.linkState("license_plate_number")}
				       required
				/>
				<Input grid={[6,3]}
				       type="number"
				       label="Año"
				       state={this.linkState("year")}
				       required
				/>
				<Input grid={[6,3]}
				       type="number"
				       label="Asientos"
				       state={this.linkState("sits")}
				       required
				/>
				<Input grid={true}
				       type="submit"
				       text="Guardar"
				       label={false}
				       button={{color: "success"}}
				/>
				{this.filling ? (
					<Input type="button"
					       text="Eliminar"
					       button={{color: "alert"}}
					       label={false}
					       click={this.handleDelete.bind(this)}
					/>
				) : null}
			</Group>
		);
	}
}
