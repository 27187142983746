import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { url } from 'helper/rest'
import './header.scss'

const logo = `${url}/static/logo`

export class Header extends Component {
  static propTypes = {
    switchNav: PropTypes.func.isRequired,
    flipWide: PropTypes.func.isRequired,
    wide: PropTypes.bool.isRequired
  }

  constructor () {
    super()
    this.state = {
      time: this.giveTime(),
      date: this.giveDate()
    }
  }

  componentWillMount () {
    this.interval = setInterval(
      () => {
        this.setState({
          time: this.giveTime(),
          date: this.giveDate()
        })
      }
      , 1000
    )
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  giveTime () {
    return new Date().toTimeString().match(/(\d?\d:\d\d:\d\d)/)[1]
  }

  giveDate () {
    const now = new Date()
    let day = now.getDate()
    let month = now.getMonth() + 1
    let year = now.getFullYear()
    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }
    return `${day}/${month}/${year}`
  }

  render () {
    return (
      <header className="row small-12 no-select">
        <h1 className="columns text-center small-4 medium-3 large-2"
            onClick={this.props.switchNav}
        >
          <div>
            <img src={logo}
                 id="logo"
                 className="logo"
                 alt="Logo"
            />
          </div>
        </h1>
        <div
          className="header-time columns text-center align-self-middle small-offset-4 medium-offset-6 large-offset-8 small-4 medium-3 large-2">{this.state.time}<br/>{this.state.date}
        </div>
        <button className="button floating-top-left" title="Usar espacio fluido"
                onClick={this.props.flipWide}>{this.props.wide ? 'Expandido' : 'Centrado'}</button>
      </header>
    )
  }
}
