//@flow
import EventEmitter2 from "eventemitter2";
import dispatcher from "misc/dispatcher";
import  AuthStore from "./AuthStore";

class PermissionStore extends EventEmitter2 {

	or(required,against) {
		//return true on first match
		//or false on the last if any hits
		return required.some(search => against.includes(search));
	}

	and (required,against) {
		//return true if all match
		//false on the first no match
		return required.some(
			search => !against.includes(search)
		);
	}

	not (required,against) {
		//return true if none match
		//false on the first match
		return !required.some((element) => {
			return against.includes(element);
		});
	}

	getUserRoles () {
		let user = AuthStore.getUser();
		if (user) {
			return user.roles.map(({slug}) => slug);
		} else {
			return [];
		}
	}

	getUserPermissions () {
		let user = AuthStore.getUser();
		if (user) {
			return user.permissions.map(({slug}) => slug);
		} else {
			return [];
		}
	}

	roles (role) {
		let against: Array = this.getUserRoles();

		if (typeof role === "string") {
			return this.or([role],against)
		}
		if (Array.isArray(role)) {
			return this.or(role,against)
		}
		if (role === Object(role)) {
			let or = role.or ? this.or(role.or,against) : true;
			let and = role.and ? this.and(role.and,against) : true;
			let not = role.not ? this.not(role.not,against) : true;
			console.log(or,and,not);
			return or && and && not;
		} else {
			return true;
		}
	}

	permissions (permission) {
		let against: Array = this.getUserPermissions();
		if (typeof permission === "string") {
			return this.or([permission],against)
		}
		if (Array.isArray(permission)) {
			return this.or(permission,against)
		}
		if (permission === Object(permission)) {
			console.log(permission);
			let or = permission.or ? this.or(permission.or,against) : true;
			let and = permission.and ? this.and(permission.and,against) : true;
			let not = permission.not ? this.not(permission.not,against) : true;
			return or && and && not;
		} else {
			return true;
		}
	}

	can (role,permission) {
		return this.roles(role) && this.permissions(permission);
	}

	handleActions() {

	}
}

const permissionStore = new PermissionStore();

dispatcher.register(permissionStore.handleActions.bind(permissionStore));

export default permissionStore;