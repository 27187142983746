import React, {
    Component
} from 'react';
import PropTypes from "prop-types"
import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState, {linkCheckState} from "helper/state";
import DataStore from "stores/DataStore";
import AlertStore from "stores/AlertStore";
import moment from "moment";

export class GeneracionRutas extends Component {
    static propTypes = {};
    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.linkCheckState = linkCheckState.bind(this);
        this.initialState = {
            data: DataStore.getModel("routes"),
            from: "",
            ruta: "0",
            to: "",
            date: '',
            type: "0",
            automatic: false,
            time: "00:00",
            redirectToEdit: null
        };
        this.state = {...this.initialState};
        this.dataListener = DataStore.listenChanges("routes", () => {
            this.setState({
                data: DataStore.getModel("routes")
            });
        })
    }

    getConfig() {
        ( this.configRequest = ajax(api.get("configs/1")) )
            .promise
            .then(({data}) => {
                this.setState({...data.value});
            });
    }

    componentWillMount() {
        this.dataListener.listen();
        this.getConfig();
    }

    componentWillUnmount() {
        this.dataListener.stop();
        this.configRequest.cancel();
    }

    handleGenerate(e) {
        if (e) {
            e.preventDefault()
        }
        let data;
        let {date, from, to} = this.state;
        switch (this.state.type) {
            case '0':
                data = {
                    date
                };
                break;
            case '1':
                data = {
                    from,
                    to
                };
                break;
            default:
                //
        }
        data = {
            ...data,
            route: this.state.ruta
        };
        AlertStore.addToQueue({
            text: "Desea generar las rutas seleccionadas?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: "Generar",
            cancelButtonText: 'Cancelar',
        }, {
            confirm: () => {
                ajax(api.post("routes/generate", data), {action: true}, true)
                    .then(({data}) => {
                        AlertStore.addToQueue({
                            text: "Rutas generadas",
                            type: "success"
                        })
                    })
            }
        });
    }

    handleAutoChanges(e) {
        if (e) {
            e.preventDefault();
        }
        let {automatic, time} = this.state;
        ajax(api.patch("configs/1", {
            value: {
                automatic,
                time
            }
        }), {action: true}, true)
            .then(() => {
                this.getConfig();
                AlertStore.addToQueue({
                    text: "Cambios guardados",
                    type: "success"
                })
            })
    }


    render() {
        let rows = this.state.data ? this.state.data
            .map(({
                      id,
                      name,
                      client: {
                          nombre: client
                      },
                      destination,
                      vehicle: {
                          submarca: vehicle
                      },
                      units,
                      departs_at,
                      arrives_at,
                      arrives_after,
                      active
                  }) => ({
                id,
                name,
                client,
                destination,
                vehicle,
                arrives_at,
                departs_at,
                units,
                days: arrives_after == 0 ? "El mismo dia" : `${arrives_after} dia(s)`,
                active
            })) : [];
        return (
            <div>
                <Group title={"Generación automatica"} margin handleForm={this.handleAutoChanges.bind(this)}>
                    <Input type="checkbox"
                           label="Activado"
                           state={this.linkCheckState("automatic")}
                           className={"text-center"}
                           required={false}
                    />
                    <Input type="time"
                           state={this.linkState("time")}
                           label="Horario"
                    />
                    <Input type="submit"
                           text={"Guardar cambios"}
                           button={{color: "success"}}
                    />
                </Group>
                <Group title={"Generación manual"} margin handleForm={this.handleGenerate.bind(this)}>
                    <Input type="select"
                           label={"Ruta"}
                           state={this.linkState("ruta")}
                           options={[
                               {show: "Todas", value: "0"},
                           ].concat(rows.map(({id: value, name: show}) => ({show, value})))}
                    />
                    <Input type="select"
                           label={"Rango de generación"}
                           state={this.linkState("type")}
                           options={[
                               {show: "Fecha", value: "0"},
                               {show: "Rango de fecha", value: "1"},
                           ]}
                    />
                    {this.state.type === "0" ? (
                        <Input type={"date"}
                               label={"Fecha"}
                               state={this.linkState("date")}
                        />
                    ) : (
                        <Group group={false}>
                            <Input type={"date"}
                                   label={"Inicio"}
                                   state={this.linkState("from")}
                            />
                            <Input type={"date"}
                                   label={"Fin"}
                                   extra={{max: moment(this.state.from).add("1", "month").format("YYYY-MM-DD")}}
                                   state={this.linkState("to")}
                            />
                        </Group>
                    )}
                    <Input type="submit"
                           text={"Generar rutas"}
                           button={{color: "success"}}
                    />
                </Group>
            </div>
        );
    }
}
