/* global google */

import React, {Component} from 'react';
import PropTypes from "prop-types";

export class GoogleMapAutocomplete extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
        listener: PropTypes.object,
        bound: PropTypes.bool,
        componentRestrictions: PropTypes.object
    };
    static defaultProps = {
        bound: false,
        componentRestrictions: {country: "mx"}
    };

    componentDidMount() {
        if (window.google) {
            const id = this.props.children.props.extra.id;
            const autocomplete = this.setupAutocomplete(id);
            if (this.props.listener) {
                Object.keys(this.props.listener).map((listener) => {
                    const callback = this.props.listener[listener];
                    autocomplete.addListener(listener, () => {
                        callback(autocomplete.getPlace());
                    })
                });
            }
            if (this.props.bound) {
                this.setBoundsToAutocomplete(autocomplete);
            }
        }
    }

    setBoundsToAutocomplete(input) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.geolocationCircle = new google.maps.Circle({
                    center: this.geolocation,
                    radius: position.coords.accuracy
                });
                input.setBounds(this.geolocationCircle.getBounds());
            });
        }
    }

    setupAutocomplete(inputId) {
        return new google.maps.places.Autocomplete(
            document.getElementById(inputId),
            {
                types: ["geocode"],
                componentRestrictions: this.props.componentRestrictions
            }
        );
    }


    render() {
        return this.props.children;
    }
}
