import React, {
	Component
} from 'react';

import {Catalog} from"components/catalog/catalog";
import {CatalogForm} from"components/catalog/catalogForm";

export class Publicidad extends Component {
	static propTypes = {};
	static defaultProps = {};

	render() {
		return (
			<Catalog
				columns={[
					{key: "name",name: "Nombre"},
					{key:"description", name: "Descripción"}
				]}
				keys={["name","description"]}
				singular={"Publicidad"}
				model={"ads"}
				name={"Publicidad"}>
				<CatalogForm inputs={[
					{type: "text", state: "name", label: "Nombre", grid: [12]},
					{type: "textarea", state: "description", label: "Descripción", grid: [12]}
				]}/>
			</Catalog>
		);
	}
}
