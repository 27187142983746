import React, {
	Component
} from 'react';
import {Catalog} from"components/catalog/catalog";
import {CatalogForm} from"components/catalog/catalogForm";

export class TiposPago extends Component {
	static propTypes = {};
	static defaultProps = {};

	render() {
		return (
			<Catalog keys={["type"]}
			         singular={"Tipo de pago"}
			         model={"payment_types"}
			         name={"Tipos de pago"}
			         columns={[
						{key: "type",name: "Tipo"}
					]}
			>
				<CatalogForm inputs={[
					{type: "text", state: "type", label: "Tipo", grid: [12]}
				]}/>
			</Catalog>
		);
	}
}
