import React, {
    Component
} from 'react';

import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import {date} from "helper/formatters";
import DataStore from "stores/DataStore";
import {Table} from "components/table/table";
import {sort} from "helper/sort";
import {generate} from "helper/print";

export class ReporteRuta extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.initialState = {
            partner_id: '',
            route_id: '',
            routes: DataStore.getModel("routes"),
            partners: DataStore.getModel("partners"),
            from: "",
            to: "",
            data: null,
            buses: DataStore.getModel("buses"),
            operators: DataStore.getModel("operators"),
            sort: null
        };
        this.state = {...this.initialState};
        this.routes = DataStore.listenChanges("routes", () => {
            this.setState({
                routes: DataStore.getModel("routes")
            });
        });
        this.partners = DataStore.listenChanges("partners", () => {
            this.setState({
                partners: DataStore.getModel("partners")
            });
        })
    }

    componentWillMount() {
        this.routes.listen();
        this.partners.listen();
    }

    componentWillUnmount() {
        this.routes.stop();
        this.partners.stop();
    }

    handleOpen(e) {
        if (e) {
            e.preventDefault();
        }
        const {from, to, route_id, partner_id} = this.state;
        let request = {
            from,
            to,
            route_id,
            partner_id
        };
        ajax(api.get("service", {
            params: {
                ...request
            }
        }), {action: true}, true)
            .then(({data}) => {
                this.setState({data});
            })
    }

    handleGenerate(e, enable) {
        let {
            from,
            to,
            route_id,
            partner_id
        } = this.state;
        const data = {
            report: "ruta",
            from,
            to,
            route_id,
            partner_id,
            ids: this.state.data.map(({id}) => id)
        };
        generate(data,enable)
    }

    handleGridSort(column, direction) {
        if (direction !== "NONE") {
            this.setState({
                sort: {
                    column,
                    direction
                }
            });
        } else {
            this.setState({
                sort: null
            });
        }
    }

    render() {
        let columns = [
            {name: "Fecha", key: "date", formatter: date},
            {name: "Folio", key: "order_id"},
            {name: "Cliente", key: "client"},
            {name: "Destino", key: "destino"},
            {name: "Autobús", key: "bus"},
            {name: "Operador", key: "operator"},
            {name: "No. Viaje", key: "id"},
        ];

        columns = columns.map(c => ({...c, sortable: true}));
        //services
        let rows = this.state.data ? this.state.data
            .map(({
                      id,
                      order: {
                          client: {
                              nombre: client
                          },
                          salida: date,
                          destino,
                          id: order_id
                      },
                      bus,
                      operator
                  }) => ({
                date,
                order_id,
                client,
                destino,
                bus: bus ? bus.clave : "N/A",
                operator: operator ? operator.nombre : "N/A",
                id
            })) : [];

        if (this.state.sort) {
            const {column, direction} = this.state.sort;
            rows = sort({
                rows,
                column,
                direction
            });
        }

        return (
            <div>
                <Group title={"Reporte de ruta"} margin handleForm={this.handleOpen.bind(this)}>
                    <Input type="select"
                           label={"Ruta"}
                           state={this.linkState("route_id")}
                           options={this.state.routes
                               .map(({
                                         name: show,
                                         id: value
                                     }) => ({
                                   show,
                                   value
                               }))}
                    />
                    <Input type="select"
                           label={"Socio/Proveedor"}
                           state={this.linkState("partner_id")}
                           options={[{show: "Todos", value: "0"}].concat(this.state.partners
                               .map(({
                                         name: show,
                                         id: value
                                     }) => ({
                                   show,
                                   value
                               })))}
                    />
                    <Input type="date"
                           label={"Inicio"}
                           state={this.linkState("from")}
                    />
                    <Input type="date"
                           label={"Fin"}
                           extra={{min: this.state.from}}
                           state={this.linkState("to")}
                    />
                    <Input type="button"
                           text={"Generar reporte"}
                           click={this.handleGenerate.bind(this)}
                           disabled={!this.state.data || (this.state.data && !this.state.data.length)}
                           button={{
                               color: "warning"
                           }}
                    />
                    <Input type="submit"
                           text={"Abrir"}
                           button={{
                               color: "success"
                           }}
                    />
                </Group>
                <Table title={"Viajes"}
                       data={rows}
                       columns={columns}
                       rowKey={"id"}
                       extra={{
                           onGridSort: this.handleGridSort.bind(this)
                       }}
                />
            </div>
        );
    }
}
