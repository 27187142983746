import "./consultItem.scss"
import React, {
    PureComponent
} from "react";
import PropTypes from "prop-types"
import {ReadOnly} from "components/forms/input";
import Group from "components/forms/group";
import {Order} from "misc/propTypes";
import {ConsultItemAsignations} from "./consultItemAsignations";
import {ConsultItemPrecios} from "./consultItemPrecios";
import {ConsultItemPagos} from "./consultItemPagos";
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";
import OrderActionCenter from "./orderActionCenter";
import {withRouter} from 'react-router-dom';
import {deepKeyString} from "helper/deepKey";

export default withRouter(class ConsultItem extends PureComponent {
    static propTypes = {
        order: Order,
        asignaciones: PropTypes.bool.isRequired,
        precios: PropTypes.bool.isRequired,
        pagos: PropTypes.bool.isRequired,
        margin: PropTypes.bool.isRequired
    };

    static defaultProps = {
        asignaciones: false,
        precios: false,
        pagos: false,
        margin: true
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.orderActions = new OrderActionCenter({
            id: props.order.id,
            history: props.history
        });
    }

    render() {
        let [salidaFecha, salidaHora] = this.props.order.salida.split(" ");
        let [regresoFecha, regresoHora] = this.props.order.regreso.split(" ");
        return (
            <div>
                <ContextMenuTrigger id={`table-context-menu-${this.props.order.id}`}>
                    <Group className={"consult-form consult-item" + (this.props.order.deleted_at ? " cancelado" : "")}
                           group={false}
                           grid={12}
                           title={`Orden - Folio: ${this.props.order.id}`}
                           margin={this.props.margin}
                           minimize={true}
                    >
                        <Group grid={[12]} group={false}>
                            <ReadOnly label="Folio"
                                      type="text"
                                      grid={[6, 3, true]}
                                      state={{value: this.props.order.id}}
                            />
                            <ReadOnly label="Unidades"
                                      type="number"
                                      grid={[6, 3, true]}
                                      state={{value: this.props.order.unidades}}
                            />
                            <ReadOnly label="Modelo"
                                      type="text"
                                      grid={[6, 3, true]}
                                      state={{value: `${this.props.order.model.submarca}`}}
                            />
                            <ReadOnly label="Asientos"
                                      type="text"
                                      grid={[6, 3, true]}
                                      state={{value: `${this.props.order.sits}`}}
                            />
                            <ReadOnly label="Cliente"
                                      type="text"
                                      grid={[12, 4, true]}
                                      state={{value: this.props.order.client.nombre}}
                            />
                            <ReadOnly label="Teléfono"
                                      type="text"
                                      grid={[6, 4, true]}
                                      state={{value: this.props.order.client.telefono}}
                            />
                            <ReadOnly label="Capturó"
                                      type="text"
                                      grid={[6, 4, true]}
                                      state={{value: deepKeyString('order.register.name', this.props, 'N/A')}}
                            />
                        </Group>
                        <Group grid={[12]} group={false}>
                            <ReadOnly label="Salida" type="date" grid={[6, true]} state={{value: salidaFecha}}/>
                            <ReadOnly label="Hora" type="time" grid={[6, true]} state={{value: salidaHora}}/>
                            <ReadOnly label="Regreso" type="date" grid={[6, true]} state={{value: regresoFecha}}/>
                            <ReadOnly label="Hora" type="time" grid={[6, true]} state={{value: regresoHora}}/>
                            <ReadOnly label="Vendedor" type="text" grid={[12, true]}
                                      value={this.props.order.seller ? this.props.order.seller.name : ""}/>
                        </Group>
                        <Group grid={[12]} group={false}>
                            <ReadOnly label="Destino"
                                      type="text"
                                      grid={[12, 4]}
                                      state={{value: this.props.order.destino}}
                            />
                            <ReadOnly label="Responsable"
                                      type="text"
                                      grid={[6, 4]}
                                      state={{value: this.props.order.responsible_name || ""}}
                            />
                            <ReadOnly label="Teléfono"
                                      type="text"
                                      grid={[6, 4]}
                                      state={{value: this.props.order.responsible_phone || ""}}
                            />
                        </Group>
                        <Group grid={[12]} group={false}>
                            <ReadOnly label="Presentarse en" type="text" grid={[12, 3]}
                                      value={this.props.order.presentarse || ""}/>
                            <ReadOnly label="Calle" type="text" grid={[12, 3]} state={{value: this.props.order.calle}}/>
                            <ReadOnly label="Colonia" type="text" grid={[12, 3]}
                                      state={{value: this.props.order.colonia}}/>
                            <ReadOnly label="Delegación" type="text" grid={[12, 3]}
                                      state={{value: this.props.order.delegacion}}/>
                            {this.props.order.observaciones ? (
                                <ReadOnly label="Observaciones"
                                          type="textarea"
                                          grid={12}
                                          state={{value: this.props.order.observaciones}}
                                          rows={3}
                                />
                            ) : null}
                        </Group>
                        {this.props.precios ? (
                            <ConsultItemPrecios order={this.props.order} hideTitle={this.props.order.deleted_at}/>
                        ) : null}
                        {this.props.pagos ? (
                            <ConsultItemPagos payments={this.props.order.payments}/>
                        ) : null}
                        {this.props.asignaciones ? (
                            <ConsultItemAsignations order={this.props.order}/>
                        ) : null}
                    </Group>
                </ContextMenuTrigger>
                <ContextMenu id={`table-context-menu-${this.props.order.id}`}>
                    <MenuItem onClick={() => this.orderActions.modify(this.props.history)}>
                        Modificar
                    </MenuItem>
                    <MenuItem onClick={() => this.orderActions.assign(this.props.history)}>
                        Asignar
                    </MenuItem>
                    <MenuItem divider/>
                    <MenuItem onClick={() => this.props.order.deleted_at ? this.orderActions.recover() : this.orderActions.cancel()}
                              attributes={{className: this.props.order.deleted_at ? "react-contextmenu-item-success" : "react-contextmenu-item-danger"}}>
                        {this.props.order.deleted_at ? "Recuperar" : "Cancelar"}
                    </MenuItem>
                </ContextMenu>
            </div>
        );
    }
})
