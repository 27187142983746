import React, {
  Component
} from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'react-data-grid'
import Group from 'components/forms/group'
import { api } from 'helper/rest'
import ajax from 'helper/ajax'
import AlertStore from 'stores/AlertStore'
import { date, money, boolean } from 'helper/formatters'
import { Menu } from 'react-data-grid-addons'
import { TODAY } from 'helper/const'

let {ContextMenu, MenuItem} = Menu

export class Payment extends Component {
  static propTypes = {
    payments: PropTypes.array.isRequired,
    deletes: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired
  }
  static defaultProps = {
    payments: [],
    deletes: false,
    update: () => {
    }
  }

  render () {
    const columns = [
      {key: 'id', name: 'ID'},
      {key: 'date', name: 'Fecha', formatter: date},
      {key: 'deposit', name: 'Anticipo', formatter: boolean},
      {key: 'payment_type', name: 'Tipo de pago'},
      {key: 'reference', name: 'Referencia'},
      {key: 'amount', name: 'Importe', formatter: money},
      {
        key: 'confirmed',
        name: 'Confirmado',
        formatter: ({value}) => value == null ? <span>No</span> : date({value})
      },
      {
        key: 'registered_by',
        name: 'Generado por'
      },
      {
        key: 'confirmed_by',
        name: 'Confirmado por'
      }
    ]

    let rows = this.props.payments.map((
      {
        id,
        confirmed,
        reference,
        deposit,
        payment_type: {
          type: payment_type
        },
        created_at: date,
        registered_by: {
          name: registered_by
        },
        confirmed_by,
        ...rest
      }) => ({
      deposit,
      confirmed,
      payment_type,
      reference,
      id,
      amount: rest.import,
      date,
      registered_by,
      confirmed_by: confirmed_by ? confirmed_by.name : 'N/A'
    }))

    const parent = this

    class Menu extends Component {
      static propTypes = {
        rowIdx: PropTypes.number
      }

      handleDelete () {
        const {rowIdx} = this.props
        const id = rows[rowIdx].id
        AlertStore.addToQueue({
          title: 'Desea eliminar este pago?',
          text: `No podrá revertir esta acción`,
          confirmButtonText: 'Eliminar',
          cancelButtonText: 'No',
          showCancelButton: true,
          type: 'warning'
        }, {
          confirm: () => {
            ajax(api.delete(`/payments/${id}`), {success: {text: 'Pago eliminado'}})
              .promise
              .then(() => {
                parent.props.update()
              })
          }
        })
      }

      handleConfirm () {
        const {rowIdx} = this.props
        const id = rows[rowIdx].id

        AlertStore.addToQueue({
          title: 'Seleccionar fecha de confirmación de pago',
          text: `<input type="date" required="required" value="${TODAY}" id="paymentDate" style="width: 50%; margin: 0 auto;">`,
          confirmButtonText: 'Guardar',
          confirmButtonColor: '#3adb76',
          preConfirm: function () {
            if (document.querySelector('#paymentDate').reportValidity()) {
              return Promise.resolve()
            } else {
              return Promise.reject()
            }
          }
        }, {
          confirm: () => {
            ajax(api.patch(`/payments/${id}/confirmed`, {
              date: document.querySelector('#paymentDate').value
            }), {success: {text: `Pago confirmado`}, action: true}, true)
              .then(() => {
                parent.props.update()
              })
          }
        })
      }

      handleConfirmUpdate () {
        const {rowIdx} = this.props
        const id = rows[rowIdx].id
        const confirmed = rows[rowIdx].confirmed
        console.log(rows[rowIdx])
        AlertStore.addToQueue({
          title: 'Seleccionar fecha de confirmación de pago',
          text: `<input type="date" required="required" value="${confirmed.split(' ')[0]}" id="paymentDate" style="width: 50%; margin: 0 auto;">`,
          confirmButtonText: 'Guardar',
          confirmButtonColor: '#3adb76',
          preConfirm: function () {
            if (document.querySelector('#paymentDate').reportValidity()) {
              return Promise.resolve()
            } else {
              return Promise.reject()
            }
          }
        }, {
          confirm: () => {
            ajax(api.patch(`/payments/${id}/confirmed`, {
              date: document.querySelector('#paymentDate').value
            }), {success: {text: `Pago actualizado`}, action: true}, true)
              .then(() => {
                parent.props.update()
              })
          }
        })
      }

      handleUnconfirm () {
        const {rowIdx} = this.props
        const id = rows[rowIdx].id
        AlertStore.addToQueue({
          title: `Desea remover la confirmación de este pago?`,
          confirmButtonText: 'Si',
          confirmButtonColor: '#3adb76',
          cancelButtonText: 'No',
          showCancelButton: true,
          type: 'warning'
        }, {
          confirm: () => {
            ajax(api.delete(`/payments/${id}/confirmed`), {
              success: {
                text: `Confirmación removida del pago`
              },
              action: true
            }, true)
              .then(() => {
                parent.props.update()
              })
          }
        })
      }

      render () {
        const {rowIdx, id} = this.props
        let confirmed = rows[rowIdx] ? Boolean(rows[rowIdx].confirmed) : false
        return (
          <ContextMenu id={id}>
            {
              confirmed ? (
                <MenuItem onClick={this.handleUnconfirm.bind(this)}>Remover
                  confirmación</MenuItem>
              ) : (
                <MenuItem onClick={this.handleConfirm.bind(this)}>Confirmar</MenuItem>
              )
            }
            {confirmed ? (
              <MenuItem onClick={this.handleConfirmUpdate.bind(this)}>Cambiar confirmación</MenuItem>
            ) : null}
            <MenuItem onClick={this.handleDelete.bind(this)}>Eliminar</MenuItem>
          </ContextMenu>
        )
      }
    }

    return (
      <Group group={false} grid={[12]}>
        <DataGrid columns={columns}
                  rowGetter={(i) => rows[i]}
                  rowsCount={rows.length}
                  minHeight={250}
                  rowKey="id"
                  contextMenu={this.props.deletes ? <Menu/> : undefined}
                  contextMenuId={'payment-row'}
        />
      </Group>
    )
  }
}
