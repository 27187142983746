import ajax from "helper/ajax";
import {api} from "../../../helper/rest";

export default class {
	constructor ({id,history}) {
		this.id = id;
		this.history = history;
	}

	cancel () {
		ajax(api.delete(`/order/${this.id}/cancel`), {
			action: true,
			success: {text: `Orden #${this.id} cancelada`},
		});
	}

	recover () {
		ajax(api.patch(`/order/${this.id}/recover`), {
			action: true,
			success: {text: `Orden #${this.id} recuperada`},
		});
	}

	modify () {
		this.history.push({
			pathname: "/app/ordenes/modifica",
			search: `?id=${this.id}`
		});
	}

	assign () {
		this.history.push({
			pathname: "/app/ordenes/asigna",
			search: `?id=${this.id}`
		});
	}
}