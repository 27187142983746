import React from "react";
import Registro from "./registro/registro";

export class Nueva extends React.Component {
	render() {
		return (
			<div>
				<Registro/>
			</div>
		);
	}
}