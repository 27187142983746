import React, {
	Component
} from 'react';

import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import linkState, {linkCheckState} from "helper/state";
import {generate} from "helper/print";

export class Contrato extends Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.linkCheckState = linkCheckState.bind(this);
		this.initialState = this.state = {
			id: "",
			withPrices: false,
			withDecoration: false
		};
	}

	handlePrint(e, enable) {
		if (e) {
			e.preventDefault();
		}
        let data = {
            id: this.state.id,
            report: "client_contract",
            withPrices: this.state.withPrices,
            withDecoration: this.state.withDecoration,
        };
        generate(data, enable);
	}

	render() {
		return (
			<div className="row align-center">
				<Group handleForm={this.handlePrint.bind(this)} grid={[12, 6, 4]} title={"Generar e imprimir contrato"}>
					<Input grid={[6]} type="number" extra={{min: 1, step: 1}} state={this.linkState("id")}
						   label="Folio" autoFocus={true}/>
					<Input grid={[true]} type="checkbox" state={this.linkCheckState("withPrices")}
						   label={"Ver precios"} required={false} className={"text-center"}/>
					<Input grid={[true]} type="checkbox" state={this.linkCheckState("withDecoration")}
						   label={"Ver membrete"} required={false} className={"text-center"}/>
					<Input grid={[12]} type="submit" text="Imprimir" button={{color: "warning"}}/>
				</Group>
			</div>
		);
	}
}
