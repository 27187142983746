import {Redirect} from 'react-router-dom';
import React, {Component} from "react";
import PropTypes from "prop-types"
import Group from "components/forms/group";
import {Input} from "components/forms/input";
import AuthStore from "stores/AuthStore";
import Loading from "components/loading/loading";
import linkState, {linkCheckState} from "helper/state";
import LoadingStore from "stores/LoadingStore";
import {url} from "helper/rest";
import Alert from 'react-s-alert';

const logo = `${url}/static/logo`;


export class Login extends Component {
	static propTypes = {
		location: PropTypes.shape({
			search: PropTypes.string
		}).isRequired
	};

	constructor (props) {
		super(props);
		this.linkState = linkState.bind(this);
		this.linkCheckState = linkCheckState.bind(this);
		this.state = {
			email: "",
			password: "",
			remember: false,
			loading: LoadingStore.isLoading()
		};
		this.loadingChange = this.loadingChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	loadingChange () {
		this.setState({
			loading: LoadingStore.isLoading()
		})
	}

	componentDidMount () {
		LoadingStore.on("change", this.loadingChange);
	}

	componentWillUnmount () {
		LoadingStore.removeListener("change", this.loadingChange);
	}

	handleSubmit (e) {
		e.preventDefault();
		let {email, password, remember} = this.state;
		AuthStore.login({
			email, password, remember
		})
			.catch(() => {
				this.setState({password: ""});
			});
	}

	render () {
		let getNext = /^\?next=(.+)/;
		let next = getNext.test(this.props.location.search) ? this.props.location.search.match(getNext)[1] : `/app/home`;
		return AuthStore.hasToken() ? (
			<Redirect to={next}/>
		) : (
			<div className="row align-center align-middle with-bg" style={{height: "100vh"}}>
				<Group grid={[10, 6, 4]} className="align-center" handleForm={this.handleSubmit} group={false}>
					<h1 className="row columns small-12 text-center">
						<div style={{width: "100%"}}>
							<img className="logo inverted"
								 src={logo}
								 alt="Logo"
							/>
						</div>
					</h1>
					<Input label="Corre electrónico"
						   required
						   type="email"
						   grid={[12, 6]}
						   extra={{autoFocus: true, name: "email"}}
						   state={this.linkState("email")}
					/>
					<Input label="Contraseña"
						   required
						   type="password"
						   grid={[12, 6]}
						   state={this.linkState("password")}
					/>
					<Input type="checkbox"
						   label="Recordar"
						   grid={[4, 3, 2]}
						   state={this.linkCheckState("remember")}
						   className={"text-center"}
						   required={false}
					/>
					<Input type="submit"
						   text="Acceder"
						   grid={[8, 9, 10]}
						   button={{color: "success"}}
					/>
				</Group>
				<Loading loading={this.state.loading}/>
				<Alert stack={{limit: 6}} effect='slide' position='bottom-right'/>
			</div>
		);
	}
}
