/**
 * Created by FeNiX on 11-Apr-16.
 */
// @flow
import EventEmitter2 from "eventemitter2";
import dispatcher from "misc/dispatcher";
import AlertStore from "./AlertStore";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import * as AuthActions from "actions/AuthActions";
import {DEBUG} from "misc/debug";

class AuthStore extends EventEmitter2 {
	constructor () {
		super();
		this.user = null;
		this.connected = false;
		this.updateRemember();
		this.on("CONNECTED", () => {
			this.connected = true;
			if (!this.getUser()) {
				throw new Error("Why is user not defined?");
			}
			if (!DEBUG && window.Raven) {
				window.Raven.setUserContext({
					email: this.getUser().email,
					id: this.getUser().id
				})
			}
		});
		this.on("DISCONNECTED", () => {
			if (!DEBUG && window.Raven) {
				window.Raven.setUserContext({})
			}
		});
		if (!sessionStorage.length) {
			// Ask other tabs for session storage
			localStorage.setItem('getSessionStorage', Date.now());
		}
		window.addEventListener('storage', (event) => {
			if (event.key == 'getSessionStorage') {
				// Some tab asked for the sessionStorage -> send it

				localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
				localStorage.removeItem('sessionStorage');

			} else if (event.key == 'sessionStorage' && !sessionStorage.length) {
				// sessionStorage is empty -> fill it

				let data = JSON.parse(event.newValue);

				for (let key in data) {
					sessionStorage.setItem(key, data[key]);
				}
			} else {
				this.updateRemember();
				if (this.getToken() && !this.connected) {
					console.log("I should be connected");
					this.emit("CONNECTED");
					this.connectedBy = "storage";
				}
				if (!this.getToken() && this.connected) {
					AuthActions.logout();
					AlertStore.addToQueue({
						text: "Ha sido desconectado",
						type: "info"
					})
				}
			}
		}, false);
	}

	hasToken () {
		return Boolean(this.getToken());
	}

	updateStorage () {
		this.storage = this.remember ? window.localStorage : window.sessionStorage;
	}

	updateRemember (state) {
		this.remember = state || window.localStorage.getItem("token");
		this.updateStorage()
	}

	setUser (user) {
		this.storage.setItem("user", JSON.stringify(user));
	}

	getUser () {
		if (this.storage.getItem("user")) {
			return JSON.parse(this.storage.getItem("user"));
		} else {
			return false;
		}
	}

	setToken (token) {
		this.storage.setItem("token", token);
	}

	getToken () {
		return this.storage.getItem("token");
	}

	removeToken () {
		this.storage.removeItem("token");
	}

	removeUser () {
		this.storage.removeItem("user");
	}

	login ({email, password, remember}) {
		return new Promise((resolve, reject) => {
			ajax(api.post("/login", {email, password}), {
				error: {
					default: {
						title: "Error Inesperado",
						text: "Reporte generado. Contacte al administrador"
					},
					[401]: ({data}) => {
						switch (data.error) {
							case 'invalid_credentials':
								return {
									title: "Compruebe sus credenciales",
									text: "La combinación de datos no coincide con los registros"
								};
							case 'outside_office_hours':
								return {
									title: "Inicio de sesión inhabilitado",
									text: "No es posible ingresar en este momento"
								};
						}
					},
					[404]: {
						title: "Servidor no disponible",
						text: "Reporte generado. Contacte al administrador"
					},
					[500]: {
						title: "El servidor ha fallado en procesar la solicitud",
						text: "Reporte generado. Contacte al administrador"
					},
					[503]: {
						title: "El servidor ha negado procesar la solicitud",
						text: "Reporte generado. Contacte al administrador"
					}
				},
				action: true
			})
				.promise
				.then((response) => {
					this.updateRemember(remember);
					this.setToken(response.headers["authorization"]);
					this.setUser(response.data);
					this.emit("CONNECTED");
					console.warn("LOGIN RESOLVED");
					resolve();
				})
				.catch((error) => {
					console.trace("LOGIN REJECTED", error);
					reject();
				})
				.catch(() => {
					//stop
				})
		})
	}

	auth () {
		console.info("Auth AUTHENTICATING");
		return new Promise((resolve, reject) => {
			ajax(api.get("/me"), {action: true})
				.promise
				.then((response) => {
					//token is already set, then set user
					console.info("Auth TOKEN OK");
					this.setUser(response.data);
					console.info("Auth EMMIT CONNECTED");
					this.emit("CONNECTED");
					resolve();
				})
				.catch((error) => {
					console.info("Auth TOKEN INVALID");
					console.log(error);
					//If error is 500 fail directly
					if (error && error.response && error.response.status === 500) {
						return Promise.reject();
					}
					//token is invalid, try to refresh
					return ajax(api.get("/refresh"), {action: true})
						.promise
						.then((response) => {
							console.info("Auth TOKEN REFRESHED");
							//success store new key and user
							this.setToken(response.headers.authorization);
							this.setUser(response.data);
							console.info("Auth EMIT CONNECTED");
							this.emit("CONNECTED");
							resolve();
						})
				})
				.catch(() => {
					//fail, delete old key. go to login
					console.info("Auth TOKEN COMPLETELY INVALID");
					this.logout();
					AlertStore.addToQueue({
						title: 'Introduzca sus credenciales',
						type: 'info'
					});
					reject();
				})
		})
	}

	logout () {
		this.removeToken();
		this.removeUser();
		this.connected = false;
		this.emit("DISCONNECTED");
		this.emit("LOGIN_SCREEN");
	}

	exit () {
		this.I_CALLED_LOGOUT = true;
		ajax(api.post("/logout"))
			.promise
			.then(() => {
				this.logout();
			})
			.catch(() => {
				this.logout();
			});
	}

	handleActions (action) {
		switch (action.type) {
			case "LOG_OUT":
				this.logout();
				break;
			case "LOG_OUT_EXIT":
				this.exit();
				break;
		}
	}
}

const authStore = new AuthStore();

dispatcher.register(authStore.handleActions.bind(authStore));

window.auth = authStore;

export default authStore;
