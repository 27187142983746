import EventEmitter2 from "eventemitter2";
import swal from "sweetalert2";

class AlertStore extends EventEmitter2 {
	constructor () {
		super();
		this.queue = [];
		this.open = false;
	}

	handleAfter (callback, ...parameters) {
		if (callback) {
			callback(parameters);
		}
		this.open = false;
		if (this.queue.length > 0) {
			this.addToQueue(...this.queue.shift());
		}
	}

	addToQueue (alertConfig, callbacks = {confirm: null, dismiss: null}) {
		if (this.open) {
			this.queue.push([alertConfig, callbacks]);
		} else {
			this.open = true;
			//migrates to html parameter
			let temp = {
				...alertConfig,
				html: alertConfig.text
			};
			delete temp.text;
			swal(temp).then(this.handleAfter.bind(this, callbacks.confirm), this.handleAfter.bind(this, callbacks.dismiss));
		}
	}
}

const alertStore = new AlertStore();

export default alertStore;