import React, {
    Component,
} from 'react';

import Group from "components/forms/group";
import {Input, ReadOnly} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import DataStore from "stores/DataStore";
import {Table} from "components/table/table";
import {date, money, boolean, getMoney} from "helper/formatters";
import {sort} from "helper/sort";
import {generate} from "helper/print";

export class Cobrar extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.state = this.initialState = {
            client: "",
            from: "",
            to: "",
            destino: "",
            filter: "0",
            factura: "0",
            trashed: "2",
            pago: "0",
            sort: null,
            data: null,
            numeroFactura: "",
            selected: []
        };
    }

    onRowsSelected (rows) {
        const ids = rows.map(({row: {id}}) => id)
        this.setState({
            selected: this.state.selected.concat(ids)
        })
    }

    onRowsDeselected (rows) {
        const ids = rows.map(({row: {id}}) => id)
        this.setState({
            selected: this.state.selected.filter(index => ids.indexOf(index) === -1)
        })
    }

    handleClean(e, enable) {
        enable();
        this.setState({...this.initialState});
    }

    componentWillMount() {
        DataStore.on("ORDERS_UPDATED", this.handleSearch.bind(this));
        DataStore.getChannel({
            priv: true,
            name: "App.Order.0"
        })
            .listen("OrderOrderPaymentChange", () => {
                this.handleSearch();
            })
    }

    componentWillUnmount() {
        DataStore.closeChannel("App.Order.0");
        DataStore.removeListener("ORDERS_UPDATED", this.handleSearch.bind(this));
    }

    handleGridSort(column, direction) {
        if (direction !== "NONE") {
            this.setState({
                ...this.state,
                sort: {
                    column,
                    direction
                }
            });
        } else {
            this.setState({
                sort: null
            });
        }
    }

    getReport(e, enable) {
        const ids = this.rows.map(({id}) => id);
        const {from, to} = this;
        let data = {
            ids,
            report: "cuentas_cobrar",
            from,
            to
        };
        generate(data, enable);
    }

    massInvoice(e, enable) {
        ajax(api.post(`order/mass-invoice`, {
            ids: this.state.selected,
            numero_factura: this.state.numeroFactura
        }), {action: true}, true)
            .then(({data}) => {
                this.setState({
                    numeroFactura: '',
                }, enable);
            })
    }

    getSelectionReport(e, enable) {
        const ids = this.state.selected;
        const {from, to} = this;
        let data = {
            ids,
            report: "cuentas_cobrar",
            from,
            to
        };
        generate(data, enable);
    }


    handleSearch(e) {
        if (e) {
            e.preventDefault();
        }
        const {client, from, to, filter, factura, trashed, destino} = this.state;
        let params;
        params = {
            filter: "date:range",
            from,
            to,
            factura,
            trashed,
            destino
        };
        if (filter === "1") {
            //client
            params = {
                ...params,
                filter: "client",
                name: client,
            };
        }
        if (filter === "2") {
            //ruta
            params = {
                ...params,
                routes: '2' // 2 means only without
            };
        }
        ajax(api.get(`order`, {
            params
        }), {action: true}, true)
            .then(({data}) => {
                this.from = from;
                this.to = to;
                this.setState({
                    data
                });
            })
    }

    render() {
        let columns = [
            {key: "salida", name: "Fecha", formatter: date, width: 120},
            {key: "client", name: "Cliente", width: 200},
            {key: "destino", name: "Destino", width: 300},
            {key: "id", name: "Folio", width: 80},
            {key: "con_factura", name: "Factura", width: 90, formatter: boolean},
            {key: "unidades", name: "Unidades", width: 110},
            {key: "costo_unitario", name: "Costo/unidad", formatter: money, width: 140},
            {key: "sale", name: "Venta", formatter: money, width: 100},
            {key: "tax", name: "Impuesto", formatter: money, width: 100},
            {key: "total", name: "Total", formatter: money, width: 100},
            {key: "payment", name: "Pago", formatter: money, width: 100},
            {key: "balance", name: "Saldo", formatter: money, width: 100},
        ];
        //make sortable
        columns = columns.map((column) => ({sortable: true, ...column}));
        let rows = this.state.data ? this.state.data.filter(({con_factura, balance: {balance, sale}}) => {
            let pago;
            let factura;
            switch (this.state.pago) {
                //todos
                case "0":
                    pago = true;
                    break;
                case "1":
                    //pagado
                    pago = (parseFloat(balance) === 0);
                    break;
                case "2":
                    //sin pagar
                    pago = (parseFloat(balance) !== 0);
                    break;
                case "3":
                    //parcial
                    pago = (balance !== sale && parseFloat(balance) !== 0);
                    break;
            }
            switch (this.state.factura) {
                //todos
                case "0":
                    factura = true;
                    break;
                case "1":
                    //con factura
                    factura = con_factura === true;
                    break;
                case "2":
                    //sin factura
                    factura = con_factura === false;
                    break;
            }
            return pago & factura;
        })
            .map(({
                      client: {
                          nombre: client
                      },
                      salida,
                      destino,
                      id,
                      unidades,
                      costo_unitario,
                      con_factura,
                      balance: {
                          tax,
                          total,
                          balance,
                          payment,
                          sale
                      }
                  }) => ({
                salida,
                client,
                id,
                destino,
                unidades,
                costo_unitario,
                con_factura,
                balance,
                sale,
                payment,
                tax,
                total,
            })) : [];

        if (this.state.sort) {
            const {column, direction} = this.state.sort;
            rows = sort({
                rows,
                column,
                direction
            });
        }

        let sale = rows.reduce((carry, {sale}) => {
            return carry + parseFloat(sale);
        }, 0);

        let balance = rows.reduce((carry, {balance}) => {
            return carry + parseFloat(balance);
        }, 0);

        let payment = rows.reduce((carry, {payment}) => {
            return carry + parseFloat(payment);
        }, 0);

        let total = rows.reduce((carry, {total}) => {
            return carry + parseFloat(total);
        }, 0);

        let tax = rows.reduce((carry, {tax}) => {
            return carry + parseFloat(tax);
        }, 0);

        this.rows = rows;

        return (
            <div>
                <Group group={true} title={"Cuentas por cobrar"} handleForm={this.handleSearch.bind(this)} margin>
                    <Input type="select"
                           label="Filtro"
                           options={[
                               {show: "General", value: "0"},
                               {show: "General - Sin Rutas", value: "2"},
                               {show: "Cliente", value: "1"},
                           ]}
                           state={this.linkState("filter")}
                           grid={[12, 6, 3]}
                    />
                    <Input type="autocomplete"
                           grid={[12, 6, 3]}
                           label={"Cliente"}
                           state={this.linkState("client", {value: (value) => this.state.filter === "0" ? "" : value})}
                           disabled={this.state.filter !== "1"}
                           autocomplete={{
                               index: "clients",
                               displayKey: "nombre",
                               keyName: "nombre",
                               onSelect: (e, {nombre: client}) => this.setState({client})
                           }}
                    />
                    <Input type="select"
                           grid={[12, 3]}
                           label="Factura"
                           options={[
                               {show: "Todos", value: "0"},
                               {show: "Con factura", value: "1"},
                               {show: "Sin factura", value: "2"}
                           ]}
                           state={this.linkState("factura")}
                    />
                    <Input type="select"
                           grid={[12, 3]}
                           label="Pago"
                           options={[
                               {show: "Todos", value: "0"},
                               {show: "Pagados", value: "1"},
                               {show: "Sin pagar", value: "2"},
                               {show: "Parcial", value: "3"},
                           ]}
                           state={this.linkState("pago")}
                    />
                  <Input type="text"
                         grid={[12, 6]}
                         label="Destino"
                         required={false}
                         state={this.linkState("destino")}
                  />
                    <Input type="date"
                           label={"Inicio"}
                           state={this.linkState("from")}
                           grid={[12, 3]}
                    />
                    <Input type="date"
                           label={"Fin"}
                           state={this.linkState("to")}
                           extra={{min: this.state.from}}
                           grid={[12, 3]}
                    />
                    <Input type="submit"
                           button={{color: "success"}}
                           text={"Abrir"}
                           grid={[6, 6, 3]}
                    />
                    <Input type="button"
                           button={{color: "alert"}}
                           text={"Limpiar"}
                           click={this.handleClean.bind(this)}
                           grid={[6, 6, 3]}
                    />
                </Group>
                <Group group={true} margin={true}>
                    <Input type="button"
                           text="Generar reporte"
                           button={{color: "warning"}}
                           grid={[12,4]}
                           click={this.getReport.bind(this)}
                           disabled={!this.state.data}
                    />
                    <Input type="button"
                           text="Generar reporte de seleccion"
                           button={{color: "warning"}}
                           grid={[12,4]}
                           click={this.getSelectionReport.bind(this)}
                           disabled={!this.state.selected.length}
                    />
                </Group>
                <Group group={true} margin={true}>
                    <ReadOnly type="text"
                              label="Venta"
                              value={getMoney(sale)}
                              grid={true}
                    />
                    <ReadOnly type="text"
                              label="Impuesto"
                              value={getMoney(tax)}
                              grid={true}
                    />
                    <ReadOnly type="text"
                              label="Total"
                              value={getMoney(total)}
                              grid={true}
                    />
                    <ReadOnly type="text"
                              label="Pago"
                              value={getMoney(payment)}
                              grid={true}
                    />
                    <ReadOnly type="text"
                              label="Saldo"
                              value={getMoney(balance)}
                              grid={true}
                    />
                </Group>
                <Table title={"Ordenes"} columns={columns} data={rows} rowKey={"id"}
                       extra={{
                           enableCellSelect: true,
                           onGridSort: this.handleGridSort.bind(this),
                           rowSelection: {
                               onRowsSelected: this.onRowsSelected.bind(this),
                               onRowsDeselected: this.onRowsDeselected.bind(this),
                               showCheckbox: true,
                               enableShiftSelect: true,
                               selectBy: {
                                   keys: {
                                       rowKey: 'id',
                                       values: this.state.selected
                                   }
                               }
                           }
                       }}/>


                <Group group={true} margin={true}>
                    <Input type="text"
                           grid={[12, 6, 3]}
                           label={"Numero de factura"}
                           state={this.linkState("numeroFactura")}
                           disabled={this.state.selected.length === 0}
                    />

                    <Input type="button"
                           text="Asociar selecction con numero de factura"
                           button={{color: "success"}}
                           grid={[12,4]}
                           click={this.massInvoice.bind(this)}
                           disabled={!this.state.numeroFactura}
                    />
                </Group>

            </div>
        );
    }
}
