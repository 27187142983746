// @flow

import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../forms/group";
import {Input} from "../forms/input.jsx";
import linkState from "helper/state";
import ModalStore from "stores/ModalStore";
import Modal from "../modal";
import {Table} from "components/table/table";
import DataStore from "stores/DataStore";
import Fuse from "fuse.js";
import AlertStore from "stores/AlertStore";
import ajax from "helper/ajax";
import {api} from "helper/rest";
import {sort} from "helper/sort";
import {deepKey} from "helper/deepKey";
import { Menu } from "react-data-grid-addons";
let {ContextMenu, MenuItem} = Menu;

export class Catalog extends Component {
	static propTypes = {
		model: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		columns: PropTypes.array.isRequired,
		keys: PropTypes.array.isRequired,
		children: PropTypes.element.isRequired,
		map: PropTypes.func,
		singular: PropTypes.string.isRequired,
		filter: PropTypes.func,
		dependencies: PropTypes.array
	};

	static defaultProps = {
		columns: []
	};

	constructor (props, context) {
		super(props, context);
		this.model = props.model;
		this.name = props.name;
		this.singular = props.singular;
		this.model_event = `${this.model.toUpperCase()}_UPDATED`;
		this.linkState = linkState.bind(this);
		this.initialState = {
			search: "",
			[this.model]: DataStore.getModel(this.model, props.filter),
			sort: null
		};
		this.state = {...this.initialState};
		this.update = this.update.bind(this);
	}

	update (force) {
		this.setState({
			[this.model]: DataStore.getModel(this.model, this.props.filter, force)
		});
	}

	register (dependencies) {
		dependencies.forEach(model => {
			console.log("registering", `${model.toUpperCase()}_UPDATED`);
			DataStore.on(`${model.toUpperCase()}_UPDATED`, () => {
				this.update(true);
			})
		});
	}

	remove (dependencies) {
		dependencies.forEach(model => {
			console.log("removing", `${model.toUpperCase()}_UPDATED`);
			DataStore.removeListener(`${model.toUpperCase()}_UPDATED`, () => {
				this.update(true);
			});
		});
	}

	componentWillMount () {
		DataStore.on(this.model_event, this.update);
		if (this.props.dependencies) {
			this.register(this.props.dependencies);
		}
	}

	componentWillUnmount () {
		DataStore.removeListener(this.model_event, this.update);
		if (this.props.dependencies) {
			this.remove(this.props.dependencies);
		}
	}

	handleGridSort (column, direction) {
		if (direction !== "NONE") {
			this.setState({
				...this.state,
				sort: {
					column,
					direction
				}
			});
		} else {
			this.setState({
				sort: null
			});
		}
	}

	render () {
		const modal_id = `form-${this.model}`;
		if (this.props.map) {
			this[this.model] = this.props.map(this.state[this.model])
		} else {
			this[this.model] = this.state[this.model].map((model) => {
				let data = {
					id: model.id
				};
				this.props.columns.forEach(({key,nullValue}) => {
					const [first, ...deep] = key.split('.');
					if (model[first] === null && nullValue) {
						data[key] = nullValue;
						return
					}
					if (deep.length > 0) {
						data[key] = model[first] ? deepKey(deep, model[first]) : model[first];
					} else {
						data[key] = model[first]
					}
				});
				return data;
			});
		}

		if (this.state.search) {
			let fuse = new Fuse(this[this.model], {
				keys: this.props.keys || this.props.columns.map(({key}) => key),
				threshold: 0.4
			});
			this[this.model] = fuse.search(this.state.search)
		}

		let rows = this[this.model];

		if (this.state.sort) {
			const {column, direction} = this.state.sort;
			rows = sort({
				rows,
				column,
				direction
			});
		}

		const parent = this;

		class Menu extends Component {
			static propTypes = {
				rowIdx: PropTypes.number
			};

			handleEdit () {
				const {rowIdx: index} = this.props;
				ModalStore.openModal(modal_id, parent.state[parent.model].find(({id}) => id === parent[parent.model][index].id));
			}

			handleDelete () {
				const {rowIdx: index} = this.props;
				const {id: model_id} = parent.state[parent.model].find(({id}) => id === parent[parent.model][index].id);
				AlertStore.addToQueue({
					title: `Desea eliminar ${parent.singular[parent.singular.length - 1] === "a" ? "esta" : "este"} ${parent.singular.toLowerCase()}?`,
					text: 'Esta acción solo la puede revertir un administrador',
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: "#da3116",
					confirmButtonText: 'Eliminar',
					cancelButtonText: 'Cancelar',
				}, {
					confirm: () => {
						ajax(api.delete(`${parent.model}/${model_id}`), {
							action: true, success: {
								title: `${parent.singular} ${parent.singular[parent.singular.length - 1] === "a" ? "eliminada" : "eliminado"}`
							}
						})
					}
				})
			}

			render () {
				const {id} = this.props
				return (
					<ContextMenu id={id}>
						<MenuItem onClick={this.handleEdit.bind(this)}>Editar</MenuItem>
						<MenuItem onClick={this.handleDelete.bind(this)}>Eliminar</MenuItem>
					</ContextMenu>
				);
			}
		}

		return (
			<div>
				<Group title={`Catalogo de ${this.name.toLowerCase()}`} margin>
					<Input type="text"
						   state={this.linkState("search")}
						   label="Buscar"
						   grid={[12, 6]}
						   autoFocus={true}
					/>
					<Input type="button"
						   text="Agregar"
						   button={{color: "warning"}}
						   grid={[12, 6]}
						   events={{onClick: () => ModalStore.openModal(modal_id)}}
					/>
				</Group>
				<Table title={this.name}
					   columns={this.props.columns.map(c => ({...c, sortable: true}))}
					   data={rows}
					   rowKey="id"
					   sort={this.handleGridSort.bind(this)}
					   extra={{
					   		contextMenu: <Menu/>,
							  contextMenuId: "catalog-row"
					   }}
				/>
				<Modal id={modal_id} grid={[12, 8, 6]}>
					{React.cloneElement(this.props.children, {
						singular: this.singular,
						model: this.model
					})}
				</Modal>
			</div>
		);
	}
}
