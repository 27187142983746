import React from 'react';
import ReactDOM from 'react-dom';
import {Root} from "components/root";
import "misc/updateAlert";
import "scss/style.scss";
import "scss/offline-theme-chrome.scss";
import "scss/offline-language-spanish.scss";
import "scss/s-alert-default.scss";
import "scss/slide.scss";
import "sweetalert2/dist/sweetalert2.min.css";

import {api} from "helper/rest";

ReactDOM.render(<Root/>, document.getElementById('App'));

api.get('/static/title').then(({data: {title}}) => {
    window.document.title = title
});