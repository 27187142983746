export const deepKey = ([current,...rest], obj, placeholder = undefined) => {
	if (rest.length === 0) {
		return obj[current];
	} else {
		if (obj[current]) {
			return deepKey(rest,obj[current],placeholder)
		} else {
            if (placeholder !== undefined) {
                return placeholder;
            } else {
                console.info('Falsy value found trying to find deepKey, returning null');
                return null;
            }
		}
	}
};

export const deepKeyString = (id, obj, placeholder = undefined) => {
    let [current, ...rest] = id.split('.');
    if (rest.length === 0) {
        return obj[current];
    } else {
        if (obj[current]) {
            return deepKey(rest,obj[current],placeholder)
        } else {
        	if (placeholder !== undefined) {
                return placeholder;
			} else {
                console.info('Falsy value found trying to find deepKey, returning null');
                return null;
			}
        }
    }
};
