import React, {
	Component,
} from 'react';

import {Catalog} from"components/catalog/catalog";
import {FormProveedores} from "./formProveedores";

export class Proveedores extends Component {

	render() {
		return (
			<Catalog columns={[
					{key: "name",name: "Nombre"},
				]}
			         keys={["name"]}
			         filter={(data: Array) => data.filter(({external}) => external)}
			         singular={"Proveedor"}
			         model={"partners"}
			         name={"Proveedor"}>
				<FormProveedores/>
			</Catalog>
		);
	}
}
