import React, {
	Component
} from 'react';
import Group from "components/forms/group";
import {ReadOnly} from "components/forms/input.jsx"
import {Order} from "misc/propTypes";
import { getMoney } from 'helper/formatters'

export class ConsultItemPrecios extends Component {
	static propTypes = {
		order: Order,
	};
	static defaultProps = {};

	render() {
		const {order} = this.props;
		return (
			<Group grid={[12]} group={false} title={this.props.hideTitle ? "" : "Precios"}>
				<ReadOnly type="text"
						  label="Factura"
						  value={order.con_factura ? "Si" : "No"}
						  grid={[6,6,3]}
				/>
				<ReadOnly type="text"
						  label="Credito"
						  value={order.credit ? "Si" : "No"}
						  grid={[6,6,3]}
				/>
				<ReadOnly type="text"
				          label="Precio Unitario"
				          value={getMoney(parseFloat(order.costo_unitario))}
				          grid={[4,4,3]}
				/>
				<ReadOnly type="text"
				          label="Venta"
				          value={getMoney(parseFloat(order.balance.sale))}
				          grid={[4,4,3]}
				/>
				<ReadOnly type="text"
						  label="Impuestos"
						  value={order.con_factura ? getMoney(parseFloat(order.balance.tax)) : 'N/A'}
						  grid={[4,4,3]}
				/>
				<ReadOnly type="text"
						  label="Total"
						  value={getMoney(parseFloat(order.balance.total))}
						  grid={[4,4,3]}
				/>
				<ReadOnly type="text"
				          label="Saldo"
				          value={getMoney(parseFloat(order.balance.balance))}
				          grid={[4,4,3]}
				/>
				<ReadOnly type="text"
				          label="Cobrar"
				          value={order.cobrar ? getMoney(parseFloat(order.cobrar)) : "No"}
				          grid={[6,6,3]}
				/>
			</Group>
		);
	}
}
