import React from "react";
import PropTypes from 'prop-types'
import Group from "components/forms/group";
import {Input} from "components/forms/input";
import linkState from "helper/state";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import AlertStore from "stores/AlertStore";
import ModalStore from "stores/ModalStore";

export class FormValeGastos extends React.Component {
	static propTypes = {
		id: PropTypes.number.isRequired
	};

	constructor(props) {
		super(props);
		this.linkState = linkState.bind(this);
		this.initialState = this.state = {
			import: "",
			date: "",
			type: "",
			comment: "",
			types: [],
		};
	}

	componentWillUnmount() {
		this.ajax.cancel();
	}

	fill(data) {
		this.filling = true;
		this.id = data.id;
		this.setState({
			import: data.import,
			date: data.date,
			type: data.deposit_type.type,
			comment: data.comment || ""
		});
	}


	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
		this.ajax = ajax(api.get("service/types/deposit"));
		this.ajax
			.promise
			.then((response) => {
			    this.initialState.types = response.data;
				this.setState({types: response.data});
			})
	}


	submitForm(e) {
		e.preventDefault();
		let data = {...this.state};
		delete data.types;
		if (data.comment === "") {
			delete data.comment;
		}

		if (this.filling) {
			ajax(api.patch(`/service/${this.props.id}/gastos/llevados/${this.id}`,data),{action: true, success: {
				text: "Vale para gastos de viaje actualizado",
				type: "success"
			}})
		} else {
			ajax(api.post(`/service/${this.props.id}/gastos/llevados`, data), {action: true})
				.promise
				.then((response) => {
					if (response.data.created) {
						AlertStore.addToQueue({
							text: "Vale para gastos de viaje agregado",
							type: "success"
						})
					} else {
						AlertStore.addToQueue({
							text: "Vale para gastos de viaje no se ha agregado",
							type: "error"
						})
					}
					this.setState({...this.initialState});
				})
		}
	}

	handleDelete (e,enable) {
		AlertStore.addToQueue({
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			title: "Eliminar vale para gastos de viaje",
			text: "¿Desea eliminar este vale? <br><em>Esta acción solo la puede revertir un administrador</em>",
			type: "warning"
		},{confirm: () => {
			ajax(api.delete(`service/${this.props.id}/gastos/llevados/${this.id}`),{action : true, success: {
				text: "Vale para gastos de viaje eliminado",
			}})
				.promise
				.then(() => {
					enable();
					ModalStore.closeModal();
				})
		}, dismiss: () => {
			enable();
		}});
	}

	render() {
		return (
			<Group grid={12} title="Vale de gastos de viaje" handleForm={this.submitForm.bind(this)}>
				<Input grid={6}
				       type="date"
				       label="Fecha"
				       state={this.linkState("date")}
				/>
				<Input grid={6}
				       type="text"
				       datalist={this.state.types.map(t => t.type)}
				       label="Tipo"
				       state={this.linkState("type")}
				/>
				<Input grid={6}
				       type="textarea"
				       label="Comentario"
				       state={this.linkState("comment")}
				/>
				<Input grid={6}
				       type="number"
				       label="Importe"
				       state={this.linkState("import")}
				/>
				<Input grid={true}
				       label={false}
				       type="submit"
				       button={{color: "success"}}
				       value="Guardar"
				/>
				{this.filling ? (
					<Input grid={3}
					       label={false}
					       type="button"
					       button={{color: "alert"}}
					       value="Eliminar"
					       click={this.handleDelete.bind(this)}
					/>
				) : null}
			</Group>
		);
	}
}
