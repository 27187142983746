import React, {
    Component
} from 'react';

import PropTypes from "prop-types";

import moment from "moment";
import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState, {linkCheckState} from "helper/state";
import AlertStore from "stores/AlertStore";
import date from "helper/date";
import {sort} from "helper/sort";
import DataStore from "stores/DataStore";
import {Menu} from "react-data-grid-addons";
import Modal from "components/modal";
import ModalStore from "stores/ModalStore";
import {Payment} from "../paga/payment";
import {Table} from "components/table/table";
import {generate} from "helper/print";
import { getMoney, money } from 'helper/formatters'

let {ContextMenu, MenuItem} = Menu;

export class Recaudacion extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.linkCheckState = linkCheckState.bind(this);
        this.state = {
            sort: null,
            filter: 0,
            paid: 0,
            selected: [],
            data: [],
            entrego: [],
            from: moment().startOf('month').format("YYYY-MM-DD"),
            to: moment().endOf("month").format("YYYY-MM-DD"),
            selectedOrder: null
        };
        this.search = this.search.bind(this);
    }

    componentWillMount() {
        this.search();
        DataStore.on("ORDERS_UPDATED", this.search);
        DataStore.on("SERVICES_UPDATED", this.search);
    }

    componentWillUnmount() {
        DataStore.removeListener("ORDERS_UPDATED", this.search);
        DataStore.removeListener("SERVICES_UPDATED", this.search);
    }


    search() {
        let data = {
            ...this.state
        };
        data.paid = +data.paid;
        delete data.data;
        ajax(api.get("service/recaudacion", {
            params: data
        }), {action: true})
            .promise
            .then((response) => {
                this.setState({
                    data: response.data
                });
            })
    }


    handleSearch(e) {
        e.preventDefault();
        this.search();
    }

    handleGenerate(e, enable) {
        let data = {
            report: "recaudacion",
            ...this.state
        };
        generate(data,enable)
    }

    markAs(status, e, enable) {
        let ids = this.state.selected;
        ajax(api.post("/order/cobrar_status", {
            ids,
            status
        }), {action: true})
            .promise
            .catch((error) => {
                if (error.response && error.response.status === 403 && error.response.data.error === "LIQUIDATION_DEFINED") {
                    const {order_ids: ids} = error.response.data;
                    const explanation = `No es posible cambiar el estado de ${ids.length > 1 ? "las recaudaciones de los siguientes folios" : "la recaudación en el siguiente folio"} ya que uno o mas viajes pertenece a una liquidación`;
                    AlertStore.addToQueue({
                        text: `${explanation} <br/> <ul>${ids.map(id => `<li>${id}</li>`)}</ul>`,
                        type: "info"
                    })
                }
            })
            .then(enable, enable);
    }

    onRowsSelected(rows) {
        const ids = rows.map(row => row.row.folio);
        this.setState({
            selected: this.state.selected.concat(ids)
        });
    }

    onRowsDeselected(rows) {
        const ids = rows.map(row => row.row.folio);
        this.setState({
            selected: this.state.selected.filter(index => ids.indexOf(index) === -1)
        });
    }

    handleRowUpdate(e) {
        const {rowIdx, updated: {entrego}, key} = e;
        if (key !== "Enter") {
            return;
        }
        if (entrego === "=") {
            const service = this.state.data[rowIdx].viaje;
            const cobro = this.state.data[rowIdx].cobroHidden;
            ajax(api.patch(`service/${service}/recaudado`, {
                import: cobro
            }), {action: true})
                .promise
                .then(() => {
                    this.search();
                })
                .catch((error) => {
                    this.search();
                    if (error.response && error.response.status === 403 && error.response.data.error === "LIQUIDATION_DEFINED") {
                        AlertStore.addToQueue({
                            text: "No es posible editar esta entrada ya que pertenece a una liquidación",
                            type: "info"
                        })
                    }
                });
            return;
        }
        const valid = entrego.match(/^\d*(\.\d+)?$/);
        if (!valid) {
            //hack to prevent dismiss with enter event
            setTimeout(() => {
                AlertStore.addToQueue({
                    text: "El valor introducido debe ser un <em>numero positivo</em>, opcionalmente con decimales",
                    type: "info"
                })
            }, 0);
        } else {
            const service = this.state.data[rowIdx].viaje;
            ajax(api.patch(`service/${service}/recaudado`, {
                import: entrego || 0
            }), {action: true})
                .promise
                .then(() => {
                    this.search();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403 && error.response.data.error === "LIQUIDATION_DEFINED") {
                        AlertStore.addToQueue({
                            text: "No es posible editar esta entrada ya que pertenece a una liquidación",
                            type: "info"
                        })
                    }
                })
        }
    }

    handleGridSort(column, direction) {
        if (direction !== "NONE") {
            this.setState({
                ...this.state,
                sort: {
                    column,
                    direction
                }
            });
        } else {
            this.setState({
                sort: null
            });
        }
    }

    render() {
        let rows = this.state.data.map(s => ({
            salida: date(s.order.salida),
            folio: s.order.id,
            viaje: s.id,
            destino: s.order.destino,
            autobus: s.bus ? s.bus.clave : "N/A",
            operador: s.operator ? s.operator.nombre : "N/A",
            cobro: s.order.cobrar,
            entrego: s.cobrar_returned,
            falta: s.balance.cobrar_balance,
            factura: s.order.con_factura ? "Si" : "No",
            recaudado: s.order.cobrar_paid,
            deposit: s.order.advances.length ? getMoney(s.order.advances_total) : "No"
        }));

        let columns = [
            {
                key: 'recaudado', name: "Recaudado", width: 120, locked: true, formatter: ({value}) => (
                <div className="checkbox-rdg-container">
                    <input type="checkbox" checked={value} value={value} readOnly className="checkbox-rdg-input"/>
                </div>
            )
            },
            {key: 'salida', name: "Salida", width: 100},
            {key: 'folio', name: "Folio", width: 80},
            {key: 'viaje', name: "No. Viaje", width: 110},
            {key: 'destino', name: "Destino", width: 200},
            {key: 'autobus', name: "Autobús", width: 120},
            {key: 'operador', name: "Operador", width: 150},
            {key: 'factura', name: "Factura", width: 100},
            {key: 'deposit', name: "Anticipo", width: 110},
            {key: 'cobro', name: "Cobró", width: 100,formatter: money},
            {key: 'entrego', name: "Entregó", editable: true, width: 100,formatter: money},
            {key: 'falta', name: "Comprobar", width: 130, formatter: money},
        ];

        columns = columns.map((column) => ({sortable: true, ...column}));

        if (this.state.sort) {
            const {column, direction} = this.state.sort;
            rows = sort({
                rows,
                column,
                direction
            });
        }

        const options = [
            {show: "Todos", value: 0},
            {show: "Con factura", value: 1},
            {show: "Sin factura", value: 2}
        ];
        const options2 = [
            {show: "Todos", value: 0},
            {show: "Recaudados", value: 1},
            {show: "No recaudados", value: 2}
        ];

        let parent = this;

        class Menu extends Component {
            static propTypes = {
                rowIdx: PropTypes.number
            };

            handleShowPayments() {
                const {rowIdx} = this.props;
                const {folio} = rows[rowIdx];
                const {order} = parent.state.data.find(d => d.order.id === folio);
                parent.setState({
                    selectedOrder: order
                }, () => {
                    ModalStore.openModal("order-payments");
                });
            }

            handleMarkAs(recolect) {
                const {rowIdx} = this.props;
                const {folio} = rows[rowIdx];
                ajax(api.post("/order/cobrar_status", {
                    ids: [folio],
                    status: recolect
                }), {action: true});
            }

            render() {
                const {rowIdx, id} = this.props;
                let recaudado = rows[rowIdx] ? Boolean(rows[rowIdx].recaudado) : false;
                return (
                    <ContextMenu id={id}>
                        <MenuItem onClick={this.handleShowPayments.bind(this)}>Ver historial de pagos</MenuItem>
                        {recaudado ? (
                            <MenuItem onClick={this.handleMarkAs.bind(this, false)}>Marcar como no recaudado</MenuItem>
                        ) : (
                            <MenuItem onClick={this.handleMarkAs.bind(this, true)}>Marcar como recaudado</MenuItem>
                        )}
                    </ContextMenu>
                )
            }
        }

        return (
            <div>
                <Modal id={"order-payments"} grid={[12, 8]}>
                    <Group>
                        <Payment payments={this.state.selectedOrder ? this.state.selectedOrder.payments : []}/>
                    </Group>
                </Modal>
                <Group title={"Reporte de recaudación"} handleForm={this.handleSearch.bind(this)}
                       style={{marginBottom: "1em"}}>
                    <Input type="date" label="Inicio del reporte" state={this.linkState("from")} required/>
                    <Input type="date" label="Fin del reporte" state={this.linkState("to")} required
                           extra={{min: this.state.from}}/>
                    <Input type="select" label="Recaudado" options={options2} state={this.linkState("paid")} required/>
                    <Input type="select" label="Factura" options={options} state={this.linkState("filter")} required/>
                    <Input type="submit" text="Abrir" button={{color: "success"}}/>
                    <Input type="button" text="Generar reporte" button={{color: "warning"}}
                           click={this.handleGenerate.bind(this)}/>
                </Group>
                <Group margin={true} title={"Acciones"}>
                    <Input type="button"
                           text={"Marcar como recaudado"}
                           button={{color: "success"}}
                           click={this.markAs.bind(this, true)}
                           grid={[12, 6, 3]}
                           disabled={this.state.selected.length === 0}
                    />
                    <Input type="button"
                           text={"Marcar como no recaudado"}
                           className={"large-offset-6"}
                           button={{color: "alert"}}
                           click={this.markAs.bind(this, false)}
                           disabled={this.state.selected.length === 0}
                           grid={[12, 6, 3]}
                    />
                </Group>
                <Table title={"Servicios a recaudar"} columns={columns} data={rows} rowKey="folio" filterable={false}
                       extra={{
                           contextMenu: <Menu/>,
                           contextMenuId: "recaudaciones-row",
                           emptyRowsView: () => <em style={{display: "block", margin: "1em 0", textAlign: "center"}}>No
                               hay datos para mostrar</em>,
                           onRowUpdated: this.handleRowUpdate.bind(this),
                           onGridSort: this.handleGridSort.bind(this),
                           enableCellSelect: true,
                           rowSelection: {
                               onRowsSelected: this.onRowsSelected.bind(this),
                               onRowsDeselected: this.onRowsDeselected.bind(this),
                               showCheckbox: true,
                               enableShiftSelect: true,
                               selectBy: {
                                   keys: {
                                       rowKey: "folio",
                                       values: this.state.selected
                                   }
                               }
                           }
                       }}/>
            </div>
        );
    }
}
