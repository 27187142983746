import React, {
  Component
} from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/table/table'
import { api } from '../../../helper/rest'
import ajax from '../../../helper/ajax'
import linkState, { linkCheckState } from '../../../helper/state'
import DataStore from '../../../stores/DataStore'
import AlertStore from '../../../stores/AlertStore'
import { boolean } from '../../../helper/formatters'
import { Redirect } from 'react-router-dom'
import { Menu } from 'react-data-grid-addons'

let {ContextMenu, MenuItem} = Menu

export class ListaRutas extends Component {
  static propTypes = {}
  static contextTypes = {
    router: PropTypes.object.isRequired
  }
  static defaultProps = {}

  constructor (props, context) {
    super(props, context)
    this.linkState = linkState.bind(this)
    this.linkCheckState = linkCheckState.bind(this)
    this.initialState = {
      data: DataStore.getModel('routes'),
      from: '',
      ruta: '0',
      to: '',
      date: '',
      type: '0',
      automatic: false,
      time: '00:00',
      redirectToEdit: null
    }
    this.state = {...this.initialState}
    this.dataListener = DataStore.listenChanges('routes', () => {
      this.setState({
        data: DataStore.getModel('routes')
      })
    })
  }

  getConfig () {
    (this.configRequest = ajax(api.get('configs/1')))
      .promise
      .then(({data}) => {
        this.setState({...data.value})
      })
  }

  componentWillMount () {
    this.dataListener.listen()
    this.getConfig()
  }

  componentWillUnmount () {
    this.dataListener.stop()
    this.configRequest.cancel()
  }

  render () {
    if (this.state.redirectToEdit) {
      return (
        <Redirect to={`/app/ordenes/rutas/editar/${this.state.redirectToEdit}`}/>
      )
    }

    let columns = [
      {key: 'name', name: 'Nombre'},
      {key: 'client', name: 'Cliente', width: 150},
      {key: 'destination', name: 'Destino', width: 150},
      {key: 'vehicle', name: 'Vehículo', width: 100},
      {key: 'units', name: 'Unidades', width: 100},
      {key: 'departs_at', name: 'Salida', width: 100},
      {key: 'arrives_at', name: 'Regreso', width: 100},
      {key: 'days', name: 'Fecha', width: 100},
      {key: 'active', name: 'Activo', formatter: boolean, width: 100}
    ]
    let rows = this.state.data ? this.state.data.map(({
                                                        id,
                                                        name,
                                                        client: {
                                                          nombre: client
                                                        },
                                                        destination,
                                                        vehicle: {
                                                          submarca: vehicle
                                                        },
                                                        units,
                                                        departs_at,
                                                        arrives_at,
                                                        arrives_after,
                                                        active
                                                      }) => ({
      id,
      name,
      client,
      destination,
      vehicle,
      arrives_at,
      departs_at,
      units,
      days: parseInt(arrives_after) === 0 ? 'El mismo dia' : `${arrives_after} dia(s)`,
      active
  })) :
    []

    const parent = this

    class Menu extends Component {
      static propTypes = {
        rowIdx: PropTypes.number
      }

      handleEdit () {
        let {id} = rows[this.props.rowIdx]
        parent.setState({
          redirectToEdit: id
        })
      }

      handleStateSwitch () {
        let {id, name, active} = rows[this.props.rowIdx]
        AlertStore.addToQueue({
          text: `Desea ${active ? 'desactivar' : 'activar'} la ruta <b>${name}</b>?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#da3116',
          confirmButtonText: `${active ? 'Desactivar' : 'Activar'}`,
          cancelButtonText: 'Cancelar'
        }, {
          confirm: () => {
            ajax(api.patch(`routes/${id}/switch`), {
              action: true, success: {
                text: `Ruta <b>${name}</b> ${active ? 'desactivada' : 'activada'}`
              }
            })
          }
        })
      }

      handleDelete () {
        let {id, name} = rows[this.props.rowIdx]
        AlertStore.addToQueue({
          text: `Desea eliminar la ruta <b>${name}</b>?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#da3116',
          confirmButtonText: 'Eliminar',
          cancelButtonText: 'Cancelar'
        }, {
          confirm: () => {
            ajax(api.delete(`routes/${id}`), {
              action: true, success: {
                text: `Ruta <b>${name}</b> eliminada`
              }
            })
          }
        })
      }

      render () {
        let {rowIdx, id} = this.props
        let active = false
        if (rowIdx >= 0 && rows[rowIdx]) {
          let {active: state} = rows[rowIdx]
          active = state
        }
        return (
          <ContextMenu id={id}>
            {/*<MenuItem onClick={this.handleEdit.bind(this)}>Editar</MenuItem>*/}
            <MenuItem
              onClick={this.handleStateSwitch.bind(this)}>{active ? 'Desactivar' : 'Activar'}</MenuItem>
            <MenuItem onClick={this.handleDelete.bind(this)}>Eliminar</MenuItem>
          </ContextMenu>
        )
      }
    }

    return (
      <Table title={'Lista de rutas creadas'}
             columns={columns}
             data={rows}
             rowKey={'id'}
             extra={{
               contextMenu: <Menu/>,
               contextMenuId: 'lista-row'
             }}/>
    )
  }
}
