// @flow

import DataGrid from "react-data-grid";
import Group from "components/forms/group";
import React, {Component} from "react";
import {ReadOnly} from "../forms/input";
import {check, listenChange, removeListener} from "helper/fullscreen"
import {getRem} from "helper/getRem";
import {debounce} from "helper/debounce";
const { Toolbar, Filters: { NumericFilter, SingleSelectFilter }, Data: { Selectors } } = require('react-data-grid-addons');

export class Table extends Component {
    static props: {
        title: string,
        columns: Array,
        data: Array,
        minHeight: number,
        rowKey: string,
        footer: Object,
        sort: Function,
        extra: Object,
        sortable: boolean,
        filterable: boolean
    };

    constructor() {
        super();
        this.state = {
            fullscreen: check(),
            container: 0,
            filters: {}
        };
        this.switchFullscreenState = this.switchFullscreenState.bind(this);
    }

    switchFullscreenState(e) {
        this.setState({
            fullscreen: check()
        });
    }

    //Start React data grid filters

    rowGetter = (rows) => {
        return (index) => {
            return Selectors.getRows({rows, filters: this.state.filters})[index];
        }
    };

    rowsCount = (rows) => {
        return Selectors.getRows({rows, filters: this.state.filters}).length;
    };

    handleFilterChange = (filter) => {
        console.log(filter);
        let newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        this.setState({filters: newFilters});
    };

    getValidFilterValues = (data) => {
        return (columnId) => {
            let values = data.map(r => r[columnId]).filter(x => x !== null);
            return values.filter((item, i, a) => {
                return i === a.indexOf(item);
            });
        }
    };

    handleOnClearFilters = () => {
        this.setState({filters: {}});
    };

    //End React data grid filters

    componentDidMount() {
        console.log('mounting table');
        listenChange(this.switchFullscreenState);
        this.updateContainerHeight();
        this._resize = debounce(() => {
            this.updateContainerHeight();
        }, 100);
        window.addEventListener("resize", this._resize);
    }

    componentWillUnmount() {
        removeListener(this.switchFullscreenState);
        window.removeEventListener("resize", this._resize)
    }

    updateContainerHeight() {
        console.log({
            client: document.body.clientHeight,
            top: this._container.getBoundingClientRect().top
        });
        let container;
        if (check()) {
            container = document.body.clientHeight
        } else {
            container = (document.body.clientHeight - this._container.getBoundingClientRect().top) - getRem(3.5)
        }
        this.setState({container});
    }

    detectColumnUsesNumbers (data, column) {
        const limit = data.length;
        const threshold = limit * 0.50;
        const values = data.map(row => row[column.key]);
        let numberCount = 0;
        for (let i = 0; i < limit; i++) {
            const isNumber = typeof values[i] === 'number';
            if (isNumber) {
                numberCount++
            }
            console.log({numberCount, threshold, compare: numberCount > threshold});
            if (numberCount > threshold) {
                // exit early if reaches threshold
                break
            }
        }
        console.log((numberCount > threshold));
        return numberCount > threshold
    }

    detectBasicColumnUsesNumber ({key}) {
        const usesNumbers = [
            'id',
            'order_id',
            'unidades',
            'costo_unitario',
            'sale',
            'tax',
            'total',
            'payment',
            'balance',
            'year',
            'sits'
        ];
        return usesNumbers.includes(key)
    }

    detectBasicUnsupportedColumn({key}) {
        const unsopported = [
            'created_at',
            'con_factura',
            'date',
            'salida',
            'regreso',
            'symbolic',
            'active',
            'ingreso',
            'rfc',
            'email',
            'codigo_postal'
        ];
        return unsopported.includes(key)
    }

    render() {
        let {
            title,
            columns,
            data,
            rowKey = "id",
            footer = {},
            sort = undefined,
            sortable = false,
            filterable = true,
            extra = {}
        } = this.props;
        data = data ? data : [];
        if (sortable === true) {
            columns = columns.map(column => ({...column, sortable: true}))
        }

        columns = columns.map((column) => {
            if (!this.detectBasicUnsupportedColumn(column)) {
                return {
                    ...column,
                    filterable: true,
                    filterRenderer: (this.detectBasicColumnUsesNumber(column) ? NumericFilter : SingleSelectFilter)
                }
            } else {
                return column
            }
        });

        return (
            <div ref={(c) => this._container = c}>
                <Group title={title} fullscreen={true} margin={!check()}>
                    <DataGrid columns={columns}
                              enableCellSelect={true}
                              rowGetter={this.rowGetter(data)}
                              rowsCount={this.rowsCount(data)}
                              minHeight={this.state.container}
                              rowKey={rowKey}
                              onGridSort={sort}
                              toolbar={<Toolbar enableFilter={filterable} filterRowsButtonText={'Filtrar columnas'}/>}
                              onAddFilter={this.handleFilterChange}
                              getValidFilterValues={this.getValidFilterValues(data)}
                              onClearFilters={this.handleOnClearFilters}
                              {...extra}
                    />
                </Group>
                {Object.keys(footer).length ? (
                    <Group title="Resultados">
                        {Object.keys(footer).map((name) => (
                            <ReadOnly key={name} type={"text"} {...{
                                value: footer[name],
                                label: name
                            }}/>
                        ))}
                    </Group>
                ) : null}
            </div>
        )
    }
}
