import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Input} from "../../../components/forms/input.jsx";
import linkState from "../../../helper/state";
import {api} from "../../../helper/rest";
import ajax from "../../../helper/ajax";
import AlertStore from "../../../stores/AlertStore";
import ModalStore from "../../../stores/ModalStore";

export class FormGastosRegresados extends Component {
	static propTypes = {
		id: PropTypes.number.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.state = {
			import: ""
		};
	}

	componentWillMount() {
		if (ModalStore.data) {
			this.setState({
				import: ModalStore.data.expenses_return || ""
			});
		}
	}


	submitForm(e) {
		e.preventDefault();
		ajax(api.patch(`/service/${this.props.id}/gastos/regresados`, this.state), {action: true})
			.promise
			.then((response) => {
				if (response.data.updated) {
					AlertStore.addToQueue({
						text: "Gastos de viaje regresados actualizados",
						type: "success"
					})
				} else {
					AlertStore.addToQueue({
						text: "Gastos de viaje regresados no se ha actualizado",
						type: "info"
					})
				}
			})
	}

	render() {
		return (
			<Group grid={12} title="Gastos de viaje devueltos" handleForm={this.submitForm.bind(this)}>
				<Input grid={9} type="number" label="Cantidad" state={this.linkState("import")}/>
				<Input grid={3} type="submit" button={{color: "success"}} value="Guardar"/>
			</Group>
		);
	}
}
