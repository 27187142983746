import React, {
    Component,
} from 'react';

import {Table} from "components/table/table";
import moment from "moment";
import Group from "components/forms/group";
import {Input} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState, {linkCheckState} from "helper/state";
import DataStore from "stores/DataStore";
import {date, money, getMoney} from "helper/formatters";
import {sort} from "helper/sort";
import {generate} from "helper/print";

const FILTER = {
    CLIENT: 'client',
    SELLER: 'seller'
};

export class ReporteVentas extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.linkCheckState = linkCheckState.bind(this);
        this.state = {
            client: "",
            clientId: "",
            seller: "",
            sellers: DataStore.getModelAutoUpdate(["sellers"], this),
            data: [],
            factura: "0",
            filter: FILTER.SELLER,
            from: moment().startOf('month').format("YYYY-MM-DD"),
            to: moment().endOf("month").format("YYYY-MM-DD"),
            sort: null
        };
        this.search = this.search.bind(this);
    }

    componentWillMount() {
        DataStore.on("ORDERS_UPDATED", this.search);
        DataStore.on("SERVICES_UPDATED", this.search);
    }

    componentWillUnmount() {
        DataStore.removeListener("ORDERS_UPDATED", this.search);
        DataStore.removeListener("SERVICES_UPDATED", this.search);
    }


    handleSearch(e) {
        e.preventDefault();
        this.search();
    }

    handleGenerate(e, enable) {
        let {filter, seller: seller_id, clientId: client_id, from, to} = this.state;
        let ids = this.state.data.map(o => o.id);
        let data = {
            report: "venta",
            from,
            to,
            filter,
            seller_id,
            client_id,
            ids,
        };
        generate(data,enable)
    }

    async search() {
        const {filter, from, to, client, seller} = this.state;
        let params = {
            filter,
            from,
            to,
            name: client,
            id: seller,
            trashed: '2'
        };
        try {
            let {data} = await ajax(api.get('order', {params}), {action: true}, true);
            console.log(data);
            this.setState({data});
        } catch (e) {
            throw e
        }
    }

    handleGridSort(column, direction) {
        if (direction !== "NONE") {
            this.setState({
                sort: {
                    column,
                    direction
                }
            });
        } else {
            this.setState({
                sort: null
            });
        }
    }

    render() {
        let data = this.state.data
            .map(({
                      id,
                      salida,
                      created_at,
                      client: {
                          nombre: client_name
                      },
                      destino,
                      balance: {
                          sale
                      }
                  }) => ({
                id,
                salida,
                created_at,
                client_name,
                destino,
                sale
            }));
        if (this.state.sort) {
            const {column, direction} = this.state.sort;
            data = sort({
                rows: data,
                column,
                direction
            });
            console.log(data);
        }
        let columns = [
            {key: 'id', name: "Folio"},
            {key: 'created_at', name: "Venta", formatter: date},
            {key: 'salida', name: "Salida", formatter: date},
            {key: 'client_name', name: "Cliente"},
            {key: 'destino', name: "Destino"},
            {key: 'sale', name: "Venta", formatter: money},
        ];
        const options = [
            {show: "Todos", value: 0},
            {show: "Con factura", value: 1},
            {show: "Sin factura", value: 2}
        ];
        const filters = [
            {show: "Vendedor", value: FILTER.SELLER},
            {show: "Cliente", value: FILTER.CLIENT}
        ];
        const grid = 3;
        const sellers = this.state.sellers.map(({name, id}) => ({show: name, value: id}));
        return (
            <div>
                <Group title={"Reporte de ventas"} handleForm={this.handleSearch.bind(this)}
                       margin={true}
                >
                    <Input type="select"
                           grid={3}
                           label={"Filtro"}
                           state={this.linkState("filter")}
                           required options={filters}
                    />
                    {this.state.filter === FILTER.CLIENT ? (
                        <Input type="autocomplete"
                               grid={grid}
                               label={"Cliente"}
                               state={this.linkState("client")}
                               autocomplete={{
                                   index: "clients",
                                   displayKey: "nombre",
                                   keyName: "nombre",
                                   onSelect: (e, {nombre: client, id}) => this.setState({client, clientId: id})
                               }}
                        />
                    ) : null}
                    {this.state.filter === FILTER.SELLER ? (
                        <Input type="select"
                               grid={grid}
                               label={"Vendedor"}
                               state={this.linkState("seller")}
                               required options={sellers}
                        />
                    ) : null}
                    <Input grid={3} type="date" label="Inicio del reporte" state={this.linkState("from")} required/>
                    <Input grid={3} type="date" label="Fin del reporte" state={this.linkState("to")} required/>
                    <Input grid={4} type="select" label="Factura" options={options} state={this.linkState("factura")}
                           required/>
                    <Input grid={4} type="submit" text="Abrir" button={{color: "success"}}/>
                    <Input grid={4} type="button" text="Generar reporte" button={{color: "warning"}}
                           disabled={!this.state.data.length}
                           click={this.handleGenerate.bind(this)}/>
                </Group>
                <Table title={"Ventas al cliente"} {...{
                    sortable: true,
                    sort: this.handleGridSort.bind(this),
                    columns,
                    data,
                    footer: {
                        Ordenes: this.state.data.length,
                        Unidades: this.state.data.reduce((carry, order) => carry + order.unidades, 0),
                        Venta: getMoney(this.state.data.reduce((carry, order) => carry + parseFloat(order.balance.sale), 0))
                    }
                }}/>
            </div>
        );
    }
}
