import React, {
    Component
} from 'react';
import PropTypes from "prop-types";

import Group from "components/forms/group";
import {Table} from "components/table/table";
import {Input} from "components/forms/input.jsx";
import {api} from "helper/rest";
import ajax from "helper/ajax";
import linkState from "helper/state";
import {date, money, boolean} from "helper/formatters";
import AlertStore from "stores/AlertStore";
import {sort} from "helper/sort";
import {TODAY} from "helper/const";
import {generate} from "helper/print";
import { Menu } from "react-data-grid-addons";
let {ContextMenu, MenuItem} = Menu;

export class Pagos extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.linkState = linkState.bind(this);
        this.initialState = {
            filter: "order",
            confirmed: "0",
            deposit: "0",
            factura: "0",
            client: "",
            client_id: "",
            autocompleted: false,
            date: "",
            from: "",
            to: "",
            order_id: "",
            data: null,
            sort: null
        };
        this.state = {...this.initialState};
    }

    handleGridSort(column, direction) {
        if (direction !== "NONE") {
            this.setState({
                ...this.state,
                sort: {
                    column,
                    direction
                }
            });
        } else {
            this.setState({
                sort: null
            });
        }
    }

    componentDidUpdate(prevProps, prev) {
        function compareProperties({prev, current, properties, cb}) {
            if (properties.find(prop => prev[prop] !== current[prop])) {
                cb();
            }
        }

        if (this.state.data !== null) {
            compareProperties({
                prev,
                current: this.state,
                properties: [
                    "confirmed",
                    "deposit",
                    "factura"
                ],
                cb: () => this.handleOpen()
            });
        }
    }

    handleOpen(e) {
        if (e) {
            e.preventDefault();
        }
        let {
            filter,
            order_id,
            date,
            from,
            to,
            client_id,
            confirmed,
            deposit,
            factura
        } = this.state;
        let params = {
            filter,
            confirmed,
            deposit,
            factura
        };
        switch (filter) {
            case "order":
                params = {
                    ...params,
                    order_id
                };
                break;
            case "date":
                params = {
                    ...params,
                    date
                };
                break;
            case "date:range:created_at":
            case "date:range:salida":
                params = {
                    ...params,
                    from,
                    to
                };
                break;
            case "client":
                params = {
                    ...params,
                    client_id,
                    from,
                    to
                };
                break;
            default:
                // nah
        }
        ajax(api.get("payments", {params}), {action: true}, true).then(({data}) => this.setState({data}));
    }

    getReport(e, enable) {
        const ids = this._current_sort.map(({id}) => id);
        const {from, to, filter, client_id, order_id, date, confirmed} = this.state;
        let data = {
            report: "pagos",
            ids,
            confirmed,
            filter
        };
        switch (filter) {
            case "order":
                data = {
                    ...data,
                    order_id
                };
                break;
            case "date":
                data = {
                    ...data,
                    date
                };
                break;
            case "client":
                data = {
                    ...data,
                    client_id,
                    from,
                    to
                };
                break;
            case "date:range:created_at":
            case "date:range:salida":
                data = {
                    ...data,
                    from,
                    to
                };
                break;
        }
        generate(data, enable);
    }

    render() {
        let columns = [
            {key: "id", name: "ID", width: 75},
            {key: "created_at", name: "Creado", formatter: date, width: 100},
            {key: "client", name: "Cliente", resizable: true, width: 200},
            {key: "order_id", name: "Folio", width: 80},
            {key: "destino", name: "Destino", resizable: true, width: 220},
            {key: "salida", name: "Salida", formatter: date, width: 100},
            {key: "payment_type", name: "Tipo", width: 100},
            {key: "reference", name: "Referencia", width: 120},
            {key: "amount", name: "Importe", formatter: money, width: 110},
            {key: "sale", name: "Venta", formatter: money, width: 110},
            {key: "factura", name: "Factura", formatter: boolean, width: 90},
            {key: "deposit", name: "Anticipo", formatter: boolean, width: 110},
            {
                key: "confirmed",
                name: "Confirmado",
                formatter: ({value}) => value === null ? <span>No</span> : date({value}),
                width: 120
            },
        ];

        columns = columns.map(column => ({sortable: true, ...column}));

        let rows = this.rows = this.state.data ? this.state.data.map(({
                                                                          id,
                                                                          created_at,
                                                                          order: {
                                                                              client: {
                                                                                  nombre: client
                                                                              },
                                                                              balance: {
                                                                                  sale
                                                                              },
                                                                              con_factura: factura,
                                                                              destino,
                                                                              salida,
                                                                              id: order_id
                                                                          },
                                                                          payment_type: {
                                                                              type: payment_type
                                                                          },
                                                                          deposit,
                                                                          reference,
                                                                          confirmed,
                                                                          ...rest
                                                                      }) => ({
            id,
            created_at,
            client,
            destino,
            salida,
            order_id,
            payment_type,
            reference,
            confirmed,
            deposit,
            sale,
            factura,
            amount: rest.import
        })) : [];

        if (this.state.sort) {
            const {column, direction} = this.state.sort;
            rows = sort({rows, column, direction});
        }

        this._current_sort = rows;

        let filters = [
            {show: "Cliente", value: "client"},
            {show: "Folio", value: "order"},
            {show: "Fecha", value: "date"},
            {show: "Rango de fechas - Creado", value: "date:range:created_at"},
            {show: "Rango de fechas - Salida", value: "date:range:salida"},
        ].sort(({show}) => show);
        let search = null;
        let search_grid = [8];
        switch (this.state.filter) {
            case "order":
                search = (
                    <Input type="number"
                           label="Folio"
                           state={this.linkState("order_id")}
                           extra={{min: 1, step: 1}}
                           grid={search_grid}
                           required
                    />
                );
                break;
            case "client":
                search = (
                    <Group group={false} grid={search_grid}>
                        <Input type="autocomplete"
                               label={"Cliente"}
                               state={this.linkState("client", {
                                   onChange: (e) => {
                                       if (this.client_temp !== null && e.target.value !== this.client_temp) {
                                           this.setState({autocompleted: false, client_id: null});
                                           this.client_temp = null;
                                       }
                                       return e.target.value;
                                   }
                               })}
                               required
                               getInput={(ref) => this._clientInput = ref}
                               autocomplete={{
                                   index: "clients",
                                   displayKey: "nombre",
                                   keyName: "nombre",
                                   onSelect: (e, {nombre: client, id: client_id}) => {
                                       this.client_temp = client;
                                       this.setState({client, client_id, autocompleted: true});
                                   }
                               }}
                        />
                        <Input type="date"
                               label="Inicio"
                               state={this.linkState("from")}
                               required
                        />
                        <Input type="date"
                               label="Fin"
                               state={this.linkState("to")}
                               extra={{min: this.state.from}}
                               required
                        />
                    </Group>
                );
                break;
            case "date":
                search = (
                    <Input type="date"
                           label="Fecha"
                           state={this.linkState("order_id")}
                           extra={{min: 1, step: 1}}
                           grid={search_grid}
                           required
                    />
                );
                break;
            case "date:range:created_at":
            case "date:range:salida":
                search = (
                    <Group group={false} grid={search_grid}>
                        <Input type="date"
                               label="Inicio"
                               state={this.linkState("from")}
                               required
                        />
                        <Input type="date"
                               label="Fin"
                               state={this.linkState("to")}
                               extra={{min: this.state.from}}
                               required
                        />
                    </Group>
                );
                break;
        }
        if (this._clientInput) {
            if (!this.state.autocompleted) {
                if (this.state.client === "") {
                    this._clientInput.setCustomValidity("");
                } else {
                    this._clientInput.setCustomValidity("Nombre de cliente incompleto");
                }
            } else {
                this._clientInput.setCustomValidity("");
            }
        }


        const parent = this;

        class Menu extends Component {
            static propTypes = {
                rowIdx: PropTypes.number
            };

            handleDelete() {
                const {rowIdx} = this.props;
                const id = rows[rowIdx].id;
                AlertStore.addToQueue({
                    title: "Desea eliminar este pago?",
                    text: `No podrá revertir esta acción`,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "No",
                    showCancelButton: true,
                    type: "warning"
                }, {
                    confirm: () => {
                        ajax(api.delete(`/payments/${id}`), {success: {text: "Pago eliminado"}})
                            .promise
                            .then(() => {
                                parent.handleOpen();
                            })
                    }
                })
            }

            handleConfirm() {
                const {rowIdx} = this.props;
                const id = rows[rowIdx].id;

                AlertStore.addToQueue({
                    title: "Seleccionar fecha de confirmación de pago",
                    text: `<input type="date" required="required" value="${TODAY}" id="paymentDate" style="width: 50%; margin: 0 auto;">`,
                    confirmButtonText: "Guardar",
                    confirmButtonColor: '#3adb76',
                    preConfirm: function () {
                        if (document.querySelector("#paymentDate").reportValidity()) {
                            return Promise.resolve();
                        } else {
                            return Promise.reject();
                        }
                    },
                }, {
                    confirm: () => {
                        ajax(api.patch(`/payments/${id}/confirmed`, {
                            date: document.querySelector("#paymentDate").value
                        }), {success: {text: `Pago confirmado`}, action: true}, true)
                            .then(() => {
                                parent.handleOpen();
                            })
                    }
                })
            }

            handleConfirmUpdate() {
                const {rowIdx} = this.props;
                const id = rows[rowIdx].id;
                const confirmed = rows[rowIdx].confirmed;
                console.log(rows[rowIdx]);
                AlertStore.addToQueue({
                    title: "Seleccionar fecha de confirmación de pago",
                    text: `<input type="date" required="required" value="${confirmed.split(" ")[0]}" id="paymentDate" style="width: 50%; margin: 0 auto;">`,
                    confirmButtonText: "Guardar",
                    confirmButtonColor: '#3adb76',
                    preConfirm: function () {
                        if (document.querySelector("#paymentDate").reportValidity()) {
                            return Promise.resolve();
                        } else {
                            return Promise.reject();
                        }
                    },
                }, {
                    confirm: () => {
                        ajax(api.patch(`/payments/${id}/confirmed`, {
                            date: document.querySelector("#paymentDate").value
                        }), {success: {text: `Pago actualizado`}, action: true}, true)
                            .then(() => {
                                parent.handleOpen();
                            })
                    }
                })
            }

            handleUnconfirm() {
                const {rowIdx} = this.props;
                const id = rows[rowIdx].id;
                AlertStore.addToQueue({
                    title: `Desea remover la confirmación de este pago?`,
                    confirmButtonText: "Si",
                    confirmButtonColor: '#3adb76',
                    cancelButtonText: "No",
                    showCancelButton: true,
                    type: "warning"
                }, {
                    confirm: () => {
                        ajax(api.delete(`/payments/${id}/confirmed`), {
                            success: {
                                text: `Confirmación removida del pago`
                            },
                            action: true
                        }, true)
                            .then(() => {
                                parent.handleOpen();
                            })
                    }
                })
            }

            render() {
                const {rowIdx, id} = this.props;
                let confirmed = rows[rowIdx] ? Boolean(rows[rowIdx].confirmed) : false;
                return (
                    <ContextMenu id={id}>
                        {
                            confirmed ? (
                                <MenuItem onClick={this.handleUnconfirm.bind(this)}>Remover confirmación</MenuItem>
                            ) : (
                                <MenuItem onClick={this.handleConfirm.bind(this)}>Confirmar</MenuItem>
                            )
                        }
                        {confirmed ? (
                            <MenuItem onClick={this.handleConfirmUpdate.bind(this)}>Cambiar confirmación</MenuItem>
                        ) : null}
                        <MenuItem onClick={this.handleDelete.bind(this)}>Eliminar</MenuItem>
                    </ContextMenu>
                );
            }
        }

        return (
            <div>
                <Group title={"Reporte de pagos"} handleForm={this.handleOpen.bind(this)} margin>
                    <Input type="select"
                           label="Filtro"
                           options={filters}
                           state={this.linkState("filter")}
                           grid={4}
                    />
                    {search}
                    <Input type="select"
                           label="Anticipos"
                           options={[
                               {show: "Todos", value: "0"},
                               {show: "Solo anticipos", value: "1"},
                               {show: "Sin anticipos", value: "2"},
                           ]}
                           state={this.linkState("deposit")}
                           grid={4}
                    />
                    <Input type="select"
                           label="Confirmado"
                           options={[
                               {show: "Todos", value: "0"},
                               {show: "Confirmado", value: "1"},
                               {show: "Sin confirmar", value: "2"},
                           ]}
                           state={this.linkState("confirmed")}
                           grid={4}
                    />
                    <Input type="select"
                           label="Factura"
                           options={[
                               {show: "Todos", value: "0"},
                               {show: "Con factura", value: "1"},
                               {show: "Sin factura", value: "2"},
                           ]}
                           state={this.linkState("factura")}
                           grid={4}
                    />
                    <Input type="button"
                           text="Generar reporte"
                           button={{color: "warning"}}
                           click={this.getReport.bind(this)}
                           disabled={!this.state.data}
                           grid={4}
                    />
                    <Input type="button"
                           button={{color: "success"}}
                           text="Pantalla completa"
                           click={(e, enable) => {
                               enable();
                               let element = this.refs.table;
                               let requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;
                               if (requestFullScreen) {
                                   requestFullScreen.call(element);
                               }
                           }}
                           grid={4}
                    />
                    <Input type="submit"
                           button={{color: "success"}}
                           text={this.state.data ? "Actualizar" : "Abrir"}
                           grid={4}
                    />
                </Group>
                <Table title={"Ordenes"} columns={columns} data={rows} rowKey={"id"}
                       extra={{
                           onGridSort: this.handleGridSort.bind(this),
                           contextMenu: <Menu/>,
                           contextMenuId: "pagos-row"
                       }}/>
            </div>
        );
    }
}
