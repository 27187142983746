import React from "react";
import Registro from "./registro/registro";
import Group from "../../components/forms/group";
import ajax from "../../helper/ajax";
import linkState from "../../helper/state";
import {Input} from "../../components/forms/input.jsx";
import AlertStore from "../../stores/AlertStore";
import {api} from "../../helper/rest";
import queryString from "query-string";

export class Modifica extends React.Component {

	constructor () {
		super();
		this.linkState = linkState.bind(this);
		this.state = {
			fill: null,
			folio: ""
		};
		this.handleSearch = this.handleSearch.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	componentWillMount () {
		console.log(this.props);
		let query = queryString.parse(this.props.location.search);
		if ("id" in query) {
			const {id} = query;
			this.setState({folio: id}, () => {
				this.search(true)
			});
		}
	}

	componentDidUpdate (prevProps,{folio}) {
		//1st case: coming from search to empty search

		//2nd case: coming from empty search to one with id

		//but havent been touched
		let {folio: folio2} = this.state;
		const locationChanged = prevProps.location.search !== this.props.location.search;
		if (locationChanged && folio === folio2) {
			if (this.props.location.search === "") {
				this.setState({fill: null, folio: ""});
			} else {
				let query = queryString.parse(this.props.location.search);
				if ("id" in query) {
					const {id} = query;
					this.setState({folio: id}, () => {
						this.search(true)
					});
				}
			}
		}
	}

	handleCancel (e, enable) {
		enable();
		this.props.history.push({
			search: ""
		})
	}

	handleSearch (e) {
		e.preventDefault();
		this.search(true);
	}

	search (action: bool = false) {
		let error = [];
		error[404] = {
			text: "El folio no existe",
		};
		ajax(api.get(`order/${this.state.folio}`), {action, error})
			.promise
			.then((response) => {
				if (response.data.deleted_at) {
					AlertStore.addToQueue({
						text: "El folio que intenta abrir ha sido cancelado",
						type: "info"
					});
					return;
				}
				if (response.data.services_liquidated) {
					AlertStore.addToQueue({
						text: `El folio no puede ser editado ya que uno o mas de sus viajes han sido liquidados`,
						type: "info"
					});
					return;
				}
				this.setState({fill: response.data}, () => {
					this.props.history.push({
						search: `?id=${this.state.folio}`
					})
				});
			})
	}

	render () {
		return (
			<div>
				<Group form={true} handleForm={this.handleSearch} grid={[12]} title={"Modificar folio"}
					   style={{marginBottom: "1em"}}>
					<Input type="number"
						   state={this.linkState("folio")}
						   label="Folio"
						   grid={4}
						   readOnly={this.state.fill !== null}
						   required
						   autoFocus={true}
					/>
					<Input type="submit"
						   label=" "
						   text="Abrir"
						   grid={4}
						   button={{color: "success"}}
					/>
					<Input type="button"
						   text="Cerrar"
						   grid={4}
						   button={{color: "alert"}}
						   disabled={!this.state.fill}
						   click={this.handleCancel}
					/>
				</Group>
				{this.state.fill !== null ? (
					<Group grid={[12]} group={false}>
						<Group grid={[12]} title={"Datos de registro y edicion"} style={{marginBottom: "1em"}}>
							<Input type="number"
								   label="Folio"
								   grid={true}
								   state={{value: this.state.fill.id}}
								   readOnly={true}/>
							<Input type="date"
								   label="Fecha de captura"
								   grid={true}
								   state={{value: this.state.fill.created_at.slice(0, 10)}}
								   readOnly={true}/>
							<Input type="text"
								   label="Capturante"
								   grid={true}
								   state={{value: this.state.fill.register.name}}
								   readOnly={true}/>
							<Input type={this.state.fill.updated_at === this.state.fill.created_at ? "text" : "date"}
								   label="Ultima Edición"
								   grid={true}
								   state={{value: this.state.fill.updated_at === this.state.fill.created_at ? "Ninguna" : this.state.fill.updated_at.slice(0, 10)}}
								   readOnly={true}/>
							<Input type="text"
								   label="Editor"
								   grid={true}
								   state={{value: this.state.fill.editor ? this.state.fill.editor.name : "Ninguno"}}
								   readOnly={true}/>
						</Group>
						<Registro key={this.state.fill.id} fill={this.state.fill} reload={this.search.bind(this)}/>
					</Group>
				) : null}
			</div>
		);
	}
}