import "./assign.scss";
import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Input, ReadOnly} from "../../../components/forms/input.jsx";

export class Asignador extends Component {
	static propTypes = {
		operators: PropTypes.array.isRequired,
		buses: PropTypes.array.isRequired,
		services: PropTypes.array.isRequired,
		order: PropTypes.object.isRequired,
		displayTitle: PropTypes.any.isRequired,
		update: PropTypes.any.isRequired
	};
	static defaultProps = {
		services: [],
		buses: [],
		operators: []
	};


	componentWillUpdate(nextProps, nextState) {
		this.props.update(nextState.services);
	}


	constructor(props, context) {
		super(props, context);
		this.state = this.initialState = {
			services: props.services.map(({operator, bus}) => ({
				operator_id: operator ? String(operator.id) : "",
				bus_id: bus ? String(bus.id) : ""
			}))
		};
	}

	assignBus(index, value) {
		//copy parent
		let services = [...this.state.services];
		//copy child
		services[index] = {...services[index]};
		//set child value
		services[index].bus_id = value;
		//try to auto complete operator
		let {buses} = this.props;
		//get bus
		let bus = buses.find(bus => bus.id === parseInt(value));
		//check if bus is symbolic
		if (bus && bus.symbolic) {
			//remove assignation
			services[index].operator_id = "";
		}
		//check if bus has operator assign
		if (bus && bus.operator) {
			//assign
			services[index].operator_id = bus.operator.id;
		}
		//update
		this.setState({services});
	}

	assignOp(index, value) {
		let services = [...this.state.services];
		services[index] = {...services[index]};
		services[index].operator_id = value;
		this.setState({services});
	}

	handleFreeBuses() {
		const {buses} = this.props;
		const usedBuses = this.state.services.map(s => s.bus_id);
		return [{show: "--- Sin Asignar ---", value: ""}].concat(buses.map((bus) => {
			let b = {
				value: bus.id,
				show: bus.full_name,
				disabled: false
			};
			if (usedBuses.find(ubus => ubus === bus.id) && !bus.symbolic) {
				b.disabled = true;
			}
			return b;
		}));
	}

	handleFreeOperators() {
		const {operators} = this.props;
		const usedOps = this.state.services.map(s => s.operator_id);
		return [{show: "--- Sin Asignar ---", value: ""}].concat(operators.map((op) => {
			let o = {
				value: op.id,
				show: op.nombre,
				disabled: false
			};
			if (usedOps.find(uop => uop === op.id)) {
				o.disabled = true;
			}
			return o;
		}));
	}

	render() {
		const {services, displayTitle, order} = this.props;
		const specialStateBus = (i) => {
			return {
				onChange: ({target: {value}}) => {
					this.assignBus(i, value);
				},
				value: this.state.services[i].bus_id
			}
		};
		const specialStateOp = (i) => {
			return {
				onChange: ({target: {value}}) => {
					this.assignOp(i, value);
				},
				value: this.state.services[i].operator_id
			}
		};
		return (
			<Group group={false} grid={12} title={displayTitle} className={`assign-order ${displayTitle ? "first" : ""}`}>
				<Group group={false} grid={12} className={"assign-order-child"}>
					<Group group={false}>
						<ReadOnly grid={[12,4]}
						          type="text"
						          label="Cliente"
						          state={{value: order.client.nombre}}
						/>
						<ReadOnly grid={[12,4]}
						          type="text"
						          label="Destino"
						          state={{value: order.destino}}
						/>
						<ReadOnly grid={[12,4]}
						          type="text"
						          label="Presentarse"
						          state={{value: order.presentarse}}
						/>
					</Group>
					{services.map(({id}, i) => (
						<Group grid={12} group={false} key={i}>
							<ReadOnly grid={2}
							          type="number"
							          label="Folio de orden"
							          state={{value: order.id}}
							/>
							<Input grid={4} type="select" label="Autobús" options={this.handleFreeBuses()}
							       state={specialStateBus(i)}
							       required={false}
							/>
							<Input grid={4} type="select" label="Operador" options={this.handleFreeOperators()}
							       state={specialStateOp(i)}
							       required={false}
							/>
							<ReadOnly type="text"
							          grid={2}
							          label="No. Viaje"
							          value={id}
							/>
						</Group>
					))}
				</Group>
			</Group>
		);
	}
}
