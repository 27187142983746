import React, {
	Component
} from 'react';
import PropTypes from "prop-types"
import Group from "../../../components/forms/group";
import {Asignador} from "./asignador";
import date from "../../../helper/date";

const FOLIO = "0";
const RANGO_FECHAS = "1";
const FECHA = "2";

export class AssignInputs extends Component {
	static propTypes = {
		parent: PropTypes.any.isRequired,
	};
	static defaultProps = {};

	constructor(props, context) {
		super(props, context);
		this.assign = [];
	}


	render() {
		const updateAssign = (id,i = 0) => {
			return (services) => {
				this.assign[i] = {
					id,
					services
				};
			}
		};
		const {
			parent: {
				state: {
					buses,
					data,
					operators,
					filter
				}
			}
		} = this.props;
		switch (filter) {
			case FOLIO:
				return (
					<Group group={false} grid={12}>
						<Asignador buses={buses}
						           order={data}
						           displayTitle={date(data.salida)}
						           operators={operators}
						           services={data.buses}
						           update={updateAssign(data.id)}
						/>
					</Group>
				);
			case RANGO_FECHAS:
			case FECHA:
				let dateBuffer = "";
				return (
					<Group group={false} grid={12}>
						{data.map((order,i) => {
							let title = "";
							let currentDate = date(order.salida);
							if (dateBuffer !== currentDate) {
								//new date
								title = currentDate;
								dateBuffer = currentDate;
							} else {
								//its the same
								title = "";
							}
							return (
								<Asignador key={order.id}
								           order={order}
								           buses={buses}
								           operators={operators}
								           services={order.buses}
								           displayTitle={title}
								           update={updateAssign(order.id,i)}
								/>
							);
						})}
					</Group>
				);
			default:
				console.error(`Invalid option for SearchInputs component, received: ${filter}`);
				return null;
		}
	}
}
