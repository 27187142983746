import React, {Component} from "react";

import {
    BrowserRouter as Router,
    Switch, Route, Redirect
} from 'react-router-dom';

import {AppRoutes, ActionRoutes} from "../menus/menu";

import {Authenticator} from "components/authenticator";

import {ErrorPage} from "../pages/errorPage";

import AuthStore from "stores/AuthStore";

import PermissionStore from "stores/PermissionStore";

const mapRoutes = (links, parentLink = "") => {
    if (!Array.isArray(links)) {
        throw new Error("The first variable should be an array of endpoints");
    }
    return links.reduce((carrie, {url, to, component, links, redirects, role, permission, hide}) => {
        if (hide) {
            return carrie;
        }
        let elements = [];
        let link = parentLink ? `${parentLink}/${url || to}` : url || to;
        if (redirects) {
            elements.push(<Route path={link} exact key={link + "REDIRECT"} render={() => (
                <Redirect to={redirects}/>
            )}/>);
        }
        if (PermissionStore.can(role, permission)) {
            if (component) {
                elements.push(<Route path={link} key={link} exact component={component}/>);
            }
            if (links && Array.isArray(links) && links.length > 0) {
                elements = elements.concat(mapRoutes(links, link));
            }
        } else {
            // console.log(link);
        }
        return carrie.concat(elements);
    }, [])
};

export class Root extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: null
        };
        this.getUser = this.getUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentWillMount() {
        AuthStore.on("CONNECTED", this.getUser);
        AuthStore.on("LOGIN_SCREEN", this.removeUser);
    }

    componentWillUnmount() {
        AuthStore.removeListener("CONNECTED", this.getUser);
        AuthStore.removeListener("LOGIN_SCREEN", this.removeUser);
    }

    getUser() {
        console.log('GETTING USER FOR ROOT');
        this.setState({
            user: AuthStore.getUser()
        });
    }

    removeUser() {
        this.setState({
            user: null
        });
    }


    render() {
        const App = mapRoutes(AppRoutes, "/app");
        const Act = mapRoutes(ActionRoutes);
        const key = this.state.user ? this.state.user.id : 'guest';
        console.log(App);
        return (
            <Router>
                <Switch>
                    <Route path="/" exact render={() => (
                        <Redirect to="/app/home"/>
                    )}/>
                    {Act}
                    <Route path={"/app"} key={key} render={(props) => (
                        <Authenticator {...props}>
                            <Switch>
                                {App}
                                <Route component={ErrorPage}/>
                            </Switch>
                        </Authenticator>
                    )}/>
                    <Route component={ErrorPage}/>
                </Switch>
            </Router>
        )
    }
}
