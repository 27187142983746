import queryString from "query-string";
import EventEmitter2 from "eventemitter2";

export class RegisterMatchParam extends EventEmitter2 {
	constructor (component,parameter) {
		super();
		this.component = component;
		this.parameter = parameter;
		let holdOriginal;
		if (component.componentWillMount) {
			holdOriginal = component.componentWillMount.bind(component);
		}
		component.componentWillMount = () => {
			console.log(component.props);
			let query = queryString.parse(component.props.location.search);
			if (parameter in query) {
				this.emit("RECEIVED", query[parameter]);
			}
			if (holdOriginal) {
				holdOriginal();
			}
		};

		let holdOriginal2;
		if (component.componentDidUpdate) {
			holdOriginal2 = component.componentDidUpdate.bind(component);
		}
		component.componentDidUpdate = (prevProps) => {
			const locationChanged = prevProps.location.search !== component.props.location.search;
			if (locationChanged) {
				if (component.props.location.search === "") {
					this.emit("CLEARED");
				} else {
					let query = queryString.parse(component.props.location.search);
					if (parameter in query) {
						this.emit("RECEIVED", query[parameter]);
					}
				}
				if (holdOriginal2) {
					holdOriginal2();
				}
			}
		}
	}
	clear () {
		this.component.props.history.push({
			search: ""
		});
	}
	assign (value) {
		this.component.props.history.push({
			search: `?${this.parameter}=${value}`
		})
	}
}