import "./accessCard.scss";
import {Link} from "react-router-dom";


import React, {
    Component
} from 'react';
import PropTypes from "prop-types";


import Group from "components/forms/group";

export class AccessCard extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired
    };
    static defaultProps = {};

    render() {
        return (
            <div className="access-card-parent columns small-6 medium-3 large-2">
                <Group group={true} title={this.props.title} className={"access-card"}
                       rowClassName={"align-center align-middle"}>
                    <Link to={this.props.link}>
                        <div className="columns small-12">
                            <this.props.icon/>
                        </div>
                    </Link>
                </Group>
            </div>
        );
    }
}
