import React, {
	Component,
} from 'react';

import {FormOperator} from "./formOperator";
import {Catalog} from"components/catalog/catalog";


export class Operadores extends Component {
	render() {
		return (
			<Catalog columns={[
				{key: "clave", name: "Clave", width: 80},
				{key: "activo", name: "Activo",width: 80, formatter: ({value}) => (
					<div className="checkbox-rdg-container">
						<input type="checkbox" checked={value} value={value} readOnly className="checkbox-rdg-input"/>
					</div>
				)},
				{key: "nombre", name: "Nombre", width: 180},
				{key: "telefono", name: "Teléfono",width: 150},
				{key: "direccion", name: "Dirección", width: 450},
				{key: "ingreso", name: "Ingreso"},
			]}
					 identifier="nombre"
					 keys={["nombre","direccion","telefono"]}
					 singular={"Operador"}
					 model={"operators"}
					 name={"Operadores"}>
				<FormOperator/>
			</Catalog>
		);
	}
}
