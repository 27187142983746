/* global google */

import React, {
	Component,
} from 'react';

//initial state :D
//ajax requests :D
//add maps
//inputs
//form handle
//name
import {api} from"helper/rest";
import {Group, Input, ReadOnly} from"components/inputs";
import linkState from"helper/state";
import ajax from"helper/ajax";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";

export class FormClient extends Component {

	constructor() {
		super();
		this.linkState = linkState.bind(this);
		this.initialState = {
			"id": "",
			"nombre": "",
			"telefono": "",
			"calle": "",
			"colonia": "",
			"delegacion": "",
			"codigo_postal": "",
			"rfc": "",
			"email": "",
			"firma": "",
			"location": ""
		};
		this.state = {...this.initialState};
		this.map = {};
		this.autocomplete = {};
	}


	fill(data) {
		this.filling = true;
		this.id = data.id;
		this.setState(data);
	}

	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
	}

	componentDidMount() {
		let map = this.setupMap("cat-client");
		this.setupAutocomplete("cat-client-input");
		this.setBoundsToAutocomplete("cat-client-input");
		this.bindAutocompleteToMap("cat-client", "cat-client-input");
		this.enableMarkers("cat-client", "cat-client-input");

		if (this.filling  && this.state.location) {
			google.maps.event.addListenerOnce(map, 'idle', () => {
				const { lat, lng } = this.state.location.geometry.location;
				let center = new google.maps.LatLng(lat,lng);
				new google.maps.Marker({
					map,
					position: this.state.location.geometry.location
				});
				this.map["cat-client"].setCenter(center);
				this.map["cat-client"].setZoom(17);
			});
		}

		this.autocomplete["cat-client-input"].addListener("place_changed", () => {

			var place = this.autocomplete["cat-client-input"].getPlace();

			if (!place.geometry) {
				return;
			}

			let findType = (type) => {
				let result = place.address_components.find(comp => comp.types.find(types => types === type));
				if (result) {
					return result
				} else {
					AlertStore.addToQueue({
						text: "No se pudo procesar la direccion correctamente",
						type: "error",
						allowEscapeKey: false,
						allowOutsideClick: false
					});
					throw new Error("address type couldn't be found");
				}
			};
			if (this.state.autocompletedClient && !this.state.clientEditable) {
				AlertStore.addToQueue({
					text: "No puede cambiar la dirección mientras no habilite la edición",
					type: "info",
					allowEscapeKey: false,
					allowOutsideClick: false
				});
				return
			}
			//set data returned from get place
			if (place.types.find(types => types === "street_address")) {
				let calle = findType("route").long_name;
				let numero = findType("street_number").long_name;
				let codigo_postal = findType("postal_code").long_name;
				let colonia = findType("sublocality_level_1").long_name;
				let delegacion = findType("administrative_area_level_3").long_name;
				let lat = place.geometry.location.lat();
				let lng = place.geometry.location.lng();
				this.setState({
					calle: `${calle} #${numero}`,
					colonia,
					delegacion,
					codigo_postal,
					lat,
					lng,
					location: JSON.stringify(place)
				});
			} else {
				AlertStore.addToQueue({
					text: "La búsqueda necesita ser mas especifica",
					type: "info",
					allowEscapeKey: false,
					allowOutsideClick: false
				});
			}
		});
	}

	handleForm(e) {
		e.preventDefault();
		let data = {...this.state};
		delete data.last_editor_id;
		delete data.id;
		if (this.state.rfc === "") {
			data.rfc = null;
		}
		if (this.state.email === "") {
			data.email = null;
		}
		if (this.state.location === "") {
			delete data.location;
		}
		if (this.state.codigo_postal === "") {
			delete data.location;
		}

        const handlesErrors = (error) => {
			if (!error.response) return;
			if (!error.response.data) return;
            const data = error.response.data.errors;
            let errorBag = [];
            if (data.codigo_postal && data.codigo_postal[0] === "The codigo postal must be a number.") {
                errorBag.push("El código postal debe ser un numero.")
            }
            if (data.email && data.email[0] === "The email must be a valid email address.") {
                errorBag.push("El correo electrónico debe ser valido.")
            }
            if (data.email && data.email[0] === "UNIQUE") {
                errorBag.push("El correo electrónico ya esta en uso por otro cliente.")
            }
            if (data.rfc && data.rfc[0] === "UNIQUE") {
                errorBag.push("El RFC ya esta en uso por otro cliente.")
            }
            if (errorBag.length > 0) {
                AlertStore.addToQueue({
                    text: `<ul>${errorBag.map(error => `<li>${error}</li>`).join()}</ul>`,
                    type: "info"
                })
            }
        };

		if (this.filling) {
			ajax(api.patch(`/clients/${this.id}`, data), {
				action: true,
				success: {
					text: `Cliente ${this.id} actualizado`
				}
			},true).catch(handlesErrors)
		} else {
			ajax(api.post("/clients", data), {
				action: true,
				success: {
					text: "Cliente guardado"
				}
			})
				.promise
				.then(() => {
					this.setState(this.initialState);
				})
                .catch(handlesErrors)
		}
	}

	setAsClient(e) {
		if (e.target.value === "=") {
			return this.state.nombre;
		} else {
			return e.target.value;
		}
	}

	setupMap(mapId) {
		return this.map[mapId] = new google.maps.Map(document.getElementById(mapId), {
			center: {
				"lat": 19.4326077,
				"lng": -99.133208
			},
			zoom: 13
		});
	}

	setupAutocomplete(inputId) {
		return this.autocomplete[inputId] = new google.maps.places.Autocomplete(
			document.getElementById(inputId),
			{
				types: ['geocode'],
				componentRestrictions: {country: "mx"}
			}
		);
	}

	bindAutocompleteToMap(mapId, inputId) {
		this.map[mapId].controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById(inputId));
		this.autocomplete[inputId].bindTo('bounds', this.map[mapId]);
	}

	setBoundsToAutocomplete(inputId) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				this.geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				this.geolocationCircle = new google.maps.Circle({
					center: this.geolocation,
					radius: position.coords.accuracy
				});
				this.autocomplete[inputId].setBounds(this.geolocationCircle.getBounds());
			});
		}
	}

	enableMarkers(mapId, inputId) {
		var infowindow = new google.maps.InfoWindow();
		var marker = new google.maps.Marker({
			map: this.map[mapId],
			anchorPoint: new google.maps.Point(0, -29)
		});

		this.autocomplete[inputId].addListener('place_changed', () => {
			infowindow.close();
			marker.setVisible(false);
			var place = this.autocomplete[inputId].getPlace();
			if (!place.geometry) {
				return;
			}
			// If the place has a geometry, then present it on a map.
			if (place.geometry.viewport) {
				this.map[mapId].fitBounds(place.geometry.viewport);
			} else {
				this.map[mapId].setCenter(place.geometry.location);
				this.map[mapId].setZoom(17);
			}
			marker.setIcon(/** @type {google.maps.Icon} */({
				url: place.icon,
				size: new google.maps.Size(71, 71),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(17, 34),
				scaledSize: new google.maps.Size(35, 35)
			}));
			marker.setPosition(place.geometry.location);
			marker.setVisible(true);

			var address = '';
			if (place.address_components) {
				address = [
					(place.address_components[0] && place.address_components[0].short_name || ''),
					(place.address_components[1] && place.address_components[1].short_name || ''),
					(place.address_components[2] && place.address_components[2].short_name || '')
				].join(' ');
			}

			infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
			infowindow.open(this.map[mapId], marker);
		});
	}

	handleDelete(e, enable) {
		AlertStore.addToQueue({
			title: 'Desea eliminar este cliente?',
			text: 'Esta acción solo la puede revertir un administrador',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}, {
			confirm: () => {
				let success = {
					title: "Cliente eliminado",
					text: `#${this.id}`
				};
				let error = [];
				error[0] = {
					title: "El cliente no fue eliminado",
					text: `#${this.id}`
				};
				ajax(api.delete(`/client/${this.state.id}`), {action: true, success, error})
					.promise
					.then(() => {
						ModalStore.closeModal();
					})
					.then(enable,enable)
			},
			dismiss: () => {
				enable();
			}
		})
	}

	render() {
		return (
			<Group handleForm={this.handleForm.bind(this)} title={`${this.filling ? "Editar" : "Agregar"} cliente`} className={"limit-height"}>
				{this.filling ? (
					<ReadOnly type="text"
					          label="Ultimo Editor"
					          value={"N/A"}
					          grid={6}
					/>
				) : null}

				{this.filling ? (
					<ReadOnly type="number"
					          label="ID"
					          state={this.linkState("id")}
					          required={false}
					          grid={6}
					/>
				) : null}
				<Input state={this.linkState("nombre")}
				       label="Nombre"
				       type="text"
				       grid={4}
				/>
				<Input state={this.linkState("telefono")}
				       label="Teléfono"
				       type="tel"
				       grid={4}
				/>
				<Input state={this.linkState("rfc")}
				       label="RFC"
				       type="text"
				       grid={4}
				       required={false}
				/>
				<Input state={this.linkState("calle")}
				       label="Calle(s)"
				       type="text"
				       grid={[12, 4]}
				/>
				<Input state={this.linkState("colonia")}
				       label="Colonia"
				       type="text"
				       grid={[12, 3]}
				/>
				<Input state={this.linkState("delegacion")}
				       label="Delegación"
				       type="text"
				       grid={[6, 3]}
				/>
				<Input state={this.linkState("codigo_postal")}
				       label="Código Postal"
				       type="number"
				       required={false}
				       grid={[6, 2]}
				/>
				<Input state={this.linkState("firma", {onChange: this.setAsClient.bind(this)})}
				       type="text"
				       label="Nombre del contratante" grid={[12.6]}
				/>
				<Input state={this.linkState("email")}
				       type="email"
				       label="E-mail" grid={[12,6]}
				       required={false}
				/>
				<div id="cat-client" className="map"></div>
				<input id="cat-client-input" className={"controls"}
				       type="text"
				       placeholder="Introducir dirección"
				/>
				<Input grid={true} type="submit" text="Guardar" button={{color: "success"}} label={false}/>
				{this.filling ? (
					<Input type="button" text="Eliminar" button={{color: "alert"}} label={false} click={this.handleDelete.bind(this)}/>
				) : null}
			</Group>
		);
	}
}
