//style
//libs
import React, {Component} from 'react'
import PropTypes from 'prop-types'
//components
import Group from 'components/forms/group'
import { Input } from 'components/forms/input'
import AlertStore from 'stores/AlertStore'
import Modal from 'components/modal'
import { FormValeGastos } from './formValeGastos'
import { FormGastosComprobados } from './formGastosComprobados'
import { FormGastosRegresados } from './formGastosRegresados'
import GastosInfo from './gastosInfo'
//stores
import ModalStore from 'stores/ModalStore'
import DataStore from 'stores/DataStore'
//helpers
import linkState from 'helper/state'
//api
import { api } from 'helper/rest'
import ajax from 'helper/ajax'

import DataGrid from 'react-data-grid'
import date from 'helper/date'
import { getMoney } from 'helper/formatters'

export class Gastos extends Component {
  constructor (props) {
    super(props)
    this.linkState = linkState.bind(this)
    this.state = this.initialState = {
      service: '',
      data: null,
      selected: [],
      selected2: []
    }
    this.channel = null
    this.handlesServiceForm = this.handlesServiceForm.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.requestExpenses = this.requestExpenses.bind(this)
  }

  componentWillMount () {
    if (this.props.location.query && this.props.location.query.id) {
      const service = this.props.location.query.id
      this.setState({service}, () => this.requestExpenses(true, true))
    }
  }

  requestExpenses (action = false, initial = false) {
    if (initial) {
      this.channel = `App.Service.${this.state.service}`
      let connection = DataStore.getChannel({
        priv: true,
        name: this.channel
      })

      let events = [
        'ServiceDepositChange',
        'ServiceExpenseChange',
        'ServiceChange',
        'ServiceOrderCobrarAmountChange'
      ]

      events.forEach(event => connection.listen(event, this.requestExpenses))
    }
    ajax(api.get(`/service/${this.state.service}`), {
      action, error: {
        '404': {
          text: `El numero de viaje "${this.state.service}" no existe`,
          type: 'info'
        }
      }
    })
      .promise
      .then((response) => {
        if (response.data.has_liquidation) {
          AlertStore.addToQueue({
            text: 'Este viaje pertenece a una liquidación y se encuentra en modo <em>solo lectura</em>',
            type: 'info'
          })
        }
        this.setState({
          data: response.data,
          selected2: [],
          selected: []
        })
      })
      .catch((error) => {
        if (error.response && error.response.status === 406 && error.response.data.error === 23505) {
          AlertStore.addToQueue('', 'Ya existe un gasto para esta combinación de datos', 'error')
        }
      })
  }

  handlesServiceForm (e) {
    e.preventDefault() // Let's stop this event.
    this.requestExpenses(true, true)
  }

  handleClose (e, enable) {
    DataStore.closeChannel(this.channel)
    this.channel = null
    this.setState(this.initialState, enable)
  }

  onRowsSelected (selection) {
    return (rows) => {
      const ids = rows.map(row => row.row.folio)
      this.setState({
        [selection]: this.state[selection].concat(ids)
      })
    }
  }

  onRowsDeselected (selection) {
    return (rows) => {
      const ids = rows.map(row => row.row.folio)
      this.setState({
        [selection]: this.state[selection].filter(index => ids.indexOf(index) === -1)
      })
    }
  }

  handleDeleteVales (e, enable) {
    AlertStore.addToQueue({
      showCancelButton: true,
      confirmButtonColor: '#da3116',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      title: `Eliminar ${this.state.selected2.length > 1 ? 'vales' : 'vale'} para gastos de viaje`,
      type: 'info',
      text: `
¿Desea eliminar ${this.state.selected2.length > 1 ? 'los siguientes vales' : 'el siguiente vale'} para gastos de viaje?
<br>
	${this.state.selected2.map(s => {
        return `<em>> ${s}</em><br>`
      }).join(' ')}
`
    }, {
      confirm: () => {
        ajax(api.delete(`/service/${this.state.service}/gastos/llevados`, {
          data: {
            ids: this.state.selected2
          }
        }), {
          action: true,
          success: {text: `${this.state.selected2.length > 1 ? 'Vales' : 'vale'} para gastos de viaje ${this.state.selected2.length > 1 ? 'eliminados' : 'eliminado'}`}
        })
          .promise
          .then(() => {
            this.requestExpenses()
          })
        enable()
      }, dismiss: () => {
        enable()
      }
    })

  }

  handleDeleteGastos (e, enable) {
    AlertStore.addToQueue({
      showCancelButton: true,
      confirmButtonColor: '#da3116',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      title: `Eliminar ${this.state.selected.length > 1 ? 'vales' : 'vale'} de gastos comprobados`,
      type: 'info',
      text: `
¿Desea eliminar ${this.state.selected.length > 1 ? 'los siguientes vales' : 'el siguiente vale'} de gastos comprobados?
<br>
	${this.state.selected.map(s => {
        return `<em>> ${s}</em><br>`
      }).join(' ')}
`
    }, {
      confirm: () => {
        ajax(api.delete(`/service/${this.state.service}/expense`, {
          data: {
            ids: this.state.selected
          }
        }), {
          action: true,
          success: {text: `${this.state.selected.length > 1 ? 'Vales' : 'Vale'} de gastos comprobados ${this.state.selected.length > 1 ? 'eliminados' : 'eliminado'}`}
        })
          .promise
          .then(() => {
            this.requestExpenses()
          })
        enable()
      }, dismiss: () => {
        enable()
      }
    })
    enable()
  }

  handleEditVales (e, enable) {
    const edit = this.state.data.deposits.find(deposit => deposit.id == this.state.selected2[0])
    ModalStore.openModal('gastos-llevados', edit)
    enable()
  }

  handleEditGastos (e, enable) {
    const edit = this.state.data.expenses_declared.find(deposit => deposit.id == this.state.selected[0])
    console.log(edit)
    ModalStore.openModal('gastos-utilizados', edit)
    enable()
  }

  render () {
    const isReadOnly = this.state.data && this.state.data.has_liquidation
    const data = this.state.data && this.state.data.expenses_declared.map(ed => ({
      folio: ed.id,
      fecha: date(ed.date),
      tipoDeGasto: ed.expense_type.type,
      referencia: `${ed.reference_type} ${ed.reference}`,
      empresaExterna: ed.external_business.name,
      importe: getMoney(ed.import),
      comment: ed.comment
    }))
    const data2 = this.state.data && this.state.data.deposits.map(de => ({
      folio: de.id,
      fecha: date(de.date),
      tipoDeDeposito: de.deposit_type.type,
      importe: getMoney(de.import),
      comment: de.comment
    }))

    let columns = [
      {key: 'folio', name: '#', width: 50},
      {key: 'fecha', name: 'Fecha'},
      {key: 'tipoDeGasto', name: 'Tipo'},
      {key: 'referencia', name: 'Referencia'},
      {key: 'empresaExterna', name: 'Empresa'},
      {key: 'importe', name: 'Importe'},
      {key: 'comment', name: 'Descripción'}
    ]

    let columns2 = [
      {key: 'folio', name: '#', width: 50},
      {key: 'fecha', name: 'Fecha'},
      {key: 'tipoDeDeposito', name: 'Tipo'},
      {key: 'importe', name: 'Importe'},
      {key: 'comment', name: 'Descripción'}
    ]

    return (
      <div>
        <Modal id="editar" grid={[12, 6, 4]}>
          <Group>
            <Input type={'button'}
                   text={'Capturar vale de gastos de viaje'}
                   grid={12}
                   button={{color: 'secondary'}}
                   events={{onClick: () => ModalStore.openModal('gastos-llevados')}}
            />
            <Input type="button"
                   text={'Capturar gastos de viaje comprobados'}
                   grid={12}
                   button={{color: 'secondary'}}
                   events={{onClick: () => ModalStore.openModal('gastos-utilizados')}}
            />
            <Input type={'button'}
                   text={'Capturar gastos de viaje devueltos'}
                   grid={12}
                   button={{color: 'secondary'}}
                   events={{onClick: () => ModalStore.openModal('gastos-regresados', this.state.data)}}
            />
          </Group>
        </Modal>
        <Modal id="gastos-utilizados" grid={[12, 8, 6]}>
          <FormGastosComprobados service={this.state.data}/>
        </Modal>
        <Modal id="gastos-llevados" grid={[12, 8, 6]}>
          <FormValeGastos id={this.state.service}/>
        </Modal>
        <Modal id="gastos-regresados" grid={[12, 8, 6]}>
          <FormGastosRegresados id={this.state.service}/>
        </Modal>
        <Group grid={12} form={true} handleForm={this.handlesServiceForm} title={'Abrir no. de viaje'}
               style={{marginBottom: '1em'}}>
          <Input type="number"
                 label="No. de Viaje"
                 extra={{step: 1, min: 1}}
                 grid={[6, 6, 3]}
                 state={this.linkState('service')}
                 disabled={this.state.data}
                 required
          />
          <Input type="submit"
                 text="Abrir"
                 grid={[6, 6, 2]}
                 button={{color: 'success'}}
                 disabled={this.state.data}
          />
          <Input type="button"
                 text="Cerrar"
                 grid={[6, 4, 2]}
                 button={{color: 'alert'}}
                 click={this.handleClose}
                 disabled={!this.state.data}
          />
          <Input type="button"
                 text="Actualizar"
                 grid={[6, 4, 2]}
                 button={{color: 'success'}}
                 disabled={!this.state.data}
                 click={(e, enable) => {
                   this.requestExpenses()
                   enable()
                 }}
          />
          <Input type="button"
                 text="Guardar vales de viaje"
                 grid={[12, 4, 3]}
                 button={{color: 'warning'}}
                 events={{onClick: () => ModalStore.openModal('editar')}}
                 disabled={!this.state.data || isReadOnly}
          />
        </Group>
        <GastosInfo grid={12} info={this.state.data ? this.state.data : undefined}/>
        <Group title={'Vale para gastos de viaje'}/>
        <div className="group">
          <DataGrid columns={columns2}
                    rowGetter={(i) => data2[i]}
                    rowsCount={data2 ? data2.length : 0}
                    minHeight={200}
                    rowKey="folio"
                    rowSelection={{
                      onRowsSelected: this.onRowsSelected('selected2'),
                      onRowsDeselected: this.onRowsDeselected('selected2'),
                      showCheckbox: true,
                      enableShiftSelect: true,
                      selectBy: {
                        keys: {
                          rowKey: 'folio',
                          values: this.state.selected2
                        }
                      }
                    }}
                    emptyRowsView={() => <em style={{display: 'block', margin: '1em 0', textAlign: 'center'}}>No hay
                      datos para mostrar</em>}
          />
          <ul style={{padding: '1em'}}>
            <li>Editar solo es valido cuando hay una sola fila seleccionada</li>
          </ul>
          <Group group={false}>
            <Input type="button"
                   label={false}
                   text={'Editar'}
                   button={{color: 'warning'}}
                   grid={[12, 4, 3]}
                   disabled={this.state.selected2.length > 1 || this.state.selected2.length == 0 || isReadOnly}
                   click={this.handleEditVales.bind(this)}
            />
            <div className="columns medium-4 large-6 hide-for-small"></div>
            <Input type="button"
                   text={'Eliminar'}
                   label={false}
                   button={{color: 'alert'}}
                   grid={[12, 4, 3]}
                   disabled={this.state.selected2.length == 0 || isReadOnly}
                   click={this.handleDeleteVales.bind(this)}
            />
          </Group>
        </div>

        <Group title={'Gastos comprobados'} style={{marginTop: '1em'}}/>
        <div className="group">
          <DataGrid columns={columns}
                    rowGetter={(i) => data[i]}
                    rowsCount={data ? data.length : 0}
                    minHeight={400}
                    rowKey="folio"
                    rowSelection={{
                      onRowsSelected: this.onRowsSelected('selected'),
                      onRowsDeselected: this.onRowsDeselected('selected'),
                      showCheckbox: true,
                      enableShiftSelect: true,
                      selectBy: {
                        keys: {
                          rowKey: 'folio',
                          values: this.state.selected
                        }
                      }
                    }}
                    emptyRowsView={() => <em style={{display: 'block', margin: '1em 0', textAlign: 'center'}}>No hay
                      datos para mostrar</em>}
          />
          <ul style={{padding: '1em'}}>
            <li>Editar solo es valido cuando hay una sola fila seleccionada</li>
          </ul>
          <Group group={false}>
            <Input type="button"
                   label={false}
                   text={'Editar'}
                   grid={[12, 4, 3]}
                   button={{color: 'warning'}}
                   disabled={this.state.selected.length > 1 || this.state.selected.length == 0 || isReadOnly}
                   click={this.handleEditGastos.bind(this)}
            />
            <div className="columns medium-4 large-6 hide-for-small"></div>
            <Input type="button"
                   grid={[12, 4, 3]}
                   label={false}
                   text={'Eliminar'}
                   button={{color: 'alert'}}
                   disabled={this.state.selected.length == 0 || isReadOnly}
                   click={this.handleDeleteGastos.bind(this)}
            />
          </Group>
        </div>

      </div>
    )
  }
}

Gastos.contextTypes = {
  router: PropTypes.func.isRequired
}
Gastos.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.string
    })
  })
}
