import React, {
	Component
} from 'react';

import {api} from"helper/rest";
import {Group, Input, ReadOnly} from"components/inputs";
import linkState, {linkCheckState} from"helper/state";
import ajax from"helper/ajax";
import ModalStore from"stores/ModalStore";
import AlertStore from"stores/AlertStore";

export class FormOperator extends Component {

	constructor(props, context) {
		super(props, context);
		this.linkState = linkState.bind(this);
		this.linkCheckState = linkCheckState.bind(this);
		this.initialState = {
			"nombre": "",
			"telefono": "",
			"direccion": "",
			"nacimiento": "",
			"ingreso": "",
			"clave": "",
			"activo": true
		};
		this.state = {...this.initialState};
	}

	componentWillMount() {
		if (ModalStore.data) {
			this.fill(ModalStore.data);
		}
	}

	fill(data) {
		this.filling = true;
		this.id = data.id;
		let filteredData = {...data};
		delete filteredData.buses;
		this.setState(filteredData);
	}

	handleDelete (e,enable) {
		AlertStore.addToQueue({
			title: 'Desea eliminar este operador?',
			text: 'Esta acción solo la puede revertir un administrador',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: "#da3116",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		},{confirm: () => {
			ajax(api.delete(`operators/${this.id}`),{success: {
				title: "Operador eliminado"
			}})
				.promise
				.then(() => {
				    enable();
					ModalStore.closeModal();
				})
		}, dismiss: enable})
	}

	handleForm(e) {
		e.preventDefault();
		let data = {...this.state};
		delete data.egreso;
		if (this.filling) {
			let success = {
				text: `Operador #${this.id} actualizado`
			};
			ajax(api.patch(`/operators/${this.id}`, data), {action: true, success})
		} else {
			let success = {
				text: "Operador guardado"
			};
			ajax(api.post("/operators", data), {action: true, success})
				.promise
				.then(() => {
					this.setState(this.initialState);
				});
		}
	}

	render() {
		return (
			<Group handleForm={this.handleForm.bind(this)} title={`${this.filling ? "Editar" : "Agregar"} operador`}>
				{this.filling ? (
					<ReadOnly type="checkbox"
					          label="Activo"
					          state={this.linkCheckState("activo")}
					          grid={[4,2]}
					          className="text-center"

					/>
				) : null}
				<Input type="text"
					   label="Clave"
					   state={this.linkState("clave")}
					   grid={[8,5]}
					   required={false}
				/>
				<Input type="text"
				       label="Nombre"
				       state={this.linkState("nombre")}
				       grid={[12,5]}
				/>
				<Input type="text"
				       label="Dirección"
				       state={this.linkState("direccion")}
				       grid={[12]}
				/>
				<Input type="text"
				       label="Teléfono"
				       state={this.linkState("telefono")}
				       grid={[12,6]}
				/>

				<Input type="date"
				       label="Fecha de ingreso"
				       state={this.linkState("ingreso")}
				       grid={[12,6]}
				/>

				<Input type="submit"
				       text={"Guardar"}
				       grid={true}
				       button={{color: "success"}}
				       label={true}
				/>
				{this.filling ? (
					<Input type="button"
					       text={"Eliminar"}
					       grid={[6]}
					       button={{color: "alert"}}
					       label={true}
					       click={this.handleDelete.bind(this)}
					/>
				) : null}
			</Group>
		);
	}
}
