//@flow

import LoadingStore from "stores/LoadingStore";
import AlertStore from "stores/AlertStore";
import debug from "misc/debug";

const makeCancelable = (promise) => {
	let hasCanceled_ = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then((val) =>
			hasCanceled_ ? reject({isCanceled: true}) : resolve(val)
		);
		promise.catch((error) =>
			hasCanceled_ ? reject({isCanceled: true}) : reject(error)
		);
	});

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
	};
};

export default function ajax(promise: Promise, config: Object = {action: false, error: null, enable: null}, return_promise: bool = false) {
	const cancelablePromise = makeCancelable(promise);
	const betterPromise = new Promise((resolve, reject)=> {
		if (config.action) {
			LoadingStore.startLoading();
		}
		cancelablePromise
			.promise
			.then((response)=> {
				resolve(response);
				switch (typeof config.success) {
					case 'object':
                        let {title = "", text = "", type = "success"} = config.success;
                        AlertStore.addToQueue({
                            title,
                            text,
                            type
                        });
                        break;
					case 'string':
                        AlertStore.addToQueue({
                            text: config.success,
                            type: 'success'
                        });
				}
			})
			.catch((error)=> {
				if (debug) {
					AlertStore.addToQueue({
						title: "DEBUG",
						text: error.message,
						type: "error"
					});

					if (error.response) {
						let myWin = window.open();
						if (typeof error.response.data === 'object') {
							myWin.document.write(JSON.stringify(error.response.data));
						} else {
							myWin.document.write(error.response.data);
						}
						myWin.focus();
					}
				} else {
					if (window.Raven) {
						window.Raven.captureMessage(error.response.statusText, {
							extra: error
						});
					}
				}
				console.error(error);
				reject(error);
				if (error.response) {
					if (config.error === Object(config.error)) {
						Object.keys(config.error).map(key => {
							if (parseInt(key) === parseInt(error.response.status)) {
								let result;
								switch (typeof config.error[key]) {
									case "function":
                                        result = config.error[key](error.response);
                                        break;
									case "object":
                                        result = config.error[key];
                                        break;
									case "string":
										result = {
											text: config.error[key]
										};
										break;
								}
								let {title = "", text = "", type = "error"} = result;
								AlertStore.addToQueue({
									title,
									text,
									type
								})
							}
						})
					}
					if (Array.isArray(config.error)) {
						config.error.forEach((obj, i)=> {
							if (i === error.response.status) {
								let {title = "", text = "", type = "error"} = obj;
								AlertStore.addToQueue({
									title,
									text,
									type
								});
							}
						})
					}
				} else {
					if (config.error === Object(config.error) && config.error.default) {
						AlertStore.addToQueue(config.error.default);
					}
				}
			})
			.then(()=> {
                if (config.action) {
                    LoadingStore.stopLoading()
                }
                if (config.enable) {
                    config.enable();
                }
            }, ()=> {
                if (config.action) {
                    LoadingStore.stopLoading()
                }
                if (config.enable) {
                    config.enable();
                }
            });
	});
	if (return_promise) {
		return betterPromise
	}
	return {
		promise: betterPromise,
		cancel: cancelablePromise.cancel.bind(cancelablePromise)
	};
}

export const asynx = (promise, config) => ajax(promise,config,true);
